import Store from '../../store.js';

const template = `
	<popup
		class="project-popup"
		width="400"
		@close="close"
		close-on-back="1"
	>
		<div slot="header">{{ ii('PROJECT') }} #{{projId}}</div>
		<div v-if="inited">
			<div class="project-info">
				<div class="project-info-part">
					<div class="project-info-label">{{ ii('WEBSITE') }}:</div>
					<div>
						<outer-link
								:href="project.url"
								v-title="project.url"
								class="static-color"
						>{{ project.domain }}</outer-link>
						<span>(#{{ project.id }})</span>
						<span
							class="link fa fa-edit"
							v-if="checkPermission('edit:project_url')"
							v-title="ii('EDIT_PROJECT_URL')"
							@click="onEditUrlClick"
						></span>
					</div>
				</div>
				<div class="project-info-part" v-if="project.searchEngine">
					<div class="project-info-label">{{ ii('SEARCH') }}:</div>
					<div>
						{{ project.searchEngine.google }}
						<template v-if="project.searchEngine.country">
							- {{ project.searchEngine.country }}
						</template>
						({{ project.searchEngine.language }})
						ID: {{ project.searchEngine.id }}
						<span
							class="link fa fa-edit"
							v-if="checkPermission('edit:project_se')"
							v-title="ii('EDIT_SEARCH_ENGINE')"
							@click="onEditSeClick()"
						></span>
						<template v-if="project.regionSe">
							<span class="m-left5 help" v-title="prettifySeRegion(project.regionSe.canonicalName)">
								{{ ii(project.regionSe.targetType) }}: {{ project.regionSe.name }}
							</span>
							<span
								class="link fa fa-edit"
								v-if="checkPermission('edit:project_se')"
								v-title="ii('EDIT_REGION_SEARCH_ENGINE')"
								@click="onEditSeClick(true)"
							></span>
						</template>
						<span
							v-else-if="checkPermission('edit:project_se')"
							class="link fa fa-plus"
							key="addSeRegion"
							v-title="ii('ADD_REGION_SEARCH_ENGINE')"
							@click="onEditSeClick(true)"
						></span>
					</div>
				</div>
				<div class="project-info-part">	
					<div class="project-info-label">{{ ii('USER') }}:</div>
					<div>
						<span>{{ user.email }}</span>
						<span>(#{{ user.id }})</span>
						<em>{{ user.username }}</em>
						<flag :cc="user.countryCode"></flag>
					</div>				
				</div>
				<div class="project-info-part">
					<div class="project-info-label">{{ ii('AUTHORIZE') }}:</div>
					<div>
						<a :href="project.authorizeUrl" class="fa fa-sign-in static-color" target="_blank"></a>
					</div>
				</div>
				<div class="project-info-part" v-if="project.campaign">
					<div class="project-info-label">{{ project.campaign.plan }}:</div>
					<div>
						<span><span class="lower">{{ ii('START') }}</span>: <strong class="font14">{{ formatDate(project.campaign.startDate, 'D MMM YYYY') }}</strong></span>
						<span>&nbsp;</span>
						<span><span class="lower">{{ ii('END') }}</span>: <strong class="font14">{{ formatDate(project.campaign.endDate, 'D MMM YYYY') }}</strong></span>
					</div>
				</div>
				<div class="project-info-part">
					<div class="project-info-label">{{ ii('CATEGORY') }}:</div>
					<div>
						<multi-sel
							v-model="projectTempCategory"
							:items="getSiteCategoryItems()"
							@change="setCategory"
						></multi-sel>
					</div>
				</div>
			</div>
		</div>
		<div v-else class="project-loading">
			<i class="fa fa-spinner spin-fast"></i>
			{{ ii('LOADING', 'Loading') }}...
		</div>
	</popup>
`;

export default {
	template,
	props: ['projId'],
	data() {
		return {
			inited: false,
			project: null,
			user: null,
			projectTempCategory: null,
		};
	},
	computed: {
		...Store.mapState([
			'siteCategories', 'userTags', 'searchEngines','worker', 'workersMap', 'managersMap', 'geoMap', 'leadMetrics', 'groups'
		])
	},
	methods: {
		close() {
			this.$emit('close');
		},
		async load() {
			let res = await this.get('Site.getProjectInfo', {projectId: this.projId});
			this.projectTempCategory = res.project.category.map(c => c.id).join(',');
			this.project = res.project || {};
			this.user = res.user || {};
		},
		async onEditUrlClick() {
			let newUrl = await this.prompt('Please enter new URL:', this.project.url);
			if (!newUrl) return;

			newUrl = String(newUrl).trim();
			if (!newUrl) return;

			let res = await this.post('Site.editProject', {projectId:  this.project.id, url: newUrl});
			if (!res.success) {
				this.alert(res.errors.join('\n'));
				return;
			}
			this.project = res.project;
		},
		async onEditSeClick(isRegion = false) {
			let inputOpts;
			if (isRegion) {
				inputOpts = {
					type: 'richsel',
					title: this.ii('CHOOSE_SEARCH_ENGINE'),
					val: this.project.se2,
					itemsUrl: '/api/project/region-search-engine-search',
					maxItems: 100,
					searchPlaceholder: this.ii('TYPE_TO_SEARCH'),
					customHandler: (item) => {
						if (item.key === 0) {
							let text = this.ii('NO_SEARCH_ENGINE');
							return {
								key: '0',
								val: text,
								displayVal: text,
								sVal: '0'
							};
						}
						let se = item.se;
						let text = [
							this.ii('GEO_' + se.countryCode) + ',',
							this.ii(se.targetType) + ':',
							se.name,
							'(' + se.id + ')'
						].join(' ');
						item.val = text;
						return item;
					}
				};
			} else {
				inputOpts = {
					type: 'richsel',
					title: this.ii('CHOOSE_SEARCH_ENGINE'),
					val: this.project.se,
					items: this.getSearchEnginesItems()
				};
			}

			let newSe = await this.input(inputOpts);
			if (newSe === null) return;

			let data = {};
			data.projectId = this.project.id;
			data[isRegion ? 'se2' : 'se'] = newSe;

			let res = await this.post('Site.editProject', data);
			if (!res.success) return this.alert(res.errors.join('\n'));

			this.project = res.project;
		},
		async setCategory() {
			if (this.projectTempCategory === this.project.category) return;
			let ok = await this.confirm('Do you really want to change site category?');
			if (ok) {
				await this.post('Site.setSiteCategory', {
					categories: this.projectTempCategory,
					id: this.project.id,
					domain: this.project.domain
				});
				this.load();
			} else {
				this.$set(this, 'projectTempCategory', this.project.category.map(c => c.id).join(','));
			}
		},
		prettifySeRegion(raw) {
			return String(raw || '').replace(/,\s*/g, ', ');
		},
		getTagsSuggestions() {
			return this.userTags.map(e => {
				return {
					val: e,
					key: e
				};
			});
		},
	},
	async mounted() {
		await this.load();
		await this.loadState([
			'siteCategories', 'userTags', 'searchEngines', 'managersMap', 'geoMap', 'leadMetrics', 'groups'
		]);
		this.inited = true;
	}
};
