const template = `
	<div class="activity">
		<div v-if="isLoading" class="loading">
			<span>
				<i class="fa fa-spinner spin-fast center"></i>
				<span class="general-upper">{{ ii('LOADING') }}...</span>
			</span>
		</div>
		<template v-else>
			<div>
				<table class="mc-tbl hover-tbl">
					<thead>
						<tr>
							<th><head-cell-inner no-sticky="1">{{ ii('TIME') }}</head-cell-inner></th>
							<th><head-cell-inner no-sticky="1">{{ ii('ACTION') }}</head-cell-inner></th>
							<th colspan="3">
								<head-cell-inner no-sticky="1">{{ ii('INFORMATION') }}</head-cell-inner>
							</th>
							<th><head-cell-inner no-sticky="1">{{ ii('DEVICE') }}</head-cell-inner></th>
							<th><head-cell-inner no-sticky="1">{{ ii('COUNTRY') }}</head-cell-inner></th>
							<th colspan="2">
								<head-cell-inner no-sticky="1">{{ ii('LANGUAGE') }}</head-cell-inner>
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(row, i) in activity" :key="'activityRow' + i">
							<td><datetime :timestamp="row.datetime"></datetime></td>
							<td>{{ row.action }}</td>
							<td>{{ row.data1 }}</td>
							<td>{{ row.data2 }}</td>
							<td>{{ row.data3 }}</td>
							<td>
								{{ row.ip }}
								<i v-if="row.osClass" :class="row.osClass"></i>
								<i v-if="row.deviceInfo" :class="row.deviceInfo.cls" v-title="row.deviceInfo.title"></i>
							</td>
							<td>
								<template v-if="row.geo">
									<flag :cc="row.geo" no-title="1"></flag>
									{{ getCountryByCode(row.geo) }}
								</template>
							</td>
							<td class="text-center">
								<span
									v-title="ii('USER_LANGUAGE') + ': ' + getLanguageByCode(row.osLang)"
									class="help"
								>{{ row.osLang }}</span>
							</td>
							<td class="text-center">
								<span
									v-title="ii('SITE_LANGUAGE') + ': ' + getLanguageByCode(row.siteLang)"
									class="help"
								>{{ row.siteLang }}</span>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="activity-bottom">
				<btn size="small" v-if="!isNoMore" @click.native="loadMore" :loading="isLoadingMore">
					<i class="fa fa-fw fa-cloud-download" slot="icon"></i>
					{{ ii('LOAD_MORE') }}
				</btn>
			</div>
		</template>
	</div>
`;

export default {
	template,
	data() {
		return {
			isLoading: true,
			isLoadingMore: false,
			isNoMore: false,
			activity: null,
			portion: 0
		};
	},
	props: ['idu', 'partner'],
	methods: {
		async loadActivity() {
			this.portion++;
			let params = {idu: this.idu, portion: this.portion};
			
			let acts = await this.get('UserCard.getActivityInfo', params, this.partner);
			if (!acts.length) {
				this.isNoMore = true;
				return;
			}
			if (!this.activity) {
				this.activity = [];
			}
			acts.forEach(act => {
				act.osClass = this.getOsClass(act.deviceOs);
				act.deviceInfo = this.getDeviceInfo(act.deviceType);
			});
			this.activity = this.activity.concat(acts);
		},
		async loadMore() {
			this.isLoadingMore = true;
			await this.loadActivity();
			this.isLoadingMore = false;
		},
		getOsClass(os) {
			if (!os) return '';
			os = String(os).toLowerCase();
			if (os.indexOf('win') !== -1) return 'fa fa-windows';
			if (os.indexOf('mac') !== -1) return 'fa fa-apple';
			if (os.indexOf('ios') !== -1) return 'fa fa-apple';
			if (os.indexOf('android') !== -1) return 'fa fa-android';
			if (os.indexOf('linux') !== -1) return 'fa fa-linux';
			return '';
		},
		getDeviceInfo(type) {
			if (!type) return;
			if (type === 'mob') return {cls: 'fa fa-mobile', title: this.ii('MOBILE')};
			if (type === 'desktop') return {cls: 'fa fa-desktop', title: this.ii('DESKTOP')};
			if (type === 'tablet') return {cls: 'fa fa-tablet', title: this.ii('TABLET')};
		}
	},
	async mounted() {
		if (this.idu) {
			await this.loadActivity();
		}
		this.isLoading = false;
	}
};