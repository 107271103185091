import Store from '../../store.js';

const template = `
	<popup
		class="user-popup"
		width="700"
		resize="1"
		@scroll="onTblScroll($event)"
		@close="close"
	>
		<div slot="header">{{ ii('USERS') }}</div>
		<div>
			<div v-if="!users" class="users-loading">
				<i class="fa fa-spinner spin-fast"></i> {{ ii('LOADING') }}...
			</div>
			<div class="orders-tbl-wrapper">
				<table class="order-files-tbl" v-show="users">
					<tr>
						<th>#</th>
						<th><span>{{ ii('ID') }}</span></th>
						<th><span>{{ ii('COUNTRY') }}</span></th>
						<th><span>{{ ii('USERNAME') }}</span></th>
						<th><span>{{ ii('MANAGER') }}</span></th>
						<th><span>{{ ii('REG_DATE') }}</span></th>
						<th><span>{{ ii('REF') }}</span></th>
					</tr>
					<tr v-if="sortedUsers && !sortedUsers.length">
						<td colspan="100" class="text-center general-upper">No data</td>
					</tr>
					<tr v-for="(user, i) in sortedUsers">
						<td class="text-center">{{ i + 1 }}</td>
						<td class="text-center">
							<a :href="'/leads?idu=' + user.id">{{ user.id }}</a>
						</td>
						<td>
							<flag :cc="user.countryCode" no-title="1"></flag>
							{{ getCountryByCode(user.countryCode) }}
						</td>
						<td>{{ user.username }}</td>
						<td><worker-tooltip :idm="user.idm">{{ getManager(user.idm).realName }}</worker-tooltip></td>
						<td class="text-center"><datetime :timestamp="user.registeredAt"></datetime></td>
						<td class="text-center">
							<span
								v-if="!user.refIdu"
								v-title="'This user is not an affiliate'"
								class="help"
							>-</span>
							<template v-else-if="checkPermission('affiliate:view')">
								<a
									v-title="'This users is affiliate of ' + user.refIdu"
									:href="'/leads?idu=' + user.refIdu"
									class="help"
								>{{ user.refIdu }}</a>
								<i class="small-sup-btn fa fa-remove" @click="resetAffiliate(user)"></i>
							</template>
							<span v-else v-title="'This users is an affiliate'" class="help">Yes</span>
						</td>
					</tr>
				</table>
			</div>
		</div>
	</popup>
`;

export default {
	template,
	props: ['opts'],
	data() {
		return {
			users: null,
			portion: null,
			sortKey: null,
			sortDesc: false
		};
	},
	computed: {
		...Store.mapState(['managersMap']),
		sortedUsers() {
			if (!this.sortKey || !this.users) return this.users;
			// let key = this.sortKey;
			// let descVal = this.sortDesc ? 1 : -1;
			// let getVal;
			// if (key === 'manager') {
			// 	getVal = el => this.getManager(el.manager).realName + el.id;
			// } else if (key === 'site') {
			// 	getVal = el => el.site && el.site.domain;
			// } else {
			// 	getVal = (el, key) => el[key];
			// }
			// return this.orders.sort((a, b) => {
			// 	let aVal = getVal(a, key);
			// 	let bVal = getVal(b, key);
			//
			// 	if (aVal > bVal) return descVal;
			// 	if (aVal < bVal) return -descVal;
			//
			// 	return 0;
			// });
		}
	},
	methods: {
		close() {
			this.$emit('close');
		},
		onTblScroll(e) {
			if (e.target.scrollTop + e.target.offsetHeight < e.target.scrollHeight) return;
			this.loadUsers({isAppend: true});
		},
		async loadUsers(opts = {}) {
			if (!opts.isAppend) {
				this.portion = 0;
			}
			this.portion++;
			let leads = await this.get('Lead.search', {query: this.opts, portion: this.portion});
			if (opts.isAppend) {
				this.users = this.users.concat(leads);
			} else {
				this.users = leads;
			}
		},
		async resetAffiliate(user) {
			let ok = await this.confirm('Reset affiliate?');
			if (!ok) return;

			let err = await this.post('Lead.resetAffiliate', user.id, this.opts.partner);
			if (err) return this.alert(err);

			this.$set(user, 'refIdu', 0);
		},
		// sort(sort) {
		// 	if (sort === this.sortKey) {
		// 		this.sortDesc = !this.sortDesc;
		// 	} else {
		// 		this.sortKey = sort;
		// 	}
		// }
	},
	mounted() {
		this.loadUsers();
	},
	destroyed() {

	}
};
