const template = `
	<span>
		<span class="user-reminder-box">
			<btn
				v-if="checkPermission('page:reminder')"
				v-title="'Calendar events'"
				size="small"
				:type="hasOverdueEvents(events) ? 'red' : 'primary'"
				:class="areEventsOver(events) ? 'opacity04' : ''"
				@click.native="briefReminderShown = true"
			>
				<i class="fa fa-fw fa-calendar" slot="icon"></i>
			</btn>
			<transition name="fade">
				<div
					class="user-brief-reminder anim-from-top"
					v-if="briefReminderShown"
					v-mousedown-outside="hideUserReminderFn()"
				>
					<div class="user-brief-rem-in">
						<div
							v-for="event in events"
							:class="getBriefEventClass(event)"
							:data-prio="getEventPrioritySlug(event.prio)"
							@click="showEvent(event)"
						>
							<span
								class="event-prio"
								v-title="'Priority: ' + getEventPriorityText(event.prio)"
							></span>
							<span class="user-brief-event-time">
								{{ event.remindAtTime | tsFormat('MMM DD, HH:mm') }}
							</span>
							<span>{{ event.comment }}</span>
						</div>
						<div v-if="!events || !events.length" class="user-brief-no-event">
							<em>No events, yet</em>
						</div>
						<div class="user-brief-event-add">
							<span @click="showNewEvent()">
								<i class="fa fa-plus"></i>
								Add event
							</span>
						</div>
					</div>
				</div>
			</transition>
		</span>
		
		<reminder-popup
			v-if="reminderBoxPopupData"
			:event="reminderBoxPopupData.event"
			@close="hideEvent($event)"
		></reminder-popup>
	</span>
`;

export default {
	template: template,
	props: ['idu', 'events'],
	data() {
		return {
			briefReminderShown: false,
			reminderBoxPopupData: null
		};
	},
	methods: {
		hasOverdueEvents(events) {
			let now = this.now();
			return events.filter(ev => ev.remindAt < now && ev.status === 0).length > 0;
		},
		areEventsOver(events) {
			if (!events || !events.length) return true;

			let isOver = true;
			events.forEach(event => {
				if (event.remindAt > this.now()) isOver = false;
			});

			return isOver;
		},
		hideUserReminderFn() {
			return () => {
				let popup = this.reminderBoxPopupData;
				if (popup && popup.event && popup.event.idu === this.idu) return;
				this.briefReminderShown = false;
			};
		},
		getBriefEventClass(event) {
			let classes = ['user-brief-event'];
			if (event.status === 1) {
				classes.push('event-completed');
			} else {
				let datetime = moment.utc().format('YYYY-MM-DD HH:mm') + ':00';
				if (event.remindAt < datetime) {
					classes.push('event-overdue');
				}
			}
			return classes.join(' ');
		},
		showEvent(event = {}) {
			this.reminderBoxPopupData = {event};
		},
		showNewEvent() {
			let event = {idu: this.idu};
			this.reminderBoxPopupData = {event};
		},
		hideEvent(event) {
			let oldEvent = this.reminderBoxPopupData && this.reminderBoxPopupData.event;
			this.reminderBoxPopupData = null;

			if (!oldEvent) return;

			this.briefReminderShown = false;
			let events = this.events;
			let data = {};

			if (!event) return;
			if (!oldEvent.id) {
				events.push(event);
				data.events = events;
				data.event = event;
				this.$emit('close', data);
				return;
			}

			events.forEach(() => {
				let idx = events.indexOf(oldEvent);
				if (idx === -1) return;

				this.$set(events, idx, event);
			});
			data.events = events;
			data.event = event;
			this.$emit('close', data);
		}
	}
};