const template = `
	<div class="dlp" v-mousedown-outside="hideCalendar">
		<div class="dlp-tf-box">
			<span v-show="value" class="dlp-clear zmdi zmdi-close" @click="clear"></span>
			<input type="text" :value="valueToDisplay" readonly @focus="showCalendar">
		</div>
		
		<div v-if="dateListShown" class="dlp-list">
			<div v-for="(date, index) in dateList" :key="index + '_' + date" class="dlp-item">
				<date-picker
					:type="type"
					:with-time="withTime"
					v-model="dateList[index]"
					@change="onDateChanged"
				></date-picker>
			</div>
			
			<div v-if="dateList.length < datesLimit" class="dlp-item dlp-add">
				<date-picker
					v-model="newDate"
					:empty-text="ii('ADD_DATE')"
					:type="type"
					:with-time="withTime"
				></date-picker>
			</div>
		</div>
	</div>
`;

export default {
	template: template,
	props: ['value', 'dates-count', 'type', 'with-time'],
	data() {
		return {
			val: null,
			newDate: null,
			dateListShown: false,
			datesLimit: 10
		};
	},
	computed: {
		valueToDisplay() {
			return this.value ? this.value.replace(/,/g, ', ') : '';
		},
		dateList() {
			if (!this.val) return [];
			return this.val.split(',');
		}
	},
	methods: {
		showCalendar() {
			this.dateListShown = true;
		},
		hideCalendar() {
			if (this.val !== this.filterItems(this.value)) {
				this.setVal(this.val);
			}
			this.dateListShown = false;
		},
		setVal(date) {
			this.$emit('input', date);
			this.$emit('change', {
				isTrusted: true,
				value: date
			});
		},
		clear() {
			this.val = '';
			this.hideCalendar();
		},
		filterItems(value) {
			if (!value) return '';

			let items = this.arrayUnique(value.split(',')).filter(i => i);
			return items.join(',');
		},
		onDateChanged() {
			let value = this.dateList.join(',');
			this.val = this.filterItems(value);
		}
	},
	watch: {
		value() {
			this.val = this.filterItems(this.value);
		},
		val() {
			this.dateList = this.val ? this.val.split(',') : [];
		},
		newDate() {
			if (!this.newDate) return;

			let value = (this.val ? this.val + ',' : '') + this.newDate;
			this.val = this.filterItems(value);
			this.newDate = null;
		}
	},
	created() {
		this.val = this.filterItems(this.value);

		if (parseInt(this.datesCount) > 1) {
			this.datesLimit = parseInt(this.datesCount);
		}
	}
};
