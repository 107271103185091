const template = `
	<popup
		width="700"
		content-max-height="400"
		close-on-back="1"
		resize="1"
		@close="$emit('close', $event)"
	>
		<div slot="header">{{ iiArgs('LINKS_FOR_N', domain) }}</div>
		<div class="seo-links-popup-content">
			<div v-if="links === null" class="popup-loading">
				<i class="fa fa-spinner spin-fast"></i>
				{{ ii('LOADING') }}...
			</div>
			<div v-else>
				<div class="subpanels-box">
					<a
						:class="tab === 'semalt' ? 'active' : ''"
						@click="tab = 'semalt'"
					>{{ ii('SEMALT') }} ({{ links.semalt.length }})</a><a
						:class="tab === 'custom' ? 'active' : ''"
						@click="tab = 'custom'"
					>{{ ii('CUSTOM') }} ({{ links.custom.length }})</a>
				</div>
				<div class="fullseo-links-stat">
					<span>
						{{ ii('TOTAL') }}: {{ links[tab].length }}
					</span>
					<span v-if="kwSearch || urlSearch" class="m-left10">
						{{ ii('FILTERED') }}: {{ handledLinks.length }}
					</span>
					<span v-if="checkedLinks.length > 0" class="m-left10">
						{{ ii('CHECKED') }}: {{ checkedLinks.length }}
					</span>
					<span
						v-if="handledLinks.length"
						class="m-left10 link"
						@click="downloadLinks(tab)"
					>
						<i class="fa fa-download"></i>
						{{ ii('DOWNLOAD') }}
					</span>
					<span
						v-if="tab === 'custom'"
						class="m-left10 link"
						@click="addLinks"
					>
						<i v-if="isAddingLinks" class="fa fa-spinner spin-fast"></i>
						<i v-else class="fa fa-plus"></i>
						{{ ii('ADD') }}
					</span>
					<template v-if="tab === 'custom' && checkedLinks.length">
						<span
							class="m-left10 link"
							@click="removeLinks"
						>
							<i v-if="isRemovingLinks" class="fa fa-spinner spin-fast"></i>
							<i v-else class="fa fa-remove"></i>
							{{ ii('REMOVE') }} ({{ checkedLinks.length }})</span>
						<span
							class="m-left10 link"
							@click="changeLinksCnt"
						>
							<i v-if="isChangingCnt" class="fa fa-spinner spin-fast"></i>
							<i v-else class="fa fa-clone"></i>
							{{ ii('CHANGE_CNT') }} ({{ checkedLinks.length }})</span>
					</template>
				</div>
				<table class="fullseo-links-tbl general-tbl hover-tbl width100p">
					<thead>
					<tr>
						<th><head-cell-inner no-sticky="1">#</head-cell-inner></th>
						<th>
							<head-cell-inner no-sticky="1">
								<input type="checkbox" v-model="checkedAll" @click="onLinksCheckAllClick">
							</head-cell-inner>
						</th>
						<th>
							<head-cell-inner no-sticky="1">
								<span class="link" @click="sortLinks('cnt')">{{ ii('CNT') }}</span>
							</head-cell-inner>
						</th>
						<th>
							<head-cell-inner no-sticky="1">
								<span class="link" @click="sortLinks('kw')">{{ ii('KEYWORD') }}</span>
							</head-cell-inner>
						</th>
						<th>
							<head-cell-inner no-sticky="1">
								<span class="link" @click="sortLinks('url')">{{ ii('URL') }}</span>
							</head-cell-inner>
						</th>
						<th><head-cell-inner no-sticky="1">{{ ii('DEL') }}</head-cell-inner></th>
					</tr>
					</thead>
					<tbody v-if="links[tab].length">
					<tr class="search-row">
						<td></td>
						<td></td>
						<td><input type="number" v-model.lazy="cntSearch"></td>
						<td><input type="text" v-model.lazy="kwSearch" :placeholder="ii('KEYWORD_SEARCH')"></td>
						<td>
							<input type="text" v-model.lazy="urlSearch" :placeholder="ii('URL_SEARCH')">
							<label>
								<input type="checkbox" v-model="urlExactMatch" style="vertical-align: inherit">
								<em>exact match</em>
							</label>
						</td>
						<td></td>
					</tr>
					<tr v-for="(row, i) in handledLinks">
						<td class="text-center">{{ i + 1 }}</td>
						<td class="text-center">
							<input type="checkbox" v-model="row.checked" @click="onLinkChbClick($event, row)">
						</td>
						<td class="text-center">
							<i v-if="row.isChangingCnt" class="fa fa-fw fa-spinner spin-fast"></i>
							<span v-else class="link" @click="changeLinkCnt(row)">{{ row.cnt }}</span>
						</td>
						<td>{{ row.kw }}</td>
						<td>{{ row.url }}</td>
						<td class="text-center">
							<i v-if="row.isRemoving" class="fa fa-fw fa-spinner spin-fast"></i>
							<span
								v-else-if="tab === 'custom'"
								class="link fa fa-fw fa-remove"
								v-title="ii('REMOVE_LINK')"
								@click="removeLink(row)"
							></span>
						</td>
					</tr>
					</tbody>
					<tfoot v-else>
					<tr>
						<td colspan="100" class="text-center">{{ ii('NO_DATA') }}</td>
					</tr>
					</tfoot>
				</table>
			</div>
		</div>
	</popup>
`;

export default {
	template,
	props: ['seoId', 'url'],
	data() {
		return {
			domain: null,
			links: null,
			tab: 'custom',
			checkedAll: false,
			cntSearch: '',
			kwSearch: '',
			urlSearch: '',
			urlExactMatch: true,
			isAddingLinks: false,
			isChangingCnt: false,
			isRemovingLinks: false,
			sortDir: null,
			sortCol: null,
			linkCheckTrigger: 0
		};
	},
	computed: {
		filteredLinks() {
			if (!this.links) return [];

			let items = this.links[this.tab];
			if (!items || !items.length) return [];

			if (this.cntSearch) {
				let cnt = parseInt(this.cntSearch);
				items = items.filter(item => item.cnt === cnt);
			}
			if (this.kwSearch) {
				let kw = this.kwSearch.toLowerCase();
				items = items.filter(item => {
					return item.kw.toLowerCase().indexOf(kw) !== -1;
				});
			}
			if (this.urlSearch) {
				let url = this.urlSearch.toLowerCase();
				items = items.filter(item => {
					if (this.urlExactMatch) return item.url.toLowerCase() === url;
					return item.url.toLowerCase().indexOf(url) !== -1;
				});
			}

			return items;
		},
		handledLinks() {
			let items = this.filteredLinks;

			let col = this.sortCol;
			if (!col) return items;

			let dirVal = this.sortDir ? 1 : -1;
			items.sort((a, b) => {
				if (a[col] > b[col]) return dirVal;
				if (a[col] < b[col]) return -dirVal;

				if (a.id > b.id) return dirVal;
				if (a.id < b.id) return -dirVal;

				return 0;
			});

			return items;
		},
		checkedLinks() {
			let _vueTriggerHack = this.linkCheckTrigger;
			let items = this.handledLinks.filter(item => item.checked);
			return items;
		}
	},
	methods: {
		downloadLinks(tab) {
			let items = this.checkedLinks;
			if (!items.length) {
				items = this.handledLinks;
			}
			if (!items.length) return;

			let content = items.map(item => item.kw + '@' + item.url).join('\r\n').trim();

			let blob = new Blob([content], {type: 'text/plain'});
			let url = window.URL.createObjectURL(blob);
			let a = document.createElement('A');
			a.href = url;
			a.download = this.domain + '_keywords.txt';
			document.body.appendChild(a);
			a.click();
			setTimeout(() => {
				document.body.removeChild(a);
				window.URL.revokeObjectURL(url);
			}, 100);
		},
		async addLinks() {
			if (this.isAddingLinks) return;

			let seoId = this.seoId;
			if (!seoId) return this.alert(this.ii('PLEASE_ACTIVATE_PROJECT_FIRST'));

			let text = await this.input({
				type: 'textarea',
				title: this.iiArgs('ENTER_LINKS_IN_FORMAT_N', this.lowerCaseFirst(this.ii('KEYWORD_AT_URL')))
			});
			if (!text) return;

			this.isAddingLinks = true;

			await this.post('FullSEO.addLinks', seoId, text);
			let links = await this.get('FullSEO.getLinks', seoId);

			this.links = links;
			this.isAddingLinks = false;
		},
		async changeLinkCnt(link) {
			let newCnt = await this.input({
				type: 'number',
				title: this.ii('ENTER_NUMBER_OF_LINKS'),
				val: link.cnt
			});
			newCnt = parseInt(newCnt);
			if (!newCnt || newCnt < 0) return;

			this.$set(link, 'isChangingCnt', true);
			let err = await this.post('FullSEO.changeLinksCnt', [link.id], newCnt, this.seoId);
			this.$set(link, 'isChangingCnt', false);
			if (err) return this.alert(err);

			this.$set(link, 'cnt', newCnt);
		},
		async changeLinksCnt() {
			let newCnt = await this.input({
				type: 'number',
				title: this.ii('ENTER_NUMBER_OF_LINKS'),
				val: 1
			});
			newCnt = parseInt(newCnt);
			if (!newCnt || newCnt < 0) return;

			let links = this.checkedLinks;
			let linkIds = links.map(item => item.id);

			this.isChangingCnt = true;
			let err = await this.post('FullSEO.changeLinksCnt', linkIds, newCnt, this.seoId);
			this.isChangingCnt = false;
			if (err) return this.alert(err);

			links.forEach(item => this.$set(item, 'cnt', newCnt));
		},
		async removeLink(link) {
			if (link.isRemoving) return;

			let ok = await this.confirm(this.ii('ARE_YOU_SURE'));
			if (!ok) return;

			let seoId = this.seoId;
			this.$set(link, 'isRemoving', true);
			let err = await this.post('FullSEO.removeLinks', [link.id], seoId);
			if (err) {
				this.$set(link, 'isRemoving', false);
				this.alert(err);
				return;
			}

			this.links = await this.get('FullSEO.getLinks', seoId);
		},
		async removeLinks() {
			if (this.isRemovingLinks) return;

			let msg = this.iiArgs('REMOVE_N_LINKS_QST', this.checkedLinks.length);
			let ok = await this.confirm(msg);
			if (!ok) return;

			let linkIds = this.checkedLinks.map(item => item.id);
			let seoId = this.seoId;

			this.isRemovingLinks = true;
			let err = await this.post('FullSEO.removeLinks', linkIds, seoId);

			if (err) {
				this.isRemovingLinks = false;
				this.alert(err);
				return;
			}

			this.links = await this.get('FullSEO.getLinks', seoId);

			this.isRemovingLinks = false;
		},
		onLinksCheckAllClick(e) {
			for (let tab in this.links) {
				this.links[tab].forEach(item => {
					this.$set(item, 'checked', e.target.checked);
				});
			}
		},
		onLinkChbClick(e, item) {
			this.linkCheckTrigger++;
			if (!e.target.checked) {
				this.checkedAll = false;
			}
		},
		sortLinks(col) {
			if (col === this.sortCol) {
				this.sortDir = !this.sortDir;
			} else {
				this.sortCol = col;
			}
		}
	},
	async mounted() {
		this.urlSearch = this.url;
		this.domain = await this.get('Site.getDomainById', this.seoId);
		this.links = await this.get('FullSEO.getLinks', this.seoId);
	}
};
