import Store from '../../store';

const template = `
	<popup
		class="register-popup"
		no-scroll="1"
		close-on-back="1"
		@close="$emit('close')"
	>
		<div slot="header">{{ ii('CREATE_NEW_ACCOUNT') }}</div>
		<div class="register-content">
			<label class="register-content-label">{{ ii('E_MAIL') }}: <span class="danger">*</span></label>
			<input
				type="email"
				class="register-content-input"
				v-model.lazy="data.email"
			>
			<label class="register-content-label">{{ ii('NAME') }}: <span class="danger">*</span></label>
			<input
				type="text"
				class="register-content-input"
				v-model.lazy="data.username"
			>
			<label class="register-content-label">{{ ii('PASSWORD') }}: <span class="danger">*</span></label>
			<input
				type="text"
				class="register-content-input"
				v-model.lazy="data.password"
			>
			<label class="register-content-label">{{ ii('COUNTRY') }}: <span class="danger">*</span></label>
			<rich-sel
				class="register-content-input"
				:items="getGeoSuggestions()"
				v-model="data.geo"
			></rich-sel>
			<label class="register-content-label">{{ ii('PHONE') }}:</label>
			<phone-input class="register-content-input" v-model="data.phone"></phone-input>
			<label class="register-content-label">{{ ii('TAG') }}:</label>
			<select v-model="data.source" class="register-content-input">
				<option :value="void 0"></option>
				<option v-for="value in getRegSourcesItems()" :value="value.key">{{ value.val }}</option>
			</select>
			<label class="register-content-label">{{ ii('SITE') }}:</label>
			<input
				type="text"
				class="register-content-input"
				v-model.lazy="data.site"
			>
			<label class="register-content-label">{{ ii('SEARCH_ENGINE') }}:</label>
			<multi-sel
				class="register-content-input"
				:items="getSearchEnginesItems()"
				:no-empty="1"
				v-model="data.se"
			></multi-sel>
			<label class="register-content-label">{{ ii('MANAGER') }}:</label>
			<rich-sel
				class="register-content-input"
				:items="getManagersItems({withNone: true})"
				:no-empty="1"
				v-model="data.idm"
			></rich-sel>
			<label class="register-content-label">{{ ii('MAILS') }}:</label>
			<div class="register-content-input">
				<label class="chb" v-title="'Get notifications about position updates on the analyzed project/projects'">
					<input
						type="checkbox"
						v-model="data.emails"
						:true-value="1"
						:false-value="0"
					>
					<span class="register-checkbox">{{ ii('PROJECTS') }}</span>
				</label>
				<label class="chb" v-title="'Get notifications about SEMALT news'">
					<input
						type="checkbox"
						v-model="data.news"
						:true-value="1"
						:false-value="0"
					>
					<span class="register-checkbox">{{ ii('NEWS') }}</span>
				</label>
				<label class="chb" v-title="'Subscription information'">
					<input
						type="checkbox"
						v-model="data.subs"
						:true-value="1"
						:false-value="0"
					>
					<span class="register-checkbox">{{ ii('SUBS') }}</span>
				</label>
			</div>
	
			<div class="register-content-button">
				<btn
					size="small"
					class="register-content-button"
					@click.native="register()"
				>
					<i class="fa fa-fw fa-check" slot="icon"></i>
					{{ ii('SAVE') }}
				</btn>
			</div>
		</div>
	</popup>
`;

export default {
	template: template,
	props: ['params', 'sourceId'],
	computed: {
		...Store.mapState(['workersMap', 'managersMap', 'geoMap', 'searchEngines', 'userSourcesMap'])
	},
	data() {
		return {
			data: {
				email: null,
				username: null,
				password: null,
				geo: null,
				phone: '',
				source: null,
				site: null,
				se: '1',
				idm: '0',
				emails: 1,
				news: 1,
				subs: 1,
			}
		};
	},
	methods: {
		async register() {
			if (!this.data.email) return this.alert('Please enter e-mail');
			if (!this.data.username) return this.alert('Please enter username');
			if (!this.data.password) return this.alert('Please enter password');
			if (!this.data.geo) return this.alert('Please choose country');
			if (!this.data.se) return this.alert('Please choose search engine');
			if (this.sourceId) this.data.statfilter = this.sourceId;

			let res = await this.post('Lead.register', this.data);
			if (res.error) return this.alert(this.ii(res.error));

			let idu = res;
			let params = {idu, idm: this.data.idm};
			this.$emit('close', params);
		},
		getRegSourcesItems() {
			let list = this.getUsersRegSources().map(key => {
				let val = key;
				return {key, val};
			});
			return list;
		},
	},
	async mounted() {
		await this.loadState(['managersMap', 'geoMap', 'searchEngines', 'userSourcesMap']);
		if (this.worker.idm) this.$set(this.data, 'idm', this.worker.idm);
		if (!this.params || !this.isObject(this.params)) return;

		let keys = Object.keys(this.data);
		for (let key in this.params) {
			if (!this.inArray(key, keys)) continue;

			this.$delete(this.data, key);
			this.$set(this.data, key, this.params[key]);
		}
	}
};