const template = `
	<span
		class="tooltip"
		:style="{cursor: cursor}"
		@mouseover="tooltipVisible = true"
		@mouseleave="tooltipVisible = false"
	>
		<span
			v-if="tooltipVisible"
			class="tooltip-text pic-link"
			@click.stop="copyMyText"
		>
			<i class="zmdi zmdi-copy"></i>
			<slot name="content"></slot>
		</span>
		<slot></slot>
	</span>
`;

export default {
	template: template,
	props: ['text', 'cursor'],
	computed: {},
	data() {
		return {
			tooltipVisible: false,
		};
	},
	methods: {
		copyMyText(e) {
			e.preventDefault();
			e.stopPropagation();

			this.copyText(this.text);
			this.tooltipVisible = false;
		}
	},
};
