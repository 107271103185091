import Store from '../../store.js';

const template = `
	<license-form v-if="isFreePlan()"></license-form>
	<div v-else class="news">
		<div>
			<div class="row">
				<div class="control-filter col xs-12 sm-8 md-6">
					<label>{{ ii('EVENT') }}:</label>
					<multi-sel
						:items="getTypes()"
						v-model="query.type"
						@change="onQueryChanged('type')"
					></multi-sel>
				</div>
				<div class="control-filter col xs-12 sm-8 md-6">
					<label>{{ ii('STATUS') }}:</label>
					<multi-sel
						:items="getStatusSuggestion()"
						v-model="query.status"
						@change="onQueryChanged('status')"
					></multi-sel>
				</div>
				<div class="control-filter col xs-12 sm-8 md-6">
					<label>{{ ii('DATE') }}:</label>
					<range-date-picker
						v-model="query.date"
						@change="onQueryChanged('date')"
					></range-date-picker>
				</div>
			</div>
			
			<div>
				<div class="left" style="margin: 10px 0 5px 0">
					<span v-if="totalCnt != null">
						<span class="general-upper">{{ ii('TOTAL') }}:</span>
						<i v-if="totalCnt === -1" class="fa fa-spinner spin-fast"></i>
						<template v-else>{{ totalCnt | numFormat }}</template>
					</span>
				</div>
			</div>
			
			<table class="mc-tbl hover-tbl timeline-table">
				<thead>
					<tr>
						<th><head-cell-inner no-sticky="1">{{ ii('EVENT') }}</head-cell-inner></th>
						<th><head-cell-inner no-sticky="1">{{ ii('TIME') }}</head-cell-inner></th>
						<th colspan="3"><head-cell-inner no-sticky="1">{{ ii('INFO') }}</head-cell-inner></th>
						<th><head-cell-inner no-sticky="1">{{ ii('STATUS') }}</head-cell-inner></th>
					</tr>
				</thead>
				<tbody>
				<tr v-for="(row, i) in rows">
					<td :class="'event-cell event-cell-' + row.type.toLowerCase()">{{ row.type | upper }}</td>
					<td><datetime :timestamp="row.eventTime"></datetime></td>
					
					<template v-if="row.type == 'REGISTRATION'">
						<td colspan="3">
							<span>{{ row.data }}</span>
						</td>
					</template>
					<template v-else-if="row.type == 'CLIENT_REQUEST' && !isNaN(row.data)">
						<td colspan="3" class="edge-cell">
							<span
								class="pic-link"
								@click="clientRequestPopupId = row.data"
							>
								<i class="fa fa-info-circle"></i>
								<span>{{ ii('VIEW_REQUEST') }}</span>
							</span>
						</td>
					</template>
					<template v-else-if="row.type == 'CALLBACK_REQUEST' && !isNaN(row.data)">
						<td colspan="3" class="edge-cell">
							<span
								class="pic-link"
								@click="callbackRequestPopupId = row.data"
							>
								<i class="fa fa-info-circle"></i>
								<span>{{ ii('VIEW_REQUEST') }}</span>
							</span>
						</td>
					</template>
					<template v-else-if="row.type == 'SITES_ADDED'">
						<td colspan="3">
							<template v-if="row.data && row.data.length">
								<div v-for="(domain, i) in row.data" v-if="i < 3">
									<outer-link :href="'http://' + domain">{{ domain }}</outer-link>
								</div>
								<div v-if="row.data.length > 3" class="pointer primColor" @click="showAllSites(row.data)">
									{{ ii('SHOW_MORE') }}
								</div>
							</template>
						</td>
					</template>
					<template v-else-if="row.type == 'REJECTED_TASK'">
						<td colspan="3">{{ ii('REJECTED_TASK') }}:
							<a :href="'/usernews?id=' + row.data + ',' + row.id">#{{ row.data }}</a>
						</td>
					</template>
					<template v-else-if="row.type == 'EMAIL_OPEN' || row.type == 'EMAIL_CLICK' || row.type == 'POPUP_CLOSE' || row.type == 'POPUP_CLICK'">
						<td colspan="3">
							<div v-for="el in getArrayFromObj(row.data)">
								<strong>{{ el.key }}:</strong>
								{{ el.val }}
							</div>
						</td>
					</template>
					<template v-else-if="row.type == 'REMINDER'">
						<td colspan="3">
							<span
								v-if="row.data"
								class="link"
								@click="onReminderEventClick(row.data)"
							>
								{{ shorten(row.data.comment, 40) }}
							</span>
						</td>
					</template>
					<template v-else>
						<td colspan="3">
							{{ row.data }}
						</td>
					</template>
					
					<td>{{ row.status == 0 ? ii('OPEN') : row.status == 1 ? ii('COMPLETED') : ii('MOVED') }}</td>
				</tr>
				</tbody>
				<tfoot>
				<tr v-show="rows && rows.length && isLoadingMore">
					<td colspan="100" class="text-center">
						<i class="fa fa-spinner spin-fast"></i>
						{{ ii('LOADING') }}...
					</td>
				</tr>
				<tr v-show="isNoMore">
					<td colspan="100" class="text-center">
						{{ rows && rows.length ? ii('NO_MORE_DATA') : ii('NO_DATA') }}
					</td>
				</tr>
				</tfoot>
			</table>
		</div>
		<div class="news-bottom">
			<btn v-if="!isNoMore" size="small" @click.native="loadEvents({isAppend: true})" :loading="isLoading">
				<i class="fa fa-fw fa-cloud-download" slot="icon"></i>
				{{ ii('LOAD_MORE') }}
			</btn>
		</div>
		
		<client-request-popup
			v-if="clientRequestPopupId"
			:request-id="clientRequestPopupId"
			@close="clientRequestPopupId = null"
		></client-request-popup>
		
		<callback-request-popup
			v-if="callbackRequestPopupId"
			:request-id="callbackRequestPopupId"
			@close="callbackRequestPopupId = null"
		></callback-request-popup>
		
		<reminder-popup
			v-if="reminderPopup"
			:event="reminderPopup.event"
			@close="reminderPopup = null;"
		></reminder-popup>
	</div>
`;

export default {
	template,
	data() {
		return {
			portion: 0,
			isLoading: false,
			isLoadingMore: false,
			isNoMore: false,
			totalCnt: null,
			rows: null,
			types: null,
			clientRequestPopupId: null,
			callbackRequestPopupId: null,
			reminderPopup: null,
			query: {
				type: null,
				status: null,
				date: null
			}
		};
	},
	props: ['idu', 'events', 'status'],
	computed: {
		...Store.mapState(['worker', 'workersMap']),
	},
	methods: {
		async loadEvents(opts = {}) {
			if (this.isLoading) return;

			if (opts.isAppend) {
				if (this.isNoMore) return;

				this.portion++;
				this.isLoadingMore = true;
			} else {
				this.portion = 1;
				this.isNoMore = false;
				this.loadStat();
			}

			this.isLoading = true;
			let res = await this.get('UserNews.getRows', {...this.query, idu: this.idu}, this.portion);
			let rows = res.rows || [];
			this.isLoading = false;
			this.isLoadingMore = false;

			if (!opts.isAppend) this.rows = [];
			if (!rows.length) return this.isNoMore = true;

			this.rows = this.rows.concat(rows);
		},
		async loadStat() {
			this.totalCnt = -1;
			let res = await this.get('UserNews.getStat', {...this.query, idu: this.idu});
			this.totalCnt = res.totalCnt || 0;
		},
		getStatusSuggestion() {
			return [
				{key: '0', val: this.ii('OPEN')},
				{key: '2', val: this.ii('MOVED')},
				{key: '1', val: this.ii('COMPLETED')}
			];
		},
		getTypes() {
			if (!this.types) return [];

			let list = this.types.all.map( e => {
				return {
					'key': e,
					'val': e.toUpperCase(),
					'displayVlad': e.toUpperCase(),
					'sortVal': e
				};
			});
			return list;
		},
		async setTypes() {
			this.types = await this.get('UserNews.getTypes');
		},
		onQueryChanged(key) {
			let val = this.query[key];
			this.$delete(this.query, key);
			if (val) this.$set(this.query, key, val);

			this.loadEvents();
		},
		onReminderEventClick(event) {
			this.reminderPopup = {event};
		},
	},
	async mounted() {
		if (!this.idu) return;

		if (this.events) this.$set(this.query, 'type', this.events);
		if (this.status) this.$set(this.query, 'status', this.status);
		await this.setTypes();
		await this.loadEvents();
	}
};
