const template = `
	<div v-if="isLoading" class="loading">
		<span>
			<i class="fa fa-spinner spin-fast center"></i>
			<span class="general-upper">{{ ii('LOADING') }}...</span>
		</span>
	</div>
	<div v-else class="user-popups">
		<div class="create-popup">
			<div>
				<select v-model="popupSelected">
					<option v-for="t in getTemplatesSuggestions()" :value="t.key">{{ t.val }}</option>
				</select>
				<btn
					size="small"
					:disabled="!popupSelected"
					@click.native="addPopup()"
				>{{ ii('CREATE_POPUP') }}</btn>
			</div>
		</div>
		<table class="mc-tbl hover-tbl">
			<thead>
				<tr>
					<th>
						<head-cell-inner no-sticky="1">#</head-cell-inner>
					</th>
					<th>
						<head-cell-inner no-sticky="1">{{ ii('POPUP') }}</head-cell-inner>
					</th>
					<th>
						<head-cell-inner no-sticky="1">{{ ii('CREATED') }}</head-cell-inner>
					</th>
					<th>
						<head-cell-inner no-sticky="1">{{ ii('READ_PAST') }}</head-cell-inner>
					</th>
					<th>
						<head-cell-inner no-sticky="1">{{ ii('READ_ACTION') }}</head-cell-inner>
					</th>
					<th>
						<head-cell-inner no-sticky="1">{{ ii('STATUS') }}</head-cell-inner>
					</th>
					<th>
						<head-cell-inner no-sticky="1">{{ ii('MESSAGE') }}</head-cell-inner>
					</th>
					<th>
						<head-cell-inner no-sticky="1">{{ ii('DELETE') }}</head-cell-inner>
					</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(row, i) in popups">
					<td class="text-center">{{ i + 1 }}</td>
					<td>
						{{ getTemplateVal(row.type) }}
						<span v-if="row.title" v-title="row.title">: {{ shorten(row.title, 25) }}</span>
					</td>
					<td>
						<datetime :timestamp="row.createdAt"></datetime>
					</td>
					<td>
						<datetime v-if="row.readTime" :timestamp="row.readTime"></datetime>
					</td>
					<td>
						<span v-if="row.readAction" v-title="row.readAction">{{ shorten(row.readAction, 30) }}</span>
					</td>
					<td class="text-center">
						{{ row.st === 1 ? ii('READ') : ii('NOT_READ') }}
					</td>
					<td class="text-center">
						<span v-if="row.body" @click="showText(row.body)" class="pointer primColor">
							{{ shorten(row.body, 20) }}
						</span>
					</td>
					<td class="text-center">
						<a @click="deletePopup(row.id, i)"><i class="fa fa-close"></i></a>
					</td>
				</tr>
				<tr v-show="!popups.length">
					<td colspan="100" class="text-center">
						{{ ii('NO_DATA') }}
					</td>
				</tr>
			</tbody>
		</table>
	</div>
`;

export default {
	template,
	data() {
		return {
			isLoading: true,
			popups: null,
			popupSelected: null,
			reloadTimeout: null
		};
	},
	props: ['idu'],
	computed: {
		isSetUnreadPopup() {
			if (!this.popups) return false;

			return this.popups.filter(i => i.st === 0).length > 0;
		}
	},
	methods: {
		getTemplatesSuggestions() {
			return [
				{
					val: this.ii('COULDNT_REACH_YOU_BY_PHONE'),
					key: 'callback_phone'
				},
				{
					val: this.ii('DIDNT_RECEIVE_FEEDBACK_FROM_YOU_EMAIL'),
					key: 'callback_email'
				},
				{
					val: this.ii('CUSTOM_POPUP'),
					key: 'custom'
				}
			];
		},
		async loadInfo(preloader = true) {
			if(preloader) this.isLoading = true;
			this.popups = await this.get('UserCard.getPopupsInfo', this.idu);
			this.isLoading = false;
		},
		async addPopup() {
			if (this.isSetUnreadPopup) {
				return this.alert(this.ii('THERE_IS_ONE_UNREAD_POPUP') + '. ' + this.ii('UNABLE_TO_CREATE_THE_NEW_ONE'));
			}
			if (!this.popupSelected) return;

			let title = '';
			let msg = '';

			if (this.popupSelected === 'custom') {
				let res = await this.multiInput({
					items: [
						{ label: this.ii('TITLE'), key: 'title', type: 'text', required: 1 },
						{ label: this.ii('MESSAGE'), key: 'msg', type: 'textarea', required: 1 }
					],
					noScroll: 1,
					header: this.ii('CUSTOM_POPUP')
				});
				if (!res) return;

				title = res.data.title;
				msg = res.data.msg;
				res.close();
			}

			await this.post('UserCard.addUserPopup', this.idu, this.popupSelected, title, msg);
			this.loadInfo();
		},
		async deletePopup(id, i) {
			let ok = await this.confirm(this.ii('ARE_YOU_SURE'));
			if (!ok || !id) return;

			await this.post('UserCard.deleteUserPopup', id);
			this.$delete(this.popups, i);
		},
		reloadInfo(e, popupId){
			this.reloadTimeout = setTimeout(() => {
				this.loadInfo(false);
				this.reloadInfo();
			}, 30*1000);
		},
		getTemplateVal(type) {
			if (!type) return '';

			let popup = this.getTemplatesSuggestions().filter(i => i.key === type);
			if (!popup || !popup.length) return type;

			return popup[0].val || type;
		}
	},
	async mounted() {
		await this.loadInfo();
		this.reloadInfo();
	},
	destroyed() {
		clearTimeout(this.reloadTimeout);
	}
};
