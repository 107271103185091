import Store from '../../store.js';

const template = `
	<popup
		v-if="q"
		class="orders-popup"
		width="800"
		resize="1"
		:close-on-back="closeOnBack"
		@scroll="onTblScroll($event)"
		@close="close"
	>
		<div slot="header">{{ ii('ORDERS') }}</div>
		<div>
			<div class="orders-tbl-wrapper">
				<table class="order-files-tbl" v-if="orders">
					<tr>
						<th>#</th>
						<th><span class="link" @click="sort('requestType')">{{ ii('TYPE') }}</span></th>
						<th><span class="link" @click="sort('idu')">USER</span></th>
						<th><span class="link" @click="sort('timestamp')">{{ ii('TIME') }}</span></th>
						<th><span class="link" @click="sort('subscription')">{{ ii('SUBSCRIPTION') }}</span></th>
						<th><span class="link" @click="sort('site')">{{ ii('SITE') }}</span></th>
						<th><span class="link" @click="sort('plan')">{{ ii('PLAN') }}</span></th>
						<th><span class="link" @click="sort('costNum')">{{ ii('COST') }}</span></th>
						<th><span class="link" @click="sort('manager')">{{ ii('MANAGER') }}</span></th>
						<th v-if="query.withAffiliate"><span class="link" @click="sort('affilateCost')">% {{ ii('TO_PARTNER') }}</span></th>
						<th colspan="2"><span class="link" @click="sort('approveSt')">{{ ii('ST') }}</span></th>
					</tr>
					<tr v-for="(order, i) in sortedOrders">
						<td class="text-center">{{ i + 1 }}</td>
						<td class="general-upper">{{ ii(orderTypesMap[order.requestType] || order.requestType) }}</td>
						<td class="nowrap">
							<flag :cc="order.countryCode"></flag>
							<a
								:href="'/leads?idu=' + order.idu"
								v-title="order.username + '\\n' + order.userEmail"
							>{{ order.idu }}</a>
						</td>
						<td><datetime :timestamp="order.timestamp"></datetime></td>
						<td>
							<div class="order-subs">
								<a
									v-if="order.reference.indexOf('SEM') === 0"
									:href="getFsOrderUrl(order.reference)"
									target="_blank"
									rel="noreferrer noopener"
								>{{ order.reference }}</a>
								<a
									v-else-if="order.paymentSystem === 'connectum'"
									:href="'https://semalt.com/connectum/connectum.php?invoice=' + order.reference"
									target="_blank"
									rel="noreferrer noopener"
								>{{ order.reference }}</a>
								<template v-else>{{ order.reference || order.subscription }}</template>
							</div>
						</td>
						<td class="text-center">
							<a
								v-if="order.site"
								:href="'http://' + order.site.domain"
								v-title="order.site.domain"
								target="_blank"
								class="fa fa-globe"
								rel="noreferrer noopener"
							></a>
						</td>
						<td>
							<span v-title="order.package" class="cursor-default">{{order.plan}}</span>
						</td>
						<td class="text-center">
							{{ order.costNum * (order.requestType === 'refund' ? -1 : 1) | numFormat(numSep) }}
						</td>
						<td>
							<worker-tooltip
								v-if="order.manager"
								:idm="order.manager"
							>{{ getManager(order.manager).realName}}</worker-tooltip>
						</td>
						<td class="text-center" v-if="query.withAffiliate">
							<span v-title="order.affilatePercent ? order.affilatePercent + ' %' : ''">
								{{ order.affilateCost * (order.requestType === 'refund' ? -1 : 1) | numFormat(numSep) }}					
							</span>
						</td>
						<td class="text-center">
							<i
								:class="approveMap[order.approveSt].cls"
								v-title="ii(approveMap[order.approveSt].title)"
							></i>
						</td>
						<td class="text-center">
							<span
								v-if="worker.idm && worker.idm == order.manager"
								class="fa link"
								:class="order.comment ? 'fa-commenting' : 'fa-comment-o'"
								v-title="ii('DISPUTE')"
								@click="dispute(order)"
							></span>
							<span
								v-else-if="order.comment && checkPermission('access:all_payments')"
								class="fa link fa-commenting"
								v-title="ii('DISPUTE_COMMENT')"
								@click="showDisputeComment(order)"
							></span>
						</td>
					</tr>
					<tr class="order-files-empty-row" v-if="!orders.length">
						<td colspan="100">{{ ii('NO_ORDERS') }}</td>
					</tr>
				</table>
			</div>
			<div v-if="isLoading" class="orders-loading">
				<i class="fa fa-spinner spin-fast"></i> {{ ii('LOADING') }}...
			</div>
		</div>
	</popup>
`;

export default {
	template,
	props: ['q', 'close-on-back'],
	data() {
		return {
			query: {},
			orders: null,
			sortKey: null,
			sortDesc: false,
			isLoading: false,
			isNoMore: false,
			portion: 1,
			orderTypesMap: {
				'order': 'Order',
				'rebill': 'Rebill',
				'order_failed': 'Order fail',
				'payment_failed': 'Rebill fail',
				'cancel': 'Cancel',
				'refund': 'Refund'
			}
		};
	},
	computed: {
		...Store.mapState(['managersMap']),
		sortedOrders() {
			if (!this.sortKey || !this.orders) return this.orders;

			let key = this.sortKey;
			let descVal = this.sortDesc ? 1 : -1;
			let getVal;
			if (key === 'manager') {
				getVal = el => this.getManager(el.manager).realName + el.id;
			} else if (key === 'site') {
				getVal = el => el.site && el.site.domain;
			} else {
				getVal = (el, key) => el[key];
			}
			return this.orders.sort((a, b) => {
				let aVal = getVal(a, key);
				let bVal = getVal(b, key);

				if (aVal > bVal) return descVal;
				if (aVal < bVal) return -descVal;

				return 0;
			});
		}
	},
	methods: {
		close() {
			this.$emit('close');
		},
		onTblScroll(e) {
			if (e.target.scrollTop + e.target.offsetHeight < e.target.scrollHeight) return;
			this.loadOrders({isAppend: true});
		},
		async loadOrders(opts = {}) {
			if (this.isLoading || !this.q) return;

			if (opts.isAppend) {
				if (this.isNoMore) return;

				this.portion++;
			} else {
				this.orders = null;
				this.portion = 1;
				this.isNoMore = false;
			}

			this.query = JSON.parse(decodeURIComponent(this.q));
			if (this.isObjectEmpty(this.query)) return;

			let params = {
				portion: this.portion,
				limit: 30,
				siteInfo: true
			};

			this.isLoading = true;
			let orders = await this.get('PayStat.getOrders', this.query, params);
			this.isLoading = false;

			if (!opts.isAppend) this.orders = [];
			if (!orders.length) return this.isNoMore = true;

			this.orders = this.orders.concat(orders);
		},
		sort(sort) {
			if (sort === this.sortKey) {
				this.sortDesc = !this.sortDesc;
			} else {
				this.sortKey = sort;
			}
		},
		async dispute(order) {
			let comment = await this.prompt(this.ii('PLEASE_ENTER_COMMENT'), order.comment, {type: 'textarea'});
			if (comment === null) return;

			let err = await this.post('Manager.setPaymentComment', order.id, comment);
			if (err) return this.alert(this.ii(err));
			order.comment = comment;
		},
		showDisputeComment(order) {
			this.alert(order.comment);
		}
	},
	mounted() {
		this.loadLangTexts([this.$root.language], ['orders_popup']);
		this.loadOrders();
	},
	watch: {
		q() {
			this.loadOrders();
		}
	}
};
