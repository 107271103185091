const template = `
	<div class="user-payments">
		<div v-if="isLoading" class="loading">
			<span>
				<i class="fa fa-spinner spin-fast center"></i>
				<span class="general-upper">{{ ii('LOADING') }}...</span>
			</span>
		</div>
		<table v-else class="mc-tbl hover-tbl">
			<thead>
				<tr>
					<th><head-cell-inner no-sticky="1">{{ ii('TIME') }}</head-cell-inner></th>
					<th><head-cell-inner no-sticky="1">{{ ii('PRICE') }}</head-cell-inner></th>
					<th><head-cell-inner no-sticky="1">{{ ii('PLAN') }}</head-cell-inner></th>
					<th colspan="2"><head-cell-inner no-sticky="1">{{ ii('METHOD') }}</head-cell-inner></th>
					<th><head-cell-inner no-sticky="1">{{ ii('TYPE') }}</head-cell-inner></th>
					<th colspan="6"><head-cell-inner no-sticky="1">{{ ii('INVOICE') }}</head-cell-inner></th>
				</tr>
			</thead>
			<tbody>
				<template v-for="subs in payments">
					<tr>
						<th><head-cell-inner no-sticky="1"></head-cell-inner></th>
						<th colspan="100">
							<head-cell-inner no-sticky="1">
								{{ subs.subscription }}
								<template>
									(<span
										v-if="subs.activeSt == 1"
										class="success"
									>{{ ii('ACTIVE') }}</span><span
										v-else-if="subs.activeSt == 0"
										class="red"
									>{{ ii('CANCELLED') }}</span><span
										v-else
										class="grey"
									>{{ ii('NO_SUBSCRIPTION') }}</span>)
								</template>
								<a
									:href="'http://' + getDomainFromUrl(subs.site, true)"
									target="_blank"
									rel="noreferrer noopener"
									class="m-left10"
								>{{ subs.site}}</a>
								<span>({{ subs.siteId }})</span>
								<template v-if="subs.rebillSt == 1">
									<span class="m-left10">{{ ii('START') }}: {{ subs.startDate }}</span>
									<span class="m-left10">{{ ii('NEXT') }}: {{ subs.nextDate }}</span>
									<span>({{ subs.nextCost | toFixed(2) }})</span>
								</template>
							</head-cell-inner>
						</th>
					</tr>
					<tr v-for="payment in subs.payments" :key="payment.id">
						<td><datetime :timestamp="payment.timestamp"></datetime></td>
						<td class="text-center">{{ payment.cost | round }}</td>
						<td>{{ payment.package }}</td>
						<td class="text-center upper">{{ subs.paymentSystem || "FS" }}</td>
						<td class="text-center">{{ getPaymentVariant(payment.paymentType, payment.paymentVariant) }}</td>
						<td class="text-center">{{ payment.requestType }}</td>
						<td class="text-center" :colspan="canEditInvoiceInfo(payment.reference, subs.paymentSystem) ? 2 : 3">
							<a
								:href="getInvoiceUrl(payment.reference, subs.paymentSystem)"
								target="_blank"
								rel="noreferrer noopener"
							><i class="fa fa-fw fa-external-link"></i></a>
						</td>
						<td class="text-center" :colspan="canEditInvoiceInfo(payment.reference, subs.paymentSystem) ? 2 : 3">
							<a
								:href="getInvoiceUrl(payment.reference, subs.paymentSystem, true)"
								target="download-frame"
								rel="noreferrer noopener"
							><i class="fa fa-fw fa-download"></i></a>
						</td>
						<td v-if="canEditInvoiceInfo(payment.reference, subs.paymentSystem)" colspan="2" class="text-center">
							<span class="link" v-title="ii('EDIT_INVOICE')" @click="editInvoiceInfo(payment)">
								<i class="fa fa-fw fa-pencil"></i>
							</span>
						</td>
					</tr>
				</template>
			</tbody>
		</table>
	</div>
`;

export default {
	template,
	data() {
		return {
			isLoading: true,
			payments: null
		};
	},
	props: ['idu', 'partner'],
	methods: {
		canEditInvoiceInfo(reference, paymentSystem) {
			let url = this.getInvoiceUrl(reference, paymentSystem);
			let semaltInvoiceUrl = 'https://semalt.com/connectum/connectum.php';
			return this.checkPermission('edit:invoice_info') && url.indexOf(semaltInvoiceUrl) === 0;
		},
		getCurrencyItems() {
			return [
				{key: 'USD', val: 'USD'},
				{key: 'EUR', val: 'EUR'},
				{key: 'AUD', val: 'AUD'},
				{key: 'GBP', val: 'GBP'},
				{key: 'RUB', val: 'RUB'}
			];
		},
		async editInvoiceInfo(payment) {
			let seller = payment.seller || '';
			let buyer = payment.buyer || '';
			let description = payment.description || '{PACKAGE_NAME}';
			let currency = payment.currency || 'USD';
			let cost = payment.costInvoice || payment.cost || 0;
			let datetime = payment.datetimeInvoice || payment.datetime || '';
			datetime = datetime.slice(0, 16);

			let res = await this.multiInput({
				items: [
					{ label: this.ii('DATE_PAYMENT'), key: 'datetime', val: datetime, type: 'datePicker', withTime:true, weight: 2 },
					{ label: this.ii('COST'), key: 'cost', val: cost,  type: 'number' },
					{ label: this.ii('CURRENCY'), key: 'currency', val: currency, type: 'select', items: this.getCurrencyItems() },
					{ label: this.ii('SELLER_INFO'), key: 'seller', val: seller, type: 'textarea', weight: 2, className: 'textarea-height80' },
					{ label: this.ii('BUYER_INFO'), key: 'buyer', val: buyer, type: 'textarea', weight: 2, className: 'textarea-height80' },
					{ label: this.ii('DESCRIPTION'), key: 'description', val: description, type: 'textarea', weight: 2, className: 'textarea-height80' },
					{ label: this.ii('RESET'), color: 'soft-red', icon: 'trash', type: 'button', weight: 2, onClick: this.removeInvoiceInfo, className: 'text-right' },
				],
				split: 2,
				noScroll: 1,
				header: this.ii('INVOICE_INFO'),
				reference: payment.reference
			});

			if (!res) return;
			if (res.data.datetime) res.data.datetime += ':00';

			res.isLoading(true);
			await this.post('PayStat.saveInvoiceInfo', payment.reference, res.data);
			await this.loadUserPayments();
			res.isLoading(false);
			res.close();
		},
		async removeInvoiceInfo() {
			let reference =  this.$root.multiInputPopup.reference || '';
			await this.post('PayStat.removeInvoiceInfo', reference);
			await this.loadUserPayments();
			this.$root.$refs.multiInput.close();
		},
		async loadUserPayments() {
			this.payments = await this.get('PayStat.getUserSubsPayments', this.idu, this.partner);
		}
	},
	async mounted() {
		if (this.idu) await this.loadUserPayments();
		this.isLoading = false;
	}
};