const template = `
	<transition name="fade">
		<div class="full-chart">
			<div class="fch-outer">
				<div class="fch-inner">
					<div class="fch-content" v-mousedown-outside="hide">
						<div v-if="chartOpts">
							<div class="fch-text">
								<slot name="header"></slot>
							</div>
							<highcharts v-if="libLoaded" :options="finalChartOpts"></highcharts>
						</div>
						<div v-else class="fch-loading">
							<i class="fa fa-spinner spin-fast"></i>
							Loading...
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
`;

export default {
	template,
	props: ['chart-opts'],
	data() {
		return {
			libLoaded: false,
			showTops: false
		};
	},
	computed: {
		finalChartOpts() {
			let opts = this.getDefaultChartOpts();
			this.merge(opts, this.chartOpts);
			return opts;
		},
		topChartOpts() {

		}
	},
	methods: {
		hide() {
			this.$emit('hide');
		}
	},
	mounted() {
		this.ensureHighCharts().then(() => {
			this.libLoaded = true;
		});
	}
};