import Store from '../../store.js';

const template = `
<div class="maincard" ref="card" :style="{maxWidth: maxWidth}">
	<div v-if="idu <= 0" class="general-upper text-center padding10">{{ ii('NO_USER') }}</div>
	<div v-else-if="!inited" class="general-upper text-center padding10">
		<i class="fa fa-spinner spin-fast"></i>
		{{ ii('LOADING') }}...
	</div>
	<template v-else>
		<div class="bodycard">
			<div class="accountcard" v-if="frame === 'user'">
				<keep-alive>
					<user-profile-batumi @load="loadChange" v-if="frame === 'user'" :idu="idu"></user-profile-batumi>
				</keep-alive>
			</div>
		</div>
	</template>
</div>`;
export default {
	template,
	props: ['idu', 'tab', 'facebookId', 'status'],
	data() {
		return {
			inited: false,
			briefInfo: null,
			headerButton: {
				iconCls: 'zmdi-account-box-o',
				spanTitle: 'Info',
				spanNum: '',
			},
			frame: 'user',
			maxWidth: 'initial'
		};
	},
	computed: {
		...Store.mapState(['resellerInfo', 'reseller'])
	},
	methods: {
		loadChange(data){
			this.$emit('load', data);
		},
		async loadBriefInfo() {
			if (!this.idu) return;
			this.user = {};

			this.briefInfo = await this.get('UserCard.getStartBriefInfo', this.idu);


			if (this.briefInfo.moneyPlus || this.briefInfo.moneyMinus) {
				let currency = this.resellerInfo && this.resellerInfo.currency || '';
				let money = this.briefInfo.moneyPlus - this.briefInfo.moneyMinus;
				let title = currency + ' ' + this.numFormat(money, this.numSep, 2);
				if (this.briefInfo.moneyMinus) {
					title += ` (${Math.round(this.briefInfo.moneyPlus)}-${Math.round(this.briefInfo.moneyMinus)})`;
				}
			}
		},
		fixWidth() {
			let parentWidth = this.$refs.card.parentNode.getBoundingClientRect().width;
			this.maxWidth = parentWidth + 'px';
		}
	},
	watch: {
		tab() {
			this.frame = this.tab;
		}
	},
	async mounted() {
		this.act('usercard', this.idu);

		if (this.tab) {
			this.frame = this.tab;
		}

		this.fixWidth();
		await this.loadLangTexts([this.$root.language], ['usercard']);
		if (!this.idu) return;

		this.inited = true;

		let states = [
			'callItems', 'siteCategories', 'managersMap', 'geoMap', 'leadMetrics',
			'groups', 'searchEngines', 'userTags', 'resellerInfo'
		];
		await this.loadState(states);
		this.loadBriefInfo();
	}
};
