const template = `
	<popup no-scroll="1" close-on-back="1" @close="emit(null)" cls="project-picker-popup">
		<div slot="header">Project picker</div>
		<div class="row">
			<div class="col xs-4 general-upper">Project:</div>
			<div class="col xs-16">
				<rich-sel
					class="width100p"
					:items-url="getItemsUrl()"
					v-model="projectId"
					search-placeholder="Type IDu, or project ID, or domain"
				></rich-sel>
			</div>
			<div class="col xs-4">
				<btn class="width100p" size="small" :disabled="!projectId" @click.native="emit(projectId)">
					<i class="fa fa-check"></i>
					OK
				</btn>
			</div>
		</div>
	</popup>
`;

export default {
	template,
	props: ['reseller-name'],
	data() {
		return {
			projectId: null
		};
	},
	methods: {
		getItemsUrl() {
			let url = '/api/project/search';
			if (this.resellerName) url += '?reseller=' + this.resellerName;
			return url;
		},
		emit(projId) {
			this.$emit('choose', projId);
		}
	}
};