import Store from '../../store';

const template = `
	<popup
		width="800"
		content-max-height="400"
		cls="seo-payments-popup"
		@close="close"
	>
		<div slot="header">{{ ii('SEO_PAYMENTS') }}</div>
		<table class="general-tbl hover-tbl filled-tbl">
			<thead>
				<tr>
					<th>
						<head-cell-inner no-sticky="1">#</head-cell-inner>
					</th>
					<th>
						<head-cell-inner no-sticky="1">{{ ii('TYPE') }}</head-cell-inner>
					</th>
					<th>
						<head-cell-inner no-sticky="1">{{ ii('USER') }}</head-cell-inner>
					</th>
					<th>
						<head-cell-inner no-sticky="1">{{ ii('SITE_ID') }}</head-cell-inner>
					</th>
					<th>
						<head-cell-inner no-sticky="1">{{ ii('DOMAIN') }}</head-cell-inner>
					</th>
					<th>
						<head-cell-inner no-sticky="1">{{ ii('PLAN') }}</head-cell-inner>
					</th>
					<th>
						<head-cell-inner no-sticky="1">{{ ii('PROJECT_COST') }}</head-cell-inner>
					</th>
					<th>
						<head-cell-inner no-sticky="1">{{ ii('DEL') }}</head-cell-inner>
					</th>
				</tr>
			</thead>
			<tbody>
				<template v-if="payments.length">
					<tr v-for="(row, i) in payments">
						<td class="text-center">
							{{ i + 1 }}
						</td>
						<td :class="'pay-type-' + String(row.type).toLowerCase()">
							<span class="general-upper pointer" @click="editType(row)">
								{{ ii(getPaymentTypeBySlug(row.type) || row.type) }}
							</span>
						</td>
						<td>
							<flag :cc="row.geo"></flag>
							<a :href="'/leads?idu=' + row.idu" v-title="row.username + '\\n' +row.email">{{ row.idu }}</a>
						</td>
						<td>
							{{ row.ids }}
							<span class="pointer grey right margin-left-10" @click="editSite(row)">
								<i class="zmdi zmdi-edit"></i>
							</span>
						</td>
						<td>
							<a
								:href="'http://' + row.domain"
								target="_blank"
								rel="noreferrer noopener"
							>{{ row.domain }}</a>
						</td>
						<td>
							<span class="help" v-title="row.package">{{ row.plan }}</span>
							<span class="pointer grey right margin-left-10" @click="editPlan(row)">
								<i class="zmdi zmdi-edit"></i>
							</span>
						</td>
						<td class="text-center">
							<input type="number" v-model="row.projectcost" min="0">
						</td>
						<td class="text-center">
							<span class="link" @click="deletePayment(row.id, i)">
								<i class="fa fa-fw fa-remove"></i>
							</span>
						</td>
					</tr>
				</template>
				<tr v-else>
					<td colspan="100" class="text-center">
						{{ ii('NO_DATA') }}
					</td>
				</tr>
			</tbody>
		</table>
		<div slot="footer" v-if="payments.length">
			<br>
			<div class="clearfix">
				<div class="left">
					<span class="link align-middle" @click="addPayment">
						<i class="fa fa-fw fa-plus"></i>
						{{ ii('ADD_PAYMENT') }}
					</span>
				</div>
				<div class="payments-footer right text-right">
					<span
						v-if="orderCost !== sumProjectCost"
						class="danger"
					>{{ ii('SUM_PROJECTS_COST_NOT_EQUAL_ORDER_COST') }}</span>
					<strong>{{ ii('SUM_PROJECTS_COST') }}: {{ sumProjectCost }}</strong>
					<strong>{{ ii('ORDER_COST') }}: {{ orderCost }}</strong>
					<btn
						size="small"
						:loading="isSaving"
						@click.native="savePayments"
					>
						<i class="fa fa-fw fa-save" slot="icon"></i>
						{{ ii('SAVE') }}
					</btn>
				</div>
			</div>
		</div>
	</popup>
`;

export default {
	template,
	props: ['orderId'],
	data() {
		return {
			payments: [],
			isSaving: false,
			orderCost: 0
		};
	},
	computed: {
		...Store.mapState(['packages']),
		sumProjectCost() {
			let sum = 0;
			if (!this.payments.length) return sum;

			this.payments.forEach(p => {
				sum = sum + +p.projectcost;
			});

			return Math.round(sum * 100) / 100;
		},
		getPackagesItems() {
			let packages = [];
			for (let key in this.packages) {
				if (!this.packages.hasOwnProperty(key)) continue;

				let val = this.packages[key].title;
				packages.push({key: val, val});
			}
			return packages;
		}
	},
	methods: {
		setOrderCost() {
			let cost = 0;
			if (!this.payments.length) return cost;

			this.orderCost =  this.payments[0].ordcost || 0;
		},
		async savePayments() {
			this.isSaving = true;
			await this.post('SEOPayments.saveSeoPayments', this.payments, this.orderId);
			this.isSaving = false;
			this.close(true);
		},
		async editType(row) {
			let type = row.type === 'order' ? 'rebill' : 'order';
			this.$set(row, 'type', type);
		},
		async editPlan(row) {
			let newPackage = await this.input({
				type: 'richsel',
				items: this.getPackagesItems,
				val: row.package
			});
			if (!newPackage) return;

			let plan = this.getPlanByPackage(newPackage);
			if (!plan) return;

			this.$set(row, 'plan', plan);
			this.$set(row, 'package', newPackage);
		},
		async editSite(row) {
			let ids = await this.input({
				type: 'richsel',
				itemsUrl: '/api/project/search',
				val: row.ids
			});
			if (!ids || ids === row.ids) return;

			let domain = await this.getDomainBySiteId(ids);
			this.$set(row, 'ids', ids);
			this.$set(row, 'domain', domain);
		},
		async deletePayment(id, i) {
			let ok = await this.confirm(this.ii('ARE_YOU_SURE'));
			if (!ok) return;

			this.$delete(this.payments, i);
		},
		getTypeSuggestions() {
			return [
				{ key: 'order', val: 'Order' },
				{ key: 'rebill', val: 'Rebill' },
			];
		},
		getPlanByPackage(pack) {
			let plan = '';
			for (let prop in this.packages) {
				if (this.packages[prop].title === pack) {
					plan = this.packages[prop].plan;
				}
			}

			return plan;
		},
		async getDomainBySiteId(idSite) {
			let domain = '';
			if (!idSite) return domain;

			domain = await this.get('Site.getDomainById', idSite);
			return domain;
		},
		async addPayment() {
			let pay = this.payments[0] || {};
			let res = await this.multiInput({
				items: [
					{ label: this.ii('TYPE'), key: 'type', type: 'select', val: pay.type, items: this.getTypeSuggestions() },
					{ label: this.ii('PROJECT_COST'), key: 'projectcost', type: 'number', val: pay.projectcost },
					{ label: this.ii('SITE'), key: 'ids', type: 'richsel', val: pay.ids, itemsUrl: '/api/project/search', weight: 2 },
					{ label: this.ii('PLAN'), key: 'package', type: 'richsel', val: pay.package, items: this.getPackagesItems, weight: 2 },
				],
				split: 2,
				noScroll: 1,
				header: this.ii('ADD_PAYMENT')
			});

			if (!res) return;

			res.isLoading(true);
			res.data.plan = this.getPlanByPackage(res.data.package);
			res.data.domain = await this.getDomainBySiteId(res.data.ids);
			res.data.idu = pay.idu;
			res.data.username = pay.username;
			res.data.email = pay.email;
			res.data.geo = pay.geo;
			this.payments.push(res.data);
			res.isLoading(false);
			res.close();
		},
		close(reload = false) {
			this.$emit('close', reload, this.payments.length);
		}
	},
	async mounted() {
		if (!this.orderId) return;

		await this.loadState(['packages']);
		this.payments = await this.get('SEOPayments.getPaymentsById', this.orderId);
		this.setOrderCost();
	},
};