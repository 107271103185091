import Store from '../../store.js';

const template = `
<div class="user-profile">
	<div v-if="isLoading" class="loading">
		<span>
			<i class="fa fa-spinner spin-fast center"></i>
			<span class="general-upper">{{ ii('LOADING') }}...</span>
		</span>
	</div>
	<div v-else>
		<div class="user-info-section">
			<h3>
				{{ ii('USER') }}
				{{ idu }}
			</h3>
			<div class="user-info-box-inner">
				<table class="main-tbl" v-if="user" :key="'accountTbl' + idu">
					<tbody>
						<tr>
							<td>{{ ii('USERNAME') }}:</td>
							<td colspan="3">{{ user.name }}
								<a
									class="link fa fa-pencil"
									v-title="ii('EDIT')"
									style="text-decoration: none;"
									@click="changeUsername(user)"
								></a>
							</td>
						</tr>
						<tr>
							<td>{{ ii('COUNTRY') }}:</td>
							<td class="user-country" colspan="3">
								<flag :cc="user.geo" no-title="1"></flag>
								<span class="user-country-name">{{ getCountryByCode(user.geo) }}</span>
								<span
									v-if="checkPermission('batumi:admin') || checkPermission('batumi:view_all')"
									v-title="ii('CHANGE_COUNTRY')"
									class="link fa fa-pencil"
									@click="changeCountry(null)"
								></span>
							</td>
						</tr>
						<tr>
							<td>{{ ii('CITY') }}:</td>
							<td colspan="3">{{ user.city }}</td>
						</tr>
						<tr>
							<td>{{ ii('STATUS') }}:</td>
							<td colspan="3">
								<select
									v-model="user.status"
									@change="changeStatus(user)">
									<option v-for="(opt, i) in getLeadsStatus()" :value="opt.key">{{ opt.value }}</option>
								</select>
							</td>
						</tr>
						<tr>
							<td>{{ ii('MANAGER') }}:</td>
							<td colspan="3">
								<template v-if="user.managerInfo && user.managerInfo > 0">
									<worker-tooltip
										:idw="user.managerInfo"
										display="inline-block"
									>
										{{ getWorker(user.managerInfo).realName || user.managerInfo }}
									</worker-tooltip>
								</template>
								<template v-else>{{ noManagerText }}</template>
								<span
									v-if="checkPermission('batumi:admin')"
									v-title="ii('ASSIGN_NEW_MANAGER')"
									class="link fa fa-pencil"
									@click="changeManager(null)"
								></span>
							</td>
						</tr>
						<tr v-if="user.fb">
							<td>{{ ii('FB_USER') }}:</td>
							<td colspan="3">
								<a 
									:href="user.fb" 
									target="_blank" 
									v-title="user.fb"
									rel="noreferrer noopener"
									class="fa fa-external-link"
								></a>
							</td>
						</tr>
						<tr>
							<td>{{ ii('LOCAL_TIME') }}:</td>
							<td colspan="3">{{ user.localTime }}</td>
						</tr>
						<tr>
							<td>{{ ii('REGISTRATION') }}:</td>
							<td colspan="3"><datetime :timestamp="user.created"></datetime></td>
						</tr>
						<tr>
							<td>{{ ii('E_MAIL') }}:
							</td>
							<td colspan="3" class="user-email" style="word-break: break-word">
								<clipboard :text="user.email" cursor="default">{{ user.email }}</clipboard>
								<span  
									v-if="checkPermission('batumi:admin') || checkPermission('batumi:view_all')"
									class="link" v-title="ii('EDIT_E_MAIL')" 
									@click="changeUserEmail(user)"
								>
									<i class="fa fa-pencil"></i>
								</span>
							</td>
						</tr>
						<tr v-if="user.phones" v-for="(r, i) in user.phones">
							<td></td>
							<td>
								<div class="user-contact-node">
									<label class="chb phone-chb" v-if="checkPermission('batumi:admin') || checkPermission('batumi:view_all')">
										<input
											type="checkbox"
											:checked="r.checked"
											@click="checkPhone($event, i)"
										>
									</label>
									<template>
										<span
											v-title="ii('COPY')"
											class="fa fa-clone link"
											@click.stop="copyText(normalizePhone(r.phone, true))"
										></span>
										<a
											:href="'tel:' + normalizePhone(r.phone, true)"
										>
											{{ r.phone }}
										</a>
									</template>
								</div>
							</td>
							<td v-if="checkPermission('batumi:admin') || checkPermission('batumi:view_all')" class="button-td" @click="deleteNumber(r, user, i)">
								<i class="fa fa-trash-o" v-title="ii('DELETE')"></i>
							</td>
							<td v-else></td>
						</tr>
						<tr>
							<template>
								<td></td>
								<td>
									<phone-input v-model="phoneAdded" :format-callback="onPhoneFormat"></phone-input>
								</td>
								<td
									class="button-td"
									v-if="phoneAddedInfo && phoneAddedInfo.valid == true"
									@click="addAnotherPhone(user)"
									v-title="ii('ADD_PHONE')"
								>
									<i class="fa fa-plus"></i>
								</td>
								<td
									class="button-td"
									v-else
									v-title="ii('CLEAR')"
									@click="phoneAddedInfo = null; phoneAdded = ''"
								>
									<i class="fa fa-eraser"></i>
								</td>
							</template>
						</tr>
						<template v-if="phoneAddedInfo && phoneAddedInfo.valid == false">
							<tr class="added-phone-info-notvalid">
								<td colspan="4">
									This number is not valid!
								</td>
							</tr>
						</template>
						<template v-if="phoneAddedInfo && phoneAddedInfo.valid == true">
							<tr class="added-phone-info-valid">
								<td>{{ ii('CARRIER') }}:</td>
								<td colspan="3" v-if="phoneAddedInfo.carrier != ''">{{ phoneAddedInfo.carrier }}</td>
								<td colspan="3" v-else>{{ phoneAddedInfo.lineType }}</td>
							</tr>
							<tr class="added-phone-info-valid">
								<td>{{ ii('INTERNATIONAL') }}:</td>
								<td colspan="3">{{ phoneAddedInfo.internationalFormat }}</td>
							</tr>
							<tr class="added-phone-info-valid">
								<td>{{ ii('LOCAL') }}:</td>
								<td colspan="3">{{ phoneAddedInfo.localFormat }}</td>
							</tr>
							<tr class="added-phone-info-valid">
								<td>{{ ii('LOCATION') }}:</td>
								<td colspan="3">{{ phoneAddedInfo.location }}</td>
							</tr>
						</template>
					</tbody>
				</table>
			</div>
		</div>
		<div class="comments-section">
			<h3>{{ ii('COMMENTS') }}</h3>
			<div class="checked-info">
				<span v-if="howManyCommentsChecked > 0" class="left general-upper">
					{{ ii('CHECKED') }}: {{ howManyCommentsChecked | numFormat }}
				</span>
				<span v-if="isCheckedCommentsMapVisible" class="left link margin-left-10" @click="bulkDelete">
					<i class="fa fa-fw fa-trash"></i>
					{{ ii('DELETE_CHECKED') }}
				</span>
			</div>
			<table class="mc-tbl hover-tbl">
				<thead>
				<tr class="thbackColor">
					<th class="text-center checkbox-col">
						<head-cell-inner no-sticky="1">
							<label v-if="howManyCommentsHasUser > 0 && (checkPermission('batumi:admin') || checkPermission('batumi:view_all'))" class="chb">
								<input
									type="checkbox"
									v-model="isCommentsCheckedAll"
									@click="toggleCommentsCheckedAll($event)"
								>
							</label>
						</head-cell-inner>
					</th>
					<th><head-cell-inner no-sticky="1">{{ ii('TIME') }}</head-cell-inner></th>
					<th><head-cell-inner no-sticky="1">{{ ii('AUTHOR') }}</head-cell-inner></th>
					<th><head-cell-inner no-sticky="1">{{ ii('TEXT') }}</head-cell-inner></th>
					<th><head-cell-inner no-sticky="1">&nbsp;</head-cell-inner></th>
				</tr>
				</thead>
				<tbody>
				<tr v-for="(c, i) in user.comments">
					<td v-if="!checkBoxSpinning[i]  && (checkPermission('batumi:admin') || checkPermission('batumi:view_all'))" class="text-center">
						<label class="chb">
							<input
								type="checkbox"
								v-model="checkedCommentsMap[i]"
								@click="onChbClick($event, i, { id: c.id, user: user, idx: i})">
						</label>
					</td>
					<td v-else-if="checkPermission('batumi:admin') || checkPermission('batumi:view_all')" class="text-center">
						<i v-if="checkBoxSpinning[i] === 'spinning'" class="fa fa-spinner spin-fast"></i>
						<i
							v-else-if="checkBoxSpinning[i] === 'done'"
							class="fa fa-check comment-ok"
							v-title="ii('DELETED_REFRESH_PAGE')"
						></i>
						<i v-else class="fa fa-times comment-fail" v-title="checkBoxSpinning[i]"></i>
					</td>
					<td v-else></td>
					<td><datetime :timestamp="c.time"></datetime></td>
					<td>
						<worker-tooltip v-if="c.idw != 0" :idw="c.idw">
							<div>{{ getWorker(c.idw).realName || c.idw }}</div>
						</worker-tooltip>
						<b v-else>{{ c.author }}</b>
					</td>
					<td v-html="newLineToBr(wrapLinks(c.text), 2)"></td>
					<td>
						<a  v-if="checkPermission('batumi:admin') || checkPermission('batumi:view_all')"
							@click="deleteComment(c.id, user, i)"
						>
							<i class="fa fa-close" v-title="ii('DELETE')"></i>
						</a>
					</td>
				</tr>
				</tbody>
			</table>
			<textarea cols="30" rows="10" :placeholder="ii('COMMENT')" v-model="addCommentArea"></textarea>
			<btn size="small" @click.native="addComment(user)" :loading="isSendingComment">
				<i class="fa fa-fw fa-send" slot="icon"></i>
				{{ ii('ADD_COMMENT') }}
			</btn>
		</div>
	</div>
</div>
`;

export default {
	template,
	data() {
		return {
			isLoading: true,
			user: null,
			isSendingComment: false,
			addCommentArea: '',
			phoneAdded: '',
			phoneAddedInfo: null,
			batumiManagers: null,
			managersItems: null,
			isCommentsCheckedAll : false,
			checkedCommentsMap: {},
			commentsChbLastIndex: null,
			checkBoxSpinning: {}
		};
	},
	props: ['idu', 'partner'],
	computed: {
		...Store.mapState(['workersMap', 'worker', 'geoMap']),
		isCheckedCommentsMapVisible() {
			return !!(Object.entries(this.checkedCommentsMap).length > 0 && this.checkedCommentsMap.constructor === Object);
		},
		howManyCommentsHasUser() {
			if (!this.user.comments) return 0;
			return Object.entries(this.user.comments).length;
		},
		howManyCommentsChecked() {
			return Object.entries(this.checkedCommentsMap).length;
		},
		headerForAttachment() {
			if (!this.user) return '';
			return `Attachments for user #${this.user.id}`;
		},
	},
	methods: {
		getLeadsStatus() {

			let status = [
				{key: 1, value: this.ii('IGNORED')},
				{key: 2, value: this.ii('ACTIVE')},
				{key: 3, value: this.ii('DONE')},
			];

			return status;
		},
		async loadUserInfo() {
			this.user = {};
			let params = {idu: this.idu};

			let res = await this.get('BatumiCRM.getRows', params);
			this.user = res.rows[0];
		},
		onPhoneFormat(j) {
			this.phoneAddedInfo = j;
		},
		async changeManager(idm = null) {
			let newIdm = idm;

			if (!idm) {
				newIdm = await this.input({
					type: 'richsel',
					title: this.ii('ASSIGN_NEW_MANAGER'),
					val: this.user.idm,
					items: this.managersItems,
					noEmpty: 1
				});
			}

			if (newIdm === null) return;


			let res = await this.post('BatumiCRM.changeManager', [this.idu], newIdm);

			let m = this.batumiManagers.find(m => m.id === newIdm);

			let idw = 0;

			if (m) {
				idw = m.idw;
			}

			this.$set(this.user, 'managerInfo', idw);
			this.$set(this.user, 'idm', newIdm);

			this.$emit('load', this.idu);
		},
		async changeCountry(geo = null) {
			let newGeo = geo;

			if (!geo) {
				newGeo = await this.input({
					type: 'richsel',
					val: this.user.geo,
					title: this.ii('CHANGE_COUNTRY'),
					items: this.getGeoSuggestions()
				});
			}

			if (newGeo === null) return;


			let res = await this.post('BatumiCRM.changeCountry', this.idu, newGeo);
			this.$set(this.user, 'geo', newGeo);

			this.$emit('load', this.idu);
		},
		async changeStatus(user) {
			if (user.status == null) return;

			let res = await this.post('BatumiCRM.changeStatus', user.id, user.status);
			if (res) return this.alert('Error: ' + res);

			this.$emit('load', this.idu);
		},
		async changeUsername(user) {
			let newName = await this.prompt('Enter new username:', user.name);

			if (newName === user.name || newName === null) return;
			if (newName === '') return this.alert('Name cannot be empty');

			let err = await this.post('BatumiCRM.changeUsername', user.id, newName);
			if (err) return this.alert('Error: ' + err);

			this.$set(user, 'name', newName);

			this.$emit('load', this.idu);
		},
		async changeUserEmail(user) {
			let newEmail = await this.prompt('Enter new e-mail:', user.email);

			if (newEmail === user.email || newEmail === null) return;
			newEmail = newEmail.toLowerCase().trim();

			let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			if (!re.test(newEmail)) return this.alert('E-mail you have entered is not valid');

			let res = await this.post('BatumiCRM.changeUserEmail', user.id, newEmail);
			if (res) return this.alert('Error: ' + res);
			this.$set(user, 'email', newEmail);

			this.$emit('load', this.idu);
		},
		async addComment(user) {
			this.addCommentArea = this.addCommentArea.trim();
			if (!this.addCommentArea) return this.alert(this.ii('PLEASE_ENTER_COMMENT'));

			let newComment = this.addCommentArea;

			this.isSendingComment = true;
			let res = await this.post('BatumiCRM.addComment', this.idu, newComment);
			let n = new Date();
			this.$set(user.comments, user.comments.length, {
				text: newComment,
				time: Math.floor(n.getTime() / 1000) - 10,
				author: this.worker.realName,
				idw: this.worker.id,
				id: res
			});
			this.isSendingComment = false;
			this.addCommentArea = '';
		},
		async deleteComment(idc, user, i) {

			if (!(this.checkPermission('batumi:admin') || this.checkPermission('batumi:view_all')))
				return;

			let ok = await this.confirm(this.ii('CONFIRM_DELETE_COMMENT'));

			if (ok) {
				let res = await this.post('BatumiCRM.deleteComment', idc);
				if (res) return this.alert('Error: ' + res);

				this.$delete(user.comments, i);
			}
		},
		async getBatumiManagers() {

			let managers = await this.get('BatumiCRM.getManagers');

			managers.forEach(m => m.active = this.workersMap[m.idw].active);

			let managerItems = managers.map(m => {
				return {key: m.id, val: m.active ? m.name : m.name + ' (F)', sortVal: m.active ? 0 : 1};
			});

			if (!managerItems) return '';

			this.managersItems = [{
				key: 0,
				sortVal: 0
			}];

			this.managersItems = this.managersItems.concat(managerItems);

			return managers;
		},
		toggleCommentsCheckedAll(e) {
			if (e.target.checked) {
				this.user.comments.forEach((row, i) => {
					if (this.checkPermission('batumi:admin') || this.checkPermission('batumi:view_all')) {
						this.$set(this.checkedCommentsMap, i, {id: row.id, user: this.user, idx: i});
					}
				});
			} else {
				this.checkedCommentsMap = {};
			}
		},
		onChbClick(e, i, opts = {}) {
			let checked = e.target.checked;
			if (!checked) this.isCommentsCheckedAll = false;

			if (e.shiftKey && this.commentsChbLastIndex != null) {
				let start = Math.min(i, this.commentsChbLastIndex);
				let end = Math.max(i, this.commentsChbLastIndex);

				if (checked) {
					for (let i = start; i <= end; i++) {
						let row = this.user.comments[i];
						this.$set(this.checkedCommentsMap, i,
							{
								idx: i,
								id: row.id,
								user: this.user,
								filename: row.filename
							}
						);
					}
				} else {
					for (let i = start; i <= end; i++) {
						this.$delete(this.checkedCommentsMap, i);
					}
				}
			} else {
				if (checked) {
					this.$set(this.checkedCommentsMap, i, opts);
				} else {
					this.$delete(this.checkedCommentsMap, i);
				}
			}

			this.commentsChbLastIndex = i;
		},
		async addAnotherPhone(user) {
			let newPhone = this.phoneAdded;

			if (newPhone === null || newPhone === 0 || newPhone === '') return;

			let added = await this.get('BatumiCRM.isPhoneAdded', newPhone);
			if (added) return this.alert(this.ii('THIS_PHONE_IS_ADDED'));

			let res = await this.post('BatumiCRM.addPhone', user.id, newPhone);
			this.$set(user['phones'], user['phones'].length, {
				phone: newPhone,
				id: res
			});
			this.phoneAddedInfo = null;
			this.phoneAdded = '';

			this.$emit('load', this.idu);
		},
		async bulkDelete() {
			let ok = await this.confirm(this.ii('ARE_YOU_SURE'));
			if (!ok) return;

			let bulkComments = Object.values(this.checkedCommentsMap);

			for (let el = 0; el < bulkComments.length; el++) {
				this.$set(this.checkBoxSpinning, bulkComments[el].idx, 'spinning');

				let res = await this.post('BatumiCRM.deleteComment', bulkComments[el].id);

				if (res) {
					this.checkBoxSpinning[bulkComments[el].idx] = 'Error: ' + res;
				} else {
					this.checkBoxSpinning[bulkComments[el].idx] = 'done';
				}
			}

			this.checkedCommentsMap = {};
			this.isCommentsCheckedAll = false;
		},
		async deleteNumber(r, user, i) {
			let ids = [];
			let checked = false;
			user.phones.forEach(p => {
				if (p.checked) {
					ids.push(p.id);
					checked = true;
				}

			});
			if (!ids.length) ids.push(r.id);

			let ok = await this.confirm(this.ii(checked ? 'CONFIRM_DELETE_CHECKED_CONTACTS' : 'CONFIRM_DELETE_CONTACT'));
			if (!ok) return;

			let res = await this.post('BatumiCRM.deletePhone', ids);
			if (res) return this.alert(this.ii('ERROR') + ': ' + res);

			this.$emit('load', this.idu);

			if (!checked) return this.$delete(user['phones'], i);
			for (let j = 0; j < user.phones.length; j++) {
				if (user.phones[j] && user.phones[j].checked) {
					this.$delete(user['phones'], j);
					--j;
				}
			}
		},
		checkPhone(e, i) {
			let checked = e.target.checked;

			if (e.shiftKey && this.chbLastIndex != null) {
				let start = Math.min(i, this.chbLastIndex);
				let end = Math.max(i, this.chbLastIndex) + 1;

				this.user.phones.slice(start, end).forEach(row => {
					this.$set(row, 'checked', checked);
				});
			} else {
				let row = this.user.phones[i];
				this.$set(row, 'checked', !row.checked);
			}
			this.chbLastIndex = i;

			if (!checked) {
				this.chbLastIndex = false;
			}
		}
	},
	async mounted() {
		await this.loadState(['geoMap']);
		this.batumiManagers = await this.getBatumiManagers();
		if (this.idu) {
			await this.loadUserInfo();
		}
		this.isLoading = false;
	}
};
