const template = `
	<popup
		:width="popupWidth"
		:cls="'multi-input-popup ' + (params.customClass || '')"
		:hard-close="params.hardClose"
		:content-max-height="params.maxHeight"
		:content-min-height="params.minHeight"
		:no-scroll="params.noScroll"
		:scroll-to-bottom="params.scrollToBottom"
		close-on-back="1"
		@close="onClose"
	>
		<div slot="header">{{ params.header || ii('INPUT') }}</div>
		<div v-for="row in handledItems" class="clearfix">
			<template v-if="row">
				<template v-for="item in row" v-if="checkVisibility(item)">
					<div
						v-if="item"
						class="item-box left"
						:class="item.className"
						:style="getItemWidth(item)"
					>
						<div
							v-if="item.type !== 'checkbox' && item.type !== 'button' && item.label"
							class="general-upper"
						>
							{{ item.label }}:
							<span v-if="item.required" class="red">*</span>
						</div>
						<multi-sel
							v-if="item.type === 'multisel'"
							v-model="item.val"
							:items="item.items"
							:placeholder="item.placeholder"
							:disabled = "item.disabled"
							:presets = "item.presets"
							:with-not="item.withNot"
							@change="onChange($event, item)"
						></multi-sel>
						<rich-sel
							v-else-if="item.type === 'richsel'"
							v-model="item.val"
							:items="item.items"
							:items-url="item.itemsUrl"
							:item-max-width="item.itemMaxWidth"
							:placeholder="item.placeholder"
							:with-not="item.withNot"
							:disabled="item.disabled"
							@change="onChange($event, item)"
						></rich-sel>
						<range-date-picker
							v-else-if="item.type === 'rangeDatePicker'"
							v-model="item.val"
							:value="item.val"
							:presets="item.presets"
							:empty-text="item.emptyText"
							:empty-val="item.emptyVal"
							:unclearable="item.unclearable"
							:with-time="item.withTime"
							@change="onChange($event, item)"
						></range-date-picker>
						<date-picker
							v-else-if="item.type === 'datePicker'"
							v-model="item.val"
							:value="item.val"
							:type="item.format"
							:empty-text="item.emptyText"
							:empty-val="item.emptyVal"
							:unclearable="item.unclearable"
							:with-time="item.withTime"
							@change="onChange($event, item)"
						></date-picker>
						<date-list-picker
							v-else-if="item.type === 'dateList'"
							:dates-count="item.datesCount"
							:type="item.dateType"
							:with-time="item.withTime"
							v-model="item.val"
							@change="onChange($event, item)"
						></date-list-picker>
						<select
							v-else-if="item.type === 'select'"
							v-model="item.val"
							:disabled="item.disabled"
							@change="onChange($event, item)"
						>
							<option v-if="!item.noEmpty" :value="void 0"></option>
							<option v-for="item in item.items" :value="item.key">{{ item.val || item.text }}</option>
						</select>
						<label v-else-if="item.type === 'checkbox'" class="nowrap chb">
							<input
								type="checkbox"
								v-model="item.val"
								:true-value="1"
								:false-value="void 0"
								@change="onChange($event, item)"
							>
							<span>{{ item.label }}</span>
							<span v-if="item.required" class="red">*</span>
						</label>
						<btn
							v-else-if="item.type === 'button'"
							:size="item.size || 'small'"
							:color="item.color"
							:disabled="item.disabled"
							:loading="item.loading"
							:icon="item.icon"
							@click.native="item.onClick($event, item)"
						>
							<span>{{ item.label }}</span>
						</btn>
						<textarea
							v-else-if="item.type === 'textarea'"
							v-model.lazy="item.val"
							:placeholder="item.placeholder"
							:disabled = "item.disabled"
							@change="onChange($event, item)"
						></textarea>
						<input
							v-else-if="item.type === 'number'"
							type="number"
							:placeholder="item.placeholder"
							:disabled="item.disabled"
							:min="item.min"
							:max="item.max"
							v-model.lazy="item.val"
							@change="onChange($event, item)"
							class="input"
						>
						<input
							v-else-if="item.type === 'text'"
							type="text"
							:placeholder="item.placeholder"
							:disabled="item.disabled"
							v-model.lazy="item.val"
							@change="onChange($event, item)"
							class="input"
						>
						<input
							v-else-if="item.type === 'time'"
							type="time"
							:placeholder="item.placeholder"
							:disabled="item.disabled"
							v-model.lazy="item.val"
							@change="onChange($event, item)"
							class="input"
							required
						>
						<suggestion-field
							v-else-if="item.type === 'suggestionField'"
							:items="item.items"
							:items-url="item.itemsUrl"
							:items-method="item.itemsMethod"
							:max-items="item.maxItems"
							:placeholder="item.placeholder"
							v-model="item.val"
							@change="onChange($event, item)"
						></suggestion-field>
						<span v-else-if="item.type === 'span'" v-html="item.html"></span>
						<div v-else-if="item.type === 'div'" v-html="item.html"></div>
					</div>
					<br v-else>
				</template>
			</template>
		</div>
		<div slot="footer">
			<div class="multi-input-popup-footer-button-box">
				<div v-if="footerItems.length" class="clearfix">
					<template v-for="item in footerItems">
						<div v-if="item" class="item-box right" :class="item.className">
							<btn
								v-if="item.type === 'button'"
								:size="item.size || 'small'"
								:color="item.color"
								:disabled="item.disabled"
								:loading="item.loading"
								:icon="item.icon"
								@click.native="item.onClick"
							>
								{{ item.label }}
							</btn>
							<span v-else-if="item.type === 'span'" v-html="item.html"></span>
							<div v-else-if="item.type === 'div'" v-html="item.html"></div>
						</div>
						<br v-else>
					</template>
				</div>
				<div v-else class="text-center">
					<btn size="small" icon="check" @click.native="onClose($event, getPopupData())" :loading="params.isLoading">
						{{ ii('OK') }}
					</btn>
					<btn size="small" icon="remove" @click.native="onClose" color="cancel">
						{{ ii('CANCEL') }}
					</btn>
				</div>
			</div>
		</div>
	</popup>
`;
export default {
	template,
	props: ['params'],
	computed: {
		items() {
			if (!this.params.items || !this.isArray(this.params.items)) return [];
			return this.params.items;
		},
		footerItems() {
			if (!this.params.footerItems || !this.isArray(this.params.footerItems)) return [];
			return this.params.footerItems;
		},
		split() {
			let split = parseInt(this.params.split);
			if (isNaN(split) || split < 1) split = 1;

			return split;
		},
		popupWidth() {
			if (this.params.width) return this.params.width;
			if (this.split > 2) return 200 * this.split;

			return 400;
		},
		handledItems() {
			let items = [];
			let firstKey = 0;
			let rowWeight = 0;

			this.items.forEach((item, key) => {
				if (!item) {
					items.push([item]);
					rowWeight = 0;
					return;
				}

				let weight = parseInt(item.weight);
				if (isNaN(weight) || weight < 1) weight = 1;
				if (rowWeight === 0) firstKey = key;
				rowWeight += weight;

				if (rowWeight > this.split && firstKey !== key) {
					items.push(this.items.slice(firstKey, key));
					rowWeight = weight;
					firstKey = key;
				}
				if (rowWeight >= this.split || !this.items[key + 1]) {
					items.push(this.items.slice(firstKey, key + 1));
					rowWeight = 0;
				}
			});

			return items;
		},
		requiredKeys() {
			return this.items.filter(i => i && i.key && i.required).map(i => i.key);
		}
	},
	methods: {
		checkVisibility(item) {
			if (!item || item.visible === undefined) return true;
			if (typeof item.visible === 'function') {
				return !!item.visible(this.getPopupData().data);
			}
			return !!item.visible;
		},
		getItemWidth(item) {
			let colWidth = this.split ? (100 / this.split) : 100;
			let weight = item.weight ? item.weight : 1;
			let width = colWidth * weight;

			return 'width: ' + (width > 100 ? 100 : width) + '%';
		},
		getPopupData() {
			let res = {
				data: {},
				close: (e) => this.onClose(e),
				isLoading: (val) => this.$set(this.params, 'isLoading', val)
			};

			this.items.forEach(item => {
				if (!item || !item.key) return;
				res.data[item.key] = item.val || '';
			});

			return res;
		},
		onClose(e, res = null) {
			if (res && res.data) {
				let ok = true;
				this.requiredKeys.forEach(key => {
					if (!res.data[key]) ok = false;
				});

				if (!ok) return this.alert(this.ii('PLEASE_ENTER_REQUIRED_FIELDS'));
			}

			this.$emit('close', res);
		},
		onChange(e, item) {
			this.setValue(item.key, item.val);

			if (typeof item.onChange === 'function') {
				item.onChange(e, item);
			}
		},
		getItemByKey(key) {
			let items = this.items.filter(i => i && i.key === key);
			return items[0] || null;
		},
		setValue(key, value) {
			let item = this.getItemByKey(key);
			if (!item) return;

			this.$delete(item, 'val');
			this.$set(item, 'val', value);
		},
	}
};