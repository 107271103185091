const template = `
	<div class="tbl-outer">
		<slot name="beforeTbl"></slot>
		<div class="tbl-box" :style="tblBoxStyle">
			<div>
				<div class="tbl-stat-left" v-if="fields">
					<template v-if="statFn">
						<template v-if="statList">
							<span v-for="item in statList">
								<span class="general-upper">{{ item.label }}:</span> {{ item.val }}
							</span>
						</template>
						<i v-else class="fa fa-spinner spin-fast stat-spinner"></i>
					</template>
					<span v-else-if="totalCnt != null" class="left">
						<span class="general-upper">{{ ii('TOTAL') }}:</span>
						<i v-if="totalCnt === -1" class="fa fa-spinner spin-fast"></i>
						<template v-else>{{ totalCnt | numFormat }}</template>
					</span>
					<span v-if="exportMethod">
						<span class="general-upper">{{ ii('EXPORT') }}:</span>
						<span v-if="isExporting" class="fa fa-fw fa-spinner spin-fast"></span>
						<span v-else class="link fa fa-fw fa-download" @click="exportFile"></span>
					</span>
					<slot name="stat">&nbsp;</slot>
				</div>
				<div v-else>&nbsp;</div>
				<div class="right">
					<em v-if="loadTime">
						<span>{{ ii('LOAD_TIME') }}: {{ Number(loadTime).toFixed(5) }}</span>
					</em>
					<template v-else>&nbsp;</template>
				</div>
			</div>
			<table class="general-tbl hover-tbl filled-tbl" ref="table">
				<thead>
				<slot name="head">
					<tr v-if="fields">
						<th>
							<head-cell-inner sort="">#</head-cell-inner>
						</th>
						<th v-for="field in fields">
							<head-cell-inner
								:sort="noSortFieldsArr.indexOf(field) === -1 ? field : undefined"
							>{{ getFieldText(field) }}</head-cell-inner>
						</th>
					</tr>
				</slot>
				</thead>
				<tbody ref="tbody">
				<template v-for="(row, i) in handledRows">
					<slot name="row" :row="row" :index="i">
						<tr>
							<td class="text-center">{{ i + 1 }}</td>
							<template v-for="(cell, field, j) in row">
								<td v-if="hiddenFieldsArr.indexOf(field) === -1">
									<slot :name="field" :row="row" :cell="cell">
										<datetime
											v-if="isTimestamp(cell)"
											:timestamp="cell"
										></datetime>
										<worker-tooltip
											v-else-if="field === 'idw'"
											:idw="cell"
										>
											{{ getWorker(cell).realName || cell }}
										</worker-tooltip>
										<div
											v-else-if="typeof cell === 'number' && field !== 'id'"
											class="text-center"
										>
											{{ cell | num }}
										</div>
										<template v-else>{{ cell }}</template>
									</slot>
								</td>
							</template>
						</tr>
					</slot>
				</template>
				</tbody>
				<tfoot ref="tfoot">
				<slot name="tfoot"></slot>
				<tr v-show="rows && rows.length && isLoadingMore">
					<td colspan="100" class="text-center">
						<i class="fa fa-spinner spin-fast"></i>
						{{ ii('LOADING') }}...
					</td>
				</tr>
				<tr v-show="isNoMore">
					<td colspan="100" class="text-center">
						{{ handledRows && handledRows.length ? ii('NO_MORE_DATA') : ii('NO_DATA') }}
					</td>
				</tr>
				</tfoot>
			</table>
		</div>
		<slot name="afterTbl"></slot>
	</div>
`;

export default {
	template,
	props: [
		'query',
		'remoteMethod',
		'remoteStatMethod',
		'exportMethod',
		'beforeExportFn',
		'fieldMap',
		'hiddenFields',
		'noSortFields',
		'localControl',
		'portionSize',
		'filterFn',
		'transformRowFn',
		'width',
		'reloadIgnoreFields',
		'smallHeader',
		'stat-fn',
		'defLocSortField',
		'loadLocAll',
		'additionalRemoteParams'
	],
	data() {
		return {
			statList: null,
			totalCnt: null,
			rows: null,
			isLoadingMore: false,
			isNoMore: false,
			portion: 0,
			loadTime: null,
			defaultPortionSize: 50,
			localShownCnt: 0,
			reloadParam: null,
			isExporting: false,
			lastGetStatRequest: 0
		};
	},
	computed: {
		params() {
			return this.query || this.app.query;
		},
		fields() {
			if (!this.rows || !this.rows[0]) return null;

			return Object.keys(this.rows[0]).filter(key => this.hiddenFieldsArr.indexOf(key) === -1);
		},
		hiddenFieldsArr() {
			if (!this.hiddenFields) return [];
			return this.hiddenFields.split(',');
		},
		noSortFieldsArr() {
			if (!this.noSortFields) return [];
			return this.noSortFields.split(',');
		},
		handledRows() {
			if (!this.rows) return null;
			if (!this.localControl) return this.rows;

			let rows = this.rows;
			let sort = this.params.sort || this.defLocSortField;
			if (sort) {
				let key = sort;
				if (sort.startsWith('-'))
					key = sort.substr(1);
				let dir = sort.startsWith('-') ? 1 : -1;
				rows = rows.sort((a, b) => {
					if (a[key] === null) return dir;
					if (b[key] === null) return -dir;
					if (a[key] < b[key]) return dir;
					if (a[key] > b[key]) return -dir;
					return 0;
				});
			}

			if (this.filterFn) {
				rows = rows.filter(this.filterFn);
			}

			this.totalCnt = rows.length;
			if(!this.loadLocAll)
				rows = rows.slice(0, this.localShownCnt);

			return rows;
		},
		tblBoxStyle() {
			if (!this.width) return null;
			let w = isNaN(this.width) ? this.width : (this.width + 'px');
			return {minWidth: w};
		}
	},
	methods: {
		async getRows(opts = {}) {
			if (this.isLoading) return;

			if (opts.isAppend) {
				if (this.isNoMore) return;

				this.portion++;
				this.isLoadingMore = true;
			} else {
				this.portion = 1;
				this.isNoMore = false;
				this.loadTime = null;
				this.setLoading(true);

				this.tryLoadStat();
			}

			this.isLoading = true;
			let portion = this.portion;

			let params = this.params
			if (opts.isAppend && this.additionalRemoteParams) {
				params = {...params, ...this.additionalRemoteParams};
			}

			let res = await this.getFull(this.remoteMethod, params, this.portion, opts.extraData);

			this.isLoading = false;
			this.isLoadingMore = false;
			this.setLoading(false);

			if (res.data.err) {
				this.$emit('error', this.getRemoteCallError(res));
				return;
			}

			this.$emit('load', res.data.res, portion);

			let rows = res.data.res.rows;
			if (this.transformRowFn) {
				rows = rows.map(this.transformRowFn);
			}
			if (res.data.res.totalCnt != null) {
				this.totalCnt = res.data.res.totalCnt;
			}

			this.loadTime = res.headers['x-request-time'];

			if (!opts.isAppend) {
				if (this.localControl) {
					this.localShownCnt = this.portionSize || this.defaultPortionSize;
				}
				this.rows = [];
			}

			if (!rows.length) {
				this.isNoMore = true;
				return;
			}

			this.rows = this.rows.concat(rows);
		},
		async tryLoadStat() {
			if (!this.remoteStatMethod || this.localControl) return;

			this.statList = null;
			this.totalCnt = -1;

			let requestNumber = ++this.lastGetStatRequest;

			let stat = await this.get(this.remoteStatMethod, this.params);
			if(requestNumber !== this.lastGetStatRequest) return;
			this.totalCnt = stat.totalCnt;

			if (this.statFn) {
				this.statList = this.statFn(stat);
			}
		},
		async exportFile() {
			let data;
			if (this.beforeExportFn) {
				data = await this.beforeExportFn();
				if (!data) return;
			}
			this.isExporting = true;
			await this.download(this.exportMethod, this.params, data);
			this.isExporting = false;
		},
		getFieldText(field) {
			return this.fieldMap && this.fieldMap[field] || field;
		},
		isTimestamp(val) {
			if (isNaN(val)) return false;
			return (val > 1300000000 && val < 1600000000);
		},
		reload(extraData) {
			this.getRows({extraData});
		},
		onScrollToBottom() {
			if (this.localControl) {
				this.localShownCnt += this.portionSize || this.defaultPortionSize;
			} else {
				this.getRows({isAppend: true});
			}
		},
		refreshReloadParam() {
			let query = {...this.params};

			if (this.reloadIgnoreFields) {
				this.reloadIgnoreFields.split(',').forEach(field => {
					delete query[field];
				});
			}

			this.reloadParam = this.toJson(query);
		}
	},
	watch: {
		params() {
			if (this.localControl) {
				this.localShownCnt = this.portionSize || this.defaultPortionSize;
			} else {
				this.refreshReloadParam();
			}
		},
		reloadParam() {
			this.getRows();
		}
	},
	async mounted() {
		this.setLoading(true);
		await this.waitRouterInited();
		this.refreshReloadParam();
		this.sub('scrollToBottom', this.onScrollToBottom);
	},
	destroyed() {
		this.unsub('scrollToBottom', this.onScrollToBottom);
	}
};
