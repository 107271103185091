const template = `
	<div class="subpanels-box">
		<template v-for="link in links">
			<template v-if="shouldRender(link)">
				<router-link
					v-if="noReload"
					:to="link.url"
					:class="itsActiveRoute(link.route) ? 'active' : ''"
				>{{ ii(link.text) }}</router-link><a
					v-else
					:href="link.url"
					:class="itsActiveRoute(link.route) ? 'active' : ''"
				>{{ ii(link.text) }}</a>
			</template>
		</template>
	</div>
`;

export default {
	template,
	props: ['links', 'no-reload'],
	methods: {
		shouldRender(link) {
			if (link.permission && !this.checkPermission(link.permission)) return false;
			return true;
		},
		itsActiveRoute(route) {
			if(this.isArray(route)){
				return route.includes(this.app.routeName);
			}
			else {
				return this.app.routeName === route;
			}
		}
	}
};
