import Store from '../../store.js';

const template = `
	<div class="news">
		<div v-if="isLoading" class="loading">
			<span>
				<i class="fa fa-spinner spin-fast center"></i>
				<span class="general-upper">{{ ii('LOADING') }}...</span>
			</span>
		</div>
		<template v-else>
			<div>
				<div>
					<div class="left" style="margin: 10px 0 5px 0">
						<span>
							<span class="general-upper">{{ ii('TOTAL') }}:</span>
							<i v-if="totalCnt === null" class="fa fa-spinner spin-fast"></i>
							<span v-else>{{ totalCnt | numFormat }}</span>
						</span>
					</div>
				</div>
				
				<table class="mc-tbl hover-tbl timeline-table" :class="opacity ? 'opacity05' : ''">
					<thead>
						<tr>
							<th><head-cell-inner no-sticky="1">{{ ii('TIME') }}</head-cell-inner></th>
							<th><head-cell-inner no-sticky="1">{{ ii('TYPE') }}</head-cell-inner></th>
							<th><head-cell-inner no-sticky="1">{{ ii('EVENT') }}</head-cell-inner></th>
						</tr>
					</thead>
					<tbody>
					<tr v-for="(row, i) in rows">
						<td class="text-center width250"><datetime :timestamp="row.ts"></datetime></td>
						<td class="text-center"><p class="event-icon"><i :class="row.icon"></i></p></td>
						<td>
							<p class="event-title" v-html="row.title"></p>
							<p v-html="row.body"></p>
						</td>
					</tr>
					</tbody>
					<tfoot>
					<tr v-show="rows && rows.length && isLoadingMore">
						<td colspan="100" class="text-center">
							<i class="fa fa-spinner spin-fast"></i>
							Loading...
						</td>
					</tr>
					<tr v-show="isNoMore">
						<td colspan="100" class="text-center">
							{{ rows && rows.length ? 'No more data' : 'No data' }}
						</td>
					</tr>
					</tfoot>
				</table>
			</div>
			<div class="news-bottom">
				<span
					class="link upper"
					v-if="rows && !isNoMore && totalCnt > query.limit && rows.length < totalCnt"
					@click="loadEvents(false)"
					:loading="isLoadingMore"
				>
					<i class="fa fa-fw fa-cloud-download" slot="icon"></i>
					<strong>{{ ii('LOAD_MORE') }}</strong>
				</span>
			</div>
		</template>
	</div>
`;

export default {
	template,
	data() {
		return {
			isLoading: true,
			isLoadingMore: false,
			isNoMore: false,
			opacity: false,
			totalCnt: null,
			allRows: null,
			rows: null,
			types: null,
			clientRequestPopupId: null,
			reminderPopupData: null,
			query: {
				portion: 0,
				limit: 50,
				type: null,
				status: null,
				date: null
			}
		};
	},
	props: ['idu'],
	computed: {
		...Store.mapState(['worker', 'leadMetrics', 'managersMap', 'workersMap'])
	},
	methods: {
		async loadEvents(firstTime = true) {
			if (firstTime) {
				this.opacity = true;
				this.query.portion = 1;
				this.isNoMore = false;
				this.allRows = await this.get('UserCard.getTL3CXEvents', this.idu);
				if (!this.allRows) this.allRows = [];

				this.totalCnt = this.allRows.length;
			} else {
				this.query.portion++;
				this.isLoadingMore = true;
			}
			if (this.isNoMore) return;

			let offset = (this.query.portion - 1) * this.query.limit;
			let rows = this.allRows.slice(offset, offset + this.query.limit);

			this.isLoading = false;
			this.isLoadingMore = false;
			this.opacity = false;

			if (firstTime) this.rows = [];
			if (!rows.length) return this.isNoMore = true;

			this.rows = this.rows.concat(rows);
			if (this.rows.length == this.totalCnt && !firstTime) this.isNoMore = true;
		}
	},
	async mounted() {
		if (this.idu) {
			//if (this.events) this.$set(this.query, 'type', this.events);
			await this.loadEvents();
		}
		this.isLoading = false;
	}
};
