const template = `
	<tab-links :links="links"></tab-links>
`;

export default {
	template,
	data() {
		return {
			links: [
				{
					permission: 'page:stats',
					url: '/stats',
					route: 'stats',
					text: 'Overall'
				},
				{
					permission: 'page:activity',
					url: '/activity',
					route: 'activity',
					text: 'Daily user activity'
				}
			]
		};
	}
};