const template = `
	<transition name="fade">
		<div v-show="shown" class="full-image" @click="close('click')">
			<div class="fimg-outer">
				<div class="fimg-inner">
					<div class="fimg-content">
						<div class="fimg-text">
							<template v-if="isError">{{ ii('CANNOT_SHOW_IMAGE') }}</template>
							<template v-else-if="isLoading">
								<i class="fa fa-spinner spin-fast"></i>
                                <span class="general-upper">{{ ii('LOADING') }}...</span>
							</template>
							<template v-else>
								<a
									:href="url"
									class="fimg-url"
									target="_blank"
									rel="noreferrer noopener"
									@click.stop
								>{{ url }}</a>
								<span v-if="size" class="fimg-size">
									{{ ii('SIZE') }}: {{ size.width }} x {{ size.height }}
								</span>
							</template>
						</div>
						<img
							:src="imageUrl"
							alt=""
							@load="onLoad"
							@error="onError"
							@dragstart.prevent
						>
					</div>
				</div>
			</div>
		</div>
	</transition>
`;

export default {
	template,
	props: ['url', 'type', 'proxy'],
	data() {
		return {
			imageUrl: null,
			shown: false,
			isLoading: true,
			isError: null,
			size: null
		};
	},
	methods: {
		onLoad(e) {
			this.isLoading = false;
			if (e.target.naturalWidth) {
				this.size = {
					width: e.target.naturalWidth,
					height: e.target.naturalHeight
				};
			}
		},
		onError() {
			this.isError = true;
		},
		async close(type) {
			this.shown = false;

			if (type !== 'back') {
				this.backButtonPop();
			}

			await this.sleep(500);
			this.$emit('close');
		}
	},
	mounted() {
		this.hideAllTitles();

		this.isLoading = true;
		this.imageUrl = this.proxy ? this.getProxyUrl(this.url, this.type) : this.url;
		this.shown = true;

		this.backButtonPush(() => this.close('back'));
	}
};
