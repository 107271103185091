import Store from '../../store.js';

const template = `
	<div class="phone-input" :data-val="value" v-mousedown-outside="hideSuggestions">
		<div>
			<div
				class="phone-val-html"
				ref="codeHtml"
				@click="showSuggestions"
			>
				<flag :cc="phoneGeo"></flag>
				<span>+</span>
			</div>
			<input
				type="text"
				v-model="number"
				class="phone-val-tf"
				ref="numberTf"
				:disabled="disabled"
				:placeholder="placeholder"
				@input="onNumberInput"
				@keyup.enter="emit(true)"
				@blur="emit(true)"
			>
			<div ref="suggestBox" :class="['phone-dd', 'phone-dd-' + ddPos, 'phone-dd-' + ddPosY]" v-show="suggestionsShown">
				<div class="phone-search-box">
					<input
						type="text"
						class="phone-search-tf"
						v-model="searchVal"
						ref="searchTf"
						@keydown.down.prevent="moveDown"
						@keydown.up.prevent="moveUp"
						@keydown.page-up.prevent="moveUpFast"
						@keydown.page-down.prevent="moveDownFast"
						@keydown.enter.prevent="choose"
						@keydown.esc="hideSuggestions"
						:placeholder="searchPlaceholder || ii('SEARCH')"
					>
				</div>
				<div class="phone-dd-items" ref="itemsBox" v-block-scroll>
					<div
						v-for="(item, index) in filteredItems" v-if="item"
						:class="['phone-dd-item', {'phone-hilited': (index === hiliteIndex)}]"
						:ref="'item' + item.key"
						:title="item.title"
						@mouseover="hiliteIndex = index"
						@click="choose"
					>
						<div v-if="item.html" v-html="item.html"></div>
						<div v-else>{{ item.val || '&nbsp;' }}</div>
					</div>
					<div v-else class="phone-dd-sep"></div>
				</div>
			</div>
		</div>
	</div>
`;

export default {
	template,
	data() {
		return {
			suggestionsShown: false,
			searchVal: '',
			number: '',
			hiliteIndex: 0,
			movingFastStep: 10,
			ddPos: null,
			ddPosY: null,
			phoneGeo: null,
			formatTm: null
		};
	},
	props: [
		'value', 'no-empty', 'placeholder', 'search-placeholder', 'default-geo', 'format-callback', 'disabled'
	],
	computed: {
		...Store.mapState(['geo', 'geoMap']),
		items() {
			if (!this.geoMap) return;

			let items = Object.keys(this.geoPhoneMap).map(geo => {
				let phone = this.geoPhoneMap[geo];

				let cc = geo.toUpperCase();
				let country = this.getCountryByCode(cc);
				if (!country) return;

				let val = phone + ' - ' + country;

				let html = `<div class="phone-code-suggestion">
					${this.getFlagHtml(cc)}
					<strong>${country}</strong>
					<span>+${phone}</span>
				</div>`;
				let valHtml = `<div>
					${this.getFlagHtml(cc)}
					<span>+${phone}</span>
				</div>`;

				let sVal = '+' + phone + '*' + cc + '*' + country;
				if (cc === 'GB') {
					sVal += '*UK*United Kingdom*Great Britain';
				} else if (cc === 'GR') {
					sVal += '*EL';
				} else if (cc === 'US') {
					sVal += '*USA*United states of America';
				} else if (cc === 'NL') {
					sVal += '*Holland';
				} else if (cc === 'RU') {
					sVal += '*Russian Federation';
				} else if (cc === 'AE') {
					sVal += '*UAE';
				}
				if (country !== this.geoMap[cc]) {
					sVal += '*' + this.geoMap[cc];
				}

				let key = geo;
				let sortVal = country;

				return {key, val, sVal, sortVal, html, valHtml, phone};
			}).filter(item => item).sort((a, b) => {
				if (a.sortVal == null && b.sortVal == null) return 0;

				if (a.sortVal > b.sortVal) return 1;
				if (a.sortVal < b.sortVal) return -1;

				return 0;
			});

			return items;
		},
		filteredItems() {
			let items = this.items.filter(this.checkItemMatch);
			if (this.searchVal || this.noEmpty) return items;

			let emptyItem = {key: null, val: ''};
			return [emptyItem, ...items];
		}
	},
	methods: {
		getFlagHtml(geo) {
			return `<img src="/img/pixel.png" class="flag flag-${geo.toLowerCase()}">`;
		},
		getCurCodeItem() {
			return this.filteredItems.filter(item => item.key === this.phoneGeo)[0];
		},
		showSuggestions() {
			this.suggestionsShown = true;
			this.ddPos = 'left';
			this.ddPosY = 'bottom';

			let item = this.getCurCodeItem();
			let index = this.filteredItems.indexOf(item);
			if (index === -1) {
				index = 0;
			}
			this.hiliteIndex = index;

			this.$nextTick(() => {
				this.tryScroll();
				this.$refs.searchTf.focus();

				let rect = this.$refs.suggestBox.getBoundingClientRect();

				let overlayX = rect.right - window.innerWidth + 50;
				this.ddPos = overlayX < 0 ? 'left' : 'right';

				let overlayY = rect.bottom - window.innerHeight;
				//this.ddPosY = overlayY < 0 ? 'bottom' : 'top';
			});
		},
		hideSuggestions() {
			this.suggestionsShown = false;
			this.searchVal = '';
		},
		getHilitedItem() {
			return this.filteredItems[this.hiliteIndex];
		},
		checkItemMatch(item) {
			if (!this.searchVal) return true;

			if (!item) return false;

			let regex = new RegExp(this.escapeRegex(this.searchVal), 'i');
			return regex.test(item.sVal || item.val);
		},
		moveUp(opt) {
			if (--this.hiliteIndex < 0) {
				this.hiliteIndex = this.filteredItems.length - 1;
			}
			if (!opt.repeated && !this.getHilitedItem()) return this.moveUp({repeated: true});
			this.$nextTick(this.tryScroll);
		},
		moveDown(opt) {
			if (++this.hiliteIndex >= this.filteredItems.length) {
				this.hiliteIndex = 0;
			}
			if (!opt.repeated && !this.getHilitedItem()) return this.moveDown({repeated: true});
			this.$nextTick(this.tryScroll);
		},
		moveUpFast(opt) {
			this.hiliteIndex -= this.movingFastStep;
			if (this.hiliteIndex < 0) {
				this.hiliteIndex = 0;
			}
			if (!opt.repeated && !this.getHilitedItem()) return this.moveUp({repeated: true});
			this.$nextTick(this.tryScroll);
		},
		moveDownFast(opt) {
			this.hiliteIndex += this.movingFastStep;
			if (this.hiliteIndex >= this.filteredItems.length) {
				this.hiliteIndex = this.filteredItems.length - 1;
			}
			if (!opt.repeated && !this.getHilitedItem()) return this.moveDown({repeated: true});
			this.$nextTick(this.tryScroll);
		},
		choose() {
			let item = this.filteredItems[this.hiliteIndex];
			if (!item) return;

			let lastCode = (this.geoPhoneMap[this.phoneGeo] || '').replace(/[^0-9]/g, '');

			this.phoneGeo = item.key;
			let code = (this.geoPhoneMap[this.phoneGeo] || '').replace(/[^0-9]/g, '');

			if (lastCode && this.number.indexOf(lastCode) === 0) {
				this.number = this.number.replace(lastCode, code);
			} else {
				this.number = code;
			}

			this.emit(true);

			this.hideSuggestions();
			this.$refs.numberTf.focus();
		},
		emit(changed = false) {
			this.$emit('input', this.number);
			if (changed) {
				this.$emit('change', {isTrusted: true});
			}
		},
		tryScroll() {
			let item = this.filteredItems[this.hiliteIndex];
			if (!item) return;

			let itemNodes = this.$refs['item' + item.key];
			if (!itemNodes || !itemNodes[0]) return;

			let itemNode = itemNodes[0];
			let itemPos = itemNode.getBoundingClientRect();

			let itemsBox = this.$refs.itemsBox;
			let itemsBoxPos = itemsBox.getBoundingClientRect();

			let extraDistBottom = itemPos.bottom - itemsBoxPos.bottom;
			if (extraDistBottom > 0) {
				itemsBox.scrollTop += extraDistBottom;
			}

			let extraDistTop = itemsBoxPos.top - itemPos.top;
			if (extraDistTop > 0) {
				itemsBox.scrollTop -= extraDistTop;
			}
		},
		handleNumber() {
			let regex = /[^0-9]/g;
			if (regex.test(this.number)) {
				this.number = this.number.replace(regex, '');
			}
			let maxLen = 15;
			if (this.number.length > maxLen) {
				this.number = this.number.slice(0, maxLen);
			}
			this.phoneGeo = this.getGeoFromPhone(this.number);
		},
		onNumberInput() {
			this.handleNumber();
			this.emit(false);
		},
		removeBadChars(raw) {
			return raw.replace(/[^0-9() -]/g, '');
		}
	},
	watch: {
		searchVal() {
			this.hiliteIndex = 0;
			this.$nextTick(this.tryScroll);
		},
		number() {
			if (!this.formatCallback || typeof this.formatCallback !== 'function') return;

			if (this.formatTm) {
				clearTimeout(this.formatTm);
			}
			if (this.number.length < 7) return this.formatCallback(null);

			let number = this.number;
			this.formatTm = setTimeout(() => {
				let params = {number};
				let cancelToken = this.tokens('phoneFormatter');
				axios('/api/phone/format', {params, cancelToken}).then(res => {
					if (!res) return;

					this.formatCallback(res.data);
				});
			}, 300);
		},
		value() {
			this.number = this.value;
			this.handleNumber();
		}
	},
	created() {
		if (this.isObjectEmpty(this.geoMap)) {
			this.loadState(['geoMap']);
		}
		this.number = this.value;
		this.handleNumber();
	}
};




