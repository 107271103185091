const template = `
	<div class="file-area">
		<div v-if="description" class="file-description">{{ description }}</div>
		<div :class="classes" v-title="title" @click="onClick">
			<div class="file-area-text">{{ text || ii(defaultText) }}</div>
			<div v-if="files.length" class="file-area-subtext">
				{{ files.length === 1 ? ii('ONE_FILE') : iiArgs('N_FILES', files.length) }}
			</div>
		</div>
		<input type="file" ref="file" :multiple="multiple ? 'multiple' : null" @change="onChange">
	</div>
`;

export default {
	template,
	props: ['text', 'multiple', 'description'],
	data() {
		return {
			defaultText: 'CLICK_OR_DROP_FILE_HERE',
			isOver: false,
			files: []
		};
	},
	computed: {
		classes() {
			let map = {
				'file-area-box': true,
				'file-area-box-over': this.isOver,
				'file-area-with-files': this.files.length
			};
			return map;
		},
		title() {
			if (!this.files.length) return;

			let names = Array.from(this.files).map(file => file.name);
			return names.join('\n');
		}
	},
	methods: {
		onClick() {
			this.$refs.file.click();
		},
		onChange(e) {
			this.files = e.target.files || [];
		},
		onDragEnter() {
			this.isOver = true;
		},
		onDragLeave() {
			this.isOver = false;
		},
		onDragOver(e) {
			e.preventDefault();
			this.isOver = true;
		},
		onDrop(e) {
			e.preventDefault();
			this.isOver = false;

			let files = e.dataTransfer.files;
			if (!this.multiple) {
				files = [files[0]];
			}
			this.files = files;
		}
	},
	watch: {
		files() {
			let val = this.files;
			if (!this.multiple) {
				val = val[0];
			}
			this.$emit('input', val);
			this.$emit('change', {
				trusted: true,
				value: val
			});
		}
	},
	mounted() {
		document.addEventListener('dragenter', this.onDragEnter);
		document.addEventListener('dragleave', this.onDragLeave);
		document.addEventListener('dragover', this.onDragOver);
		document.addEventListener('drop', this.onDrop);
	},
	destroyed() {
		document.removeEventListener('dragenter', this.onDragEnter);
		document.removeEventListener('dragleave', this.onDragLeave);
		document.removeEventListener('dragover', this.onDragOver);
		document.removeEventListener('drop', this.onDrop);
	}
};
