const template = `
	<div v-if="checkGroup('developer_links')" class="developer-links">
		<template v-for="(link, i) in links">
			<span v-if="i !== 0" class="m-left5">|</span><a
				v-if="link.url"
				:href="link.url"
				class="static-color m-left5"
				target="_blank"
				rel="noreferrer noopener"
			>{{ link.text || 'Link' }}</a><a
				v-if="link.table"
				:href="getPmaUrl(link)"
				class="static-color m-left5"
				target="_blank"
				rel="noreferrer noopener"
			>{{ link.text || link.table }}</a><a
				v-else-if="link.index"
				:href="getElasticUrl(link)"
				class="static-color m-left5"
				target="_blank"
				rel="noreferrer noopener"
			>{{ link.text || 'Elastic' }}</a>
		</template>
	</div>
`;

export default {
	template,
	props: ['links', 'partner'],
	data() {
		return {
			partnerServersMap: {
				moradam: '89.38.96.85',
				mooseify: '89.38.96.86',
				azaseo: '89.38.96.99',
				webroute: '89.38.96.88'
			}
		};
	},
	methods: {
		getPmaUrl(link) {
			let server = link.server || 's14';
			let db = link.db || 'semalt';
			let tbl = link.table || '';

			if (this.partner) {
				server = this.partnerServersMap[this.partner];
				let dbMap = {
					'tasks_semalt': this.partner + '_tasks',
					'eff_stage': this.partner + '_eff_stage',
					'romastat': this.partner + '_stat',
					'semalt_keywords': this.partner + '_keywords',
					'semalt_activity': this.partner + '_activity',
					'seoteam': this.partner + '_seoteam',
					'semalt': this.partner
				};
				db = dbMap[db] || db;
			}

			let url = 'https://pma.icctech.org/' + this.urlEnc(server) + '/sql.php?server=1&db=' + this.urlEnc(db) + '&table=' + this.urlEnc(tbl) + '&pos=0';
			return url;
		},
		getElasticUrl(link) {
			let cluster = link.cluster || 'el.semalt.com';
			let index = link.index;
			let url = 'http://' + cluster + '/' + index + '/_search?pretty';
			return url;
		}
	}
};
