// jshint ignore: start
const template = `
	<popup
		width="400px"
		@close="emitClose()"
		no-scroll="1"
	>
		<div slot="header">Google Calendar Reminder</div>
		
		<div class="body">
			<div class="body-dates">
				<date-picker v-model="date"></date-picker>
				<input type="time" v-model="time">
			</div>
			<div class="body-dates">
				<label>
					Duration:
					<span class="link" v-title="'All times are in UTC'">?</span> 
				</label>
				<select
					v-model="dateEnd"
				>
					<option :value="1">1 minute</option>
					<option :value="10">10 minutes</option>
					<option :value="30">30 minutes</option>
					<option :value="60">1 hour</option>
					<option :value="180">3 hours</option>
					<option :value="1440">1 day</option>
				</select>
			</div>
			<div class="body-title">
				<label>Event title:</label>
				<input type="text" v-model="eventTitle" class="fullWidth">
				<span v-if="(!eventTitle || !eventTitle.trim()) && isNotification" class="notification">
					* Please, fill this field
				</span>
			</div>
			<div class="body-description">
				<label>Event description:</label>
				<textarea v-model="eventDescription"></textarea>
				<span v-if="!eventDescription && isNotification" class="notification">* Please, fill this field</span>
			</div>
			<div class="body-emails">
				<div v-for="(email, i) in emails" class="body-emails-email">
					<template v-if="i === 0">
						<input type="email" v-model="emails[i]" :value="email" :disabled="i === 0" class="body-emails-email-input">
						<span class="fa fa-fw fa-plus link" v-title="'Add email'" @click="addEmail()"></span>
					</template>
					<template v-else>
						<rich-sel v-model="emails[i]" items-url="/api/calendar/getEmailsForGoogle" class="body-emails-email-input"></rich-sel>
						<span class="fa fa-fw fa-plus link" v-title="'Add email'" @click="addEmail()"></span>
						<span v-if="i !== 0" class="fa fa-fw fa-trash link" v-title="'Delete email'" @click="deleteEmail(i)"></span>
					</template>
				</div>
			</div>
		</div>
		
		<div class="footer">
			<btn 
				class="right"
				size="small"
				:loading="processingRequest"
				@click.native="sendEvent"
			>
				<i class="fa fa-fw fa-paper-plane" slot="icon"></i>
				Send
			</btn>
		</div>
	
	</popup>
`;

export default {
	template,
	props: {
		event: {
			type: Object,
			required: false,
		}
	},
	data() {
		return {
			date: null,
			time: null,
			dateEnd: null,
			timeEnd: null,
			eventTitle: null,
			eventDescription: null,
			processingRequest: false,
			isNotification: false,
			emails: [],
		};
	},
	computed: {
		dateTimeStart() {
			return moment(`${this.date} ${this.time}`).utc().format('YYYY-MM-DDTHH:mm') + ':00+00:00';
		},
		dateTimeEnd() {
			return moment(this.dateTimeStart).add(this.dateEnd, 'minutes').utc().format('YYYY-MM-DDTHH:mm:ss')+'+00:00';
		}
	},
	created() {
		this.dateEnd = 1;
		this.date = this.event.remindDate || moment().add(1, 'days').utc().format('YYYY-MM-DD');
		this.time = this.event.remindTime || moment().format('HH:mm');
		this.emails.push(this.event.email);
	},
	methods: {
		addEmail() {
			let emails = this.emails;

			if (emails[emails.length - 1].trim().length === 0) return this.alert('You already have empty field to fill');
			this.emails.push('');
		},
		deleteEmail(idx) {
			this.emails.splice(idx, 1);
		},
		async sendEvent() {
			this.isNotification = !this.eventTitle || !this.eventDescription || !this.eventTitle.trim();
			if (this.isNotification) return;

			let params = {
				dateTimeStart:this.dateTimeStart,
				dateTimeEnd: this.dateTimeEnd,
				description: this.eventDescription,
				summary: this.eventTitle,
				attendees: this.emails,
			};

			this.processingRequest = true;
			let result = await this.post('GoogleCalendar.createEventForGoogleCalendar', params);
			this.processingRequest = false;

			this.emitClose(result);
		},
		emitClose(event) {
			this.$emit('close', event);
		}
	}
};