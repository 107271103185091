const template = `
	<div class="head-cell-inner nowrap" :style="style">
		<div class="left">
			<slot name="left"></slot>
		</div>
	
		<span class="link" v-if="sort !== void 0" @click="sortTable(sort, !sortRev, isPTable)">
			<slot> </slot>
			<i
				v-if="app.query.sort == sort || (isPTable && app.query.sort && app.query.sort.substr(1) == sort)"
				:class="'sort-icon zmdi ' + ((app.query.sortDesc == 1 || (isPTable && app.query.sort.startsWith('-'))) ? 'zmdi-triangle-down' : 'zmdi-triangle-up')"
			></i>
		</span>
		<slot v-else>&nbsp;</slot>
		
		<div class="right">
			<slot name="right"></slot>
		</div>
	</div>
`;

export default {
	template: template,
	data() {
		return {
			parentY: 0,
			height: 0,
			offset: 0,
			isPTable: 0
		};
	},
	props: ['sort', 'sortRev', 'noSticky', 'noCssSticky'],
	computed: {
		style() {
			let css = {};
			let offset = !this.offset ? 0 : (this.offset - 1);
			css.borderBottomWidth = offset ? '1px' : 0;
			css.transform = 'translateY(' + offset + 'px)';
			return css;
		}
	},
	methods: {
		tryRecalc() {
			let scrollY = this.scrollTop();
			let diff = scrollY - this.parentY + this.height - 1;
			this.offset = Math.max(diff, 0);
		},
		updateParentY() {
			if (this.win.width > 1200 && !this.noCssSticky) {
				this.$el.parentNode.style.position = 'sticky';
				this.$el.parentNode.style.top = (this.height - 2) + 'px';
				this.$el.parentNode.style.zIndex = 9;
				this.offset = 0;

				return;
			}

			this.$el.parentNode.style.position = 'relative';
			this.$el.parentNode.style.top = '0px';

			let newParentY = this.$el.parentNode.getBoundingClientRect().top + this.scrollTop();
			if (newParentY !== this.parentY) {
				this.parentY = newParentY;
				this.tryRecalc();
			}
		},
		onScroll() {
			if (this.win.width > 1200 && !this.noCssSticky) return;

			this.tryRecalc();
		},
		isPageTable() {
			let isPageTable = this.$root.$refs.appView.$children.find(c => {
				if (c.$options._componentTag === 'kw-pos') {
					if(c.$children[0].$options._componentTag === 'page-table'){
						return true;
					}
				}
				return c.$options._componentTag === 'page-table';
			});
			if (isPageTable) {
				this.isPTable = 1;
			}
		}
	},
	mounted() {
		this.isPageTable();
		if (this.noSticky) return;

		this.updateParentY();
		setInterval(_ => this.updateParentY(), 2000);

		let header = this.$root.$refs.header || this.$parent.$parent.$refs.header;
		this.height = header.$el.clientHeight;

		window.addEventListener('scroll', this.onScroll);
		window.addEventListener('resize', this.updateParentY);
	},
	destroyed() {
		if (this.noSticky) return;

		window.removeEventListener('scroll', this.onScroll);
		window.removeEventListener('resize', this.updateParentY);
	}
};
