// jshint ignore: start
const template = `
	<popup
		class="export-popup"
		@close="$emit('close')"
	>
		<div slot="header">{{ header }}</div>

		<div class="export-popup-body">
			<label
				class="chb body-label"
				v-for="(checkbox, index) in checkboxes"
			>
				<input
					type="checkbox"
					v-model="checkboxValues.values[index]"
					:true-value="1"
					:false-value="0"
				>
				<span class="body-checkbox">{{ checkbox }}</span>
			</label>

			<div class="body-button">
				<a :href="buildLink" @click="isClickable($event)">
					<btn
						size="small"
						class="body-button-btn"
					>
						<i class="fa fa-fw fa-download" slot="icon"></i>
						{{ ii('EXPORT') }}
					</btn>
				</a>
			</div>

		</div>
	</popup>
`;

export default {
	template,
	props: {
		header: {
			type: String,
			required: true,
		},
		checkboxes: {
			type: Array,
			required: true,
		},
		exportLink: {
			type: String,
			required: true,
		},
		loadRequestLink: {
			type: String,
			required: false,
		},
	},
	data() {
		return {
			checkboxValues: {
				values: []
			}
		};
	},
	computed: {
		flags() {
			let result = [];

			this.checkboxes.map((el, index) => {
				if(!this.checkboxValues.values[index]) {
					result.push({ name: el+'Pop', value: 0 });
				} else {
					result.push({ name: el+'Pop', value: this.checkboxValues.values[index] });
				}
			});

			return result;
		},
		isEmptyFlags() {
			let result = true;
			this.checkboxValues.values.map(el => {
				if(el === 1) result = false;
			});
			return result;
		},
		buildLink() {
			let link = '';
			this.flags.map(el => {
				if(el.value === 1) link += `${el.name.toLowerCase()}=${el.value}&`;
			});
			let query = location.search.slice(1);

			if (this.loadRequestLink) {
				return  this.loadRequestLink + '?' + link + query;
			}

			return this.exportLink + '?' + link + query;
		},
	},
	methods: {
		async isClickable (e) {
			if(this.isEmptyFlags) {
				this.alert('Please, check at least one checkbox');
				return e.preventDefault();
			}

			if (this.loadRequestLink) {
				e.preventDefault();
				let result = await axios(this.buildLink);
				console.log(result);
				if (!result.err) {
					await this.alert('When the file is downloaded, you\'ll be notified');
				}
				else {
					return await this.alert('Sorry! Something went wrong.');
				}
			}

			this.$emit('load');
		}
	},
	mounted() {},
	destroyed() {},
};
