const template = `
	<popup
		width="400"
		:cls="'modal-popup ' + params.opts.customClass"
		no-scroll="1"
		close-on-back="1"
		@close="onClose($event, defaultOnCloseData)"
	>
		<template v-if="params.type == 'alert'">
			<div slot="header">{{ ii('MESSAGE') }}</div>
			<div>
				<output v-if="params.opts.html" v-html="String(params.msg).split('\\n').join('<br>')"></output>
				<output v-else>
					<template v-for="item in (String(params.msg).split('\\n'))">
						{{ item }}<br>
					</template>
				</output>
				<div class="modal-popup-button-box">
					<btn size="small" @click.native="onClose($event)" v-focus><i class="fa fa-check"></i> {{ ii('OK') }}</btn>
				</div>
			</div>
		</template>
		<template v-if="params.type == 'text'">
			<div slot="header">{{ params.opts.header || ii('CONTENT') }}</div>
			<div>
				<output v-if="params.opts.html" v-html="params.text"></output>
				<output v-else class="modal-content-area" :class="{'code': params.opts.code}">
					<div
						v-for="line in params.text.split('\\n')"
						v-html="params.opts.withoutWrapLinks ? line : wrapLinks(line)"
					></div>
				</output>
				<div class="modal-popup-button-box">
					<btn size="small" @click.native="onClose($event)" v-focus><i class="fa fa-check"></i> {{ ii('OK') }}</btn>
				</div>
			</div>
		</template>
		<template v-if="params.type == 'prompt'">
			<div slot="header">{{ params.opts.header || ii('INPUT') }}</div>
			<div @keyup.esc="onClose($event, null)">
				<output v-if="params.opts.html" v-html="params.promptTitle"></output>
				<output v-else> {{ params.promptTitle }}</output>
				<textarea
					v-if="params.opts.type == 'textarea'"
					class="area-prompt"
					v-model.lazy="params.val"
					v-focus
				></textarea>
				<div
					v-else-if="params.opts.type == 'html'"
					class="area-prompt readonly"
					v-html="params.val"
				></div>
				<input
					v-else-if="params.opts.type == 'number'"
					type="number"
					class="input-prompt"
					:step="params.opts.step"
					v-model.lazy="params.val"
					v-focus
					@keyup.enter="onClose($event, params.val)"
				>
				<select
					v-else-if="params.opts.type == 'select'"
					class="input-prompt"
					v-model="params.val"
				>
					<option v-for="item in params.opts.items" :value="item.key">{{ item.text || item.val }}</option>
				</select>
				<rich-sel
					v-else-if="params.opts.type == 'richsel' && params.opts.highlightAbsentManagers"
					v-model="params.val"
					:item-max-width="params.opts.itemMaxWidth"
					:no-empty="params.opts.noEmpty"
					:empty-val="params.opts.emptyVal"
					:items="params.opts.items"
				>
					<template slot="item" scope="rp">
						<div :class="[{'bg-hilited-red': checkAbsentManager(rp.item.key)}]">
							<worker-online-act
								v-if="checkPermission('show:online_activity') && rp.item.key > 0"
								:idm="rp.item.key"
							></worker-online-act>
							{{ rp.item.val || '&nbsp;' }}
						</div>
					</template>
				</rich-sel>
				<rich-sel
					v-else-if="params.opts.type == 'richsel' && params.opts.itemsUrl"
					v-model="params.val"
					class="width100p font13"
					:items-url="params.opts.itemsUrl"
					:custom-handler="params.opts.customHandler"
					:item-max-width="params.opts.itemMaxWidth"
					:max-items="params.opts.maxItems"
					:search-placeholder="params.opts.searchPlaceholder"
				></rich-sel>
				<rich-sel
					v-else-if="params.opts.type == 'richsel'"
					v-model="params.val"
					:item-max-width="params.opts.itemMaxWidth"
					:no-empty="params.opts.noEmpty"
					:empty-val="params.opts.emptyVal"
					:items="params.opts.items"
				></rich-sel>
				<multi-sel
					v-else-if="params.opts.type == 'multisel'"
					v-model="params.val"
					:items="params.opts.items"
					:presets="params.opts.presets"
				></multi-sel>
				<date-picker
					v-else-if="params.opts.type == 'datetime' || params.opts.type == 'date'"
					v-model="params.val"
					:with-time="params.opts.type == 'datetime'"
					class="block"
				></date-picker>
				<date-list-picker
					v-else-if="params.opts.type == 'dateList'"
					:dates-count="params.opts.datesCount"
					:with-time="params.opts.withTime"
					:type="params.opts.dateType"
					v-model="params.val"
				></date-list-picker>
				<input
					v-else-if="params.opts.type == 'password'"
					type="password"
					class="input-prompt"
					v-model.lazy="params.val"
					v-focus
					@keyup.enter="onClose($event, params.val)"
				>
				<input
					v-else-if="params.opts.type == 'color'"
					type="color"
					class="input-prompt"
					v-model.lazy="params.val"
					v-focus
					@keyup.enter="onClose($event, params.val)"
				>
				<file-area
					v-else-if="params.opts.type == 'file'"
					v-model="params.val"
					:text="params.opts.text"
					:description="params.opts.description"
					:multiple="params.opts.multiple"
				></file-area>
				<suggestion-field
					v-else-if="params.opts.type === 'suggestionField'"
					:items="params.opts.items"
					:items-url="params.opts.itemsUrl"
					:items-method="params.opts.itemsMethod"
					:max-items="params.opts.maxItems"
					:placeholder="params.opts.placeholder"
					v-model="params.val"
					@change="onControlChanged"
				></suggestion-field>
				<input
					v-else
					type="text"
					class="input-prompt"
					v-model.lazy="params.val"
					v-focus
					@keyup.enter="onClose($event, params.val)"
				>
				<div class="modal-popup-button-box" v-if="!params.opts.hideBtn">
					<btn size="small" @click.native="onClose($event, params.val)"><i class="fa fa-check"></i> {{ ii('OK') }}</btn>
					<btn color="cancel" size="small" @click.native="onClose($event, null)"><i class="fa fa-remove"></i> {{ ii('CANCEL') }}</btn>
				</div>
			</div>
		</template>
		<template v-if="params.type == 'confirm'">
			<div slot="header">{{ ii('CONFIRMATION') }}</div>
			<div>
				<output v-if="params.opts.html" v-html="params.msg"></output>
				<output v-else>{{ params.msg }}</output>
				<div class="modal-popup-button-box">
					<btn size="small" @click.native="onClose($event, true)" v-focus><i class="fa fa-check"></i> {{ ii('OK') }}</btn>
					<btn color="cancel" size="small" @click.native="onClose($event, false)"><i class="fa fa-remove"></i> {{ ii('CANCEL') }}</btn>
				</div>
			</div>
		</template>
	</popup>
`;
export default {
	template,
	props: ['params'],
	computed: {
		defaultOnCloseData() {
			if (this.params.type === 'prompt') {
				return null;
			} else if (this.params.type === 'confirm') {
				return false;
			} else {
				return undefined;
			}
		}
	},
	methods: {
		onClose(e, data = undefined) {
			this.$emit('close', data);
		}
	}
};