const template = `
	<span v-if="!timestamp">{{ fallbackText || '-' }}</span>
	<span v-else @click="onClick" class="datetime" :data-serv-ts="timestamp">
		{{ getDatetime() }}
	</span>
`;

export default {
	template,
	props: ['timestamp', 'no-diff', 'format', 'fallback-text'],
	methods: {
		getDatetime() {
			let store = this.app.store;

			//tell Vue to rerender when `datetimeRefresher` changes
			store.datetimeRefresher;

			let clientTimestamp = parseInt(this.timestamp);
			if (!this.noDiff && store) {
				clientTimestamp += store.timeDiff || 0;
			}

			let t = moment.unix(clientTimestamp);
			let isFull = store.isDatetimeFull;
			return isFull ? t.format(this.format || 'YYYY MMM D, HH:mm:ss') : t.fromNow();
		},
		onClick() {
			this.switchDatetimeFormat();
		}
	}
};
