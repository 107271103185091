import Store from '../../store.js';

const template = `
	<div class="timeline">
		<div v-if="isLoading" class="loading">
			<span>
				<i class="fa fa-spinner spin-fast center"></i>
				<span class="general-upper">{{ ii('LOADING') }}...</span>
			</span>
		</div>
		<template v-else>
			<table class="mc-tbl hover-tbl timeline-table">
				<thead>
					<tr>
						<th><head-cell-inner no-sticky="1">{{ ii('TIME') }}</head-cell-inner></th>
						<th><head-cell-inner no-sticky="1">{{ ii('TYPE') }}</head-cell-inner></th>
						<th><head-cell-inner no-sticky="1">{{ ii('EVENT') }}</head-cell-inner></th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(row, i) in timeline" :key="'timelineRow' + i" :data-type="row.type">
					<td><datetime :timestamp="row.ts"></datetime></td>
					<td>
						<p v-if="row.type == 'changes'" class="event-icon bg-primColor">
							<i class="zmdi zmdi-hc-lg zmdi-account-box"></i>
						</p>
						<p v-else-if="row.type == 'mails'" class="event-icon  bg-warning">
							<i class="zmdi zmdi-hc-lg zmdi-flash"></i>
						</p>
						<p v-else-if="row.type == 'timeline'" class="event-icon  bg-light-spirit">
							<i class="zmdi zmdi-info-outline"></i>
						</p>
						<p v-else-if="row.type == 'history'" class="event-icon  bg-light-spirit">
							<i class="zmdi zmdi-comment-text"></i>
						</p>
						<p v-else-if="row.type == 'unassignables'" class="event-icon  bg-danger">
							<i class="zmdi zmdi-close"></i>
						</p>
						<p v-else-if="row.type == 'messages'" class="event-icon  bg-light-spirit">
							<i class="zmdi zmdi-hc-lg zmdi-receipt"></i>
						</p>
						<p v-else-if="row.type == 'gmails'" class="event-icon" :class="row.v ? 'bg-warning' : 'bg-grass'">
							<i class="zmdi zmdi-hc-lg zmdi-email"></i>
						</p>
						<p v-else-if="row.type == 'mailtrack'" class="event-icon" :class="row.v ? 'bg-mediumPrio' : 'bg-spirit-highPrio'">
							<i class="zmdi zmdi-hc-lg zmdi-email"></i>
						</p>
						<p v-else-if="row.type == 'popups'" class="event-icon" :class="row.ptype ? 'bg-lowPrio' : 'bg-mediumPrio'">
							<i class="zmdi zmdi-hc-lg zmdi-window-maximize"></i>
						</p>
						<p v-else-if="row.type == 'automailing'" class="event-icon bg-mediumPrio">
							<i class="zmdi zmdi-hc-lg zmdi-mail-send"></i>
						</p>
						<p v-else-if="row.type == 'fb_get_msg'" class="event-icon bg-mediumPrio">
							<i class="zmdi zmdi-hc-lg zmdi-comment"></i>
						</p>
						<p v-else-if="row.type == 'fb_send_msg'" class="event-icon bg-mediumPrio">
							<i class="zmdi zmdi-hc-lg zmdi-comment-alt-text"></i>
						</p>
						<p
							v-else-if="row.type == 'payments'"
								class="event-icon"
								:class="
									row.paytype == 'order' ? 'bg-info' :
									row.paytype == 'rebill' ? 'bg-success' :
									row.paytype == 'refund' ? 'bg-nice' :
									row.paytype == 'cancel' ? 'bg-fb-color' : 'bg-go-color'
								"
							>
							<i
								class="zmdi zmdi-hc-lg"
								:class="
									row.paytype == 'order' ? 'zmdi-card' :
									row.paytype == 'rebill' ? 'zmdi-repeat-one' : 'zmdi-close'
								"
							></i>
						</p>
						<p v-else :class="row.iconbg" class="event-icon">
							<i :class="row.icon"></i>
						</p>
					</td>
					<td>
						<!-- title -->
						<p v-if="row.type == 'changes'" class="event-title">
							<b>{{row.name}}</b> changed
							<b>{{row.metric}}</b> to "{{row.title}}"
						</p>
						<p
							v-else-if="row.type == 'history'"
							class="event-title"
						>
							<span class="subtitle">
								<img
									v-if="row.img && resellerInfo.semaltDomain"
									:src="'https://' + resellerInfo.semaltDomain + '/' + row.img"
								>
								<b>{{ row.name }}</b>
								comment task
							</span>
						</p>
						<p
							v-else-if="row.type == 'unassignables'"
							class="event-title"
						>{{row.name}} marked user as unassignable</p>
						<p v-else-if="row.type == 'messages'" class="event-title"><b>New request</b></p>
						<p v-else-if="row.type == 'gmails'" class="event-title">
							<template v-if="row.v">
								<template v-if="row.img"><span class="subtitle"><b>{{row.email}}</b>"{{row.subj}}"</span></template>
								<template v-else>
									<span class="subtitle">
										<img v-if="row.img && resellerInfo.semaltDomain" :src="'https://' + resellerInfo.semaltDomain + '/' + row.img">
										<b>{{row.name}}</b>
										"{{row.subj}}"
									</span>
								</template>
							</template>
							<template v-else>
								<span class="subtitle">
									<img v-if="resellerInfo.semaltDomain" :src="'https://' + resellerInfo.semaltDomain + '/img/org/placeholder-avatar.png'">
									<b>{{row.email}}</b>
									"{{row.subj}}"
								</span>
							</template>
						</p>
						<p v-else-if="row.type == 'mailtrack'" class="event-title">
							Email: <b>{{row.recipient}}</b>
						</p>
						<p v-else-if="row.type == 'popups'" class="event-title">
							Popup: <b>{{row.st}}</b> {{row.msg}}
							<i class="zmdi zmdi-chevron-right zmdi-hc-hc"></i>
							{{row.pmsg}}
						</p>
						<p v-else-if="row.type == 'popups'" class="event-title">
							New {{row.ptype}} for $<b>{{row.cash}}</b> {{row.plan}}
						</p>
						<p v-else-if="row.type == 'automailing'">
							Mailing {{ row.event }}
						</p>
						<p v-else-if="row.type == 'fb_get_msg'">
							{{ row.title }}
						</p>
						<p v-else-if="row.type == 'fb_send_msg'">
							{{ row.title }}
						</p>
						<p v-else class="event-title" v-html="row.title"></p>
						
						<!-- body -->
						<p v-if="row.body" v-html="row.body"></p>
						<div v-else-if="row.bodyObject">
							<div><strong>Title:</strong> {{ row.bodyObject.title }}</div>
							<div><strong>Events:</strong> {{ row.bodyObject.events }}</div>
							<div>
								<strong>Body:</strong>
								<template v-for="(line, i) in String(row.bodyObject.body || '').split('\\n')">
									<br v-if="i">
									{{ line }}
								</template>
							</div>
							<div><strong>Status:</strong> {{ row.bodyObject.status }}</div>
						</div>
						<p v-else-if="row.type == 'mailtrack'">
							<template v-if="row.v">user: {{row.recipient}} open mail</template>
							<template v-else>user: {{row.recipient}} click {{row.url}}</template>
						</p>
						<p v-else-if="row.type == 'automailing'" :data-mail-name="row.mailName">
							<strong>Subject:</strong>
							{{ row.subject }}
						</p>
						<p v-else-if="row.type == 'fb_get_msg'">
							{{ row.body }}
						</p>
						<p v-else-if="row.type == 'fb_send_msg'">
							{{ row.body }}
						</p>
						<div v-if="row.mailId">
							<span class="pic-link" @click="showMailContent(row.mailId)">
								<i class="fa fa-envelope-o"></i>
								<span class="lower">{{ ii('SHOW_MAIL_CONTENT') }}</span>
							</span>
						</div>
						<div v-else-if="row.mailUrl">
							<span class="pic-link" @click="showFramePopup(row.mailUrl, ii('TEMPLATE_PREVIEW'))">
								<i class="fa fa-envelope-o"></i>
								<span class="lower">{{ ii('SHOW_MAIL_CONTENT') }}</span>
							</span>
						</div>
					</td>
				</tr>
				</tbody>
			</table>
			<div class="timeline-bottom">
				<btn size="small" v-if="!isNoMore" @click.native="loadMore" :loading="isLoadingMore">
					<i class="fa fa-fw fa-cloud-download" slot="icon"></i>
					{{ ii('LOAD_MORE') }}
				</btn>
			</div>
		</template>
	</div>
`;

export default {
	template,
	data() {
		return {
			isLoading: true,
			isLoadingMore: false,
			isNoMore: false,
			timeline: null,
			portion: 0
		};
	},
	props: ['idu', 'partner'],
	computed: {
		...Store.mapState(['resellerInfo'])
	},
	methods: {
		async loadTimeline() {
			this.portion++;
			let params = {idu: this.idu, portion: this.portion};

			let rows = await this.get('UserCard.getTimeLineInfo', params, this.partner);

			if (!rows.length) {
				this.isNoMore = true;
				return;
			}
			if (!this.timeline) {
				this.timeline = [];
			}
			this.timeline = this.timeline.concat(rows);
		},
		async loadMore() {
			this.isLoadingMore = true;
			await this.loadTimeline();
			this.isLoadingMore = false;
		}
	},
	async mounted() {
		if (this.idu) {
			await this.loadTimeline();
		}
		this.isLoading = false;
	}
};
