const template = `
	<transition name="fade">
		<div :class="['popup', cls]" @click="onClick" @mousedown="mouseDown = $event.target">
			<div class="popup-panel" :style="style" ref="popupBox">
				<div class="popup-header" @mousedown="onHeaderMouseDown" @touchstart="onHeaderTouchStart" 
				ref="popupHeader">
					<div class="left">
						<slot name="header"></slot>
					</div>
					<div class="right">
						<a href="javascript:;" @click="close('icon')" class="popup-close fa fa-close"></a>
					</div>
				</div>
				<div class="popup-content-outer">
					<div
						ref="content"
						:class="['popup-content', {'popup-scrollable': !noScroll}]"
						:style="contentStyle"
						v-block-scroll
						@scroll="$emit('scroll', $event)"
					>
						<slot>empty popup</slot>
					</div>
				</div>
				<div class="popup-footer">
					<slot name="footer"></slot>
					<div v-if="resize" class="popup-resizer-box" @mousedown="onResizeMouseDown"></div>
				</div>
			</div>
			<div class="overlaying-fix" v-if="overfix"></div>
		</div>
	</transition>
`;

export default {
	template,
	props: [
		'cls',
		'width',
		'content-min-height',
		'content-max-height',
		'scroll-to-bottom',
		'hard-close',
		'no-scroll',
		'resize',
		'close-on-back'
	],
	data() {
		return {
			popupId: null,
			dndStartX: 0,
			dndStartY: 0,
			offsetLeft: 0,
			offsetTop: 0,
			overfix : false,
			widthPopup : 0,
			heightPopup : 0,
			mouseDown: null,
			keyDownActiveElement: null
		};
	},
	computed: {
		style() {
			let style = {};

			if (this.offsetLeft || this.offsetTop) {
				style.transform = 'translate(' + this.offsetLeft + 'px,' + this.offsetTop + 'px)';
			}
			if (this.width) {
				style.width = isNaN(this.width) ? this.width : (this.width + 'px');
				if (this.widthPopup > 0) style.width = this.widthPopup + 'px';
				if (this.heightPopup > 0) this.$refs.content.style.minHeight = this.heightPopup + 'px';
				if (this.heightPopup > 0) this.$refs.content.style.maxHeight = this.heightPopup + 'px';
			}
			return style;
		},
		contentStyle() {
			let style = {};
			if (this.contentMinHeight) {
				style.minHeight = isNaN(this.contentMinHeight) ? this.contentMinHeight : this.contentMinHeight + 'px';
			}
			if (this.contentMaxHeight) {
				style.maxHeight = isNaN(this.contentMaxHeight) ? this.contentMaxHeight : this.contentMaxHeight + 'px';
			}
			return style;
		}
	},
	methods: {
		onClick(e) {
			if (this.hardClose) return;
			if (e.target !== this.$el || this.mouseDown !== this.$el) return;
			this.close('outside');
		},

		onHeaderMouseDown(e) {
			if (e.target !== e.currentTarget) return;

			e.preventDefault();

			this.dndStartX = e.clientX - this.offsetLeft;
			this.dndStartY = e.clientY - this.offsetTop;

			document.addEventListener('mousemove', this.onDocMouseMove);
			document.addEventListener('mouseup', this.unbindDocMouseUp);

			this.unbindDocTouchEnd();
		},
		onDocMouseMove(e) {
			this.offsetLeft = e.clientX - this.dndStartX;
			this.offsetTop = e.clientY - this.dndStartY;
		},
		unbindDocMouseUp(e) {
			document.removeEventListener('mousemove', this.onDocMouseMove);
			document.removeEventListener('mouseup', this.unbindDocMouseUp);
		},

		onResizeMouseDown(e) {
			if (!this.resize) return;
			this.overfix = true;

			e.preventDefault();

			document.addEventListener('mousemove', this.onResizeMouseMove);
			document.addEventListener('mouseup', this.unbindResizeMouseUp);

			this.$refs.popupBox.style.left = this.$refs.popupBox.offsetLeft + 'px';
			this.$refs.popupBox.style.margin = 0;
		},
		onResizeMouseMove(e) {
			this.widthPopup = e.clientX - this.offsetLeft - this.$refs.popupBox.offsetLeft;
			this.heightPopup = e.clientY - this.offsetTop - this.$refs.popupBox.offsetTop - this.$refs.popupHeader.offsetHeight;
		},
		unbindResizeMouseUp(e) {
			this.overfix = false;

			document.removeEventListener('mousemove', this.onResizeMouseMove);
			document.removeEventListener('mouseup', this.unbindResizeMouseUp);
		},

		onHeaderTouchStart(e) {
			if (e.target !== e.currentTarget) return;
			if (!e.touches || !e.touches[0]) return;

			e.preventDefault();

			let touch = e.touches[0];

			this.dndStartY = touch.clientY - this.offsetTop;

			document.addEventListener('touchmove', this.onDocTouchMove);
			document.addEventListener('touchend', this.unbindDocTouchEnd);

			this.unbindDocMouseUp();
		},
		onDocTouchMove(e) {
			if (!e.touches || !e.touches[0]) return;

			let touch = e.touches[0];
			this.offsetTop = touch.clientY - this.dndStartY;
		},
		unbindDocTouchEnd(e) {
			document.removeEventListener('touchmove', this.onDocTouchMove);
			document.removeEventListener('touchend', this.unbindDocTouchEnd);
		},
		close(type) {
			let types = ['back', 'outside', 'icon', 'escape'];
			if (this.closeOnBack && types.indexOf(type) === -1) {
				this.backButtonPop();
			}
			this.$emit('close');
		},
		removeFromPopupStack() {
			let idx = this.app.popupStack.indexOf(this.popupId);
			this.app.popupStack.splice(idx, 1);
		},
		onKeyDown(e) {
			this.keyDownActiveElement = document.activeElement;
		},
		onKeyUp(e) {
			if (this.hardClose) return;

			const escapeCode = 27;
			if (e.keyCode !== escapeCode) return;

			if (this.keyDownActiveElement !== document.body) return;
			if (document.activeElement !== document.body) return;

			let stack = this.app.popupStack;
			if (stack[stack.length - 1] !== this.popupId) return;

			this.close('escape');
		}
	},
	mounted() {
		this.popupId = Date.now() + Math.random();
		this.app.popupStack.push(this.popupId);

		document.body.classList.add('with-popup');

		if (this.closeOnBack) {
			this.backButtonPush(() => this.close('back'));
		}
		this.$root.hideAllTitles();

		document.addEventListener('keydown', this.onKeyDown);
		document.addEventListener('keyup', this.onKeyUp);
	},
	updated() {
		if (this.scrollToBottom == 1) {
			this.$nextTick(() => {
				this.$refs.content.scrollTop = this.$refs.content.scrollHeight;
			});
		}
	},
	destroyed() {
		setTimeout(() => {
			document.body.classList.remove('with-popup');
		}, 200);

		document.removeEventListener('keydown', this.onKeyDown);
		document.removeEventListener('keyup', this.onKeyUp);

		this.removeFromPopupStack();
	}
};
