const template = `
	<div class="suggestion-field" v-mousedown-outside="outsideClick">
		<input
			ref="input"
			type="text"
			v-model.trim="text"
			@click="inputClick"
			@input="getItems"
			@keydown.down.prevent="moveDown"
			@keydown.up.prevent="moveUp"
			@keydown.page-up.prevent="moveUpFast"
			@keydown.page-down.prevent="moveDownFast"
			@keydown.enter.prevent="choose(text)"
			@keydown.esc="hideSuggestions"
			:placeholder="placeholder"
		>
		<div class="suggestions-items" v-if="showSuggestions && suggestions.length" ref="itemsBox" v-block-scroll>
			<div
				v-for="(item, index) in suggestions"
				:class="['suggestions-dd-item', {'suggestions-hilited': (index === hiliteIndex)}]"
				:ref="'item' + index"
				@click="choose(item, false)"
				@mouseover="hiliteIndex = index"
			>
				<div>{{ item || '&nbsp;' }}</div>
			</div>
		</div>
	</div>
`;

export default {
	template,
	data() {
		return {
			text: '',
			searchValue: '',
			hiliteIndex: -1,
			timer: null,
			source: null,
			showSuggestions: false,
			suggestions: [],
		};
	},
	props: ['value', 'items', 'items-url', 'items-method', 'placeholder', 'max-items'],
	methods: {
		async getItems() {
			if (!this.text) {
				this.searchValue = '';
				this.suggestions = [];
				return;
			}

			if (this.searchValue === this.text) return;
			this.searchValue = this.text;

			if (this.itemsUrl || this.itemsMethod) return this.loadItems();
			if (!this.items || !this.isArray(this.items)) return this.suggestions = [];

			let items = this.items.filter(this.checkItemMatch);
			this.setSuggestions(items);
		},
		loadItems() {
			if (this.timer) clearTimeout(this.timer);

			this.timer = setTimeout(() => {
				if (this.source) this.source.cancel('');

				this.source = axios.CancelToken.source();

				let url = '';
				let args = { cancelToken: this.source.token };

				if (this.itemsMethod) {
					url = '/api/call/' + this.itemsMethod;
					args.params = {argsJson: JSON.stringify([this.text])};
				} else {
					let req = this.itemsUrl.indexOf('?') !== -1 ? '&q=' : '?q=';
					url = this.itemsUrl + req + this.text;
				}

				axios.get(url, args)
					.then(data => {
						if (!data || (!data.data.items && !data.data.res)) {
							return this.suggestions = [];
						}

						let items = data.data.res || data.data.items;
						if (!this.isArray(items) || !items.length) return this.suggestions = [];
						if (this.isHasObject(items)) {
							items = items.map(item => item.key || item.val || item);
							items = items.filter(item => !this.isObject(item));
						}

						this.setSuggestions(items);
					})
					.catch(err => {
						console.log(err);
					});
			}, 300);
		},
		checkItemMatch(item) {
			if (!this.text) return true;
			if (!item) return false;

			let regex = new RegExp(this.escapeRegex(this.text), 'i');
			return regex.test(item);
		},
		setSuggestions(items) {
			if (!items.length || (items.length === 1 && items[0] === this.text)) {
				return this.suggestions = [];
			}

			items = items.sort(this.compare);
			this.suggestions = items.slice(0, this.maxItems || 10);
			this.showSuggestions = true;
		},
		compare(a, b) {
			if (a.indexOf(this.text) < b.indexOf(this.text)) return -1;
			if (a.indexOf(this.text) > b.indexOf(this.text)) return 1;
			if (this.matchCount(a) < this.matchCount(b)) return 1;
			if (this.matchCount(a) > this.matchCount(b)) return -1;
			if (a.length < b.length) return -1;
			if (a.length > b.length) return 1;

			return 0;
		},
		matchCount(item) {
			if (!item) return 0;
			let cnt = item.match(new RegExp(this.escapeRegex(this.text), 'ig')).length;

			return cnt;
		},
		choose(value, inFocus = true, ignoreHilited = false) {
			let hilited = ignoreHilited ? '' : this.getHilitedItem();
			this.text = hilited || value;
			this.searchValue = '';
			this.suggestions = [];
			this.hiliteIndex = -1;
			this.$emit('input', this.text);
			this.$emit('change', {isTrusted: true});
			this.showSuggestions = inFocus;
			if (inFocus) this.$refs.input.focus();
		},
		outsideClick() {
			this.showSuggestions = false;
			if (this.value === this.text) return;
			this.choose(this.text, false, true);
		},
		inputClick() {
			if (!this.showSuggestions) this.getItems();
			this.showSuggestions = true;
		},
		hideSuggestions() {
			this.showSuggestions = false;
			this.hiliteIndex = -1;
		},
		moveUp(opt) {
			if (--this.hiliteIndex < 0) {
				this.hiliteIndex = this.suggestions.length - 1;
			}
			if (!opt.repeated && !this.getHilitedItem()) return this.moveUp({repeated: true});
			this.$nextTick(this.tryScroll);
		},
		moveDown(opt) {
			if (++this.hiliteIndex >= this.suggestions.length) {
				this.hiliteIndex = 0;
			}
			if (!opt.repeated && !this.getHilitedItem()) return this.moveDown({repeated: true});
			this.$nextTick(this.tryScroll);
		},
		moveUpFast(opt) {
			this.hiliteIndex -= this.movingFastStep;
			if (this.hiliteIndex < 0) {
				this.hiliteIndex = 0;
			}
			if (!opt.repeated && !this.getHilitedItem()) return this.moveUp({repeated: true});
			this.$nextTick(this.tryScroll);
		},
		moveDownFast(opt) {
			this.hiliteIndex += this.movingFastStep;
			if (this.hiliteIndex >= this.suggestions.length) {
				this.hiliteIndex = this.suggestions.length - 1;
			}
			if (!opt.repeated && !this.getHilitedItem()) return this.moveDown({repeated: true});
			this.$nextTick(this.tryScroll);
		},
		tryScroll() {
			let item = this.suggestions[this.hiliteIndex];
			if (!item) return;

			let itemNodes = this.$refs['item' + this.hiliteIndex];
			if (!itemNodes || !itemNodes[0]) return;

			let itemNode = itemNodes[0];
			let itemPos = itemNode.getBoundingClientRect();

			let itemsBox = this.$refs.itemsBox;
			let itemsBoxPos = itemsBox.getBoundingClientRect();

			let extraDistBottom = itemPos.bottom - itemsBoxPos.bottom;
			if (extraDistBottom > 0) {
				itemsBox.scrollTop += extraDistBottom;
			}

			let extraDistTop = itemsBoxPos.top - itemPos.top;
			if (extraDistTop > 0) {
				itemsBox.scrollTop -= extraDistTop;
			}
		},
		getHilitedItem() {
			return this.suggestions[this.hiliteIndex];
		},
		isHasObject(items) {
			return items.filter(i => this.isObject(i)).length > 0;
		}
	},
	watch: {
		value() {
			this.text = this.value;
		}
	},
	created() {
		this.text = this.value;
	}
};