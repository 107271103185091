import Store from '../../store.js';

const template = `
	<popup
		class="reviews-popup"
		width="700"
		resize="1"
		content-min-height="350"
		@scroll="onTblScroll"
		@close="close"
	>
		<div slot="header">{{ ii('REVIEWS') }}</div>
		<div class="content">
			<div class="controls-box-wr">
				<div class="controls-box row">
					<div v-if="params.showAll && checkPermission('reviews:admin')" class="control-filter col xs-12 sm-8 md-6 lg-6">
						<label>{{ ii('MANAGER') }}:</label>
						<multi-sel
							:items="getManagersItems()"
							with-not="1"
							v-model="query.idms"
							@change="onChanged"
						></multi-sel>
					</div>
						
					<div class="control-filter col xs-12 sm-8 md-6 lg-6">
						<label>{{ ii('DATE') }}:</label>
						<range-date-picker v-model="query.dateRange" @change="onChanged"></range-date-picker>
					</div>
					
					<div class="control-filter col xs-12 sm-8 md-6 lg-6">
						<label>{{ ii('USER') }}:</label>
						<input type="text" v-model="query.idu" @change="onChanged">
					</div>
					
					<div class="control-filter col xs-12 sm-8 md-6 lg-6">
						<label>{{ ii('COST') }}:</label>
						<input type="number" v-model="query.cost" @change="onChanged">
					</div>
					
					<div v-if="checkPermission('reviews:admin')" class="control-filter col xs-12 sm-8 md-6 lg-6">
						<btn size="small" @click.native="editReview()">
							<i class="fa fa-fw fa-plus" slot="icon"></i>
							{{ ii('ADD_REVIEW') }}
						</btn>
					</div>
				</div>
			</div>
			<div class="tbl-box">
				<div>
					<span v-if="totalCnt != null" class="left general-upper">
						{{ ii('TOTAL') }}:
						<i v-if="totalCnt === -1" class="fa fa-spinner spin-fast"></i>
						<span v-else>{{ totalCnt | numFormat }}</span>
					</span>
				</div>
				<table class="hover-tbl filled-tbl">
					<thead>
					<tr>
						<th>#</th>
						<th>
							<span class="link" @click="sort('idu')">
								{{ ii('USER') }}
								<i
									v-if="query.sort == 'idu'"
									:class="'sort-icon zmdi ' + (query.sortDesc == 0 ? 'zmdi-triangle-up' : 'zmdi-triangle-down')"
								></i>
							</span>
						</th>
						<th>
							<span class="link" @click="sort('cost')">
								{{ ii('COST') }}
								<i
									v-if="query.sort == 'cost'"
									:class="'sort-icon zmdi ' + (query.sortDesc == 0 ? 'zmdi-triangle-up' : 'zmdi-triangle-down')"
								></i>
							</span>
						</th>
						<th>
							<span class="link" @click="sort('idm')">
								{{ ii('MANAGER') }}
								<i
									v-if="query.sort == 'idm'"
									:class="'sort-icon zmdi ' + (query.sortDesc == 0 ? 'zmdi-triangle-up' : 'zmdi-triangle-down')"
								></i>
							</span>
						</th>
						<th>
							<span class="link" @click="sort('ts')">
								{{ ii('DATE') }}
								<i
									v-if="query.sort == 'ts'"
									:class="'sort-icon zmdi ' + (query.sortDesc == 0 ? 'zmdi-triangle-up' : 'zmdi-triangle-down')"
								></i>
							</span>
						</th>
						<th>
							<span class="link" @click="sort('url')">
								{{ ii('LINK') }}
								<i
									v-if="query.sort == 'url'"
									:class="'sort-icon zmdi ' + (query.sortDesc == 0 ? 'zmdi-triangle-up' : 'zmdi-triangle-down')"
								></i>
							</span>
						</th>
						<th v-if="checkPermission('reviews:admin')">{{ ii('ACTION') }}</th>
					</tr>
					</thead>
					<tbody>
					<tr v-for="(review, i) in reviews">
						<td class="text-center">{{ i + 1 }}</td>
						<td class="nowrap text-center">
							<a
								:href="'/leads?idu=' + review.idu"
								v-title="review.username + '\\n' + review.userEmail"
							>{{ review.idu }}</a>
						</td>
						<td class="text-center">{{ review.cost }}</td>
						<td class="text-center">
							<worker-tooltip v-if="review.idm" :idm="review.idm">
								{{ getManager(review.idm).realName }}
							</worker-tooltip>
						</td>
						<td class="text-center"><datetime :timestamp="review.ts"></datetime></td>
						<td class="text-center">
							<a
								v-if="review.url"
								:href="review.url"
								target="_blank"
								class="fa fa-globe"
								rel="noreferrer noopener"
							></a>
						</td>
						<td class="text-center" v-if="checkPermission('reviews:admin')">
							<span class="link middle" v-title="ii('EDIT')" @click="editReview(review)">
								<i class="fa fa-fw fa-edit" slot="icon"></i>
							</span>
							<span class="link" v-title="ii('DELETE')" @click="deleteReview(review.id, i)">
								<i class="fa fa-fw fa-remove" slot="icon"></i>
							</span>
						</td>
					</tr>
					</tbody>
					<tfoot>
					<tr v-show="reviews && reviews.length && isLoadingMore">
						<td colspan="100" class="text-center general-upper">
							<i class="fa fa-spinner spin-fast"></i>
							{{ ii('LOADING') }}...
						</td>
					</tr>
					<tr v-show="isNoMore">
						<td colspan="100" class="text-center general-upper">
							{{ reviews && reviews.length ? ii('NO_MORE_DATA') : ii('NO_DATA') }}
						</td>
					</tr>
					</tfoot>
				</table>
			</div>
		</div>
	</popup>
`;

export default {
	template,
	props: ['params'],
	data() {
		return {
			portion: 0,
			totalCnt: null,
			reviews: null,
			isLoadingMore: false,
			isNoMore: false,
			query: {},
		};
	},
	computed: {
		...Store.mapState(['managersMap'])
	},
	methods: {
		async loadReviews(opts = {}) {
			if (opts.isAppend) {
				if (this.isNoMore) return;

				this.portion++;
				this.isLoadingMore = true;
			} else {
				this.portion = 1;
				this.isNoMore = false;
				this.setLoading(true);

				this.loadStat();
			}

			let reviews = await this.get('Reviews.getRows', this.query, this.portion);

			this.isLoadingMore = false;
			this.setLoading(false);

			if (!opts.isAppend) this.reviews = [];

			if (!reviews.length) {
				this.isNoMore = true;
				return;
			}

			this.reviews = this.reviews.concat(reviews);
		},
		async loadStat() {
			this.totalCnt = -1;
			let res = await this.get('Reviews.getStat', this.query);
			this.totalCnt = res.totalCnt;
		},
		close() {
			this.$emit('close');
		},
		onTblScroll(e) {
			if (e.target.scrollTop + e.target.offsetHeight < e.target.scrollHeight) return;
			this.loadReviews({isAppend: true});
		},
		onChanged() {
			this.reviews = null;
			this.loadReviews();
		},
		sort(key) {
			this.query.sort = key;
			this.query.sortDesc = !this.query.sortDesc ? 1 : 0;
			this.onChanged();
		},
		async editReview(review = {}) {
			let res = await this.multiInput({
				items: [
					{ label: 'Manager', key: 'idm', val: review.idm, type: 'richsel', items: this.getManagersItems() },
					{ label: 'Cost', key: 'cost', val: review.cost, type: 'number' },
					{ label: 'User', key: 'idu', val: review.idu, type: 'number' },
					{ label: 'Url', key: 'url', val: review.url, type: 'text' }
				],
				split: 2,
				noScroll: 1,
				header: this.isObjectEmpty(review) ? this.ii('ADD_REVIEW') : this.ii('EDIT_REVIEW')
			});

			if (!res) return;
			if (!res.data.url) res.data.url = '';
			if (!res.data.idm || !res.data.cost || !res.data.idu) {
				let text;
				if (!res.data.idu) text = 'PLEASE_ENTER_USER_ID';
				if (!res.data.cost) text = 'PLEASE_ENTER_COST';
				if (!res.data.idm) text = 'SELECT_MANAGER';
				await this.alert(this.ii(text));
				res.close();
				this.isObjectEmpty(review) ? this.addReview() : this.editReview(review);
				return;
			}

			res.isLoading(true);
			if (this.isObjectEmpty(review)) {
				await this.post('Reviews.addReview', res.data);
			} else {
				await this.post('Reviews.editReview', res.data, review.id);
			}
			res.isLoading(false);
			res.close();

			if (this.params.showAll || this.params.idm == res.data.idm) this.onChanged();
		},
		async deleteReview(id, i) {
			let ok = await this.confirm(this.ii('ARE_YOU_SURE'));
			if (!ok) return;

			await this.post('Reviews.deleteReview', id);
			this.totalCnt -= 1;
			this.$delete(this.reviews, i);
		}
	},
	mounted() {
		if (this.params.showAll) this.query.showAll = this.params.showAll;
		if (this.params.idm) this.query.idm = this.params.idm;
		if (this.params.dateRange) this.query.dateRange = this.params.dateRange;

		this.loadReviews();
	}
};