'use strict';

import components from './components';
import methods from './mixin/methods';

if (!window.Proxy) {
	window.Proxy = () => {};
}

methods.initComponents(components);
Vue.mixin({
	directives: {
		title() {}
	}
});

try {
	localStorage.setItem('idw', 0);
} catch (err) {
	alert(err);
}

var app = new Vue({
	el: '#app',
	data: {
		login: '',
		password: '',
		isLoading: false,
		loginErrors: []
	},
	methods: {
		async authorize() {
			if (this.isLoading) return;

			this.loginErrors = [];

			if (!this.login) {
				this.loginErrors.push('Please enter login or e-mail');
			}
			if (!this.password) {
				this.loginErrors.push('Please enter password');
			}
			if (this.loginErrors.length) return;

			this.isLoading = true;

			let res = await axios.post('/api/login', {
				login: this.login,
				password: this.password
			});

			if (res.data.errors) {
				this.loginErrors = res.data.errors;
				this.logFailedAttempt();
			} else if (!res.data.success) {
				this.loginErrors = ['Unknown error occurred'];
				this.logFailedAttempt();
			} else {
				methods.act('login', this.login);
				location.reload();
				await methods.sleep(200);
				return;
			}

			this.isLoading = false;
		},
		logFailedAttempt() {
			methods.act('loginfail', this.login);
		}
	},
	mounted() {
		methods.act('visit', 'auth_page');
	}
});

document.body.setAttribute('data-theme', 'default');
window.app = window.application = app;
