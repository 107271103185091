import Store from '../../store.js';

const template = `
	<popup
		v-if="idu || reqId"
		width="350"
		class="req-cp-popup"
		no-scroll="1"
		@close="close"
	>
		<div slot="header">Request for Commercial Proposal</div>
		<div>
			<table>
				<tbody>
				<tr>
					<td>User</td>
					<td v-if="user.id">#{{ user.id }} {{ user.email }}</td>
					<td v-else>
						<i class="fa fa-spinner spin-fast"></i>
						Loading...
					</td>
				</tr>
				<template v-if="user.id">
					<tr v-if="history.length">
						<td>History</td>
						<td>
							<div v-for="cp in history">
								<span v-title="'Request ID'" class="help">#{{ cp.id }}</span>
								<span v-title="'Request status'" class="help">{{ cp.st ? cp.st : '' }}</span>
								<span v-if="cp.estimatedEndAt" v-title="'Estimated time until done'" class="help">
									Est.time:
									<strong>{{ cp.estimatedEndAt | fromNow }}</strong>
								</span>
							</div>
						</td>
					</tr>
					<tr>
						<td>Site Finder</td>
						<td>
							<rich-sel
								:items="getCpSitesItems(user)"
								default-text="Choose"
								v-model="siteData"
								@change="onChooseCpProject"
							></rich-sel>
						</td>
					</tr>
					<tr>
						<td>URL</td>
						<td><input type="text" v-model="siteUrl" ref="cpSiteUrlTf"></td>
					</tr>
					<tr>
						<td>Google</td>
						<td>
							<rich-sel
								:items="getSearchEnginesItems()"
								v-model="se"
							></rich-sel>
						</td>
					</tr>
					<tr>
						<td>
							Language
							<span class="hint" v-title="'Desired language for keywords'">[?]</span>
						</td>
						<td>
							<rich-sel
								:items="getLangsItems()"
								v-model="lang"
							></rich-sel>
						</td>
					</tr>
					<tr>
						<td>
							Price
							<span class="hint" v-title="'Price for one month'">[?]</span>
						</td>
						<td>
							<input type="number" v-model="price" placeholder="Default">
						</td>
					</tr>
					<tr>
						<td>Deadline</td>
						<td>
							<select v-model="endDate" class="req-cp-date">
								<option v-for="date in cpReqDeadlineDates" :value="date.key">{{ date.val }}</option>
							</select>
							<select v-model="endTime" class="req-cp-time">
								<option v-for="time in cpReqDeadlineTimes" :value="time">{{ time }}</option>
							</select>
						</td>
					</tr>
					<tr>
						<td>Priority</td>
						<td>
							<select v-model="prio">
								<option value="low">Low</option>
								<option value="normal">Normal</option>
								<option value="high">High</option>
								<option value="urgent">Urgent!</option>
							</select>
						</td>
					</tr>
					<tr>
						<td>Comment</td>
						<td>
							<textarea v-model="comment"></textarea>
						</td>
					</tr>
				</template>
				</tbody>
			</table>
		</div>
		<div slot="footer" class="req-cp-footer">
			<btn
				v-if="idu && !reqId"
				size="small"
				@click.native="sendCpRequest"
				:disabled="!siteUrl || !se"
				:loading="isSending"
			>
				<i class="fa fa-fw fa-send" slot="icon"></i>
				Send
			</btn>
			<btn
				v-if="idu && reqId"
				size="small"
				@click.native="saveCpRequest(reqId)"
				:disabled="!siteUrl || !se"
				:loading="isSending"
			>
				<i class="fa fa-fw fa-save" slot="icon"></i>
				Save edits
			</btn>
		</div>
	</popup>

`;

export default {
	template,
	props: ['idu', 'reqId'],
	data() {
		return {
			cpReqDeadlineDates: null,
			cpReqDeadlineTimes: null,
			user: {},
			siteId: 0,
			siteUrl: '',
			se: 0,
			// lang: 'en',
			price: null,
			endDate: null,
			endTime: null,
			prio: 'normal',
			comment: '',
			history: [],
			isSending: false,
			siteData: null,
			cp: {}
		};
	},
	computed: {
		...Store.mapState(['geoMap', 'searchEngines'])
	},
	methods: {
		close() {
			this.$emit('close');
		},
		async sendCpRequest() {
			let params = {};
			params.idu = this.idu;
			params.siteUrl = this.siteUrl;
			params.se = this.se;
			params.lang = this.lang;
			params.price = parseInt(this.price) || 0;
			params.endDate = this.endDate;
			params.endTime = this.endTime;
			params.prio = this.prio;
			params.comment = this.comment;

			this.isSending = true;
			let success = await this.post('CP.sendRequest', params);
			if (!success) return alert('An error occurred');
			this.close();
		},
		async saveCpRequest (id) {
			let params = {};
			params.idu = this.idu;
			params.reqId = this.reqId;
			params.siteUrl = this.siteUrl;
			params.se = this.se;
			params.lang = this.lang;
			params.price = parseInt(this.price) || 0;
			params.endDate = this.endDate;
			params.endTime = this.endTime;
			params.prio = this.prio;
			params.comment = this.comment;

			this.isSending = true;
			let success = await this.post('CP.saveRequestEdits', params);
			if (!success) return alert('An error occurred');
			this.close();
		},
		getCpSitesItems(user) {
			if (!user.sites || !user.sites.length) return [];

			let sites = user.sites.map(site => {
				let key = site.id + '::' + site.seId + '::' + site.url;
				let val = `#${site.id} - ${site.domain}`;
				let domainHtml = '<strong>' + this.escapeHtml(site.domain) + '</strong>';
				let country = this.geoMap[site.seGeo] || site.seGeo;
				let seAddit = [];
				if (country) {
					seAddit.push(country);
				}
				seAddit.push(site.seLang);
				let seHtml = this.escapeHtml('google.' + site.google + ' (' + seAddit.join(', ') + ')');

				let html =`#${site.id} - ${domainHtml} - ${seHtml}`;
				let displayVal = 'Choose';
				return {key, val, html, displayVal};
			});

			return sites;
		},
		onChooseCpProject() {
			if (!this.siteData) {
				this.se = 0;
				this.siteUrl = '';
				return;
			}
			let [siteId, se, siteUrl] = this.siteData.split('::');
			this.se = se;
			this.siteUrl = siteUrl;

			this.$refs.cpSiteUrlTf.focus();
		},
		getLangsItems() {
			return Object.keys(this.langsMap).map(key => {
				let val = this.langsMap[key];
				let sortVal = val;
				return {key, val, sortVal};
			});
		},
		async tryLoadInfo() {
			if (!this.idu) return;
			this.user = {};
			this.history = await this.get('CP.getUserHistory', this.idu);
			this.user = await this.get('Lead.getUserInfo', {idu: this.idu});
		}
	},
	mounted() {
		let today = moment().format('YYYY-MM-DD');
		if (!this.cpReqDeadlineDates || !this.cpReqDeadlineTimes) {
			this.cpReqDeadlineDates = [
				{key: today, val: 'Today'},
				{key: moment().add(1, 'day').format('YYYY-MM-DD'), val: 'Tomorrow'},
				{key: moment().add(2, 'day').format('YYYY-MM-DD'), val: 'Day after tomorrow'},
				{key: moment().add(7, 'day').format('YYYY-MM-DD'), val: 'Next 7 days'}
			];
			this.cpReqDeadlineTimes = [];
			for (let hour = 10; hour < 20; hour++) {
				for (let min = 0; min < 60; min += 15) {
					let time = this.padLeft(hour, 2, '0') + ':' + this.padLeft(min, 2, '0');
					this.cpReqDeadlineTimes.push(time);
				}
			}
		}

		let endDate = today;

		let nextHour = moment().add(1, 'hour').format('HH:mm');
		let endTime = null;
		this.cpReqDeadlineTimes.some(t => {
			if (t > nextHour) {
				endTime = t;
				return true;
			}
		});
		if (!endTime) {
			endDate = moment().add(1, 'day').format('YYYY-MM-DD');
			endTime = this.cpReqDeadlineTimes[0];
		}
		this.endDate = endDate;
		this.endTime = endTime;

		this.tryLoadInfo();
	},
	watch: {
		idu() {
			this.user = {};
			this.siteId = 0;
			this.siteUrl = '';
			this.se = 0;
			this.lang = 'en';
			this.price = null;
			this.endDate = null;
			this.endTime = null;
			this.prio = 'normal';
			this.comment = '';
			this.history = [];
			this.isSending = false;
			this.siteData = null;
			this.cp = {};

			this.tryLoadInfo();
		},
		async reqId() {
			if (!this.reqId) return;
			let data = await this.get('CP.getRequest', this.reqId);
			this.siteUrl  = data.siteUrl;
			this.siteUrl  = data.siteUrl;
			this.se = data.se;
			this.lang = data.lang;
			this.price = data.price;
			this.prio = data.prio;
			this.comment = data.comment;
		}
	}
};
