import Store from '../../store.js';

const template = `
	<div class="license-form">
		<h1>{{ title }}</h1>
		<h5>{{ subtitle }}</h5>

		<div class="form">
			<div class="header-form" v-if="semaltManager">
				<div class="img-wr">
					<img :src="'//semalt.com' + semaltManager.img">
				</div>
				<div class="info-wr">
					<div class="fullName">
						<p class="name">{{ semaltManager.name }}</p>
						<p class="position">{{ ii('TALK_TO_A_PRODUCT_EXPERT') }}</p>
					</div>

					<div class="contacts">
						<a v-if="semaltManager.skype" :href="'skype:' + semaltManager.skype + '?call'" class="skype">
							<div><img width="16" src="//semalt.com/img/franchise/skype_icon.png"></div>
							<div>{{ semaltManager.skypeName }}</div>
						</a>
						<a v-if="semaltManager.phone" :href="'tel:' + semaltManager.phone" class="phone">
							<div><img width="16" src="//semalt.com/img/franchise/phone_icon.png"></div>
							<div>{{ semaltManager.phone }}</div>
						</a>
						<a v-if="semaltManager.email" target="_blank" :href="'mailto:' + semaltManager.email" class="mail">
							<div><img width="16" src="//semalt.com/img/franchise/mail_icon.png"></div>
							<div>{{ semaltManager.email }}</div>
						</a>
						<a v-if="semaltManager.whatsapp" :href="'https://api.whatsapp.com/send?phone=' + semaltManager.whatsapp" target="_blank" rel="noreferrer noorigin">
							<div><img width="16" src="//semalt.com/img/icons/whatsapp.png"></div>
							<div>{{ semaltManager.whatsapp }}</div>
						</a>
						<a v-if="semaltManager.telegram" :href="'https://telegram.me/' + semaltManager.telegram" target="_blank" rel="noreferrer noorigin">
							<div><img width="16" src="//semalt.com/img/icons/telegram.svg"></div>
							<div>{{ semaltManager.telegram }}</div>
						</a>
					</div>
				</div>
			</div>
			<div class="body-form">
				<input type="text" :placeholder="ii('ENTER_YOUR_NAME')" v-model.lazy.trim="name">
				<input type="text" :placeholder="ii('ENTER_YOUR_E_MAIL')" v-model.lazy.trim="email">
				<phone-input v-model="phone" :format-callback="onPhoneFormat"></phone-input>
				<textarea v-model.lazy.trim="msg" :placeholder="ii('HOW_CAN_WE_HELP')"></textarea>
			</div>
			<div class="footer-form text-center">
				<btn :loading="isSending" @click.native="sendMessage">
					<i class="fa fa-fw fa-send" slot="icon"></i>
					{{ ii('SEND_MESSAGE') }}
				</btn>
			</div>
		</div>
	</div>
`;

export default {
	template: template,
	props: ['title', 'subtitle'],
	data() {
		return {
			msg: '',
			name: '',
			email: '',
			phone: '',
			phoneInfo: null,
			isSending: false
		};
	},
	computed: {
		...Store.mapState(['semaltUser', 'semaltManager']),
	},
	methods: {
		onPhoneFormat(info) {
			this.phoneInfo = info;
		},
		validateEmail(email){
			let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return re.test(email);
		},
		async sendMessage() {
			if (!this.name) return this.alert(this.ii('PLEASE_ENTER_NAME'));
			if (!this.email) return this.alert(this.ii('PLEASE_ENTER_E_MAIL'));
			if (!this.validateEmail(this.email)) return this.alert(this.ii('E_MAIL_INVALID'));
			if (!this.phone) return this.alert(this.ii('PLEASE_ENTER_PHONE'));
			if (!this.phoneInfo || !this.phoneInfo.valid) return this.alert(this.ii('PHONE_INVALID'));
			if (!this.msg) return this.alert(this.ii('PLEASE_ENTER_HOW_WE_CAN_HELP'));

			if (this.isSending) return;

			this.isSending = true;
			let params = {
				name: this.name,
				email: this.email,
				phoneSkype: this.phone,
				message: this.msg,
				referer: location.href
			};
			await this.post('ClientRequest.sendRequestToSupersemalt', params);
			this.isSending = false;

			this.alert(this.ii('THANK_YOU_FOR_YOUR_CONFIDENCE') + '. ' + this.ii('OUR_MANAGER_WILL_CONTACT_YOU_SOON') + '.');
		}
	},
	async mounted() {
		if (!this.semaltUser) return;

		if(!this.title) this.title = this.ii('THIS_SECTION_IS_FOR_PAID_USERS_ONLY');
		if(!this.subtitle) this.subtitle = this.ii('TO_OPEN_SECTION_ADMIN_PANEL_CONTACT_ME');


		this.name = this.semaltUser.username || '';
		this.email = this.semaltUser.email || '';
		this.phone = this.semaltUser.phone || '';
		await this.sleep(10);
		this.setLoading(false);
	}
};
