import Store from '../../store.js';

const template = `
<div class="user-comments">
	<div v-if="isLoading" class="loading">
		<span>
			<i class="fa fa-spinner spin-fast center"></i>
			<span class="general-upper">{{ ii('LOADING') }}...</span>
		</span>
	</div>
	<div v-else>
		<h3>{{ ii('COMMENTS') }}</h3>
		<div class="checked-info" v-if="checkPermission('delete:user_comments')">
			<span v-if="howManyCommentsChecked > 0" class="left general-upper">
				{{ ii('CHECKED') }}: {{ howManyCommentsChecked | numFormat }}
			</span>
			<span v-if="isCheckedCommentsMapVisible" class="left link margin-left-10" @click="bulkDelete">
				<i class="fa fa-fw fa-trash"></i>
				{{ ii('DELETE_CHECKED') }}
			</span>
		</div>
		<table class="mc-tbl hover-tbl">
			<thead>
			<tr class="thbackColor">
				<th class="text-center checkbox-col">
					<head-cell-inner no-sticky="1">
						<template v-if="checkPermission('delete:user_comments')">
							<label v-if="howManyCommentsHasUser > 0" class="chb">
								<input
									type="checkbox"
									v-model="isCommentsCheckedAll"
									@click="toggleCommentsCheckedAll($event)"
								>
							</label>
						</template>
						<label v-else class="chb">
							<input type="checkbox" disabled>
						</label>
					</head-cell-inner>
				</th>
				<th><head-cell-inner no-sticky="1">{{ ii('TIME') }}</head-cell-inner></th>
				<th><head-cell-inner no-sticky="1">{{ ii('AUTHOR') }}</head-cell-inner></th>
				<th><head-cell-inner no-sticky="1">{{ ii('TEXT') }}</head-cell-inner></th>
				<th><head-cell-inner no-sticky="1">{{ ii('FILE') }}</head-cell-inner></th>
				<th><head-cell-inner no-sticky="1">&nbsp;</head-cell-inner></th>
			</tr>
			</thead>
			<tbody>
			<tr v-for="(c, i) in comments">
				<template v-if="checkPermission('delete:user_comments')">
					<td v-if="!c.action" class="text-center">
						<label class="chb">
							<input
								type="checkbox"
								v-model="checkedCommentsMap[i]"
								@click="onChbClick($event, i, { id: c.id, idx: i, filename: c.filename })">
						</label>
					</td>
					<td v-else class="text-center">
						<i v-if="c.action === 'spinning'" class="fa fa-spinner spin-fast"></i>
						<i
							v-else-if="c.action === 'done'"
							class="fa fa-check comment-ok"
							v-title="ii('DELETED_REFRESH_PAGE')"
						></i>
						<i v-else class="fa fa-times comment-fail" v-title="c.action"></i>
					</td>
				</template>
				<td v-else class="text-center">
					<label class="chb">
						<input type="checkbox" disabled>
					</label>
				</td>
				<td><datetime :timestamp="c.date"></datetime></td>
				<td>
					<worker-tooltip v-if="c.idm != 0" :idm="c.idm">
						<div>{{ getManager(c.idm).realName || c.idm }}</div>
					</worker-tooltip>
					<b v-else>{{ c.author }}</b>
				</td>
				<td style="word-break: break-word;" v-html="newLineToBr(wrapLinks(c.text), 2)"></td>
				<td>
					<template v-if="c.filename">
						<span
							class="link fa fa-download"
							v-title="getActionFileTitle(c, 'download')"
							@click="downloadFile(c.filename)"
						></span>
						<a :href="watchDownloadFile(c.filename)" target="_blank">
							<i class="fa fa-eye" v-title="getActionFileTitle(c, 'open')"></i>
						</a>
					</template>
				</td>
				<td>
					<a
						v-if="(worker.idm && worker.idm === c.idm) || checkPermission('delete:user_comments')"
						@click="deleteComment(c.id, i, c.filename)"
					>
						<i class="fa fa-close" v-title="ii('DELETE')"></i>
					</a>
				</td>
			</tr>
			</tbody>
		</table>
		<textarea cols="30" rows="10" :placeholder="ii('COMMENT')" v-model="addCommentArea"></textarea>
		<btn size="small" class="attach-button" type="file" @change="attachFile($event)">
			<i class="fa fa-fw fa-file-archive-o" slot="icon"></i>
			{{ ii('ATTACH_FILE') }}
		</btn>
		<btn size="small" @click.native="addComment()" :loading="isSendingComment">
			<i class="fa fa-fw fa-send" slot="icon"></i>
			{{ ii('ADD_COMMENT') }}
		</btn>
		<span v-if="attachedFile" style="position:relative; left: 8px;">{{ ii('ATTACHED_FILE') }}: {{ attachedFile.name }}</span>
	</div>


</div>
`;

export default {
	template,
	data() {
		return {
			isLoading: true,
			comments: null,
			isCommentsCheckedAll : false,
			checkedCommentsMap: {},
			commentsChbLastIndex: null,
			loadingAttachIdu: null,
			isSendingComment: false,
			attachedFile: null,
			addCommentArea: '',
		};
	},
	props: ['idu'],
	computed: {
		...Store.mapState(['workersMap', 'managersMap', 'worker']),
		howManyCommentsChecked() {
			return Object.entries(this.checkedCommentsMap).length;
		},
		isCheckedCommentsMapVisible() {
			return !!(Object.entries(this.checkedCommentsMap).length > 0 && this.checkedCommentsMap.constructor === Object);
		},
		howManyCommentsHasUser() {
			if (!this.comments) return 0;
			return Object.entries(this.comments).length;
		},
	},
	methods: {
		async loadUserComments() {
			this.comments = await this.get('UserCard.getUserComments', this.idu);
		},
		onAttachLoaded() {
			this.loadingAttachIdu = null;
		},
		async bulkDelete() {
			let ok = await this.confirm(this.ii('ARE_YOU_SURE'));
			if (!ok) return;

			let bulkComments = Object.values(this.checkedCommentsMap);

			for (let el = 0; el < bulkComments.length; el++) {
				this.$set(this.comments[bulkComments[el].idx], 'action', 'spinning');
				let res = await this.post('UserCard.deleteComment', bulkComments[el].id, bulkComments[el].filename);
				let action =  res ? ('Error: ' + res) : 'done';
				this.$set(this.comments[bulkComments[el].idx], 'action', action);
			}

			this.checkedCommentsMap = {};
			this.isCommentsCheckedAll = false;
		},
		toggleCommentsCheckedAll(e) {
			if (e.target.checked) {
				this.comments.forEach((row, i) => {
					this.$set(this.checkedCommentsMap, i, { id: row.id, idx: i, filename: row.filename });
				});
			} else {
				this.checkedCommentsMap = {};
			}
		},
		onChbClick(e, i, opts = {}) {
			let checked = e.target.checked;
			if (!checked) this.isCommentsCheckedAll = false;

			if (e.shiftKey && this.commentsChbLastIndex != null) {
				let start = Math.min(i, this.commentsChbLastIndex);
				let end = Math.max(i, this.commentsChbLastIndex);

				if (checked) {
					for (let i = start; i <= end; i++) {
						let row = this.comments[i];
						this.$set(this.checkedCommentsMap, i,
							{
								idx: i,
								id: row.id,
								filename: row.filename
							}
						);
					}
				} else {
					for (let i = start; i <= end; i++) {
						this.$delete(this.checkedCommentsMap, i);
					}
				}
			} else {
				if (checked) {
					this.$set(this.checkedCommentsMap, i, opts);
				} else {
					this.$delete(this.checkedCommentsMap, i);
				}
			}

			this.commentsChbLastIndex = i;
		},
		async downloadFile(filename) {
			await this.download('UserCard.getAttachCommentFile', filename, true);
		},
		watchDownloadFile(...args) {
			return '/api/call/UserCard.getAttachCommentFile?argsJson=' + this.urlEnc(JSON.stringify(args));
		},
		async deleteComment(idc, i, filename) {
			let ok = await this.confirm(this.ii('CONFIRM_DELETE_COMMENT'));
			if (!ok) return;

			let res = await this.post('UserCard.deleteComment', idc, filename);
			if (res) return this.alert('Error: ' + res);
			this.$delete(this.comments, i);
		},
		attachFile(e) {
			this.attachedFile = e.target.files[0];
		},
		async addComment() {
			this.addCommentArea = this.addCommentArea.trim();
			if (!this.addCommentArea) return this.alert(this.ii('PLEASE_ENTER_COMMENT'));

			let newComment = this.addCommentArea;

			let filename = '';

			this.isSendingComment = true;
			let res = await this.post('UserCard.addComment', this.idu, newComment);
			if (this.attachedFile) {
				let form = new FormData;
				form.append('id', res);
				form.append('file', this.attachedFile);
				filename = await this.upload('UserCard.attachCommentFile', form);
			}
			let n = new Date();
			this.comments.unshift({
				text: newComment,
				date: Math.floor(n.getTime() / 1000)-10,
				author: this.worker.name,
				idw: this.worker.id,
				id: res,
				filename: filename
			});

			this.isSendingComment = false;
			this.addCommentArea = '';
			this.attachedFile = null;
		},
		getActionFileTitle(c, action) {
			return this.ii(action) + '\n' + c.filename;
		},
	},
	async mounted() {
		if (this.idu) await this.loadUserComments();

		this.isLoading = false;
	}
};