const template = `
	<div class="clock-widget">
		<div class="outer-clock-face">
			<div class="marking marking-one"></div>
			<div class="marking marking-two"></div>
			<div class="marking marking-three"></div>
			<div class="marking marking-four"></div>
			<div class="inner-clock-face">
				<div class="hand hour-hand" :style="hourStyle"></div>
				<div class="hand min-hand" :style="minStyle"></div>
				<div class="hand second-hand" :style="secondStyle"></div>
			</div>
		</div>
	</div>
`;

export default {
	template: template,
	data() {
		return({
			datetime: new Date(),
			hours: 0,
			mins: 0,
			seconds: 0,
			hoursDegrees: 90,
			minsDegrees: 90,
			secondsDegrees: 90
		});
	},
	props: ['timestamp'],
	computed: {
		hourStyle() {
			return {transform: 'rotate(' + this.hoursDegrees +'deg)'};
		},
		minStyle() {
			return {transform: 'rotate(' + this.minsDegrees +'deg)'};
		},
		secondStyle() {
			return {transform: 'rotate(' + this.secondsDegrees +'deg)'};
		}
	},
	methods: {
		setDate() {
			this.datetime = this.timestamp ? new Date(this.timestamp) : new Date();

			this.seconds = this.datetime.getSeconds();
			this.secondsDegrees = ((this.seconds / 60) * 360) + 90;

			this.mins = this.datetime.getMinutes();
			this.minsDegrees = ((this.mins / 60) * 360) + ((this.seconds / 60) * 6) + 90;

			this.hours = this.datetime.getHours();
			this.hoursDegrees = ((this.hours / 12) * 360) + ((this.mins / 60) * 30) + 90;
		}
	},
	watch: {
		timestamp() {
			this.setDate();
		}
	},
	mounted() {
		if (!this.timestamp) {
			setInterval(() => this.setDate(), 1000);
		}

		this.setDate();
	}
};