export default {
	mapState(arr) {
		let map = {};
		arr.forEach(key => {
			map[key] = function() {
				return this.app.store[key];
			};
		});
		return map;
	}
};
