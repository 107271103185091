const template = `
	<popup
		class="history-msg-popup"
		scroll-to-bottom="1"
		close-on-back="1"
		resize="1"
		width="750"
		content-max-height="40vh"
		@close="onClose"
	>
		<div slot="header">{{ ii('HISTORY') }}</div>
		<div class="msg-hist">
			<template v-if="msgs">
				<div 
					v-if="!msgs.length || (filteredMsgs && !filteredMsgs.length)" 
					class="text-center"
				>
					<br>
					<em>{{ ii('NO_MESSAGES_YET') }}</em>
				</div>
				<table>
					<tr v-for="msg in filteredMsgs">
						<td class="msg-hist-worker">
							<worker-tooltip :idw="msg.idw" v-if="msg.idw > 0">
								<strong>{{ getWorker(msg.idw).realName }}:</strong>
							</worker-tooltip>
							<span v-else>{{ ii('ROBOT') }}:</span>
						</td>
	
						<td
							class="msg-hist-msg newlineable"
							v-html="getHistoryMessageHtml(msg)"
						></td>
						<td class="msg-hist-datetime"><datetime :timestamp="msg.ts"></datetime></td>
					</tr>
				</table>
			</template>
			<div v-else class="text-center">
				<br>
				<em>{{ ii('LOADING') }}...</em>
			</div>
		</div>
		<div slot="footer">
			<div>
				<label v-for="type in typeItems" v-if="!fixedTypes || checkedTypesMap[type.key]">
					<input type="checkbox" :disabled="fixedTypes" v-model="checkedTypesMap[type.key]" @click="updateTypes">
					<span>{{ ii(type.key) }}</span>
				</label>
			</div>
			<template v-if="!withoutSend">
				<div class="msg-send-box">
					<btn
						size="small"
						:loading="isMessageSending"
						@click.native="sendMsg"
					>
						<i slot="icon" class="fa fa-fw fa-send"></i>
						{{ ii('SEND') }}
					</btn>
				</div>
				<textarea
					v-model.lazy="messageToSend"
					v-focus="{minWidth: win.MD}"
					:disabled="isMessageSending"
				></textarea>
			</template>
		</div>
	</popup>
`;

export default {
	template,
	props: ['domainId', 'taskId', 'showTypes', 'fixedTypes', 'checkItemId', 'withoutSend'],
	data() {
		return {
			isMessageSending: false,
			messageToSend: '',
			msgs: null,
			checklistMap: {},
			typeItems: [
				{key: 'MESSAGE', types: ['message', 'checklist_comment_edit']},
				{key: 'CHECKLIST', types: ['checklist_status_changed', 'checklist_approved_st_changed']},
				{key: 'TASKS', types: ['task_created', 'task_status_changed']},
				{key: 'CONTENT_MESSAGE', types: ['content_message']},
				{key: 'DEV_MESSAGE', types: ['dev_message']},
				{key: 'SALES_MESSAGE', types: ['sales_message']},
				{key: 'RECOMMENDATIONS', types: ['reco_status_changed']},
			],
			checkedTypesMap: {
				MESSAGE: true,
				CHECKLIST: true,
				TASKS: true,
				'CONTENT_MESSAGE': true,
				'DEV_MESSAGE': true,
				'SALES_MESSAGE': true,
				'RECOMMENDATIONS': true
			},
			visibleTypes: [],
			recoStatuses: [
				'No status',
				'Canceled',
				'Need recomendation',
				'Sent to the manager',
				'Sent to the client',
				'Required check',
				'Implemented',
				'Not implemented',
			]
		};
	},
	computed: {
		filteredMsgs() {
			let msgs = this.msgs.filter(msg => {
				if (this.visibleTypes.indexOf(msg.type) !== -1) {
					if (!this.checkItemId) return true;
					if (this.typeItems[1].types.indexOf(msg.type) !== -1) {
						let json = this.parseJson(msg.data);
						if (!json || !json.itemId) return false;

						let ids = json.itemId.toString().split(',');
						if (ids.indexOf(this.checkItemId.toString()) !== -1) return true;

						return false;
					}

					return true;
				}
			});
			return msgs;
		}
	},
	methods: {
		onClose() {
			this.$emit('close');
		},
		updateTypes() {
			let visibleTypes = [];
			Object.keys(this.checkedTypesMap).forEach(key => {
				if (!this.checkedTypesMap[key]) return;

				let item = this.typeItems.find(type => type.key === key);
				visibleTypes = visibleTypes.concat(item.types);
			});
			this.visibleTypes = visibleTypes;
		},
		async loadHistory() {
			this.msgs = await this.getProjectHistory();
		},
		async getProjectHistory() {
			let res = await this.get('SEOTasks.getProjectHistory', this.domainId);
			return res.msgs;
		},
		async sendMsg() {
			let msg = this.messageToSend.trim();
			if (!msg) return this.alert('Please type a message');

			let taskId = this.taskId;
			let domainId = this.domainId;
			this.isMessageSending = true;
			let cnt = await this.post('SEOTasks.sendMsg', domainId, taskId, msg);
			this.isMessageSending = false;

			this.messageToSend = '';
			this.msgs = await this.getProjectHistory(domainId);

			this.$emit('sent');
		},
		getHistoryMessageHtml(msg) {
			let html = '';
			let data = this.parseJson(msg.data);
			if (!data) {
				html = this.wrapLinks(msg.data);
			} else if (msg.type === 'sales_message' || msg.type === 'task_created') {
				let type = msg.type === 'sales_message' ? 'SALES' : 'SEO';
				let text = this.wrapLinks(data.text);
				if (data.toIdw) {
					let workerName = this.getWorker(data.toIdw).realName;
					html = this.iiArgs('CREATED_' + type + '_TASK_FOR_N', workerName, text);
					return html;
				}

				html = this.iiArgs('CREATED_' + type + '_TASK_N', text);
			} else if (msg.type === 'task_status_changed') {
				let status = this.ii(data.status == 1 ? 'DONE' : 'OPENED');
				html = this.escapeHtml(this.iiArgs('CHANGED_STATUS_TO_N', status));
			} else if (msg.type === 'checklist_approved_st_changed') {
				let status = this.ii(data.approved == 1 ? 'APPROVED' : 'DISAPPROVED');

				let itemsName = [];
				let ids = data.itemId.toString().split(',');
				ids.forEach(id => {
					itemsName.push(this.checklistMap[id] ? this.checklistMap[id].title : '');
				});

				html = this.iiArgs('CHANGED_STATUS_TO_N_FOR_M', status, itemsName.join(', '));
			} else if (msg.type === 'checklist_status_changed') {
				let statuses = ['remove red', 'check green', 'thumbs-down red', 'meh-o yellow', 'trash-o blue'];
				let status = '<i class="fa fa-fw fa-' + (statuses[data.status] || 'question-circle-o grey') + '"></i>';

				let itemsName = [];
				let ids = data.itemId.toString().split(',');
				ids.forEach(id => {
					itemsName.push(this.checklistMap[id] ? this.checklistMap[id].title : '');
				});

				html = this.iiArgs('CHANGED_STATUS_TO_N_FOR_M', status, itemsName.join(', '));
			} else if (msg.type === 'checklist_comment_edit') {
				let checklistItem = this.checklistMap[data.itemId];
				let itemName = checklistItem ? checklistItem.title : '';
				html = itemName + ': ' + this.wrapLinks(data.comment);
			} else if (msg.type === 'message' || msg.type === 'dev_message' || msg.type === 'content_message') {
				html = this.wrapLinks(data.msg);
			} else if (msg.type === 'reco_status_changed') {
				let status = this.recoStatuses[data.status] || '';
				html = this.escapeHtml(this.iiArgs('CHANGED_STATUS_TO_N', status));
			} else {
				html = this.wrapLinks(msg.data);
			}
			return html;
		},
		async initChecklist() {
			let checklist = await this.get('SEOTasks.getChecklist');
			let map = {};
			checklist.forEach(item => {
				map[item.id] = item;
			});
			this.checklistMap = map;
		}
	},
	async mounted() {
		if (this.showTypes) {
			this.checkedTypesMap.MESSAGE = this.showTypes.message ? true : false;
			this.checkedTypesMap.CHECKLIST = this.showTypes.checklist ? true : false;
			this.checkedTypesMap.TASKS = this.showTypes.tasks ? true : false;
			this.checkedTypesMap['CONTENT_MESSAGE'] = this.showTypes.contentMessage ? true : false;
			this.checkedTypesMap['DEV_MESSAGE'] = this.showTypes.devMessage ? true : false;
			this.checkedTypesMap['SALES_MESSAGE'] = this.showTypes.salesMessage ? true : false;
			this.checkedTypesMap['RECOMMENDATIONS'] = this.showTypes.reco ? true : false;
		}

		this.updateTypes();
		await this.initChecklist();
		this.loadHistory();
	}
};
