const template = `
	<div class="the-header">
		<div class="header" :class="groupPages ? 'header-grouped' : ''" ref="header">
			<div class="header-left">
				<slot name="burger">
					<div
						:class="['burger', {'open': burgerActive}]"
						@click.prevent="onBurgerClick"
					>
						<span v-for="i in 3"></span>
					</div>
				</slot>
				<a v-if="title" href="/" class="static-color">{{ title }}</a>
			</div>
			
			<div class="header-menu" @scroll="onMenuScroll" v-horiz-scroll>
				<slot name="menu">
					<template v-if="win.width >= win.XL && isMultiLangReady">
						<template v-if="groupPages">
							<div
								v-for="(group, i) in tree"
								class="header-group"
								:class="group.isCurrent ? 'active' : ''"
							>
								{{ ii(group.label) }}
								<span
									v-if="group.withNews"
									:class="(isWorkerQmGroup || isPenaltyNewsInGroup(group.pages) ? 'orangered ' : '') + 'page-group-news'"
								></span>
								<div class="header-submenu" :style="headerSubmenuStyle">
									<a
										v-for="page in group.pagesWithChilds"
										:href="page.isLink ? ('/' + page.slug) : '#'"
										:class="['clearfix submenu-notif-link nowrap', {'active': page.isCurrent}, {'cursor-default': !page.isLink}]"
									>
										<span>
											{{ ii(page.label) }}
											<template v-if="app.pagesNumbers[page.slug]">({{ app.pagesNumbers[page.slug] }})</template>
										</span>
										<span
											v-if="page.isParent"
											class="children-chervon"
										><i class="zmdi zmdi-chevron-right"></i></span>
										<div v-if="page.isParent" class="children-submenu">
											<a
												v-for="p in page.pages"
												:href="'/' + p.slug"
												class="clearfix submenu-notif-link nowrap"
												:class="p.isCurrent ? 'active' : ''"
											>
												<span>{{ ii(p.label) }}</span>
												<span
													v-if="p.newsCnt"
													:class="(isWorkerQmGroup || isPenaltyNews(p.slug) ? 'orangered ' : '') + 'submenu-notif-news-cnt'"
												>
													<span>{{ p.newsCnt }}</span>
												</span>
											</a>							
										</div>
									</a>
								</div>
							</div>
						</template>
						<template v-else>
							<a
								v-for="(page, i) in flatPages"
								v-if="page.displaySt"
								v-show="!page.hidden || page.isCurrent"
								:href="'/' + page.slug"
								:class="page.isCurrent ? 'active' : ''"
							>
								<span>{{ ii(page.label) }}</span>
								<template v-if="app.pagesNumbers[page.slug]">({{ app.pagesNumbers[page.slug] }})</template>
							</a>
						</template>
						
						<a
							href="javascript:;"
							class="narrow zmdi zmdi-edit"
							v-show="flatPages.length"
							@click="onMenuSettingsClick"
						></a>
						<a
							href="javascript:;"
							class="narrow zmdi zmdi-search"
							@click="showPageSearch"
						></a>
					</template>
				</slot>
			</div>
	
			<div class="header-right">
				<slot name="right"></slot>
			</div>
		</div>
		<div class="header-padding"></div>
		
		<transition name="fade">
			<div
				v-show="isMenuSettingsShown"
				v-mousedown-outside="hideMenuSettingsPanel"
				class="menu-settings-panel anim-from-bottom"
				:style="menuSettingsStyle"
			>
				<div ref="menuSettingsPanel" class="menu-set-panel-inner" v-block-scroll>
					<label class="chb">
						<input type="checkbox" :checked="groupPages" @click="groupPageVisClick()">
						<span class="upper">{{ ii('GROUP_PAGES') }}</span>
					</label>
					<div class="pages-group">
						<h4>{{ ii('PAGES') }}</h4>
						<div v-for="page in flatPages" v-if="page.displaySt">
							<label class="chb">
								<input
									type="checkbox"
									:checked="!page.hidden"
									:disabled="groupPages"
									@click="onPageVisClick(page)"
								>
								<span>{{ ii(page.label) }}</span>
							</label>
						</div>
					</div>
				</div>
			</div>
		</transition>
		
		<transition name="fade">
			<div
				v-show="isPageSearchVisible"
				v-mousedown-outside="hidePageSearch"
				class="page-search-panel anim-from-bottom"
				:style="pageSearchStyle"
			>
				<rich-sel
					ref="pageSearch"
					v-model="foundPage"
					:items="pageSearchItems"
					@change="choosePage"
				></rich-sel>
			</div>
		</transition>
	</div>
`;

export default {
	template,
	props: [
		'title',
		'tree',
		'burger-active'
	],
	data() {
		return {
			groupPages: null,
			hiddenPagesMap: {},
			isMenuSettingsShown: false,
			menuSettingsStyle: {},
			pageSearchStyle: {},
			isPageSearchVisible: false,
			foundPage: null,
			menuScrollLeft: 0
		};
	},
	computed: {
		isMultiLangReady() {
			return !this.isObjectEmpty(this.$root.i18n);
		},
		flatPages() {
			let pages = [];
			this.tree.forEach(g => {
				pages = pages.concat(g.pages);
			});

			pages.forEach(page => {
				page.hidden = !!this.hiddenPagesMap[page.slug];
			});

			pages.sort((a, b) => {
				if (a.sort > b.sort) return 1;
				if (a.sort < b.sort) return -1;

				if (a.id > b.id) return 1;
				if (a.id < b.id) return -1;

				return 0;
			});

			return pages;
		},
		isWorkerQmGroup() {
			return this.checkGroup('qm');
		},
		pageSearchItems() {
			let items = this.flatPages.map(p => {
				return {
					key: p.slug,
					val: this.ii(p.label),
					sVal: p.slug + '*' + p.label + '*' + this.ii(p.label)
				};
			});
			items.sort((a, b) => {
				if (a.val > b.val) return 1;
				if (a.val < b.val) return -1;
				return 0;
			});
			return items;
		},
		headerSubmenuStyle() {
			return {
				transform: 'translateX(-' + this.menuScrollLeft + 'px)'
			};
		}
	},
	methods: {
		onMenuSettingsClick(e) {
			this.isMenuSettingsShown = true;
			this.menuSettingsStyle = {
				left: e.clientX + 'px'
			};

			this.hideAllTitles();

			this.$nextTick(() => {
				this.$refs.menuSettingsPanel.scrollTop = 0;
			});
		},
		hideMenuSettingsPanel() {
			this.isMenuSettingsShown = false;
		},
		groupPageVisClick() {
			this.groupPages = !this.groupPages;
			localStorage.setItem('groupPages', this.groupPages);
		},
		onPageVisClick(page) {
			let hidden = !page.hidden;
			this.$set(page, 'hidden', hidden);
			this.$set(this.hiddenPagesMap, page.slug, hidden);
			let hiddenPages = this.flatPages.filter(page => page.hidden).map(page => page.slug).join(',');
			localStorage.setItem('hiddenPages', hiddenPages);
		},
		getHiddenPagesMap() {
			let hiddenPages = localStorage.getItem('hiddenPages');
			if (!hiddenPages) return [];

			let map = {};
			hiddenPages.split(',').forEach(p => {
				map[p] = true;
			});
			return map;
		},
		onBurgerClick() {
			this.$emit('burger-click');
		},
		showPageSearch(e) {
			this.pageSearchStyle = {
				left: e.clientX + 'px'
			};
			this.isPageSearchVisible = true;
			this.$refs.pageSearch.showSuggestions();
		},
		hidePageSearch() {
			this.isPageSearchVisible = false;
		},
		choosePage() {
			this.hidePageSearch();

			if (!this.foundPage) return;
			location.href = '/' + this.foundPage;
		},
		isPenaltyNews(page) {
			return page === 'penalties';
		},
		isPenaltyNewsInGroup(pages) {
			if (!pages) return false;
			return pages.filter(p => p.newsCnt && p.slug === 'penalties').length > 0;
		},
		onMenuScroll(e) {
			if (!this.groupPages) return;
			this.menuScrollLeft = e.target.scrollLeft;
		}
	},
	mounted() {
		this.groupPages = (localStorage.getItem('groupPages') !== 'false');
		this.hiddenPagesMap = this.getHiddenPagesMap();
	}
};
