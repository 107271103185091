import Store from '../../store.js';

const template = `
	<span 
		class="tooltip-area" 
		:style="{display: display}" 
		@click="showTooltip($event)"
	>
		<slot>
			<template v-if="idw">{{ getWorker(idw).realName || idw }}</template>
			<template v-else-if="idm">{{ getManager(idm).realName || idm }}</template>
		</slot>
	</span>
`;

export default {
	template: template,
	props: ['idm', 'idw', 'login', 'email', 'display', 'partner'],
	computed: {
		...Store.mapState(['workersMap', 'managersMap', 'resellerInfo', 'resellers']),
		workers() {
			return Object.keys(this.workersMap).map(idw => this.workersMap[idw]);
		},
		worker() {
			let idw;
			if (this.idw) {
				idw = this.idw;
			} else if (this.idm) {
				let worker = this.workers.find(w => w.idm === parseInt(this.idm));
				idw = worker && worker.id;
			} else if (this.login) {
				let worker = this.workers.find(w => w.login === this.login);
				idw = worker && worker.id;
			} else if (this.email) {
				let worker = this.workers.find(w => w.email === this.email);
				idw = worker && worker.id;
			}
			if (!idw) return {};
			return this.workersMap[idw] || {};
		}
	},
	methods: {
		showTooltip(e) {
			let tooltipH 	= 210,
				tooltipW 	= 160,
				tooltipEl 	= e.currentTarget.getBoundingClientRect(),
				ifCase 		= e.clientY > tooltipH,
				tooltipStrY = e.pageY - e.clientY + tooltipEl.top;

			let left 	= tooltipEl.left,
				bottom 	= ifCase ? null : 0,
				animate = ifCase ? 'anim-from-top' : 'anim-from-bottom',
				top 	= ifCase ? tooltipStrY - tooltipH : tooltipStrY,
				hiddenH = tooltipH + tooltipEl.height,
				hiddenW = tooltipEl.width,
				margin 	= (hiddenW - tooltipW) / 2;

			let	worker = {
				show: 	true,
				url: 	this.getWorkerPhotoUrl(),
				idm: 	this.idm,
				idw: 	this.idw,
				realName: this.getWorkerRealName(),
				name: 	this.getWorkerName(),
				skype: 	this.getWorkerSkype(),
				telegram: 	this.getWorkerTelegram(),
				left, top, bottom, animate, hiddenH, hiddenW, margin
			};

			this.app.workerTooltip = worker;
		},
		getWorkerSkype() {
			return this.worker.skype;
		},
		getWorkerTelegram() {
			return this.worker.telegram;
		},
		getWorkerPhotoUrl() {
			if (this.idm === -1) return;
			if (!this.resellerInfo.semaltDomain) return '/img/placeholder-avatar.png';
			if (!this.worker.img) return;
			if (!this.partner) return 'https://' + this.resellerInfo.semaltDomain + this.worker.img;
			if (!this.resellers) return;

			let resellers = this.resellers.filter(r => r.name === this.partner);
			if (!resellers.length) return;

			let resellerInfo = resellers[0];
			return 'https://' + resellerInfo.semaltCloneDomain + this.worker.img;
		},
		getWorkerRealName() {
			if (this.idm === -1) return 'Slack';
			return this.worker.realName;
		},
		getWorkerName() {
			if (this.idm === -1) return '';
			return this.worker.name;
		}
	}
};
