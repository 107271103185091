import Btn from './component/control/Btn';
import Money from './component/control/Money';
import Toggle from './component/control/Toggle';
import Trigger from './component/control/Trigger';
import RichSel from './component/control/RichSel';
import MultiSel from './component/control/MultiSel';
import Calendar from './component/control/Calendar';
import DropDown from './component/control/DropDown';
import ClipBoard from './component/control/ClipBoard';
import PhoneInput from './component/control/PhoneInput';
import DatePicker from './component/control/DatePicker';
import ReminderBox from './component/control/ReminderBox';
import ClockWidget from './component/control/ClockWidget';
import DateListPicker from './component/control/DateListPicker';
import DeveloperLinks from './component/control/DeveloperLinks';
import RangeDatePicker from './component/control/RangeDatePicker';
import SuggestionField from './component/control/SuggestionField';

import Snow from './component/general/Snow';
import Flag from './component/general/Flag';
import KwPos from './component/general/KwPos';
import Datetime from './component/general/Datetime';
import FileArea from './component/general/FileArea';
import OuterLink from './component/general/OuterLink';
import TheHeader from './component/general/TheHeader';
import PagesList from './component/general/PagesList';
import PageTable from './component/general/PageTable';
import Translate from './component/general/Translate';
import UserScore from './component/general/UserScore';
import LineChart from './component/general/LineChart';
import WorkerCard from './component/general/WorkerCard';
import TheSidebar from './component/general/TheSidebar';
import LicenseForm from './component/general/LicenseForm';
import QuizResults from './component/general/QuizResults';
import PageFilters from './component/general/PageFilters';
import ImagePreview from './component/general/ImagePreview';
import WorkerProfile from './component/general/WorkerProfile';
import WorkerTooltip from './component/general/WorkerTooltip';
import HeadCellInner from './component/general/HeadCellInner';
import SessionChecker from './component/general/SessionChecker';
import FullScreenChart from './component/general/FullScreenChart';
import BackgroundVideo from './component/general/BackgroundVideo';
import PageUrlRemember from './component/general/PageUrlRemember';
import KeywordsTopsChart from './component/general/KeywordsTopsChart';
import WorkerOnlineActivity from './component/general/WorkerOnlineActivity';

import Popup from './component/popup/Popup';
import ModalPopup from './component/popup/ModalPopup';
import UserPopups from './component/popup/UserPopups';
import UsersPopup from './component/popup/UsersPopup';
import CallsPopup from './component/popup/CallsPopup';
import FramePopup from './component/popup/FramePopup';
import ExportPopup from './component/popup/ExportPopup';
import AttachPopup from './component/popup/AttachPopup';
import OrdersPopup from './component/popup/OrdersPopup';
import ReviewsPopup from './component/popup/ReviewsPopup';
import PenaltyPopup from './component/popup/PenaltyPopup';
import ProjectPopup from './component/popup/ProjectPopup';
import SeoTaskPopup from './component/popup/SeoTaskPopup';
import SeoLinksPopup from './component/popup/SeoLinksPopup';
import ReminderPopup from './component/popup/ReminderPopup';
import CpRequestPopup from './component/popup/CpRequestPopup';
import SeoHistoryPopup from './component/popup/SeoHistoryPopup';
import MultiInputPopup from './component/popup/MultiInputPopup';
import SeoPaymentsPopup from './component/popup/SeoPaymentsPopup';
import ProjectPickerPopup from './component/popup/ProjectPickerPopup';
import ClientRequestPopup from './component/popup/ClientRequestPopup';
import CreateNewLeadPopup from './component/popup/CreateNewLeadPopup';
import SurveyConfigBuilder from './component/popup/SurveyConfigBuilder';
import CallbackRequestPopup from './component/popup/CallbackRequestPopup';
import EditLocalizationPopup from './component/popup/EditLocalizationPopup';
import ReminderGoogleCalendar from './component/popup/ReminderGoogleCalendar';

import FBChat from './component/sales/FBChat';
import Editor from './component/sales/Editor';
import PayLink from './component/sales/PayLink';
import EventTable from './component/sales/EventTable';

import TabLinks from './component/tab/TabLinks';
import TabLinksStats from './component/tab/TabLinksStats';
import TabLinksCo from './component/tab/TabLinksCo';

import News from './component/usercard/News';
import Calls from './component/usercard/Calls';
import Whois from './component/usercard/Whois';
import Timeline from './component/usercard/Timeline';
import UserCard from './component/usercard/UserCard';
import Activity from './component/usercard/Activity';
import MailSender from './component/usercard/MailSender';
import MailHistory from './component/usercard/MailHistory';
import UserProfile from './component/usercard/UserProfile';
import UserComments from './component/usercard/UserComments';
import UserProjects from './component/usercard/UserProjects';
import UserPayments from './component/usercard/UserPayments';
import UserServices from './component/usercard/UserServices';
import UserCampaigns from './component/usercard/UserCampaigns';
import Subscriptions from './component/usercard/Subscriptions';
import UserCardBatumi from './component/usercard/UserCardBatumi';
import ActivityLegacy from './component/usercard/ActivityLegacy';
import UserPaySettings from './component/usercard/UserPaySettings';
import UserProfileBatumi from './component/usercard/UserProfileBatumi';

const components = {
	'the-sidebar': TheSidebar,
	'the-header': TheHeader,
	'pages-list': PagesList,
	'page-table': PageTable,
	'page-filters': PageFilters,
	'page-url-remember': PageUrlRemember,
	'image-preview': ImagePreview,
	'background-video': BackgroundVideo,
	'btn': Btn,
	'datetime': Datetime,
	'drop-down': DropDown,
	'head-cell-inner': HeadCellInner,
	'worker-tooltip': WorkerTooltip,
	'popup': Popup,
	'user-score': UserScore,
	'flag': Flag,
	'rich-sel': RichSel,
	'session-checker': SessionChecker,
	'phone-input': PhoneInput,
	'date-picker': DatePicker,
	'range-date-picker': RangeDatePicker,
	'multi-sel': MultiSel,
	'file-area': FileArea,
	'outer-link': OuterLink,
	'reminder-popup': ReminderPopup,
	'attach-popup': AttachPopup,
	'frame-popup': FramePopup,
	'cp-request-popup': CpRequestPopup,
	'orders-popup': OrdersPopup,
	'calendar': Calendar,
	'calls-popup': CallsPopup,
	'worker-card': WorkerCard,
	'worker-profile': WorkerProfile,
	'project-picker-popup': ProjectPickerPopup,
	'project-popup': ProjectPopup,
	'client-request-popup': ClientRequestPopup,
	'callback-request-popup': CallbackRequestPopup,
	'edit-localization-popup': EditLocalizationPopup,
	'seo-task-popup': SeoTaskPopup,
	'seo-history-popup': SeoHistoryPopup,
	'user-card': UserCard,
	'trigger': Trigger,
	'toggle': Toggle,
	'pay-link': PayLink,
	'full-screen-chart': FullScreenChart,
	'editor': Editor,
	'mail-sender': MailSender,
	'developer-links': DeveloperLinks,
	'users-popup': UsersPopup,
	'event-table': EventTable,
	'money': Money,
	'quiz-results': QuizResults,
	'surver-config-builder': SurveyConfigBuilder,
	'seo-links-popup': SeoLinksPopup,
	'user-profile': UserProfile,
	'whois': Whois,
	'user-popups': UserPopups,
	'mail-history': MailHistory,
	'user-projects': UserProjects,
	'subscriptions': Subscriptions,
	'user-payments': UserPayments,
	'timeline': Timeline,
	'activity': Activity,
	'activity-legacy': ActivityLegacy,
	'fb-chat': FBChat,
	'translate': Translate,
	'news': News,
	'calls': Calls,
	'reviews-popup': ReviewsPopup,
	'penalty-popup': PenaltyPopup,
	'export-popup' : ExportPopup,
	'reminder-google-calendar': ReminderGoogleCalendar,
	'clipboard' : ClipBoard,
	'reminder-box': ReminderBox,
	'tab-links': TabLinks,
	'tab-links-stats': TabLinksStats,
	'tab-links-co': TabLinksCo,
	'kw-pos': KwPos,
	'user-card-batumi': UserCardBatumi,
	'user-profile-batumi': UserProfileBatumi,
	'suggestion-field': SuggestionField,
	'create-new-lead-popup': CreateNewLeadPopup,
	'user-pay-settings': UserPaySettings,
	'line-chart': LineChart,
	'keywords-tops-chart': KeywordsTopsChart,
	'date-list-picker': DateListPicker,
	'user-campaigns': UserCampaigns,
	'user-comments': UserComments,
	'user-services': UserServices,
	'seo-payments-popup': SeoPaymentsPopup,
	'worker-online-act': WorkerOnlineActivity,
	'clock-widget': ClockWidget,
	'multi-input-popup': MultiInputPopup,
	'modal-popup': ModalPopup,
	'snow': Snow,
	'license-form': LicenseForm
};

export default components;
