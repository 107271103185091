import Store from '../../store.js';

const template = `
<div class="user-profile">
	<div v-if="isLoading" class="loading">
		<span>
			<i class="fa fa-spinner spin-fast center"></i>
			<span class="general-upper">{{ ii('LOADING') }}...</span>
		</span>
	</div>
	<div v-else>
		<div class="user-info-section">
			<h3>
				{{ ii('USER') }}
				<a :href="'/leads?idu=' + idu + '#usercard'" class="static-color">{{ idu }}</a>
			</h3>
			<div class="user-info-box-inner">
				<table class="main-tbl" v-if="user" :key="'accountTbl' + idu">
					<tbody>
						<tr>
							<td>{{ ii('USERNAME') }}:</td>
							<td colspan="3">{{ user.name }}
								<span
									class="link zmdi zmdi-edit"
									:class="isFreePlan() ? 'cursor-not-allowed opacity04' : ''"
									v-title="ii('EDIT')"
									@click="changeUsername(user)"
								></span>
							</td>
						</tr>
						<tr>
							<td>{{ ii('COUNTRY') }}:</td>
							<td class="user-country" colspan="3">
								<flag :cc="user.geo" no-title="1"></flag>
								<span class="user-country-name">{{ getCountryByCode(user.geo) }}</span>
							</td>
						</tr>
						<tr>
							<td>{{ ii('STATUS') }}:</td>
							<td colspan="3">
								<select
									v-model="user.statusData"
									@change="changeStatusData(user)">
									<option :value="0"></option>
									<option v-for="(opt, i) in leadMetricsStatus" :value="opt.id">{{ opt.title }}</option>
								</select>
							</td>
						</tr>
						<tr>
							<td>{{ ii('LOCAL_TIME') }}:</td>
							<td colspan="3">{{ user.localTime }}</td>
						</tr>
						<tr>
							<td>{{ ii('REGISTRATION') }}:</td>
							<td colspan="3"><datetime :timestamp="user.createdAt"></datetime></td>
						</tr>
						<tr>
							<td>{{ ii('SOURCE') }}:</td>
							<td colspan="3">{{ user.oauthProvider }}</td>
						</tr>
						<tr>
							<td>{{ ii('E_MAIL') }}:</td>
							<td colspan="3" class="user-email" style="word-break: break-word">
								<clipboard :text="user.email" cursor="default">{{ user.email }}</clipboard>
							</td>
						</tr>
						<tr>
							<td>{{ ii('PHONES') }}:</td>
							<td>
								<phone-input
									v-model="phoneAdded"
									:format-callback="onPhoneFormat"
									:disabled="isFreePlan()"
								></phone-input>
							</td>
							<td
								class="button-td"
								v-if="phoneAddedInfo && phoneAddedInfo.valid == true"
								@click="addAnotherPhone(user)"
								v-title="ii('ADD_PHONE')"
							>
								<i
									class="fa fa-plus"
									:class="isFreePlan() ? 'cursor-not-allowed opacity04' : ''"
								></i>
							</td>
							<td
								class="button-td"
								v-else
								v-title="ii('CLEAR')"
								@click="phoneAddedInfo = null; phoneAdded = ''"
							>
								<i
									:class="isFreePlan() ? 'cursor-not-allowed opacity04' : ''"
									class="fa fa-eraser"
								></i>
							</td>
						</tr>
						<tr v-if="user.additionalPhones" v-for="(r, i) in user.additionalPhones">
							<td></td>
							<td>
								<div class="user-contact-node">
									<template>
										<span
											v-title="ii('COPY')"
											class="fa fa-clone link"
											@click.stop="copyText(normalizePhone(r.phone, true))"
										></span>
										<a
											:href="'tel:' + normalizePhone(r.phone, true)"
										>
											{{ r.phone }}
										</a>
									</template>
								</div>
							</td>
							<td class="button-td" @click="deleteNumber(r, user, i)">
								<i
									class="fa fa-trash-o"
									:class="isFreePlan() ? 'cursor-not-allowed opacity04' : ''"
									v-title="ii('DELETE')"
								></i>
							</td>
						</tr>
						<template v-if="phoneAddedInfo && phoneAddedInfo.valid == false">
							<tr class="added-phone-info-notvalid">
								<td colspan="4">
									{{ ii('THIS_PHONE_IS_NOT_VALID') }}
								</td>
							</tr>
						</template>
						<template v-if="phoneAddedInfo && phoneAddedInfo.valid == true">
							<tr class="added-phone-info-valid">
								<td>{{ ii('CARRIER') }}:</td>
								<td colspan="3" v-if="phoneAddedInfo.carrier != ''">{{ phoneAddedInfo.carrier }}</td>
								<td colspan="3" v-else>{{ phoneAddedInfo.lineType }}</td>
							</tr>
							<tr class="added-phone-info-valid">
								<td>{{ ii('INTERNATIONAL') }}:</td>
								<td colspan="3">{{ phoneAddedInfo.internationalFormat }}</td>
							</tr>
							<tr class="added-phone-info-valid">
								<td>{{ ii('LOCAL') }}:</td>
								<td colspan="3">{{ phoneAddedInfo.localFormat }}</td>
							</tr>
							<tr class="added-phone-info-valid">
								<td>{{ ii('LOCATION') }}:</td>
								<td colspan="3">{{ phoneAddedInfo.location }}</td>
							</tr>
						</template>
						<tr>
							<td>{{ ii('MANAGER') }}:</td>
							<td colspan="3" style="word-break: break-word">
								{{ workersMap[user.idm] ? workersMap[user.idm].name : '-' }}
							</td>
						</tr>
						<tr>
							<td>{{ ii('DELETE_ACCOUNT') }}:</td>
							<td colspan="3">
								<span
									:class="isFreePlan() ? 'cursor-not-allowed opacity04' : 'pointer'"
									@click="deleteUser(user)"
									v-title="ii('DELETE_ACCOUNT')"
								>
									<i class="fa fa-trash-o"></i>
								</span>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<div class="comments-section">
			<user-comments ref="userComments" :idu="idu"></user-comments>
		</div>
	</div>
</div>
`;

export default {
	template,
	data() {
		return {
			isLoading: true,
			user: {},
			phoneAdded: '',
			phoneAddedInfo: null
		};
	},
	props: ['idu'],
	computed: {
		...Store.mapState(['leadMetrics', 'workersMap']),
		leadMetricsStatus() {
			if (!this.leadMetrics || !this.leadMetrics.status) return [];
			return this.leadMetrics.status;
		}
	},
	methods: {
		async loadUserInfo() {
			this.user = await this.get('UserCard.getUserProfileInfo', {idu: this.idu});
		},
		async changeUsername(user) {
			if(this.isFreePlan()) return;
			let newName = await this.prompt('Enter new username:', user.name);

			if (newName === user.name || newName === null) return;
			if (newName === '') return this.alert('Name cannot be empty');

			let err = await this.post('UserCard.changeUsername', user.id, newName);
			if (err) return this.alert('Error: ' + err);

			this.$set(user, 'name', newName);
		},
		onPhoneFormat(j) {
			this.phoneAddedInfo = j;
		},
		async addAnotherPhone(user) {
			if(this.isFreePlan()) return;
			let newPhone = this.phoneAdded;

			if (newPhone === null || newPhone === 0 || newPhone === '') return;

			let countryCode = this.phoneAddedInfo.countryCode;

			let params = [
				user.id,
				newPhone,
				countryCode,
			];
			let res = await this.post('UserCard.addUserPhone', ...params);
			this.$set(user['additionalPhones'], user['additionalPhones'].length, {
				phone: newPhone,
				id: res
			});
			this.phoneAddedInfo = null;
			this.phoneAdded = '';
		},
		async changeStatusData(user) {
			if (user.statusData == null) return;

			let res = await this.post('UserCard.changeStatusData', user.id, user.statusData);
			if (res) return this.alert('Error: ' + res);
		},
		async deleteNumber(r, user, i) {
			if(this.isFreePlan()) return;
			let ok = await this.confirm(this.ii('ARE_YOU_SURE_DELETE_PHONE'));
			if (!ok) return;

			let res = await this.post('UserCard.deleteUserPhone', r.id);
			if (res) return this.alert(this.ii('ERROR') + ': ' + res);

			return this.$delete(user['additionalPhones'], i);
		},
		async deleteUser(user) {
			if(this.isFreePlan()) return;
			let ok = await this.confirm(this.ii('GOING_DELETE_ACCOUNT_SURE'));
			if (!ok) return;

			let res = await this.post('UserCard.deleteUser', user.id);

			if(!res) return this.alert('Error');

			this.$emit('delete', this.idu);
		}
	},
	async mounted() {
		if (this.idu) await this.loadUserInfo();

		this.isLoading = false;
	}
};
