import Store from '../../store.js';

const template = `
	<transition name="fade">
		<div class="popup">
			<popup
				cls="penalty-popup"
				width="400"
				@close="$emit('close')"
				no-scroll="1"
			>
				<div slot="header">{{ ii('PENALTY') }}</div>
				<div>
					<table>
						<tr>
							<td>{{ ii('TYPE') }}: </td>
							<td>
								<rich-sel 
									v-if="penaltyPopup.id"
									:items="getPenaltyTypeSuggestions()" 
									:placeholder="ii('OTHER')"
									v-model="penaltyPopup.type"
									style="width: calc(100% - 25px)"
									@change="changeTypePenalty()"
								></rich-sel>
								<multi-sel 
									v-else
									:items="getPenaltyTypeSuggestions()" 
									:placeholder="ii('OTHER')"
									v-model="penaltyPopup.type"
									:style="!penaltyPopup.type || penaltyPopup.type.split(',').length < 2 ? 'width: calc(100% - 25px)' : 'width: 100%'"
									@change="changeTypePenalty()"
								></multi-sel>
								<span 
									v-if="penaltyPopup.id || !penaltyPopup.type || penaltyPopup.type.split(',').length < 2" 
									class="link right" 
									@click="editTypePenalty()"
								><i :class="'fa sub ' + (!penaltyPopup.type ? 'fa-plus' : 'fa-edit')"></i></span>
							</td>
						</tr>
						<tr>
							<td>{{ ii('MANAGER') }}: </td>
							<td>
								<rich-sel :items="getManagersItems()" v-model="penaltyPopup.idm"></rich-sel>
							</td>
						</tr>
						<tr>
							<td>{{ ii('USER_ID') }}: </td>
							<td>
								<input class="width100" type="number" v-model="penaltyPopup.idu">
							</td>
						</tr>
						<tr>
							<td>{{ ii('PENALTY') }}: </td>
							<td>
								<input 
									class="width100" 
									type="number" 
									v-model="penaltyPopup.penalty" 
									:disabled="penaltyPopup.type"
								>
							</td>
						</tr>
						<tr>
							<td>{{ ii('COMMENT') }}: </td>
							<td>
								<textarea v-model="penaltyPopup.comment"></textarea>
							</td>
						</tr>
					</table>
				</div>
				<div slot="footer">
					<btn
						class="penalty-save right"
						size="small"
						:loading="penaltyPopup.isSaving"
						@click.native="savePenalty()"
					>
						<i slot="icon" class="fa fa-fw fa-save"></i>
						{{ ii('SAVE') }}
					</btn>
				</div>
			</popup>
				
			<popup 
				cls="penalty-popup"
				v-if="editTypePopup" 
				@close="editTypePopup = null"
				width="400"
				no-scroll="1"
			>
				<div slot="header">{{ ii('PENALTY_TYPE') }}</div>
				<div>
					<table>
						<tr>
							<td>{{ ii('TITLE') }}: </td>
							<td><input class="width100" type="text" v-model="editTypePopup.title"></td>
						</tr>
						<tr>
							<td>{{ ii('PENALTY') }}: </td>
							<td><input class="width100" type="number" v-model="editTypePopup.penalty"></td>
						</tr>
						<tr>
							<td>{{ ii('CATEGORY') }}: </td>
							<td>
								<rich-sel 
									:items="getCategorySuggestions()" 
									:placeholder="ii('OTHER')"
									class="width100"
									v-model="editTypePopup.catId"
									@change="changeTypePenalty()"
								></rich-sel>
							</td>
						</tr>
					</table>
				</div>
				<div slot="footer">
					<btn
						class="right"
						size="small"
						:loading="editTypePopup.isSaving"
						@click.native="savePenaltyType()"
					>
						<i slot="icon" class="fa fa-fw fa-save"></i>
						{{ ii('SAVE') }}
					</btn>
					<btn
						class="right"
						v-if="penaltyPopup.type"
						size="small"
						color="soft-red"
						@click.native="deletePenaltyType()"
						:loading="editTypePopup.isDeleting"
					>
						<i class="fa fa-fw fa-remove" slot="icon"></i>
						{{ ii('DELETE') }}
					</btn>
				</div>	
			</popup>
		</div>
	</transition>
`;

export default {
	template: template,
	props: ['event'],
	computed: {
		...Store.mapState(['workersMap', 'managersMap'])
	},
	data() {
		return {
			penaltyPopup: {},
			types: null,
			categories: null,
			editTypePopup: null
		};
	},
	methods: {
		getPenaltyTypeSuggestions() {
			if (!this.types) return;
			return this.types.map((t, i) => {
				return ({
					key: t.id,
					val: (t.title == 'Other' ? t.title : t.category + ' - ' + t.title) + ' ' + t.penalty + '$',
					sortVal: t.sort
				});
			});
		},
		async loadPenaltyTypeSuggestions() {
			this.types = await this.get('Penalties.getPenaltyType');
		},
		getCategorySuggestions() {
			if (!this.categories) return;
			return this.categories.map((c, i) => {
				return ({
					key: c.id,
					val: c.category,
					sortVal: c.sort
				});
			});
		},
		async loadCategorySuggestions() {
			this.categories = await this.get('Penalties.getCategories');
		},
		changeTypePenalty() {
			if (!this.penaltyPopup.type || !this.types) {
				this.$delete(this.penaltyPopup, 'type');
				this.$set(this.penaltyPopup, 'penalty', 0);
				return;
			}

			this.penaltyPopup.type = this.penaltyPopup.type.toString();
			let types = this.types.filter(t => this.penaltyPopup.type.split(',').indexOf(t.id.toString()) != -1);
			if (!types.length) return;

			let sumPenalty = 0;
			types.forEach(type => {
				sumPenalty = sumPenalty + type.penalty;
			});
			this.$set(this.penaltyPopup, 'penalty', sumPenalty);
		},
		async editTypePenalty() {
			if (!this.penaltyPopup.type) this.editTypePopup = {};

			let type = this.types.filter(t => t.id == this.penaltyPopup.type);
			if (!type.length) return;

			this.editTypePopup = {
				isSaving: false,
				title: type[0].title,
				penalty: type[0].penalty,
				catId: type[0].catId
			};
		},
		async deletePenaltyType() {
			let ok = await this.confirm(this.ii('ARE_YOU_SURE'));
			if (!ok || !this.penaltyPopup.type) return;

			this.editTypePopup.isDeleting = true;
			await this.post('Penalties.deletePenaltyType', this.penaltyPopup.type);
			await this.loadPenaltyTypeSuggestions();
			this.editTypePopup.isDeleting = false;
			this.editTypePopup = null;
			this.$delete(this.penaltyPopup, 'type');
		},
		async savePenaltyType() {
			let p = this.editTypePopup;
			if (!p.title) return await this.alert(this.ii('ENTER_TITLE'));
			if (!p.penalty || p.penalty == 0) this.$set(p, 'penalty', 0);

			p.isSaving = true;
			await this.post('Penalties.savePenaltyType', p, this.penaltyPopup.type);
			await this.loadPenaltyTypeSuggestions();
			await this.loadCategorySuggestions();
			await this.changeTypePenalty();
			p.isSaving = false;
			this.editTypePopup = null;
		},
		async savePenalty() {
			let p = this.penaltyPopup;

			if (!p.idm) return await this.alert(this.ii('SELECT_MANAGER'));
			if (!p.penalty || p.penalty == 0) this.$set(p, 'penalty', 0);

			p.isSaving = true;
			await this.post('Penalties.savePenalty', p);
			if (p.type > 0) p.title = this.types.filter(t => t.id == p.type)[0].title;
			p.fromIdw = this.worker.id;

			p.isSaving = false;
			this.$emit('close', p);
		}
	},
	async mounted() {
		await this.loadPenaltyTypeSuggestions();
		await this.loadCategorySuggestions();
		this.penaltyPopup = { penalty: 0, comment: '' };

		if (this.event && typeof this.event === 'object') this.penaltyPopup = this.event;
		if (this.penaltyPopup.type || this.penaltyPopup.type == 0) {
			let type = this.types.filter(t => t.id == this.penaltyPopup.type);
			if (!type.length) this.$delete(this.penaltyPopup, 'type');
		}
	}
};
