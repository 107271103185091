import Store from '../../store.js';

const template = `
	<span v-title="fullInfo ? '' : visitedAt">
		<i :class="'fa fa-fw fa-circle font10 ' + circleClass"></i>
		{{ fullInfo ? visitedAt : '' }}
	</span>
`;

export default {
	template: template,
	data() {
		return({
			visitedAt: null,
			circleClass: ''
		});
	},
	props: [
		'idw',
		'idm',
		'full-info',
	],
	computed: {
		...Store.mapState(['workersMap']),
	},
	methods: {
		getWorkerLastVisit() {
			let visitedAt = null;

			if (this.idw > 0) {
				visitedAt = this.workersMap[this.idw].visitedAt;
			} else if (this.idm > 0) {
				for (let idw in this.workersMap) {
					if (this.workersMap[idw].idm === this.idm) {
						visitedAt = this.workersMap[idw].visitedAt;
					}
				}
			}

			this.circleClass = (moment() - moment(visitedAt) > 120000) || !visitedAt ? 'grey' : 'green';
			this.visitedAt = visitedAt ? moment(visitedAt).fromNow() : '';
		}
	},
	mounted() {
		if (!this.idw && !this.idm) return;

		this.getWorkerLastVisit();
	}
};
