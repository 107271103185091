const template = `
	<a
		:href="curHref"
		target="_blank"
		rel="noreferrer noopener"
	><slot></slot></a>
`;

export default {
	template,
	props: ['href'],
	data() {
		return {
			curHref: null
		};
	},
	created() {
		this.curHref = this.href;
	}
};
