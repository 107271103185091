const template = `
	<div class="snow-sky">
		<div class="snow" v-for="i in flakesCount"></div>
	</div>
`;

export default {
	template: template,
	props: ['flakes-cnt'],
	data() {
		return {
			defaultFlakesCnt: 50
		};
	},
	computed: {
		flakesCount() {
			let count = parseInt(this.flakesCnt);
			if (isNaN(count) || count < 1) count = this.defaultFlakesCnt;
			if (count > 200) count = 200;

			return count;
		}
	}
};
