const template = `
	<transition name="fade">
		<div
			class="sidebar anim-from-left"
			v-show="value"
			ref="sidebar"
			@touchstart="onSidebarTouchStart"
			@touchend="onSidebarTouchEnd"
			@click="onSidebarClick"
		>
			<div class="sidebar-content" v-show="value && !sidebarSettingsOpen">
				<div class="sidebar-worker">
					<div class="sidebar-photo">
						<img :src="workerImg || app.defaultAvatarUrl" alt="">
					</div>
					<div class="sidebar-worker-name">{{ workerName }}</div>
				</div>
				<div class="sidebar-menu">
					<div v-for="(group, i) in tree" class="sidebar-group">
						<div class="sidebar-group-header">{{ ii(group.label) }}</div>
						<template v-for="(page, i) in group.pagesWithChilds">
							<div
								v-if="page.displaySt"
								:class="['sidebar-item', {'sidebar-item-current': page.isCurrent}]"
							>
								<a
									:href="page.isLink ? ('/' + page.slug) : '#sidebar'"
									@click="!page.isLink ? showHideSubmenu(page) : ''"
									:class="[{'active': page.isCurrent}]"
								>
									<i :class="'fa fa-fw fa-' + (page.fa || 'file-o')"></i>
									{{ ii(page.shortLabel || page.label) }}
									<template v-if="app.pagesNumbers[page.slug]">({{ app.pagesNumbers[page.slug] }})</template>
								</a>
								<span
									v-if="page.isParent"
									:class="'children-chervon' + (page.showSubmenu ? ' active' : '')"
									@click="showHideSubmenu(page)"
								><i class="zmdi zmdi-chevron-right"></i></span>
							</div>
							<div v-if="page.isParent && page.showSubmenu" class="children-submenu">
								<div
									v-if="p.displaySt"
									:class="['sidebar-item', {'sidebar-item-current': p.isCurrent}]"
									v-for="p in page.pages"
								>
									<a
										:href="'/' + p.slug"
										class="clearfix submenu-notif-link nowrap"
										:class="p.isCurrent ? 'active' : ''"
									>
										<span>
											<i class="fa fa-fw" :class="'fa-' + p.fa"></i>
											{{ ii(p.label) }}
										</span>
										<span
											v-if="p.newsCnt"
											:class="(isWorkerQmGroup || isPenaltyNews(p.slug)? 'orangered ' : '') + 'submenu-notif-news-cnt'"
										>
											<span>{{ p.newsCnt }}</span>
										</span>
									</a>	
								</div>						
							</div>
						</template>
						
					</div>
				</div>
				<!--
				<div class="sidebar-bottom-section">
					<div><a href="javascript:;" @click="showMobileSettings">{{ ii('NOTIFICATIONS') }}</em></a></div>
				</div>
				-->
				<div class="sidebar-bottom-section">
					<div><a href="javascript:;" @click="logout">{{ ii('LOGOUT') }}</em></a></div>
				</div>
			</div>
			<transition name="fade">
				<div class="mob-settings" v-if="sidebarSettingsOpen">
					<div v-if="webpushAvailable">
						Push notifications:
						<a href="javascript:;" @click="subscribeWebPush">subscribe</a>
					</div>
				</div>
			</transition>
		</div>
	</transition>
`;

export default {
	template,
	props: [
		'value',
		'worker-img',
		'worker-name',
		'tree',
		'current-page',
		'webpush-available'
	],
	data() {
		return {
			sidebarSettingsOpen: false
		};
	},
	computed: {
		isWorkerQmGroup() {
			return this.checkGroup('qm');
		}
	},
	methods: {
		preventDefault: e => e.preventDefault(),
		onSidebarTouchStart(e) {
			if (e.target === this.$refs.sidebar) {
				window.addEventListener('touchmove', this.preventDefault, {passive: false});
			}
		},
		onSidebarTouchEnd(e) {
			if (e.target === this.$refs.sidebar) {
				window.removeEventListener('touchmove', this.preventDefault);
			}
		},
		onSidebarClick(e) {
			if (e.target === this.$refs.sidebar) {
				this.hideSidebar();
				window.removeEventListener('touchmove', this.preventDefault);
			}
		},
		showMobileSettings() {
			this.sidebarSettingsOpen = true;
		},
		subscribeWebPush() {
			this.$emit('subscribe-webpush');
		},
		hideSidebar() {
			this.sidebarSettingsOpen = false;
			this.$emit('input', false);
		},
		logout() {
			this.$emit('logout');
		},
		showHideSubmenu(page) {
			this.$set(page, 'showSubmenu', !page.showSubmenu);
		},
		isPenaltyNews(page) {
			return page === 'penalties';
		},
		isPenaltyNewsInGroup(pages) {
			if (!pages) return false;
			return pages.filter(p => p.newsCnt && p.slug === 'penalties').length > 0;
		}
	},
	watch: {
		value() {
			if (!this.value) {
				this.navigateOutHash(true);
			}
		}
	}
};
