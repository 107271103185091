const template = `
	<div class="calendar">
		<div class="dp-calendar">
			<div v-if="withTime" class="dp-time">
				<!--<rich-sel :items="timesSuggestions" v-model="time"></rich-sel>-->
				<input type="time" v-model.lazy="time" @change="onTimeChanged" required>
			</div>
			<div class="dp-controls">
				<select v-model="month" class="dp-month">
					<option v-if="!month" value=""></option>
					<option
						v-for="(monthText, key) in months"
						:value="monthIndexToString(key)"
					>{{ monthText }}</option>
				</select>
				<input type="number" v-model.lazy="year" class="dp-year">
			</div>
			<div>
				<table>
					<thead>
						<tr>
							<th v-for="day in weekDays">{{ day }}</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="row in grid">
							<td
								v-for="cell in row"
								:class="getCellClasses(cell)"
								@click="onCellClick(cell)"
							>
								<span v-if="cell">{{ cell.day }}</span>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
`;

export default {
	template: template,
	props: ['value', 'with-time', 'unclearable', 'empty-val', 'empty-text'],
	data() {
		return {
			nativeVal: null,
			calendarShown: false,
			year: null,
			month: null,
			time: '00:00',
			curDate: moment().format('YYYY-MM-DD'),
			curTime: moment().format('HH:mm'),
			minYear: 1950,
			maxYear: 2025
		};
	},
	computed: {
		weekDays() {
			this.app.store.datetimeRefresher;
			return this.getLocalizedDows();
		},
		months() {
			this.app.store.datetimeRefresher;
			return this.getLocalizedMonths();
		},
		tfText() {
			if (this.emptyVal && this.emptyText && this.value === this.emptyVal) return this.emptyText;
			return this.value;
		},
		val() {
			return this.value || this.curDate;
		},
		grid() {
			if (!this.year || !this.month) return [];

			let yearMonth = this.year + '-' + this.month;

			let time = moment(yearMonth + '-01');
			let dayOfWeek = time.day() - 1;
			if (dayOfWeek < 0) {
				dayOfWeek = 6;
			}
			let daysInMonth = time.daysInMonth();

			let grid = [];
			let rowsCnt = Math.ceil((daysInMonth + dayOfWeek) / 7);
			for (let i = 0; i < rowsCnt; i++) {
				let row = [];
				for (let j = 0; j < 7; j++) {
					let cell = null;
					if (i > 0 || j >= dayOfWeek) {
						let day = i * 7 + j - dayOfWeek + 1;
						let date = yearMonth + '-' + (day < 10 ? '0' : '') + day;
						if (day <= daysInMonth) {
							cell = {day, date};
						}
					}
					row.push(cell);
				}
				grid.push(row);
			}

			return grid;
		},
		timesSuggestions() {
			let items = [];

			for (let h = 0; h < 24; h++) {
				for (let m = 0; m < 60; m += 10) {
					let time = (h < 10 ? '0' + h : h) + ':' + (m < 10 ? '0' + m : m);
					items.push({
						key: time,
						val: time
					});
				}
			}

			return items;
		}
	},
	methods: {
		showCalendar() {
			this.updateDatetimeData();
			this.calendarShown = true;
		},
		hideCalendar() {
			this.calendarShown = false;
			this.time = '00:00';
		},
		onCellClick(cell) {
			if (!cell || cell.date === this.date) return;

			let val = cell.date;
			if (this.withTime) {
				val += ' ' + this.time;
			}
			this.setVal(val);
			// this.hideCalendar();
		},
		updateDatetimeData() {
			if (this.val === this.emptyVal) return;

			this.year = this.val.slice(0, 4);
			this.month = this.val.slice(5, 7);
			if (this.withTime) {
				this.time = this.val.slice(11, 16) || '00:00';
			}
		},
		monthIndexToString(index) {
			let month = String(index + 1);
			if (month.length < 2) {
				month = '0' + month;
			}
			return month;
		},
		getCellClasses(cell) {
			let classes = [];
			if (cell) {
				classes.push('dp-day');
			} else {
				classes.push('dp-day-empty');
			}
			if (cell && cell.date === this.curDate) {
				classes.push('today');
			}
			if (cell && cell.date === this.val && !this.value) {
				classes.push('chosen');
			}
			if (cell && cell.date === String(this.val || '').slice(0, 10)) {
				classes.push('chosen');
			}
			return classes;
		},
		setVal(date) {
			this.$emit('input', date);
			this.$emit('change', {
				isTrusted: true,
				value: date
			});
		},
		clear() {
			this.setVal(this.emptyVal || '');
			this.hideCalendar();
		},
		onNativeValChanged() {
			this.setVal(this.nativeVal);
		},
		restrictNumber(e, min, max) {
			let val = e.target.value;
			if (isNaN(val) || val === '') {
				e.target.value = '';
			} else if (val < min) {
				e.target.value = min;
			} else if (val > max) {
				e.target.value = max;
			}
		},
		onTimeChanged() {
			let date = this.value || this.val || '';
			let datetime = date.substr(0, 10) + ' ' + this.time;
			this.setVal(datetime);
		}
	},
	watch: {
		value() {
			this.updateDatetimeData();
			this.nativeVal = this.value;
		},
		year() {
			if (this.year) {
				if (this.year < this.minYear) {
					this.year = this.minYear;
				} else if (this.year > this.maxYear) {
					this.year = this.maxYear;
				}
			}
			else {
				this.year = moment().format('YYYY');
			}
		},
		month() {
			this.month = this.month ? this.month :moment().format('MM');
		}
	},
	created() {
		this.updateDatetimeData();

		this.nativeVal = this.value;
	}
};
