import Store from '../../store.js';

const template = `
	<div class="user-pay-settings">
		<div v-if="isLoading" class="loading">
			<span>
				<i class="fa fa-spinner spin-fast center"></i>
				<span class="general-upper">{{ ii('LOADING') }}...</span>
			</span>
		</div>
		<div v-else class="user-section">
			<div class="user-pay-rates">
				<span v-for="cur in rateDisplayCurrencies">
					<strong>{{ cur }}:</strong>
					<span
						v-title="getCurrencyTitle(cur)"
						class="help"
					>{{ 1 / currencyRates[cur + '_USD'] | toFixed(4) }}</span>
				</span>
			</div>
			<table :key="idu">
				<tbody>
					<tr>
						<td colspan="2">
							<h3>{{ ii('SETTINGS') }}</h3>
						</td>
					</tr>
					<tr>
						<td class="general-upper">{{ ii('PAYMENT_SYSTEM') }}:</td>
						<td>
							<select
								:disabled="!checkPermission('pay_settings:admin')"
								v-model="settings.payment_system"
							>
								<option value="fastspring">Fastspring</option>
								<option value="connectum">Сonnectum</option>
							</select>
						</td>
					</tr>
					<tr>
						<td class="general-upper">{{ ii('CURRENCY') }}:</td>
						<td>
							<select
								:disabled="!checkPermission('pay_settings:admin')"
								v-model="settings.currency"
							>
								<option v-for="cur in currencies" :value="cur.key">{{ cur.text }}</option>
							</select>
						</td>
					</tr>
					<tr>
						<td class="general-upper">{{ ii('HIDE_PRICING') }}:</td>
						<td>
							<label class="chb">
								<input
									type="checkbox"
									v-model="noPricing"
									:true-value="1"
									:false-value="0"
									:disabled="!checkPermission('pay_settings:admin')"
								>
							</label>
						</td>
					</tr>
					<tr>
						<td class="general-upper">{{ ii('START_WITH') }} $99:</td>
						<td>
							<label class="chb">
								<input
									type="checkbox"
									v-model="startWith99"
									:true-value="1"
									:false-value="0"
									:disabled="!checkPermission('pay_settings:admin')"
								>
							</label>
						</td>
					</tr>
					<tr v-if="checkPermission('pay_settings:admin')">
						<td colspan="2" class="save">
							<btn size="small" @click.native="saveSettings()" :loading="isSaving">
								<i class="fa fa-fw fa-save" slot="icon"></i>
								{{ ii('SAVE') }}
							</btn>
							<transition name="fade">
								<span v-if="savedTitleShown" class="saved-title">Settings saved successfully</span>
							</transition>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
`;

export default {
	template,
	data() {
		return {
			isLoading: true,
			isSaving: false,
			noPricing: 0,
			startWith99: 0,
			settings: {
				payment_system: '',
				currency: '',
			},
			rateDisplayCurrencies: ['EUR', 'GBP', 'RUB'],
			savedTitleShown: false
		};
	},
	props: ['idu', 'partner'],
	computed: {
		...Store.mapState(['workersMap', 'managersMap', 'worker', 'currencyRates']),
		currencies() {
			let list;
			if (this.settings.payment_system === 'connectum') {
				list = [
					{key: 'usd', text: 'USD'},
					{key: 'eur', text: 'EUR'},
					{key: 'gbp', text: 'GBP'},
					{key: 'rub', text: 'RUB'}
				];
			} else {
				list = [
					{key: 'usd', text: 'USD'}
				];
			}
			return list;
		}
	},
	methods: {
		getCurrencyTitle(cur) {
			let rate = this.currencyRates[cur + '_USD'];
			let revRate = 1 / rate;
			let lines = [
				'1 USD = ' + revRate.toFixed(4) + ' ' + cur,
				'1 ' + cur + ' = ' + rate.toFixed(4) + ' USD'
			];
			return lines.join('\n');
		},
		async saveSettings() {
			this.isSaving = true;
			await this.post('Lead.savePaySettings', this.idu, this.settings);
			await this.post('Lead.saveNoPricing', this.idu, this.noPricing);
			await this.post('Lead.saveStartWith99', this.idu, this.startWith99);
			this.isSaving = false;

			this.savedTitleShown = true;
			await this.sleep(2000);
			this.savedTitleShown = false;
		},
		async loadSettings() {
			this.isLoading = true;

			await this.loadState(['currencyRates']);
			this.settings = await this.get('Lead.getPaySettings', this.idu);
			this.noPricing = await this.get('Lead.getNoPricing', this.idu);
			this.startWith99 = await this.get('Lead.getStartWith99', this.idu);

			this.isLoading = false;
		}
	},
	watch: {
		'settings.payment_system'() {
			let cur = this.currencies.find(cur => cur.key === this.settings.currency);
			if (!cur) {
				this.$set(this.settings, 'currency', this.currencies[0].key);
			}
		}
	},
	mounted() {
		if (!this.idu) return this.isLoading = false;

		this.loadSettings();
	}
};