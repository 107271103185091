import Store from '../../store.js';

const template = `
	<div class="quiz-results" v-if="inited" :style="general ? 'display: inline-block;' : ''">
		<span class="short-results">
			<template v-if="general">
				<span @click="groupSelected = 0; showResult = trueResults">
					<img src="/img/smile/all.svg">
				</span>
			</template>
			<template v-else-if="trueResults && shortResult">
				<span @click="groupSelected = 1; showResult = trueResults" v-title="ii('GOOD_FOR_YOU', 'Good for you')">
					<img src="/img/smile/happy.svg"> - {{shortResult[1]}}
				</span>
				<span @click="groupSelected = 2; showResult = trueResults" v-title="ii('BAD_NEWS', 'Bad news')">
					<img src="/img/smile/poo.svg"> - {{shortResult[2]}}
				</span>
				<span @click="groupSelected = 3; showResult = trueResults" v-title="ii('JUST_CALL', 'Just call')">
					<img src="/img/smile/phone-receiver.svg"> - {{shortResult[3]}}
				</span>
				<span @click="groupSelected = 4; showResult = trueResults" v-title="ii('NEUTRAL', 'Neutral')">
					<img src="/img/smile/confused.svg"> - {{shortResult[4]}}
				</span>
				<span @click="groupSelected = 0; showResult = trueResults" v-title="ii('SHOW_ALL', 'Show all')">
					<img src="/img/smile/all.svg">
				</span>
			</template>
			<template v-else>
				<span><img src="/img/smile/happy.svg" alt=""> - 0</span>
				<span><img src="/img/smile/poo.svg" alt=""> - 0</span>
				<span><img src="/img/smile/phone-receiver.svg" alt=""> - 0</span>
				<span><img src="/img/smile/confused.svg" alt=""> - 0</span>
			</template>
		</span>
		<popup v-if="showResult" @close="showResult = null" content-max-height="80vh" width="700px">
			<div class="popup-inner">
				<template v-if="!general">
					<div class="heading">
						<h2>{{quizes[trueResults.type]}}</h2>
						<div class="btns rigth">
							<img  
								v-title="ii('GOOD_FOR_YOU', 'Good for you')" 
								:class="groupSelected == 1 ? 'active' : ''" 
								src="/img/smile/happy.svg" 
								@click="groupSelected = 1; showResult = trueResults">
							<img  
								v-title="ii('BAD_NEWS', 'Bad news')" 
								:class="groupSelected == 2 ? 'active' : ''" 
								src="/img/smile/poo.svg" 
								@click="groupSelected = 2; showResult = trueResults">
							<img  
								v-title="ii('JUST_CALL', 'Just call')" 
								:class="groupSelected == 3 ? 'active' : ''" 
								src="/img/smile/phone-receiver.svg" 
								@click="groupSelected = 3; showResult = trueResults">
							<img  
								v-title="ii('NEUTRAL', 'Neutral')" 
								:class="groupSelected == 4 ? 'active' : ''" 
								src="/img/smile/confused.svg" 
								@click="groupSelected = 4; showResult = trueResults">
							<img 
								v-title="ii('SHOW_ALL', 'Show all')" 
								:class="groupSelected == -1 ? 'active' : ''" 
								src="/img/smile/all.svg"  
								@click="groupSelected = -1; showResult = trueResults"
							></img>
						</div>
					</div>
				</template>
				
				<template v-for="res in showResult.result">
					<div class="result-row" v-if="res.st != 5">
					<template v-if="groupSelected > 0 ? (''+res.st+'').includes(groupSelected) : true">
						<template v-if="res.type == 'radio' || res.type == 'check'">
							<div class="question"
								 v-html="quizLocalization[res.id] ? quizLocalization[res.id].en : 'No questions with id' + res.question"
							></div>
							<div class="answer">
								<table>
									<tr 
										v-for="answer in res.answers"
									>
										<td>
											<template v-if="answer.st == 1">
												<img src="/img/smile/happy.svg" alt="" v-title="ii('GOOD_FOR_YOU', 'Good for you')">
											</template>
											<template v-else-if="answer.st == 2">
												<img src="/img/smile/poo.svg" alt="" v-title="ii('BAD_NEWS', 'Bad news')">
											</template>
											<template v-else-if="answer.st == 3">
												<img src="/img/smile/phone-receiver.svg" alt="" v-title="ii('JUST_CALL', 'Just call')">
											</template>
											<template v-else-if="answer.st == 4">
												<img src="/img/smile/confused.svg" alt="" v-title="ii('NEUTRAL', 'Neutral')">
											</template>
										</td>
										<td><i class="fa success" :class="answer.checked == 1 ? 'fa-check' : ''"></i></td>
										<td :class="(answer.checked == 1 && answer.st == groupSelected && groupSelected > 0) ? 'bg-success' : ''">
											<span v-html="getAnswer(answer)"></span>
											<span v-if="generalResults"> (<strong>{{generalResults[showResult.type][res.id][answer.id] || 0}}</strong>)</span>
										</td>
									</tr>
								</table>
							</div>
						</template>
						<template v-if="!general && res.type == 'range'">
							<div class="question"
								 v-html="quizLocalization[res.id] ? quizLocalization[res.id].en : 'No questions with id' + res.question"
							></div>
							<div class="answer">
								<table>
									<tr>
										<td>
											<template v-if="res.st == 1">
												<img src="/img/smile/happy.svg" alt="" v-title="ii('GOOD_FOR_YOU', 'Good for you')">
											</template>
											<template v-else-if="res.st == 2">
												<img src="/img/smile/poo.svg" alt="" v-title="ii('BAD_NEWS', 'Bad news')">
											</template>
											<template v-else-if="res.st == 3">
												<img src="/img/smile/phone-receiver.svg" alt="" v-title="ii('JUST_CALL', 'Just call')">
											</template>
											<template v-else-if="res.st == 4">
												<img src="/img/smile/confused.svg" alt="" v-title="ii('NEUTRAL', 'Neutral')">
											</template>
										</td>
										<td><i class="fa success fa-check"></i></td>
										<td
											:class="(res.st == groupSelected && groupSelected > 0) ? 'bg-success' : ''"
										>{{res.custom}}</td>
									</tr>
								</table>
							</div>
						</template>
						<template v-if="!general && (res.type == 'field' || res.type == 'tel')">
							<div class="question"
								 v-html="quizLocalization[res.id] ? quizLocalization[res.id].en : 'No questions with id' + res.question"
							></div>
							<div class="answer">
								<table>
									<tr>
										<td>
											<template v-if="res.st == 1">
												<img src="/img/smile/happy.svg" alt="" v-title="ii('GOOD_FOR_YOU', 'Good for you')">
											</template>
											<template v-else-if="res.st == 2">
												<img src="/img/smile/poo.svg" alt="" v-title="ii('BAD_NEWS', 'Bad news')">
											</template>
											<template v-else-if="res.st == 3">
												<img src="/img/smile/phone-receiver.svg" alt="" v-title="ii('JUST_CALL', 'Just call')">
											</template>
											<template v-else-if="res.st == 4">
												<img src="/img/smile/confused.svg" alt="" v-title="ii('NEUTRAL', 'Neutral')">
											</template>
										</td>
										<td><i class="fa success fa-check"></i></td>
										<td
											:class="(res.st == groupSelected && groupSelected > 0) ? 'bg-success' : ''"
										>{{res.custom}}</td>
									</tr>
								</table>
							</div>
						</template>
					</template>
				</div>
				</template>
			</div>
		</popup>
	</div>
`;

export default {
	template,
	props: ['results', 'id', 'general'],
	data() {
		return {
			inited: false,
			groupSelected: 0,
			showResult: null,
			trueResults: null,
			quizes: {
				1: 'QUIZ A',
				2: 'QUIZ B',
				3: 'QUIZ C',
			},
			generalResults: null,
			surveyConfigs: null
		};
	},
	computed: {
		...Store.mapState(['quizLocalization']),
		shortResult() {
			if (this.general) return [];
			if (this.trueResults.result.length) {
				let short = {1:0, 2:0, 3:0, 4:0};
				this.trueResults.result.forEach(e => {
					short[e.st]++;
				});
				return short;
			}
			return [];
		}
	},
	methods: {
		getAnswer(data) {
			if (data.custom) return data.custom;
			else {
				return this.quizLocalization[data.id] ? this.quizLocalization[data.id].en : 'No questions with id' + data.id;
			}
		}
	},
	async mounted() {
		if(this.general) {
			this.generalResults = await this.get('Survey.getGeneralResults');
			this.trueResults = {
				type: this.general.id,
				result: JSON.parse(this.general.config)
			};
			if (!this.generalResults[this.general.id]) {
				return this.inited = false;
			}
		}
		else {
			if (this.results) {
				this.trueResults = this.results;
			}
			else if (!this.results && this.id) {
				this.trueResults = await this.get('Survey.getSingleQuizResult', {id: this.id});
			}
			else {
				return this.inited = false;
			}
		}
		this.loadState(['quizLocalization']).then(res => {
			this.inited = true;
		});
	},
};
