import Store from '../../store.js';

const template = `
	<div v-show="inited" class="event-table">
		<page-table
			ref="pageTable"
			:query="query"
			remote-method="UserNews.getRows"
			remote-stat-method="UserNews.getStat"
			width="1000"
			@load="onLoad"
			export-method="UserNews.downloadUserIds"
		>
			<template slot="head">
				<tr>
					<th>
						<head-cell-inner :no-sticky="headNoSticky" sort="">#</head-cell-inner>
					</th>
					<th>
						<head-cell-inner :no-sticky="headNoSticky" sort="type">{{ ii('EVENT') }}</head-cell-inner>
					</th>
					<th>
						<head-cell-inner :no-sticky="headNoSticky" sort="eventTime">{{ ii('TIME') }}</head-cell-inner>
					</th>
					<th colspan="2">
						<head-cell-inner :no-sticky="headNoSticky" sort="idu">{{ ii('USER') }}</head-cell-inner>
					</th>
					<th colspan="2">
						<head-cell-inner :no-sticky="headNoSticky" sort="idu">{{ ii('MANAGER') }}</head-cell-inner>
					</th>
					<th>
						<head-cell-inner :no-sticky="headNoSticky">{{ ii('SITES') }}</head-cell-inner>
					</th>
					<th colspan="3">
						<head-cell-inner :no-sticky="headNoSticky" >{{ ii('INFO') }}</head-cell-inner>
					</th>
					<th>
						<head-cell-inner :no-sticky="headNoSticky" sort="status">{{ ii('STATUS') }}</head-cell-inner>
					</th>
					<th>
						<head-cell-inner :no-sticky="headNoSticky">{{ ii('CHECK') }}</head-cell-inner>
					</th>
				</tr>
			</template>

			<template slot="row" scope="p">
				<tr :key="'row' + p.row.id">
					<td class="text-center">
						{{ p.index + 1 }}
					</td>
					<td :class="'event-cell event-cell-' + p.row.type.toLowerCase()">
						{{ p.row.type | upper }}
					</td>
					<td><datetime :timestamp="p.row.eventTime" :format="getDatetimeFormat()"></datetime></td>
					<td colspan="2"  class="text-center" v-if="!p.row.idu || p.row.idu === 10000000">{{ ii('NOT_AUTHORIZED') }}</td>
					<template v-else>
						<td class="leads-user-cell">
							<span class="left">
								<flag :cc="p.row.geo"></flag>
								<clipboard :text="p.row.idu" cursor="default">
									<a :href="'/leads?idu=' + p.row.idu">{{ p.row.idu }}</a>
								</clipboard>
							</span>
							<span class="user-name">
								{{p.row.username}}
							</span>
						</td>
						<td class="text-center">
							<btn size="small" @click.native="toggleMiniCard(p.row)">
								<i slot="icon" class="fa fa-fw fa-vcard"></i>
							</btn>
						</td>
					</template>
					<td colspan="2"  class="text-center nowrap" v-if="!p.row.idm || p.row.idm === 1">
						{{ ii('NO_MANAGER') }}
						<span
							v-if="checkPermission('leads:assignment') && p.row.idu && p.row.idu !== 10000000"
							v-title="ii('EDIT')"
							@click="onEditManager(p.row)"
							class="pointer"
						>
							<i class="fa fa-fw fa-pencil"></i>
						</span>
					</td>
					<template v-else>
						<td>
							#{{ p.row.idm }}
						</td>
						<td class="nowrap">
							{{ workersMap[p.row.idm] ? workersMap[p.row.idm].name : '-' }}
							<span
								v-if="checkPermission('leads:assignment') && p.row.idu && p.row.idu !== 10000000"
								v-title="ii('EDIT')"
								@click="onEditManager(p.row)"
								class="pointer"
							>
								<i class="fa fa-fw fa-pencil"></i>
							</span>
						</td>
					</template>
					<td class="text-center">
						<div class="all-sites" v-for="(site, i) in p.row.uniqueSite" v-if="p.row.allSitesShown || i < sitesToShow" :key="site">
							<a
							   :href="'http://' + site"
							   class="fa fa-external-link"
							   target="_blank"
							   rel="noreferrer noopener"
							   v-title="site"
							></a>
						</div>
						<span
							v-if="p.row.uniqueSite.length > sitesToShow"
							v-title="'and ' + (p.row.uniqueSite.length - sitesToShow) + ' more sites'"
							class="help"
						>...</span>
					</td>

					<template v-if="p.row.type == 'REGISTRATION'">
						<td colspan="3">
							<span>{{ p.row.data }}</span>
						</td>
					</template>
					<template v-else-if="p.row.type == 'CLIENT_REQUEST' && !isNaN(p.row.data)">
						<td colspan="3" class="edge-cell">
							<span
								class="pic-link"
								@click="clientRequestPopupId = p.row.data"
							>
								<i class="fa fa-info-circle"></i>
								<span>{{ ii('VIEW_REQUEST') }}</span>
							</span>
						</td>
					</template>
					<template v-else-if="p.row.type == 'CALLBACK_REQUEST' && !isNaN(p.row.data)">
						<td colspan="3" class="edge-cell">
							<span
								class="pic-link"
								@click="callbackRequestPopupId = p.row.data"
							>
								<i class="fa fa-info-circle"></i>
								<span>{{ ii('VIEW_REQUEST') }}</span>
							</span>
						</td>
					</template>
					<template v-else-if="p.row.type == 'SITES_ADDED'">
						<td colspan="3">
							<template v-if="p.row.data && p.row.data.length">
								<div v-for="(domain, i) in p.row.data" v-if="i < 3">
									<outer-link :href="'http://' + domain">{{ domain }}</outer-link>
								</div>
								<div v-if="p.row.data.length > 3" class="pointer primColor" @click="showAllSites(p.row.data)">
									{{ ii('SHOW_MORE') }}
								</div>
							</template>
						</td>
					</template>
					<template v-else-if="p.row.type == 'EMAIL_OPEN' || p.row.type == 'EMAIL_CLICK' || p.row.type == 'POPUP_CLOSE' || p.row.type == 'POPUP_CLICK'">
						<td colspan="3">
							<div v-for="el in getArrayFromObj(p.row.data)">
								<strong>{{ el.key }}:</strong>
								{{ el.val }}
							</div>
						</td>
					</template>
					<template v-else-if="p.row.type == 'REMINDER'">
						<td colspan="3">
							<span
								v-if="p.row.data"
								class="link"
								@click="onReminderEventClick(p.row.data)"
							>
								{{ shorten(p.row.data.comment, 40) }}
							</span>
						</td>
					</template>
					<template v-else>
						<td colspan="3">
							{{ p.row.data }}
						</td>
					</template>

					<td class="sales-action-cell">
						<template v-if="types && types.canChangeStatus.indexOf(p.row.type) != -1 && p.row.idu > 0">
							<reminder-box
								:idu="p.row.idu"
								:events="p.row.events"
								@close="reminderBoxClose(p.row, $event)"
							></reminder-box>

							<btn size="small" @click.native="showCompleteTaskPopup(p.row)">
								<span class="link no-line fa fa-fw fa-check secColor"></span>
							</btn>
							<span>{{ p.row.status == 0 ? ii('OPEN') : p.row.status == 1 ? ii('COMPLETED') : ii('MOVED') }}</span>
							<span v-if="p.row.comment.length" class="news-comment fa fa-commenting" v-title="p.row.comment"></span>
							<span
								v-if="p.row.status == 1"
								v-title="ii('SET_OPEN_STATUS')"
								@click="setOpenStatus(p.row)"
								class="link fa fa-fw fa-remove"
							></span>
						</template>
					</td>
					<td class="text-center">
						<btn
							size="small"
							:class="[{'opacity04' : !p.row.checkerComment}]"
							v-title="p.row.checkerComment ? p.row.checkerComment : ii('ADD_COMMENT')"
							@click.native="leaveCheckerComment(p.row)"
						>
							<i class="fa fa-fw fa-pencil-square-o"></i>
						</btn>
					</td>
				</tr>
				<tr v-if="p.row.usercardShown === true" class="minicard-row">
					<td colspan="100">
						<user-card :idu="p.row.idu"></user-card>
					</td>
				</tr>
			</template>
		</page-table>

		<client-request-popup
			v-if="clientRequestPopupId"
			:request-id="clientRequestPopupId"
			@close="clientRequestPopupId = null"
		></client-request-popup>

		<callback-request-popup
			v-if="callbackRequestPopupId"
			:request-id="callbackRequestPopupId"
			@close="callbackRequestPopupId = null"
		></callback-request-popup>

		<reminder-popup
			v-if="reminderPopup"
			:event="reminderPopup.event"
			@close="reminderPopup = null;"
		></reminder-popup>

		<popup
			v-if="completeTaskPopup"
			width="500"
			close-on-back="1"
			@close="completeTaskPopup = null"
			no-scroll="1"
			class="completeTask-popup"
		>
			<div slot="header">{{ ii('COMPLETE_TASK') }}</div>
			<table class="completeTask-content">
				<tr>
					<td colspan="3">{{ ii('ADD_YOUR_COMMENT') }}</td>
				</tr>
				<tr>
					<td colspan="3">
						<textarea v-model="completeTaskPopup.comment"></textarea>
					</td>
				</tr>
				<tr>
					<td>
						<label>
							<input type="radio" value="0" v-model="completeTaskPopup.typeComplete">
							{{ ii('CLOSE_CURRENT_EVENT') }}
						</label>
					</td>
					<td>
						<label>
							<input type="radio" value="1" v-model="completeTaskPopup.typeComplete">
							{{ ii('CLOSE_SAME_TYPE') }}
						</label>
					</td>
					<td>
						<label>
							<input type="radio" value="2" v-model="completeTaskPopup.typeComplete">
							{{ ii('CLOSE_ALL') }}
						</label>
					</td>
				</tr>
			</table>
			<div slot="footer">
				<btn
					size="small"
					@click.native="saveCompleteTask()"
					:loading="completeTaskPopup.isSaving"
					class="right"
				>
					<i class="fa fa-fw fa-check" slot="icon"></i>
					{{ ii('SAVE') }}
				</btn>
			</div>
		</popup>
	</div>
`;

export default {
	template: template,
	props: ['query', 'headNoSticky'],
	data() {
		return {
			inited: false,
			mountedFirst: false,
			types: null,
			clientRequestPopupId: null,
			callbackRequestPopupId: null,
			completeTaskPopup: null,
			reminderPopup: null,
			sitesToShow: 5,
		};
	},
	computed: {
		...Store.mapState(['geoMap', 'workersMap']),
	},
	methods: {
		onLoad(e) {
			let itemsIds = e.rows.map(item => item.id);
			if (!itemsIds.length) return this.$emit('load', {});

			let lastId = Math.max.apply(Math, itemsIds);
			this.$emit('load', {lastId});
		},
		async reload() {
			await this.$refs.pageTable.reload();
		},
		toggleMiniCard(row) {
			this.$set(row, 'usercardShown', !row.usercardShown);
		},
		getDatetimeFormat() {
			if (this.app.query.mode !== 'tasks') return;
			return 'HH:mm:ss';
		},
		async setTypes() {
			this.types = await this.get('UserNews.getTypes');
		},
		async leaveCheckerComment(row) {
			let newComment = await this.prompt(this.ii('ADD_YOUR_COMMENT'), row.checkerComment, {type: 'textarea'});
			if (newComment === null || newComment === row.checkerComment) return;

			let res = await this.post('UserNews.changeCheckerComment', row.id, newComment);
			if (!res.success) return this.alert('Error: ' + res.err);

			this.$set(row, 'checkerComment', newComment);
		},
		showCompleteTaskPopup(row) {
			this.completeTaskPopup = {
				isSaving: false,
				typeComplete: 2,
				comment: row.comment,
				event: row
			};
		},
		async saveCompleteTask() {
			let p = this.completeTaskPopup;
			let comment = p && p.comment ? p.comment.trim() : '';
			let q = p.event;
			p.isSaving = true;

			this.$set(q, 'comment', comment);
			this.$set(q, 'status', 1);
			this.$set(q, 'simpleCompleteStatus', p.typeComplete);

			let params = {
				comment,
				id: q.id,
				idu: q.idu,
				type: q.type,
				completeSt: p.typeComplete,
				status: q.status,
				taskId: q.type === 'CIRCLES' ? q.data3 : null
			};

			this.autocomplete(q);
			await this.post('UserNews.setStatus', params);
			p.isSaving = false;
			this.completeTaskPopup = null;
		},
		autocomplete(q) {
			this.$refs.pageTable.rows.forEach(e => {
				let ify;
				if (q.simpleCompleteStatus) {
					if (q.simpleCompleteStatus == 0) {
						ify = e.idu == q.idu && e.id == q.id;
					}
					else if (q.simpleCompleteStatus == 1) {
						ify = e.idu == q.idu && e.type == q.type;
					}
					else {
						ify = e.idu == q.idu;
					}
				}

				if (ify) {
					e.status = 1;
					e.comment = q.comment;
				}
			});
		},
		showModal(row, action) {
			this.hideAllTitles();
			this.$set(row, action, true);
		},
		hideModal(row, action) {
			return () => {
				this.$set(row, action, false);
			};
		},
		async setOpenStatus(row) {
			let ok = await this.confirm(this.ii('ARE_YOU_SURE'));
			if (!ok) return;

			let params = {
				id: row.id,
				idu: row.idu,
				type: row.type,
				status: 0,
				completeSt: 0,
				comment: ''
			};
			await this.post('UserNews.setStatus', params);
			this.$set(row, 'status', 0);
		},
		showAllSites(sites) {
			this.showText(sites.map(site => 'http://' + site).join('\n'));
		},
		getArrayFromObj(obj) {
			if (!obj || typeof obj === 'number' || typeof obj === 'string') return null;

			let arr = [];
			for (let key in obj) {
				let val = obj[key];
				arr.push({key, val});
			}

			return arr;
		},
		async reminderBoxClose(row, e) {
			if (!e || !e.event) return;

			let event = e.event;
			let params = {
				id: row.id,
				idu: row.idu,
				comment: event.comment
			};

			if (event.status == 1) {
				event.status = 1;
				this.autocomplete(event);
				this.$set(row, 'status', 1);
			}
			if (event.status == 0 && event.comment) {
				event.status = 2;
				this.autocomplete(event);
				this.$set(row, 'status', 2);
			}

			params.status = event.status;
			if (!params.status) return;

			await this.post('UserNews.setStatus', params);
		},
		onReminderEventClick(event) {
			this.reminderPopup = {event};
		},
		async onEditManager(row) {
			let newManager = await this.input({
				header: 'Edit manager for user',
				type: 'select',
				items: [{key: 1, val: this.ii('NO_MANAGER')}, ...this.managersSuggestions],
				val: row.idm
			});
			if(!newManager) return;

			await this.post('Lead.editManager', row.id, newManager);
			this.$set(row, 'idm', newManager);
		},
	},
	async mounted() {
		await this.setTypes();
		this.inited = true;
		this.$nextTick(() => {
			this.mountedFirst = true;
		});
	},
	deactivated() {
		this.$emit('load', {});
	},
	activated() {
		if (this.mountedFirst) {
			this.$emit('load', {});
		}
	}
};
