const template = `
	<popup
		class="frame-popup"
		:width="width || 800"
		:resize="resize"
		content-min-height="500"
		content-max-height="520"
		@close="close"
	>
		<div slot="header">{{cuttr()}}</div>
		<div class="popup-content-outer">
			<div
				ref="content"
				class="popup-content popup-scrollable"
				v-block-scroll
			>
				<iframe :src="url" frameborder="0"></iframe>
			</div>
		</div>
	</popup>
`;

export default {
	template,
	props: ['header', 'url', 'width', 'resize'],
	data() {
		return {

		};
	},
	methods: {
		close() {
			this.$emit('close');
		},
		cuttr() {
			if (this.header) return this.header;
			return this.getDomainFromUrl(this.url);
		}
	}
};


//2 use

// <frame-popup
// v-if="initFramePopup"
// :url="testingUrl"
// :header="headerFramePopup"
// @close="initFramePopup = false"
// 	>
// 	</frame-popup>


// <span v-else-if="colsData[j].type === 'url'" :href="cell" @click="testFrame(cell)">{{ cell }}</span>


//  testingUrl : '',
// 	headerFramePopup : 'iframe of this particular link you clicked on',
// 	initFramePopup : false,


// testFrame(fra) {
// 	this.initFramePopup = true;
// 	this.testingUrl = fra;
// },