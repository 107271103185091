import Store from '../../store.js';

const template = `
	<popup
		v-if="phone"
		class="calls-popup"
		width="700"
		@close="close"
	>
		<div slot="header">Calls to {{ phone }}</div>
		<div>
			<div v-if="!calls" class="calls-loading">
				<i class="fa fa-spinner spin-fast"></i> Loading...
			</div>
			<div class="calls-box" v-block-scroll>
				<table class="calls-tbl" v-show="calls">
					<tr>
						<th>#</th>
						<th>Datetime</th>
						<th>Sec</th>
						<th>Manager</th>
						<th>Listen</th>
					</tr>
					<tr v-for="(call, i) in calls">
						<td class="text-center">{{ i + 1 }}</td>
						<td><datetime :timestamp="call.startTs"></datetime></td>
						<td class="text-center">{{ call.duration }} sec</td>
						<td>
							<i
								v-if="call.incomming == 1"
								v-title="'Incoming call'"
								class="call-dir-pic zmdi zmdi-arrow-left-bottom"
							></i>
							<i
								v-else
								v-title="'Outgoing call'"
								class="call-dir-pic zmdi zmdi-arrow-right-top"
							></i>
							<worker-tooltip :idm="call.idm">
								{{ getManager(call.idm).realName || '#' + call.idm }}
							</worker-tooltip>
						</td>
						<td>
							<audio
								v-if="call.record"
								:src="'https://voip.semalt.com/SemaltCryptKeyForApiAccess/media/' + call.record"
								controls=""
								preload="metadata"
							></audio>
						</td>
					</tr>
					<tr class="calls-empty-row" v-if="calls && !calls.length">
						<td colspan="100">No calls</td>
					</tr>
				</table>
			</div>
		</div>
	</popup>
`;

export default {
	template,
	props: ['phone'],
	data() {
		return {
			calls: null
		};
	},
	computed: {
		...Store.mapState(['managersMap'])
	},
	methods: {
		close() {
			this.$emit('close');
		},
		async loadCalls() {
			this.calls = await this.get('Lead.getCallsByPhone', {phone: this.phone});
		}
	},
	watch: {
		phone() {
			this.loadCalls();
		}
	},
	mounted() {
		this.loadCalls();
	}
};
