import Store from '../../store.js';

const template = `
	<div v-if="inited">
		<table class="paylink-tbl">
			<tbody>
			<tr>
				<td>{{ ii('USER_ID') }}:</td>
				<td>
					<rich-sel
						v-model="userId"
						items-url="/api/customPayLink/getIdu"
					></rich-sel>
				</td>
			</tr>
			<tr>
				<td>{{ ii('E_MAIL') }}:</td>
				<td><input type="text" v-model="email"></td>
			</tr>
			<tr>
				<td>{{ ii('PACKAGE') }}:</td>
				<td>
					<rich-sel :items="packagesSuggestions" v-model="package"></rich-sel>
				</td>
			</tr>
			<tr>
				<td>{{ ii('PROJECT') }}{{ additProjectIds.length ? ' 1' : '' }}:</td>
				<td>
					<rich-sel :items="getProjectsSuggestions()" v-model="projectId"></rich-sel>
				</td>
				<td>
					<span class="pic-link" @click="addProject">
						<i class="fa fa-plus"></i>
						<span>{{ ii('ADD') }}</span>
					</span>
				</td>
			</tr>
			<tr v-for="(proj, i) in additProjectIds">
				<td>{{ ii('PROJECT') }} {{ i + 2 }}:</td>
				<td>
					<rich-sel :items="getProjectsSuggestions()" v-model="additProjectIds[i]"></rich-sel>
				</td>
				<td>
					<span class="pic-link" @click="delProject(i)">
						<i class="fa fa-remove"></i>
						<span>{{ ii('DELETE') }}</span>
					</span>
				</td>
			</tr>	
			<template v-if="reseller !== 'moradam' && reseller !== 'webroute' ">		
				<tr>
					<td>{{ ii('CURRENCY') }}:</td>
					<td>
						<rich-sel 
							:items="getCurrenciesMap()" 
							v-model="currency" 
							no-empty="1"
						></rich-sel>
					</td>
				</tr>
			</template>			
			<tr>
				<td>{{ ii('SOURCE') }}:</td>
				<td class="pl-source-cell">
					<span>
						<label>
							<input type="radio" v-model="source" value="2">
							<img src="/img/gmail32.png" alt="" v-title="ii('E_MAIL')">
						</label>
					</span>
					<span>
						<label>
							<input type="radio" v-model="source" value="3">
							<img src="/img/skype32.png" alt="" v-title="ii('SKYPE')">
						</label>
					</span>
				</td>
			</tr>	
			<template v-if="reseller !== 'moradam'">		
				<tr>
					<td>{{ ii('BILLING') }}:</td>
					<td v-if="reseller === 'semalt'">
						<select v-model="paymentSystem">
							<option value="connectum">Connectum</option>
							<!--<option value="alipay">AliPay</option>-->
							<option value="fs">FastSpring</option>
							<option value="paypro-16">PayPro - iDEAL</option>
							<option value="paypro-17">PayPro - Giropay</option>
							<option value="paypro-32">PayPro - Yandex.Money</option>
							<option value="paypro-33">PayPro - Boleto Bancario</option>
							<option value="paypro-34">PayPro - Alipay</option>
							<option value="paypro-36">PayPro - QIWI</option>
							<option value="paypro-45">PayPro - Sofort</option>
							<option value="paypro-52">PayPro - POLi</option>
							<option value="paypro-62">PayPro - ToditoCash</option>
							<option value="paypro-86">PayPro - UnionPay</option>
							<option value="paypro-89">PayPro - Konbini</option>
							<option value="paypro-95">PayPro - WeChat</option>
							<option value="paypro-37">PayPro - Real-time Bank Transfer</option>
						</select>
					</td>
					<td v-else-if="reseller === 'azaseo'">
						<select v-model="paymentSystem">
							<option value="alepay">Alepay</option>
							<!--<option value="nl">NganLuong</option>-->
							<option value="paypal">Paypal</option>
						</select>
					</td>
					<td v-else-if="reseller === 'webroute'">
						<select v-model="paymentSystem">
							<option value="iyzico">Iyzico</option>
							<option disabled="" value="connectum">Connectum</option>
						</select>
					</td>
					<td class="payment-desc-cell">
						<span
							class="fa fa-fw fa-question-circle link"
							v-title="ii('SHOW_PAYMENT_METHOD_DESCRIPTION')"
							@click="showPaymentDescription('primary')"
						></span>
						<span
							v-if="checkPermission('payment_desc:edit')"
							class="fa fa-fw fa-edit link"
							v-title="ii('EDIT_PAYMENT_METHOD_DESCRIPTION')"
							@click="editPaymentDescription('primary')"
						></span>
					</td>
				</tr>
			</template>
			<tr v-if="paymentSystem == 'connectum'">
				<td>{{ ii('3D_SECURE') }}:</td>
				<td>
					<label class="chb">
						<input type="checkbox" v-model="connectumSecure" :true-value="1" :false-value="0">
					</label>
				</td>
			</tr>
			<tr v-if="paymentSystem == 'paypro-37'">
				<td>{{ ii('SECONDARY_METHODS') }}:</td>
				<td v-if="reseller === 'semalt'">
					<select v-model="secondaryMethod">
						<option value="46">MrCash</option>
						<option value="47">EPS</option>
						<option value="49">Przelewy24</option>
						<option value="53">Multibanco-SIBS</option>
						<option value="54">Moneta Wallet</option>
						<option value="58">Trustly</option>
						<option value="65">TrustPay</option>
						<option value="67">Finnish Banks</option>
						<option value="69">DragonPay</option>
						<option value="71">AmBank</option>
						<option value="72">CIMB Clicks</option>
						<option value="73">FPX</option>
						<option value="74">Hong Leong Bank Transfer</option>
						<option value="75">Maybank2U</option>
						<option value="76">Mandiri</option>
						<option value="77">XLTunai</option>
						<option value="81">OP-Pohjola</option>
						<option value="82">Nordea</option>
						<option value="83">Danske</option>
					</select>
				</td>
				<td class="payment-desc-cell" v-if="secondaryMethod">
					<span
						class="fa fa-fw fa-question-circle link"
						v-title="ii('SHOW_PAYMENT_METHOD_DESCRIPTION')"
						@click="showPaymentDescription('paypro')"
					></span>
					<span
						v-if="checkPermission('payment_desc:edit')"
						class="fa fa-fw fa-edit link"
						v-title="ii('EDIT_PAYMENT_METHOD_DESCRIPTION')"
						@click="editPaymentDescription('paypro')"
					></span>
				</td>
			</tr>
			<template v-if="reseller === 'webroute' ">		
				<tr>
					<td>{{ ii('CURRENCY') }}:</td>
					<td><!-- :items="getCurrenciesMap()"  -->
						<rich-sel 
							v-model="currency" 
							no-empty="1"
						></rich-sel>
					</td>
				</tr>
			</template>
			<tr>
				<td>{{ ii('COST') }}{{ currencies[currency] ? ' (' + currencies[currency] + ')' : '' }}:</td>
				<td><input type="number" v-model="price"></td>
			</tr>
			<template v-if="reseller === 'moradam'">
				<tr>
					<td>KDV (%):</td>
					<td><input type="number" v-model="kdv"></td>
				</tr>
				<tr>
					<td>COST + KDV:</td>
					<td><input type="number" :value="costKdv" disabled></td>
				</tr>
			</template>		
			<template v-if="reseller === 'webroute'">
				<tr>
					<td>KDV (%):</td>
					<td><input type="number" v-model="kdv"></td>
				</tr>
				<tr>
					<td>COST + KDV:</td>
					<td><input type="number" :value="costKdv" disabled></td>
				</tr>
			</template>
			<tr v-if="withLinkTextTf">
				<td>{{ ii('LINK_TEXT') }}:</td>
				<td><input type="text" :placeholder="ii('OPTIONAL')" v-model="linkText"></td>
			</tr>
			<tr>
				<td>{{ ii('MANAGER') }}:</td>
				<td>
					<input type="text" :value="managerTfText" disabled>
				</td>
			</tr>
			<tr>
				<td>
					<btn size="small" :loading="isLoading" @click.native="generate">
						<i class="fa fa-fw fa-link" slot="icon"></i>
						{{ ii('GENERATE') }}
					</btn>
				</td>
				<td colspan="2">
					<input v-if="withResultTf" type="text" v-model="payLink" ref="payLinkTf">
				</td>
			</tr>
			</tbody>
		</table>
	</div>
`;

export default {
	template,
	props: ['idu', 'with-link-text-tf', 'with-result-tf', 'package-id'],
	data() {
		return {
			linkText: 'payment link',
			userId: null,
			email: '',
			package: '',
			defaultPackage: 'autoseo',
			projectId: 0,
			additProjectIds: [],
			price: null,
			kdv: 18,
			currency: 'USD',
			currencies: {
				'USD': '$',
				'VND': '₫',
				'EUR': '€',
				'GBP': '£',
				'RUB': '₽',
				'TRY': '₺'
			},
			source: 2,
			connectumSecure: 0,
			paymentSystem: 'connectum',
			secondaryMethod: '',
			payLink: '',
			userInfo: {},
			isLoading: false,
			XXX: location.search.includes('test=1'),
			inited: false,
			moradamPackages: {
				'doping': 'doping',
				'doping-3mo': 'doping',
				'doping-6mo': 'doping',
				'doping-12mo': 'doping',
				'doping-trial': 'doping',
				'kapsamli': 'kapsamli',
				'kapsamli-3mo': 'kapsamli',
				'kapsamli-6mo': 'kapsamli',
				'kapsamli-12mo': 'kapsamli',
				'kapsamli-country': 'kapsamli',
				'kapsamli-country-12mo': 'kapsamli',
				'kapsamli-country-3mo': 'kapsamli',
				'kapsamli-country-6mo': 'kapsamli',
				'kapsamli-global': 'kapsamli',
				'kapsamli-global-12mo': 'kapsamli',
				'kapsamli-global-3mo': 'kapsamli',
				'kapsamli-global-6mo': 'kapsamli',
				'kapsamli-local': 'kapsamli',
				'kapsamli-local-12mo': 'kapsamli',
				'kapsamli-local-3mo': 'kapsamli',
				'kapsamli-local-6mo': 'kapsamli',
				'kurumsal': 'kurumsal',
				'kurumsal-12mo': 'kurumsal',
				'kurumsal-3mo': 'kurumsal',
				'kurumsal-6mo': 'kurumsal',
				'google': 'google',
				'google-3mo': 'google',
				'google-6mo': 'google',
				'google-12mo': 'google',
				'analiz-standart': 'analiz',
				'analiz-uzman': 'analiz',
				'analiz-pro': 'analiz',
				'analytics-premium': 'analiz',
				'analytics-premium-12mo': 'analiz',
				'analytics-premium-3mo': 'analiz',
				'analytics-premium-6mo': 'analiz',
				'analytics-prof': 'analiz',
				'analytics-prof-12mo': 'analiz',
				'analytics-prof-3mo': 'analiz',
				'analytics-prof-6mo': 'analiz',
				'analytics-standart': 'analiz',
				'analytics-standart-12mo': 'analiz',
				'analytics-standart-3mo': 'analiz',
				'analytics-standart-6mo': 'analiz',
				'e-tickaret': 'e-tickaret',
				'diger-hizmet': 'diger-hizmet',
				'xturk-lisans': 'xturk-lisans',
				'kurulum-dahil': 'kurulum-dahil',
				'hosting-dahil': 'hosting-dahil',
				'kurulum-ve-hosting-dahil': 'kurulum-ve-hosting-dahil'
			}
		};
	},
	computed: {
		...Store.mapState(['worker', 'packages', 'currencyRates', 'resellerInfo', 'currenciesMap']),
		managerTfText() {
			let idm = this.worker && this.worker.idm || 0;
			if (!idm) return 'None';

			return `${this.worker.name} (#${this.worker.idm})`;
		},
		linkDependantValues() {
			return (
				this.userId,
				this.email,
				this.package,
				this.projectId,
				this.price,
				this.source,
				this.additProjectIds,
				this.paymentSystem,
				this.linkText,
				Date.now()
			);
		},
		test() {
			return location.search.includes('test=1');
		},
		costKdv() {
			if (this.price) {
				return +this.price + ( this.price * this.kdv / 100);
			}
		}
	},
	methods: {
		getCurrenciesMap() {
			let list = this.currenciesMap[this.paymentSystem];
			if (this.paymentSystem.includes('paypro')) {
				list = this.currenciesMap.paypro;
			}
			return list.map(e => {
				return {
					key: e.code,
					val: e.code + ' - ' + e.name,
					html: `
						<div class="geo-suggestion"> 
							<img src="//semalt.com/img/pixel.png" class="flag flag-${e.alpha2.toLowerCase()}" > 
							<span>${e.code} - ${e.name}</span> 
						</div>`
				};
			});
		},
		async loadUserInfo() {
			let idu = parseInt(this.userId);
			if (!idu) {
				this.email = '';
				this.userInfo = {};
				return;
			}

			let info = await this.get('Lead.getUserInfo', {idu});
			if (!info) return;

			this.userInfo = info;
			this.email = info.email;

			if (this.userInfo.sites && this.userInfo.sites.length) {
				this.projectId = this.userInfo.sites[0].id || 0;
			} else {
				this.projectId = 0;
			}
			if (!this.package) {
				this.package = this.defaultPackage;
			}
		},
		getProjectsSuggestions() {
			if (!this.userInfo.sites || !this.userInfo.sites.length) return [];

			return this.userInfo.sites.map(site => {
				let plan;
				if (site.campaign) {
					plan = site.campaign.plan.replace('seo', '');
					if (site.campaign.endDate < this.getCurDate()) {
						plan = 'ex-' + plan;
					}
				}
				let key = site.id;
				let val = `#${site.id} - ${site.domain}`;
				let urlHtml = this.escapeHtml(site.url);
				let html =`#${site.id} - ${urlHtml}`;
				if (plan) {
					val = plan + ' :: ' + val;
					html = '<strong>' + plan.toUpperCase() + ' :: </strong> ' + html;
				}
				return {key, val, html};
			});
		},
		addProject() {
			this.additProjectIds.push(0);
		},
		delProject(i) {
			this.additProjectIds.splice(i, 1);
		},
		clearLink() {
			this.payLink = '';
		},
		async generate() {

			let projectIds = [this.projectId, ...this.additProjectIds];
			projectIds = projectIds.filter(id => parseInt(id)).filter(this.filterUnique);

			if (!projectIds.length) {
				projectIds = [0];
			}
			if (!this.package || !this.price || !this.userId) {
				return this.alert(this.ii('ENTER_ALL_FIELDS'));
			}

			let link;
			let pfs;
			let idm = (this.worker.idm || 1);
			let packageInfo = this.packages[this.package];
			if (!packageInfo) {
				return this.alert(this.ii('PACKAGE_NOT_FOUND'));
			}
			let packageId = packageInfo.psId;
			let username = '%20';
			let tags = 'total=' + Math.floor(this.price * 100);
			let referrer;
			if (this.package.toLowerCase().indexOf('amazon') !== -1) {
				referrer = 'amazdirect:' + this.userId + ':' + projectIds[0] + ';m=' + idm + '-' + this.source;
				link = 'https://sites.fastspring.com/semalt/checkout/' + packageId + '?referrer=' + referrer + '&tags=' + tags;
			} else {
				referrer = this.userId + '*' + projectIds.join('*') + ';m=' + idm + '-' + this.source;
				link = 'https://sites.fastspring.com/semalt/checkout/' + packageId + '?contact_fname=' + username + '&contact_lname=%20&contact_email=' + this.email + '&referrer=' + referrer + '&tags=' + tags;
			}

			if (this.paymentSystem === 'alipay') {
				link = 'https://semalt.com/alipay/pay.php?' + [
					'idu=' + this.userId,
					'idm=' + idm,
					'price=' + parseFloat(this.price),
					'referrer=' + this.urlEnc(referrer),
					'plan=' + this.urlEnc(packageInfo.plan),
					'package=' + this.urlEnc(packageInfo.title),
					'period=' + this.getPeriodFromPackage(packageId)
				].join('&');
			}
			if (this.paymentSystem === 'connectum') {
				link = 'https://semalt.com/connectum/connectum.php?' + [
					'action=create-order',
					'idu=' + this.userId,
					'idm=' + idm,
					'amount=' + parseFloat(this.price),
					'referrer=' + this.urlEnc(referrer),
					'pack=' + this.urlEnc(packageInfo.plan),
					'packTitle=' + this.urlEnc(packageInfo.title),
					'subs=' + this.getPeriodFromPackage(packageId),
					'currency=' + this.currency.toUpperCase(),
					'psId=' + this.urlEnc(packageInfo.psId),
					'secure3d=' + this.connectumSecure
				].join('&');
			}
			if (this.paymentSystem === 'nl') {
				if (this.currency == 'USD' && this.price < 2000) {
					return await this.alert('The price can not be less than 2000 dong');
				}
				link = 'https://dashboard.azaseo.com/ngangluong/buy.php?' + [
					'action=pay',
					'price=' + parseFloat(this.price),
					'idu=' + this.userId,
					'currency=' + this.currency.toLowerCase(),
					'pack=' + this.urlEnc(packageInfo.plan),
					'subs=' + this.getPeriodFromPackage(packageId),
					'idm=' + idm,
					'referrer=' + this.urlEnc(referrer),
					'lang=' + (this.currency == 'USD' ? 'eng' : '')
				].join('&');
			}
			if (this.paymentSystem === 'alepay') {
				link = 'https://dashboard.azaseo.com/payment/alepay.php?' + [
					'action=sendOrderToAlepay',
					'price=' + parseFloat(this.price),
					'idu=' + this.userId,
					'currency=' + this.currency.toLowerCase(),
					'pack=' + this.urlEnc(packageInfo.plan),
					'subs=' + this.getPeriodFromPackage(packageId),
					'idm=' + idm,
					'orderDescription=Azaseo',
					'referrer=' + this.urlEnc(referrer),
					'lang=' + (this.currency == 'USD' ? 'eng' : '')
				].join('&');
			}
			if (this.paymentSystem === 'paypal') {
				link = 'https://dashboard.azaseo.com/payment/paypal.php?' + [
					'action=sendCardLinkRequest',
					'price=' + parseFloat(this.price),
					'idu=' + this.userId,
					'currency=' + this.currency.toLowerCase(),
					'pack=' + this.urlEnc(packageInfo.plan),
					'subs=' + this.getPeriodFromPackage(packageId),
					'idm=' + idm,
					'orderDescription=some description',
					'referrer=' + this.urlEnc(referrer),
					'lang=' + (this.currency == 'USD' ? 'eng' : '')
				].join('&');
			}
			if (this.paymentSystem.includes('paypro')) {
				let paymentMethod = this.paymentSystem.split('-')[1],
					secondaryMethod = this.secondaryMethod;
				link = await this.get(
					'PaymentSystem/PayPro.getPaymentUrl',
					{
						price: this.price,
						currency: this.currency.toUpperCase(),
						referrer,
						package: this.package,
						paymentMethod,
						secondaryMethod
					}
				);
			}

			if (this.reseller === 'webroute' && this.paymentSystem === 'iyzico') {
				/*link = {
					"user":{
						"id": this.userId,
						"email":"",
						"firstName":"",
						"lastName":"",
						"tel":""
					},
					"path": packageInfo.id,
					"referrer": this.urlEnc(referrer),
					"secure": false,
					"days": this.getPeriodFromPackage(packageId),
					"currency": this.currency.toLowerCase(),
					"cost": parseFloat(this.price),
					"vat": 0
				};
				*/
				let linkStr = [
					'idu='+this.userId,
					'package='+this.package,
					'price='+parseInt(this.costKdv),
					'params='+this.userId+'*'+projectIds.join('*')
					//'json=0'
				];
				link = linkStr.join('&');
				console.log(link, this.$data);
				pfs = 8;
			}

			if (this.reseller === 'moradam') {
				let params = [
					'idu=' + this.userId,
					'ids=' + projectIds.join('.'),
					'plan=' + this.urlEnc(this.moradamPackages[packageInfo.id]),
					'period=' + Math.round(this.getPeriodFromPackage(packageId) / 30),
					'k=' + this.kdv,
					'p=' + this.price,
					'source=' + this.source,
					'idm=' + idm,
					'pack=' + this.urlEnc(packageInfo.id)
				];
				if(packageId=='basit-trial'){
					params[3] = 'period=0';
					params.push('days=14');
				}
				link = 'https://panel.moradam.com/pay?' + params.join('&');
				this.payLink = link;

				this.$emit('generate', {
					url: this.payLink,
					text: this.linkText
				});

				if (!this.$refs.payLinkTf) return;
				setTimeout(() => {
					this.$refs.payLinkTf.select();
				}, 1);

				return;
			}

			this.payLink = '';
			this.isLoading = true;


			let params = {
				link,
				pfs,
				ps: this.paymentSystem,
				idu: this.userId
			};



			let res = await this.get('PayLink.generate', params);
			this.isLoading = false;

			if (res.error) return this.alert(this.ii(res.error));

			let payDomain = this.resellerInfo.payDomain || this.resellerInfo.semaltDomain;
			this.payLink = 'https://' + payDomain + res.wrappedLink;
			this.$emit('generate', {
				url: this.payLink,
				text: this.linkText
			});

			if (!this.$refs.payLinkTf) return;
			setTimeout(() => {
				this.$refs.payLinkTf.select();
			}, 1);
		},
		getPeriodFromPackage(packageSlug) {
			let match;
			match = packageSlug.match(/(\d+)mo/i);
			if (match) return Math.round(match[1] * 30.4);

			match = packageSlug.match(/(\d+)y/i);
			if (match) return Math.round(match[1] * 365.25);

			match = packageSlug.match(/annual/i);
			if (match) return 365;

			match = packageSlug.match(/year/i);
			if (match) return 365;

			match = packageSlug.match(/trial/i);
			if (match) return 14;

			return 30;
		},
		async showPaymentDescription(type = 'primary') {
			let key = this.getPaymentSystemKey(type);
			let desc = await this.get('PayLink.getPaymentDescription', key);

			if (!desc) return this.alert(this.ii('NO_DESCRIPTION'));

			this.showText(desc);
		},
		async editPaymentDescription(type = 'primary') {
			let key = this.getPaymentSystemKey(type);
			let desc = await this.get('PayLink.getPaymentDescription', key);

			let newDesc = await this.input({
				type: 'textarea',
				val: desc
			});
			if (newDesc === null) return;

			let err = await this.post('PayLink.setPaymentDescription', key, newDesc);
			if (err) return this.alert(this.ii(err));
		},
		getPaymentSystemKey(type) {
			if (type === 'primary') return this.paymentSystem;
			if (type === 'paypro') return 'paypro-sub:' + this.secondaryMethod;
			return null;
		}
	},
	watch: {
		linkDependantValues() {
			this.clearLink();
		},
		userId() {
			this.loadUserInfo();
		},
		package() {
			let field = 'price' + (this.capitalize(this.currency));
			for (let i in this.packages) {
				if (i !== this.package) continue;

				if(this.reseller == 'moradam'){
					this.price = this.packages[i].price;
					continue;
				}

				let price = this.price;
				let priceX = this.packages[i][field];
				if (!priceX) {
					price = this.packages[i].price;
				}
				else {
					price = priceX;
				}
				if(this.reseller == 'webroute'){
					this.price = +price.toFixed(2);
					break;
				}

				if (this.currency.toUpperCase() !== 'USD') {
					price = price / this.currencyRates[this.currency.toUpperCase() + '_USD'];
				}
				this.price = +price.toFixed(2);
				if(this.package=='basit-trial'){
					this.price = 49;
					this.kdv = 0;
				}
				break;
			}
		},
		currency(newVal, oldVal) {
			if (this.currencyRates && this.price) {

				let n = newVal.toUpperCase();
				let o = oldVal.toUpperCase();
				let price = this.price;
				if (this.currencyRates[o + '_' + n]) {
					price = price * this.currencyRates[o + '_' + n];
				}
				else if (this.currencyRates[n + '_' + o]) {
					price = price / this.currencyRates[n + '_' + o];
				}
				else {
					price = price * this.currencyRates[o + '_USD'] / this.currencyRates[n + '_USD'];
				}
				this.price = +price.toFixed(2);
			}
		},
		paymentSystem() {
			if (this.reseller === 'azaseo') {
				if (this.paymentSystem === 'paypal') {
					this.currency = 'USD';
				} else {
					this.currency = 'VND';
				}
			} else if (this.paymentSystem === 'alipay' || this.paymentSystem === 'fs') {
				this.currency = 'USD';
			}
		}
	},
	async mounted() {
		if (this.idu) {
			this.userId = this.idu;
		}
		if (this.reseller == 'azaseo') {
			this.currency = 'VND';
			this.paymentSystem = 'alepay';
		}
		else if (this.reseller == 'moradam') {
			this.currency = 'TRY';
			this.defaultPackage = 'doping';
		}
		else if (this.reseller == 'webroute') {
			this.currency = 'TRY';
			this.defaultPackage = 'autoseo1m';
			this.paymentSystem = 'iyzico';
		}

		if (this.packageId) {
			this.package = this.packageId;
		}

		if (
			this.isObjectEmpty(this.packages) ||
			this.isObjectEmpty(this.currencyRates) ||
			this.isObjectEmpty(this.resellerInfo)
		) {
			await this.loadState(['packages', 'currencyRates', 'resellerInfo', 'currenciesMap']);
		}

		this.inited = true;
	}
};
