let loadScript = (() => {
	let actualData;
	if (typeof window === 'undefined') {
		global.window = {};
	}
	window.define = data => {
		actualData = data;
	};

	let cachedResults = {};
	let promises = {};

	function load(url, isOnce) {
		if (!promises[url]) {
			promises[url] = new Promise((resolve, reject) => {
				if (isOnce && cachedResults[url]) return resolve(cachedResults[url].data);

				let script = document.createElement('script');
				script.onload = () => {
					let data = actualData;
					actualData = void 0;
					if (data instanceof Function) {
						data = data();
					}
					if (isOnce) {
						cachedResults[url] = {data: data};
					}
					resolve(data);
				};
				script.onerror = err => reject(err);
				script.src = url;
				document.head.appendChild(script);
			});
		}
		return promises[url];
	}

	return load;
})();

let loadedStyleMap = {};
function loadStyle(url, isOnce) {
	return new Promise(function(resolve, reject) {
		if (isOnce && loadedStyleMap[url]) return resolve();
		loadedStyleMap[url] = true;

		let link = document.createElement('link');
		link.rel = 'stylesheet';
		link.onload = function() {
			resolve();
		};
		link.onerror = function(err) {
			reject(err);
		};
		link.href = url;
		document.head.appendChild(link);
	});
}

export {loadScript, loadStyle};