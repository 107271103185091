const template = `
	<div class="user-services">
		<div class="row">
			<div class="control-filter col xs-12 sm-8 md-6 lg-4">
				<label>{{ ii('PLAN') }}:</label>
				<multi-sel
					:items="getPlans()"
					v-model="plan"
				></multi-sel>
			</div>
			<div class="control-filter col xs-12 sm-8 md-6 lg-4">
				<label>{{ ii('SITE') }}:</label>
				<rich-sel
					:items-url="'/api/seo-sites-suggestions'"
					v-model="site"
				></rich-sel>
			</div>
			<div class="control-filter col xs-12 sm-8 md-6 lg-4">
				<label>{{ ii('SUBSCRIPTION') }}:</label>
				<input type="text" v-model.lazy="subs">
			</div>
			<div class="control-filter col xs-12 sm-8 md-6 lg-4">
				<label>{{ ii('PAYMENT_SYSTEM') }}:</label>
				<multi-sel
					:items="getPaymentSystems()"
					v-model="ps"
				></multi-sel>
			</div>
			<div class="control-filter col xs-12 sm-8 md-6 lg-4">
				<label class="chb">
					<input type="checkbox" v-model="activeCampaign">
					<span>{{ ii('ACTIVE_CAMPAIGN') }}</span>
				</label>
			</div>
			<div class="control-filter col xs-12 sm-8 md-6 lg-4">
				<label class="chb">
					<input type="checkbox" v-model="activeRebill">
					<span>{{ ii('ACTIVE_REBILL') }}</span>
				</label>
			</div>
		</div>
		<div v-if="isLoading" class="loading">
			<span>
				<i class="fa fa-spinner spin-fast center"></i>
				<span class="general-upper">{{ ii('LOADING') }}...</span>
			</span>
		</div>
		<table v-else class="mc-tbl hover-tbl">
			<thead>
				<tr>
					<th>
						<head-cell-inner no-sticky="1">
							<strong class="link pointer" @click="setSort('')">#</strong>
						</head-cell-inner>
					</th>
					<th>
						<head-cell-inner no-sticky="1">
							<strong class="link pointer" @click="setSort('site')">
								{{ ii('SITE') }}
								<i
									v-if="sort == 'site'"
									:class="'sort-icon zmdi ' + (sortDesc ? 'zmdi-triangle-down' : 'zmdi-triangle-up')"
								></i>
							</strong>
						</head-cell-inner>
					</th>
					<th>
						<head-cell-inner no-sticky="1">
							<strong class="link pointer" @click="setSort('plan')">
								{{ ii('PLAN') }}
								<i
									v-if="sort == 'plan'"
									:class="'sort-icon zmdi ' + (sortDesc ? 'zmdi-triangle-down' : 'zmdi-triangle-up')"
								></i>
							</strong>
						</head-cell-inner>
					</th>
					<th>
						<head-cell-inner no-sticky="1">
							<strong class="link pointer" @click="setSort('camapaignStart')">
								{{ ii('START') }}
								<i
									v-if="sort == 'camapaignStart'"
									:class="'sort-icon zmdi ' + (sortDesc ? 'zmdi-triangle-down' : 'zmdi-triangle-up')"
								></i>
							</strong>
						</head-cell-inner>
					</th>
					<th colspan="2">
						<head-cell-inner no-sticky="1">
							<strong class="link pointer" @click="setSort('camapaignEnd')">
								{{ ii('END') }}
								<i
									v-if="sort == 'camapaignEnd'"
									:class="'sort-icon zmdi ' + (sortDesc ? 'zmdi-triangle-down' : 'zmdi-triangle-up')"
								></i>
							</strong>
						</head-cell-inner>
					</th>
					<th colspan="2">
						<head-cell-inner no-sticky="1">
							<strong class="link pointer" @click="setSort('subscription')">
								{{ ii('SUBSCRIPTION') }}
								<i
									v-if="sort == 'subscription'"
									:class="'sort-icon zmdi ' + (sortDesc ? 'zmdi-triangle-down' : 'zmdi-triangle-up')"
								></i>
							</strong>
						</head-cell-inner>
					</th>
					<th colspan="2">
						<head-cell-inner no-sticky="1">
							<strong class="link pointer" @click="setSort('paymentSystem')">
								{{ ii('PAYMENT_SYSTEM') }}
								<i
									v-if="sort == 'paymentSystem'"
									:class="'sort-icon zmdi ' + (sortDesc ? 'zmdi-triangle-down' : 'zmdi-triangle-up')"
								></i>
							</strong>
						</head-cell-inner>
					</th>
					<th v-title="ii('PRICE_OF_LAST_PAYMENT')">
						<head-cell-inner no-sticky="1">
							<strong class="link pointer" @click="setSort('lastCost')">
								{{ ii('PRICE') }}
								<i
									v-if="sort == 'lastCost'"
									:class="'sort-icon zmdi ' + (sortDesc ? 'zmdi-triangle-down' : 'zmdi-triangle-up')"
								></i>
							</strong>
						</head-cell-inner>
					</th>
					<th colspan="3">
						<head-cell-inner no-sticky="1">
							<strong class="link pointer" @click="setSort('nextRebill')">
								{{ ii('NEXT_REBILL') }}
								<i
									v-if="sort == 'nextRebill'"
									:class="'sort-icon zmdi ' + (sortDesc ? 'zmdi-triangle-down' : 'zmdi-triangle-up')"
								></i>
							</strong>
						</head-cell-inner>
					</th>
				</tr>
			</thead>
			<tbody>
			<template v-if="sortedRows.length">
				<template v-for="(row, i) in sortedRows">
					<tr v-if="row.type === 'unassigned' && unassignedIndex === i">
						<th colspan="100">
							<head-cell-inner no-sticky="1">
								<strong class="link pointer" @click="showUnassigned = !showUnassigned">
									{{ ii('UNASSIGNED_PAYMENTS') }}
									({{ unassignedCount }})
									<i :class="'sort-icon zmdi ' + (showUnassigned ? 'zmdi-triangle-up' : 'zmdi-triangle-down')"></i>
								</strong>
							</head-cell-inner>
						</th>
					</tr>
					<tr v-if="row.type !== 'unassigned' || showUnassigned">
						<td class="text-center">{{ i + 1 }}</td>
						<td>
							<a :href="row.url" target="_blank" rel="noreferrer noopener">{{ row.site }}</a>
							({{ row.siteId }})
						</td>
						<td class="text-center">
							<span v-title="row.package" class="help">{{ row.plan }}</span>
						</td>
						<td
							v-if="row.type === 'unassigned'"
							class="text-center"
							colspan="3"
						>
							{{ ii('UNASSIGNED') }}
						</td>
						<template v-else>
							<td class="text-center">
								<span
									class="link"
									v-if="checkPermission('edit:seolaunch') && reseller === 'semalt'"
									@click="changeCampaignDate(row, 'camapaignStart', 'start', row.type)"
								>{{ row.camapaignStart || '-' }}</span>
								<span v-else>{{ row.camapaignStart || '-' }}</span>
							</td>
							<td class="text-center">
								<span
									class="link"
									v-if="checkPermission('edit:seolaunch') && reseller === 'semalt'"
									@click="changeCampaignDate(row, 'camapaignEnd', 'end', row.type)"
								>{{ row.camapaignEnd || '-' }}</span>
								<span v-else>
									{{ row.camapaignEnd || '-' }}
								</span>
							</td>
							<td class="text-center">
								<i v-if="row.active === 1" class="fa fa-fw fa-check green"></i>
								<i v-else-if="row.active === 0" class="fa fa-fw fa-remove danger"></i>
							</td>
						</template>
						<td>
							<a v-if="row.subscription" :href="'/subscriptions?subs=' + row.subscription">
								{{ row.subscription }}
							</a>
							<span v-else>?</span>
							<span
								v-if="canEditSubscription(row.type)"
								@click="editSubscription(row)"
								class="pointer grey"
							>
								<i class="zmdi zmdi-edit"></i>
							</span>
							<span
								v-if="row.projectSubs && row.projectSubs.length > 1"
								v-title="row.projectSubs.join('\\n')"
								class="right"
							>
								<i class="fa fa-fw fa-info-circle"></i>
							</span>
						</td>
						<td class="text-center">
							<span
								v-if="row.subscription"
								v-title="ii('SHOW_ORDERS')"
								@click="showOrdersPopup(row.subscription)"
								class="link"
							>
								<i class="fa fa-fw fa-list"></i>
							</span>
						</td>
						<td class="text-center">
							{{ row.paymentSystem || '' }}
						</td>
						<td class="text-center">
							<span
								:class="row.paymentSystem === 'connectum' ? 'link' : ''"
								@click="row.paymentSystem === 'connectum' ? showCardInfo(row) : null"
							>
								{{ getPaymentVariant(row.paymentType, row.paymentVariant) || '' }}
							</span>
						</td>
						<td class="text-center">
							<span v-if="row.lastCost">
								{{ row.lastCost | num }}
							</span>
						</td>
						<td>
							<template v-if="row.subscription">
								<span
									v-if="checkPermission('subscriptions:admin')"
									@click="changeNextRebillDate(row)"
									class="link"
								>
									{{ row.nextRebill || '' }}
								</span>
								<span v-else>
									{{ row.nextRebill || '' }}
								</span>
								<i
									v-if="row.nextRebill && row.camapaignEnd && row.nextRebill !== row.camapaignEnd"
									class="fa fa-fw fa-exclamation-triangle yellow"
									v-title="ii('END_CAMPAIGN_DATE_NOT_EQUAL_NEXT_REBILL_DATE')"
								></i>
							</template>
						</td>
						<td>
							<em v-if="row.subscription && row.nextRebill && row.nextRebill !== '0000-00-00'" class="m-left5 grey">
								{{ getTimePassed(toUnix(row.nextRebill)) }}
							</em>
						</td>
						<td class="text-center">
							<i v-if="row.rebillSt" class="fa fa-fw fa-check green"></i>
							<span v-else-if="!row.subscription">?</span>
							<i v-else class="fa fa-fw fa-remove danger"></i>
						</td>
					</tr>
				</template>
			</template>
			<tr v-else>
				<td colspan="100" class="text-center">{{ ii('NO_DATA') }}</td>
			</tr>
			</tbody>
		</table>
		
		<orders-popup
			:q="popupOrdersQuery"
			no-scroll="1"
			@close="popupOrdersQuery = null"
		></orders-popup>
		
		<popup
			v-if="cardInfo"
			@close="cardInfo = null"
		>
			<div slot="header">Card info</div>
			<div>
				<table class="width100p">
					<tr>
						<td><strong>Bank</strong></td>
						<td>{{ cardInfo.bank }}</td>
					</tr>
					<tr>
						<td><strong>Country</strong></td>
						<td>{{ cardInfo.country }}</td>
					</tr>
					<tr>
						<td><strong>Variant</strong></td>
						<td>{{ cardInfo.variant }}</td>
					</tr>
					<tr>
						<td><strong>Number</strong></td>
						<td>{{ cardInfo.number || cardInfo.bin }}</td>
					</tr>
					<tr>
						<td><strong>Cardholder</strong></td>
						<td>{{ cardInfo.holder }}</td>
					</tr>
					<tr>
						<td><strong>Type</strong></td>
						<td>{{ cardInfo.type }} {{ cardInfo.subtype }}</td>
					</tr>
				</table>
			</div>
		</popup>
	</div>
`;

export default {
	template,
	data() {
		return {
			isLoading: true,
			rows: [],
			subscriptions: [],
			popupOrdersQuery: null,
			sort: null,
			sortDesc: false,
			activeCampaign: false,
			activeRebill: false,
			plan: null,
			site: null,
			subs: null,
			ps: null,
			cardInfo: null,
			showUnassigned: false
		};
	},
	props: ['idu', 'partner'],
	computed: {
		sortedRows() {
			if (!this.filteredRows) return [];

			let rows = this.filteredRows;
			let key = this.sort ? this.sort : 'type';
			let dir = this.sortDesc ? 1 : -1;

			rows.sort((a, b) => {
				if (a.type === 'unassigned' && b.type !== 'unassigned') return 1;
				if (a[key] === null) return dir;
				if (b[key] === null) return -dir;
				if (a[key] < b[key]) return dir;
				if (a[key] > b[key]) return -dir;
				if (a.id < b.id) return -1;
				if (a.id > b.id) return 1;
				return 0;
			});

			return rows;
		},
		filteredRows() {
			if (!this.rows) return [];

			let rows = this.rows.filter(row => {
				if (this.subs && row.subscription !== this.subs) return false;
				if (this.site && !row.site.includes(this.site)) return false;
				if (this.ps && this.ps.split(',').indexOf(row.paymentSystem) === -1) return false;
				if (this.plan && this.plan.split(',').indexOf(row.plan) === -1) return false;
				if (this.activeCampaign && !row.active) return false;
				if (this.activeRebill && !row.rebillSt) return false;

				return true;
			});

			return rows;
		},
		unassignedCount() {
			if (!this.filteredRows) return 0;

			return this.filteredRows.filter(r => r.type === 'unassigned').length;
		},
		unassignedIndex() {
			let index = null;
			if (!this.sortedRows) return index;

			this.sortedRows.forEach((r, i) => {
				if (r.type === 'unassigned' && index === null) index = i;
			});

			return index;
		}
	},
	methods: {
		getPlans() {
			if (!this.rows) return [];

			let plans = this.arrayUnique(this.rows.map(r => r.plan));
			return plans.map((plan, i) => {
				return ({
					key: plan,
					val: this.ii(plan),
					sortVal: i
				});
			});
		},
		getPaymentSystems() {
			return [
				{key: 'fs', val: 'FastSpring'},
				{key: 'connectum', val: 'Connectum'},
				{key: 'alipay', val: 'AliPay'},
				{key: 'paypro', val: 'PayPro'},
				{key: 'btc', val: 'Bitcoin'},
				{key: 'bank', val: 'Bank transfer'},
				{key: 'privat', val: 'Privat'},
				{key: 'webmoney', val: 'WebMoney'},
				{key: 'pakbank', val: 'Pakistan Bank'},
				{key: 'urugbank', val: 'Uruguay Bank'},
				{key: 'payoneer', val: 'Payoneer'},
				{key: 'cash', val: 'Cash'},
				{key: 'other', val: 'Other'},
			];
		},
		canEditSubscription(plan) {
			return this.checkPermission('edit:seolaunch') && this.reseller === 'semalt' && plan !== 'unassigned';
		},
		async getRows() {
			this.rows = await this.get('UserCard.getUserServices', this.idu, this.partner);
		},
		async loadUserSubscriptions() {
			this.subscriptions = await this.get('UserCard.getAllUserSubscriptions', this.idu, this.partner);
		},
		setSort(field) {
			this.sortDesc = !this.sort || this.sortDesc || this.sort !== field  ? false : true;
			this.sort = !this.sort || this.sortDesc || this.sort !== field ? field : null;
		},
		async changeCampaignDate(row, field, type, plan) {
			let date = await this.input({
				type: 'date',
				title: this.ii('EDIT_' + type.toUpperCase() + '_CAMPAIGN_DATE'),
				val: row[field] || '0000-00-00'
			});
			if (!date) return;

			let params = {id: row.id, date, type, plan};
			let res = await this.post('SEOLaunch.changeCampaignDate', params);
			if (res.err) return this.alert(res.err);

			this.$set(row, field, date);
		},
		async changeNextRebillDate(row) {
			let nextRebill = await this.input({
				type: 'date',
				title: this.ii('EDIT_NEXT_REBILL_DATE'),
				val: row.nextRebill || '0000-00-00'
			});
			if (!nextRebill) return;

			let err = await this.post('Subscription.setRebillDate', row.subscription, nextRebill);
			if (err) return this.alert(err);

			this.$set(row, 'nextRebill', nextRebill);
		},
		getUserSubscriptions() {
			return this.subscriptions.map(subs => {
				return ({
					key: subs,
					val: subs
				});
			});
		},
		async editSubscription(row) {
			let subs = await this.input({
				type: 'multisel',
				title: this.ii('EDIT_SUBSCRIPTION'),
				items: this.getUserSubscriptions(),
				emptyVal: '',
				val: row.projectSubs.join(',')
			});
			if (subs === null) return;

			subs = subs.split(',');
			let res = await this.post('Subscription.editUserSubscription', row.id, row.type, subs);
			if (res.success) return this.getRows();
			if (res.err) this.alert(res.err);
		},
		showOrdersPopup(subs) {
			let params = {
				idu: this.idu,
				subscription: subs,
				partner: this.partner
			};
			this.popupOrdersQuery = this.toJson(params);
		},
		async showCardInfo(row) {
			this.cardInfo = await this.get('Subscription.getCardInfo', 'connectum', row.subscription);
			if (this.cardInfo) {
				this.cardInfo.ref = row.subscription;
			}
		}
	},
	async mounted() {
		if (this.idu) {
			await this.loadUserSubscriptions();
			await this.getRows();
		}

		this.isLoading = false;
	}
};