const template = `
	<div class="page-url-remember-box">
		<span class="link" @click="rememberFilters">
			<i class="fa fa-fw fa-save"></i>
			<span>{{ ii('REMEMBER_FILTERS') }}</span>
		</span>
		&nbsp;
		<span class="link" @click="resetFilters">
			<i class="fa fa-trash"></i>
			<span>{{ ii('RESET_FILTERS') }}</span>
		</span>
	</div>
`;

export default {
	template,
	data() {
		return {};
	},
	methods: {
		rememberFilters() {
			let key = 'qs_' + this.app.routeName;
			let qs = location.search.slice(1);
			if (qs) {
				localStorage.setItem(key, qs);
			} else {
				localStorage.removeItem(key);
			}
		},
		resetFilters() {
			let key = 'qs_' + this.app.routeName;
			localStorage.removeItem(key);
			this.$router.push(this.$route.path);
		}
	}
};