const template = `
	<span>
		<template v-for="(item, key) in getTranslate">
			{{ item }}
			<slot :name="key.slice(5)"></slot>
		</template>
	</span>
`;

export default {
	template: template,
	props: ['str'],
	computed: {
		getTranslate() {
			let arr = this.ii(this.str).split(/@(\d+)/g);
			if (arr.length < 3) return { key: arr[0] };

			let obj = {};
			arr.forEach((item, key) => {
				if (key == arr.length-1) {
					obj.slot_Last = item;
					return;
				}
				if (key == 0 || key % 2 == 0) return;

				obj['slot_'+item] = arr[key-1];
			});

			return obj;
		}
	}
};