const template = `
	<div ref="rangeDatePicker" :class="[disabled ? 'is-disabled' : '']" class="rdp" v-mousedown-outside="hideCalendar">
		<div class="dp-tf-box">
			<template v-if="win.width >= win.SM">
				<span v-if="!unclearable" v-show="value" class="dp-clear zmdi zmdi-close" @click="clear"></span>
				<input type="text" :value="tfText" readonly :disabled="disabled" @focus="showCalendar">
			</template>
			<template v-else>
				<input type="text" :value="tfText" :disabled="disabled" @click="showMobilePopup()">
			</template>
			<!--<input v-else type="date" @change="onNativeValChanged">-->
		</div>
		<div v-if="calendarShown" class="clalendars-show" :style="position">
			<div class="picker-header">
				<span  class="quick-bar">
					<i class="fa fa-arrow-circle-o-left quick-bar-btn" @click="movePeriod('d', 0)"></i>
					<span class="upper">{{ ii('DAY') }}</span>
					<i class="fa fa-arrow-circle-o-right quick-bar-btn" @click="movePeriod('d', 1)"></i>
				</span>
				<span  class="quick-bar">
					<i class="fa fa-arrow-circle-o-left quick-bar-btn" @click="movePeriod('M', 0)"></i>
					<span class="upper">{{ ii('MONTH') }}</span>
					<i class="fa fa-arrow-circle-o-right quick-bar-btn" @click="movePeriod('M', 1)"></i>
				</span>
				<span  class="quick-bar">
					<i class="fa fa-arrow-circle-o-left quick-bar-btn" @click="movePeriod('y', 0)"></i>
					<span class="upper">{{ ii('YEAR') }}</span>
					<i class="fa fa-arrow-circle-o-right quick-bar-btn" @click="movePeriod('y', 1)"></i>
				</span>
			</div>
			<div class="calendar-wrap">
				<calendar v-model="from" class="calendar-from" :with-time="withTime"></calendar>
				<calendar v-model="to" class="calendar-to" :with-time="withTime"></calendar>
			</div>
			<div class="presets-bottom" v-if="presets">
				<span v-for="i in filteredPresets" class="pic-link" @click="choosePreset(i.val)">
					<i class="fa fa-calendar"></i>
					<span>{{ i.displayVal }}</span>
				</span>
			</div>
			<div class="picker-bottom">
				<span v-if="error" class="left error-message">
					<i class="fa fa-exclamation-triangle"></i>
					<span>{{ error }}</span>
				</span>
				<span class="right pic-link apply" @click="onNativeValChanged">
					<i class="fa fa-save"></i>
					<span>{{ ii('APPLY') }}</span>
				</span>
				<span class="right pic-link" @click="chooseCurDay">
					<i class="fa fa-calendar"></i>
					<span>{{ ii('TODAY') }}</span>
				</span>
			</div>
		</div>

		<popup
			v-if="showPopup"
			no-scroll="1"
			width="100%"
			class="popup"
			@close="showPopup = false"
			close-on-back="1"
		>
		<div slot="header">{{ ii('SELECT_DATE_RANGE') }}</div>

		<div class="popup-body">
			<div class="col">
				<label>{{ ii('DATE_FROM') }}</label>
				<input v-if="withTime" type="datetime-local" v-model="popDateFrom">
				<input v-else type="date" v-model="popDateFrom">
			</div>
			<div class="col">
				<label>{{ ii('DATE_TO') }}</label>
				<input v-if="withTime" type="datetime-local" v-model="popDateTo">
				<input v-else type="date" v-model="popDateTo">
			</div>
		</div>

		<div class="popup-footer text-center">
			<btn size="small" @click.native="setNewDate()">
				<i class="fa fa-fw fa-floppy-o" slot="icon"></i>
				{{ ii('APPLY') }}
			</btn>
			<btn size="small" @click.native="clearDates()">
				<i class="fa fa-fw fa-eraser" slot="icon"></i>
				{{ ii('CLEAR_ALL') }}
			</btn>
		</div>
	</popup>

	</div>
`;

export default {
	template: template,
	props: ['value', 'unclearable', 'empty-val', 'empty-text', 'presets', 'with-time', 'disabled'],
	data() {
		return {
			from: null,
			to: null,
			defaultFrom: null,
			defaultTo: null,
			error: '',

			calendarShown: false,
			weekDays: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
			months: [
				'January', 'February', 'March',
				'April', 'May', 'June',
				'July', 'August', 'September',
				'October', 'November', 'December'
			],
			curDate: moment().format('YYYY-MM-DD'),
			curTime: moment().format('HH:mm'),
			curYear: moment().format('YYYY'),
			showPopup: false,
			popDateFrom: this.getYesterday(),
			popDateTo: moment().format('YYYY-MM-DD'),
		};
	},
	computed: {
		tfText() {
			if (this.emptyVal && this.emptyText && this.value === this.emptyVal) return this.emptyText;
			if (!this.value && this.emptyText && !this.emptyVal) return this.emptyText;
			if (!this.value) return this.value;

			let formattedVal = this.value.split('_').map(date => {
				let m = moment(date);
				let val = m.format('MMM D');
				let year = m.format('YYYY');
				let time = m.format('HH:mm');
				if (year !== this.curYear) {
					val += ', ' + year;
				}
				if (this.withTime && time !== '00:00' && time !== '23:59') {
					val += ' ' + time;
				}
				return val;
			}).join(' - ');

			return formattedVal;
		},
		val() {
			if (this.from && this.to) return this.from + '_' + this.to;
			if (this.value) return this.value;
			return '_';
		},
		popVal() {
			let from = this.popDateFrom.split('T').join(' ');
			let to = this.popDateTo.split('T').join(' ');
			this.popDateFrom = from && this.withTime ? from + ':00' : from;
			this.popDateTo = to && this.withTime ? to + ':59' : to;

			if (from > to) {
				this.alert(this.ii('START_DATE_MORE_THAN_END_DATE'));
				return false;
			}
			if (this.popDateFrom && this.popDateTo) return this.popDateFrom + '_' + this.popDateTo;
			if (this.value) return this.value;
			return false;
		},
		position() {
			let pos = this.$refs.rangeDatePicker.getBoundingClientRect().x;
			let win = this.getWinWidth();
			return pos > win/2 ? 'right: 10px' : 'left: 10px';
		},
		filteredPresets() {
			return [...this.presets].map(e => {
				let period = +e.val,
					format = e.format;

				let to = moment().format(this.dateFormat);
				let from = moment().subtract(period, format).format(this.dateFormat);

				e.val = [from, to].join('_');

				return e;
			});
		},
		dateFormat() {
			return this.withTime ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD';
		},
		curFromDatetime() {
			return this.curDate + (this.withTime ? ' 00:00' : '');
		},
		curToDatetime() {
			return this.curDate + (this.withTime ? ' 23:59' : '');
		}
	},
	methods: {
		showCalendar() {
			this.calendarShown = true;
		},
		hideCalendar() {
			if (this.from > this.to) {
				this.from = '';
				this.to = '';
				this.defaultFrom = '';
				this.defaultTo = '';
				this.val = '';
				this.error = '';
				this.setVal(this.emptyVal || '');
			}
			this.calendarShown = false;
		},
		setVal(date) {
			this.$emit('input', date);
			this.$emit('change', {
				isTrusted: true,
				value: date
			});
		},
		setPopVal(date) {
			this.$emit('input', date);
			this.$emit('change', {
				isTrusted: true,
				value: date
			});
		},
		clear() {
			this.from = this.curFromDatetime;
			this.to = this.curToDatetime;
			this.defaultFrom = '';
			this.defaultTo = '';
			this.val = '';
			this.error = '';
			this.setVal(this.emptyVal || '');
			this.hideCalendar();
		},
		onNativeValChanged() {
			if (!this.from) this.from = this.curFromDatetime;
			if (!this.to) this.to = this.curToDatetime;
			if (this.from > this.to) {
				this.error = this.ii('START_DATE_MORE_THAN_END_DATE');
				return;
			}
			this.error = '';
			this.setVal(this.val);
			this.hideCalendar();
		},
		chooseCurDay() {
			this.from = this.curFromDatetime;
			this.to = this.curToDatetime;
			this.error = '';
			this.setVal(this.val);
			this.hideCalendar();
		},
		choosePreset(date) {
			this.from = date.split('_')[0] + (this.withTime ? ' 00:00' : '');
			this.to = date.split('_')[1] + (this.withTime ? ' 23:59' : '');
			this.error = '';
			this.setVal(this.val);
			this.hideCalendar();
		},
		movePeriod(type, meth) {
			let s = this.from || this.defaultFrom || moment().format(this.dateFormat);
			let e = this.to || this.defaultTo || moment().format(this.dateFormat);

			if (meth) {
				s = moment(s).add(1, type).format(this.dateFormat);
				e = moment(e).add(1, type).format(this.dateFormat);
			}
			else {
				s = moment(s).subtract(1, type).format(this.dateFormat);
				e = moment(e).subtract(1, type).format(this.dateFormat);
			}

			this.$set(this, 'from', s);
			this.$set(this, 'to', e);
		},
		getYesterday() {
			let d = new Date();
			let day = d.getMonth() <= 8 ? `0${d.getMonth() + 1}` : `${d.getMonth()+1}`;
			return `${d.getFullYear()}-${day}-${d.getDate()-1}`;
		},
		showMobilePopup() {
			let from = this.curFromDatetime;
			let to = this.curToDatetime;

			if (this.value) {
				from = this.value.split('_')[0];
				to = this.value.split('_')[1];

				if (this.withTime) {
					from = from.slice(0, 16);
					to = to.slice(0, 16);
				}
			}

			this.popDateFrom = from.split(' ').join('T');
			this.popDateTo = to.split(' ').join('T');
			this.showPopup = true;
		},
		setNewDate() {
			if (!this.popVal) return this.showPopup = false;

			let from = this.popVal.split('_')[0] + (this.withTime ? ':00' : '');
			let to = this.popVal.split('_')[1] + (this.withTime ? ':59' : '');
			let val = from + '_' + to;
			this.setPopVal(this.popVal);
			this.showPopup = false;
		},
		clearDates() {
			this.popDateFrom = this.curFromDatetime;
			this.popDateTo = this.curToDatetime;

			this.setPopVal(null);
			this.showPopup = false;
		}
	},
	watch: {
		val() {
			let from = this.val.split('_')[0];
			let to = this.val.split('_')[1];

			this.from = from && from.length < 18 && this.withTime ? from + ':00' : from;
			this.to = to && to.length < 18 && this.withTime ? to + ':59' : to;
		}
	},
	mounted() {
		if (!this.value) {
			this.from = this.curFromDatetime;
			this.to = this.curToDatetime;
			return;
		}

		this.from = this.value.split('_')[0];
		this.to = this.value.split('_')[1];
	}
};


//
// getDatePresets() {
// 	return [
// 		{val: '0', format: 'd', displayVal: 'Today'},
// 		{val: '1', format: 'd', displayVal: 'Yesterday'},
// 		{val: '3', format: 'd', displayVal: 'Last 3 days'},
// 		{val: '1', format: 'w', displayVal: 'A week'},
// 		{val: '4', format: 'w', displayVal: 'Last 4 weeks'},
// 		{val: '1', format: 'M', displayVal: 'A month'},
// 		{val: '4', format: 'M', displayVal: 'Last 4 months'}
// 	];
// },
