const template = `
	<div class="sesscheck" v-show="isShown">
		<div class="sesscheck-box">
			<div class="sesscheck-text">Something has changed with your current session. Please reload a page.</div>
			<button @click="reload">Reload</button>
		</div>
	</div>
`;

export default {
	template,
	props: ['storage-key', 'check-store'],
	computed: {
		worker() {
			return this.app.store.worker;
		}
	},
	data() {
		return {
			isShown: false
		};
	},
	methods: {
		onStorageChanged(e) {
			if (e.key !== this.storageKey) return;
			if (this.checkStore && e.newVal == this.app.store.worker.id) return;

			this.isShown = true;
		},
		reload() {
			location.reload();
		}
	},
	created() {
		window.addEventListener('storage', this.onStorageChanged);
	},
	destroyed() {
		window.removeEventListener('storage', this.onStorageChanged);
	}
};
