import Store from '../../store';

const template = `
	<div class="charts keywords-tops-charts">
		<div class="chart">
			<div v-if="chartLoading" class="chart-info">
				<span>
					<i class="fa fa-spinner spin-fast center"></i>
					<span class="general-upper">Chart loading...</span>
				</span>
			</div>
			<template v-else-if="chartOpts">
				<div v-if="showSeChart" class="right-filters">
					<label>Search engine:</label>
					<rich-sel
						v-model="seChart"
						:items="getSearchEnginesItems()"
						@change="loadCharts()"
					></rich-sel>
				</div>
				
				<div class="chart-inner-wrapper">
					<highcharts v-if="chartOpts" :options="chartOpts"></highcharts>
				</div>
			</template>
			<div v-else class="chart-info">
				<span>
					<span class="general-upper">No data</span>
				</span>
			</div>
		</div>
	</div>
`;

export default {
	template,
	props: [
		'se',
		'showSe',
		'domain',
	],
	data() {
		return {
			seChart: 1,
			showSeChart: false,
			chartOpts: null,
			chartLoading: false
		};
	},
	computed: {
		...Store.mapState(['geoMap', 'searchEngines']),
	},
	methods: {
		async loadCharts() {
			if (!this.domain) return;

			this.chartLoading = true;
			this.chartOpts = null;

			let res = await this.get('KwPos.getChartTops', this.domain, this.seChart);
			if (!res) return this.chartLoading = false;

			this.chartLoading = false;
			this.chartOpts = this.getChartOpts(res);
		},
		getChartOpts(result) {
			let series = [...result.series];
			let opts = {
				title: {
					text: 'Number of keywords in Tops'
				},
				chart: {
					spacingTop: 35,
					marginTop: 85,
					style: {
						fontFamily: this.getChartFonts()
					}
				},
				yAxis: {
					title: {
						text: 'Number of keywords'
					}
				},
				xAxis: result.extOpts.xAxis,
				series
			};
			return this.merge(this.getDefaultChartOpts(), opts);
		},
		updateCharts() {
			this.seChart = this.se || 1;
			this.loadCharts();
		}
	},
	watch: {
		domain() {
			this.updateCharts();
		},
		se() {
			if (!this.se && this.seChart === 1) return;
			if (this.se === this.seChart) return;

			this.updateCharts();
		},
		showSe() {
			this.showSeChart = this.showSe;
		}
	},
	async mounted() {
		if (this.se) this.seChart = this.se;
		if (this.showSe) this.showSeChart = this.showSe;

		await this.loadState(['geoMap', 'searchEngines']);
		await this.ensureHighStock();
		await this.loadCharts();
	}
};