const template = `
	<span
		:class="getClasses()"
		@click="onClick"
	>
		<i v-if="loading" class="fa fa-spinner spin-fast"></i>
		<template v-else>{{ val ? onText : offText }}</template>
	</span>
`;

export default {
	template,
	props: ['value', 'loading', 'true-text', 'false-text', 'true-value', 'false-value'],
	data() {
		return {
			val: null
		};
	},
	computed: {
		onText() {
			return (this.trueText != null ? this.trueText : this.ii('ON'));
		},
		offText() {
			return (this.falseText != null ? this.falseText : this.ii('OFF'));
		},
		onVal() {
			return this.trueValue !== void 0 ? this.trueValue : true;
		},
		offVal() {
			return this.falseValue !== void 0 ? this.falseValue : false;
		}
	},
	methods: {
		getClasses() {
			let classes = ['toggle-btn'];
			if (this.loading) {
				classes.push('toggle-btn-loading');
			}
			if (this.val) {
				classes.push('toggle-btn-enabled');
			}
			return classes;
		},
		onClick() {
			if (this.loading) return;

			this.val = !this.val;
			let val = this.val ? this.onVal : this.offVal;

			this.$emit('input', val);
			this.$emit('change', val);
		},
		updateVal() {
			if (this.trueValue !== void 0) {
				this.val = (this.value === this.trueValue);
			} else {
				this.val = !!this.value;
			}
		}
	},
	watch: {
		value() {
			this.updateVal();
		}
	},
	mounted() {
		this.updateVal();
	}
};