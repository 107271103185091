const template = `
	<popup
		class="attach-popup"
		width="700"
		content-min-height="300"
		content-max-height="300"
		@close="close"
	>
		<div slot="header">{{ header }}</div>
		<div class="attach-files-pane">
			<div class="attach-files-pane-inner">
				<div v-if="!files" class="attach-loading">
					<i class="fa fa-spinner spin-fast"></i> Loading...
				</div>
				<table v-else class="attach-files-tbl">
					<tr>
						<th>
							<head-cell-inner no-sticky="1">
								<span @click="sort('name')">Filename</span>
							</head-cell-inner>
						</th>
						<th>
							<head-cell-inner no-sticky="1">
								<span @click="sort('format')">Format</span>
							</head-cell-inner>
						</th>
						<th>
							<head-cell-inner no-sticky="1">
								<span @click="sort('size')">Size</span>
							</head-cell-inner>
						</th>
						<th>
							<head-cell-inner no-sticky="1">
								<span @click="sort('ctime')">Created</span>
							</head-cell-inner>
						</th>
						<th><head-cell-inner no-sticky="1">Del</head-cell-inner></th>
					</tr>
					<tr v-for="file in sortedFiles" class="monospace">
						<td>
							<a
								:href="getViewAttachUrl(file)"
								class="static-color"
								target="_blank"
							>{{ file.name }}</a>
							<a
								:href="getDownloadAttachUrl(file)"
								target="download-frame"
								class="fa fa-download"
								data-no-preview="1"
								v-title="'Download'"
							></a>
						</td>
						<td class="text-center">{{ file.format | upper }}</td>
						<td>{{ file.sizeHuman }}</td>
						<td><datetime :timestamp="file.ctime"></datetime></td>
						<td class="text-center">
							<i v-if="file.isRemoving" class="fa fa-spinner spin-fast"></i>
							<a v-else href="javascript:;" class="fa fa-fw fa-remove" @click="removeFile(file)"></a>
						</td>
					</tr>
					<tr v-if="files && !files.length" class="attach-files-empty-row">
						<td colspan="100">{{ ii('NO_FILES_YET') }}</td>
					</tr>
				</table>
			</div>
			<div class="attach-files-bottom">
				<div v-if="uploading" class="attach-progress-bar">
					<span class="attach-progress-done" :style="{width: uploading.percent + '%'}">
						<span class="attach-label">{{ uploading.file.name }}</span>
					</span>
					<span class="attach-label">{{ uploading.file.name }}</span>
				</div>
				<div v-else class="attach-upload-text">{{ ii('DRAG_N_DROP_FILES_HERE') }}</div>
			</div>
		</div>
	</popup>
`;

export default {
	template,
	props: ['folder', 'subfolder', 'header', 'idu'],
	data() {
		return {
			isLoading: true,
			files: null,
			sortKey: 'ctime',
			sortDesc: true,
			uploading: null
		};
	},
	computed: {
		sortedFiles() {
			if (!this.files) return [];

			let multiplier = this.sortDesc ? -1 : 1;
			return this.files.sort((a, b) => {
				if (a[this.sortKey] > b[this.sortKey]) return multiplier;
				if (a[this.sortKey] < b[this.sortKey]) return -multiplier;
				return 0;
			});
		}
	},
	methods: {
		async loadFiles() {
			let res = await this.get('Attachment.getFiles', {folder: this.folder, subfolder: this.subfolder});
			this.$emit('load');

			this.files = res.files.map(file => {
				file.isRemoving = false;
				return file;
			});
		},
		sort(key) {
			if (key === this.sortKey) {
				this.sortDesc = !this.sortDesc;
			}
			this.sortKey = key;
		},
		setupDnd() {
			document.addEventListener('dragenter', this.onDragEnter);
			document.addEventListener('dragover', this.onDragOver);
			document.addEventListener('drop', this.onDrop);
		},
		unsetDnd() {
			document.removeEventListener('dragenter', this.onDragEnter);
			document.removeEventListener('dragover', this.onDragOver);
			document.removeEventListener('drop', this.onDrop);
		},
		onDragEnter(e) {
			e.preventDefault();
		},
		onDragOver(e) {
			e.preventDefault();
		},
		onDrop(e) {
			e.preventDefault();

			if (this.uploading) return;
			if (!e.dataTransfer || !e.dataTransfer.files) return;

			let files = [].slice.call(e.dataTransfer.files);

			this.uploading = {file: {}};
			let promise = Promise.resolve();
			files.forEach(file => {
				promise = promise.then(() => this.uploadFile(file));
			});
			promise.then(() => {
				this.uploading = null;
			});
		},
		async uploadFile(file) {
			if (this.files) {
				let exists = this.files.filter(f => f.name === file.name).length > 0;
				if (exists) {
					let question = `File "${file.name}" exists. Overwrite it?`;
					let ok = await this.confirm(question);
					if (!ok) return;
				}
			}

			let form = new FormData;
			form.append('file', file);
			form.append('folder', this.folder);
			form.append('subfolder', this.subfolder);

			this.uploading = {
				file,
				percent: 0
			};

			let opts = {
				onUploadProgress: (e) => {
					if (!e.lengthComputable) return;

					let percent = Math.floor(e.loaded / e.total * 100);
					this.$set(this.uploading, 'percent', percent);
				}
			};
			return axios.post('/api/call/Attachment.upload', form, opts).then(res => {
				this.loadFiles();
			});
		},
		async removeFile(file) {
			let ok = await this.confirm(`Remove "${file.name}" file?`);
			if (!ok) return;

			let params = {
				folder: this.folder,
				subfolder: this.subfolder,
				filename: file.name
			};
			this.$set(file, 'isRemoving', true);
			await this.post('Attachment.remove', params);
			this.loadFiles();
		},
		getDownloadAttachUrl(file) {
			let params = {
				folder: this.folder,
				subfolder: this.subfolder,
				filename: file.name,
				download: 1
			};
			return this.getMethodUrl('Attachment.getView', params);
		},
		getViewAttachUrl(file) {
			let url = '';
			let fileFormat = file.format.toLowerCase();
			if (fileFormat === 'csv' || fileFormat === 'xlsx') {
				let filename = this.idu + '/' + file.name;
				url = this.getMethodUrl('Attachment.getCustomView', filename);
			} else {
				let params = {
					folder: this.folder,
					subfolder: this.subfolder,
					filename: file.name
				};
				url = this.getMethodUrl('Attachment.getView', params);
			}
			return url;
		},
		close() {
			this.$emit('close');
		}
	},
	mounted() {
		this.loadFiles();
		this.setupDnd();
	},
	destroyed() {
		this.unsetDnd();
	}
};
