const template = `
	<div class="whois">
		<div v-if="isLoading" class="loading">
			<span>
				<i class="fa fa-spinner spin-fast center"></i>
				<span class="general-upper">{{ ii('LOADING') }}...</span>
			</span>
		</div>
		<table v-else class="mc-tbl hover-tbl">
			<thead>
				<tr>
					<th><head-cell-inner no-sticky="1">#</head-cell-inner></th>
					<th><head-cell-inner no-sticky="1">{{ ii('DOMAIN') }}</head-cell-inner></th>
					<th><head-cell-inner no-sticky="1">{{ ii('NAME') }}</head-cell-inner></th>
					<th><head-cell-inner no-sticky="1">{{ ii('PHONE') }}</head-cell-inner></th>
					<th><head-cell-inner no-sticky="1">{{ ii('E_MAIL') }}</head-cell-inner></th>
					<th><head-cell-inner no-sticky="1">{{ ii('COUNTRY') }}</head-cell-inner></th>
					<th><head-cell-inner no-sticky="1">{{ ii('CITY') }}</head-cell-inner></th>
				</tr>
			</thead>
			<tbody>
				<tr v-if="handledWhois.length === 0">
					<td colspan="100" class="text-center">{{ ii('NO_DATA') }}</td>
				</tr>
				<tr v-for="(s, i) in handledWhois">
					<td class="text-center">{{ i + 1 }}</td>
					<td>
						<a
							:href="'http://' + s.site"
							target="_blank"
							rel="noreferrer noopener"
						>{{ s.site }}</a>
					</td>
					<td>{{ s.name }}</td>
					<td>
						<flag v-if="s.phone" :cc="s.phoneGeo"></flag>
						<a
							v-if="s.phone"
							:href="'tel:' + normalizePhone(s.phone)"
						>{{ normalizePhone(s.phone) }}</a>
					</td>
					<td>{{ s.email }}</td>
					<td>
						<template v-if="s.countryCode">
							<flag :cc="s.countryCode" no-title="1"></flag>
							<span>{{ getCountryByCode(s.countryCode) || s.country }}</span>
						</template>
						<template v-else>{{ s.country }}</template>
					</td>
					<td>{{ s.city }}</td>
				</tr>
			</tbody>
		</table>
	</div>
`;

export default {
	template,
	data() {
		return {
			isLoading: true,
			whois: null
		};
	},
	props: ['idu', 'partner'],
	computed: {
		handledWhois() {
			if (!this.whois) return [];
			
			let namesIgnore = ['whois', 'private', 'proxy', 'protect', 'backorder', 'domain', 'privacy', 'abuse'];
			let emailsIgnore = ['whois', 'private', 'proxy', 'protect', 'backorder', 'domain', 'abuse', 'privacy', 'o-w-o.info', 'gandi.net'];
			let phonesIgnore = ['00000'];
			let list = this.whois.filter(row => {
				let name = String(row.name).toLowerCase();
				if (namesIgnore.filter(ign => name.indexOf(ign) !== -1).length) return false;
				
				let email = String(row.email).toLowerCase();
				if (emailsIgnore.filter(ign => email.indexOf(ign) !== -1).length) return false;
				
				let phone = String(row.phone).toLowerCase();
				if (phonesIgnore.filter(ign => phone.indexOf(ign) !== -1).length) return false;
				
				return true;
			}).map(row => {
				row.phoneGeo = this.getGeoFromPhone(row.phone);
				return row;
			});
			return list;
		}
	},
	async mounted() {
		if (this.idu) {
			let params = {idu: this.idu, partner: this.partner};
			this.whois = await this.get('UserCard.getWhoisInfo', params);
		}
		this.isLoading = false;
	}
};