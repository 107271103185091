const template = `
	<div class="edit-localization-popup">
		<popup
			no-scroll="1"
			width="900"
			@close="close"
		>
			<div slot="header">
				Edit localization #{{i18n.id}}
			</div>
			<div class="row edit-local-content">
				<div class="control-filter col xs-12 sm-12 md-12 lg-12">
					<label>Site:</label>
					<textarea type="text" v-model="i18n.site"></textarea>
				</div>
				<div class="control-filter col xs-12 sm-12 md-12 lg-12">
					<label>Controller:</label>
					<textarea type="text" v-model="i18n.controller"></textarea>
				</div>
				<div class="control-filter col xs-12 sm-12 md-12 lg-12">
					<label>EN:</label>
					<textarea type="text" v-model="i18n.en"></textarea>
				</div>
				<div class="control-filter col xs-12 sm-12 md-12 lg-12">
					<label>DE:</label>
					<textarea type="text" v-model="i18n.de"></textarea>
				</div>
				<div class="control-filter col xs-12 sm-12 md-12 lg-12">
					<label>ES:</label>
					<textarea type="text" v-model="i18n.es"></textarea>
				</div>
				<div class="control-filter col xs-12 sm-12 md-12 lg-12">
					<label>FR:</label>
					<textarea type="text" v-model="i18n.fr"></textarea>
				</div>
				<div class="control-filter col xs-12 sm-12 md-12 lg-12">
					<label>IT:</label>
					<textarea type="text" v-model="i18n.it"></textarea>
				</div>
				<div class="control-filter col xs-12 sm-12 md-12 lg-12">
					<label>NL:</label>
					<textarea type="text" v-model="i18n.nl"></textarea>
				</div>
				<div class="control-filter col xs-12 sm-12 md-12 lg-12">
					<label>PT_BR:</label>
					<textarea type="text" v-model="i18n.ptBr"></textarea>
				</div>
				<div class="control-filter col xs-12 sm-12 md-12 lg-12">
					<label>TR:</label>
					<textarea type="text" v-model="i18n.tr"></textarea>
				</div>
				<div class="control-filter col xs-12 sm-12 md-12 lg-12">
					<label>CHS:</label>
					<textarea type="text" v-model="i18n.chs"></textarea>
				</div>
				<div class="control-filter col xs-12 sm-12 md-12 lg-12">
					<label>RU:</label>
					<textarea type="text" v-model="i18n.ru"></textarea>
				</div>
			</div>
			<div slot="footer">
				<div style="padding-left: 430px">
					<btn :size="'small'" @click.native="updateLocalization">
						<i slot="icon" class="fa fa-pencil-square-o"></i>
						{{ ii('SAVE') }}
					</btn>
				</div>
			</div>
		</popup>
	</div>
`;

export default {
	template,
	props: ['i18nId'],
	data() {
		return {
			i18n: {},
			i18nID: ''
		};
	},
	computed: {},
	methods: {
		close() {
			this.$emit('close');
		},
		async updateLocalization() {
			let res = await this.post('Localization.updateLocalization', {
				data: this.i18n
			});
			if (!res.success) return false;
			this.$emit('close');
			this.$emit('getRows');
			this.alert('Changes saved successfully');
		},
		async getLocalization(i18nID) {
			this.i18n = {};
			this.app.query.id = i18nID;
			let res = await this.get('Localization.getLocalization', this.app.query, 1);
			this.i18n = res[0];
		},
	},
	mounted() {
		this.getLocalization(this.i18nId);
	}
};