import Store from '../../store.js';

const template = `
	<div class="activity">
		<div v-if="isLoading" class="loading">
			<span>
				<i class="fa fa-spinner spin-fast center"></i>
				<span class="general-upper">{{ ii('LOADING') }}...</span>
			</span>
		</div>
		<template v-else>
			<div>
				<div class="row">
					<div class="control-filter col xs-12 sm-8 md-6 lg-4">
						<label>{{ ii('TIME') + ':' }}</label>
						<range-date-picker
							v-model="params.time"
							@change="loadActivity(true)"
						></range-date-picker>
					</div>
					<div class="control-filter col xs-12 sm-8 md-6 lg-4">
						<label>{{ ii('URL') + ':' }}</label>
						<input type="text" v-model="params.url" @change="loadActivity(true)">
					</div>
					<div class="control-filter col xs-12 sm-8 md-6 lg-4">
						<label>{{ ii('IP') + ':' }}</label>
						<input type="text" v-model="params.ip" @change="loadActivity(true)">
					</div>

					<div class="control-filter col xs-12 sm-8 md-6 lg-4">
						<label>{{ ii('ACTION') + ':' }}</label>
						<input type="text" v-model="params.event" @change="loadActivity(true)">
					</div>

					<div class="control-filter col xs-12 sm-8 md-6 lg-4">
						<label>{{ ii('COUNTRY') + ':' }}</label>
						<multi-sel
							:items="getGeoSuggestions()"
							v-model="params.geo"
							with-not="1"
							@change="loadActivity(true)"
						></multi-sel>
					</div>

					<div class="control-filter col xs-12 sm-8 md-6 lg-4">
						<label>{{ ii('LANGUAGE') + ':' }}</label>
						<multi-sel
							:items="getLanguagesSuggestions()"
							v-model="params.lang"
							key-ignore-case="1"
							@change="loadActivity(true)"
						></multi-sel>
					</div>

					<div class="control-filter col xs-12 sm-8 md-6 lg-4">
						<label>{{ ii('MONTHS') + ':' }}</label>
						<multi-sel
							:items="getMonthsFromCurrentDate(2020)"
							v-model="params.months"
							:with-not="false"
							@change="loadActivity(true)"
						></multi-sel>
					</div>
				</div>

				<div>
					<div class="left" style="margin: 10px 0 5px 0">
						<span>
							<span class="general-upper">{{ ii('TOTAL') }}:</span>
							<i v-if="totalCnt === null" class="fa fa-spinner spin-fast"></i>
							<span v-else>{{ totalCnt | numFormat }}</span>
						</span>
					</div>
				</div>

				<table class="mc-tbl hover-tbl" :class="{opacity05: opacity}">
					<thead>
						<tr>
							<th class="text-center" style="width: auto !important;">
								<head-cell-inner no-sticky="1">#</head-cell-inner>
							</th>
							<th>
								<head-cell-inner no-sticky="1" @click.native="loadActivity(true, 'datetime')">
									<span class="link">{{ ii('TIME') }}
										<i
											v-if="params.sortDesc !== null && params.sort === 'datetime'"
											:class="'sort-icon zmdi ' + (params.sortDesc === 0 ? 'zmdi-triangle-up' : 'zmdi-triangle-down')"
										></i>
									</span>
								</head-cell-inner>
							</th>
							<th><head-cell-inner no-sticky="1">{{ ii('PAGE') }}</head-cell-inner></th>
							<th colspan="2">
								<head-cell-inner no-sticky="1">{{ ii('DETAILS') }}</head-cell-inner>
							</th>
							<th>
								<head-cell-inner no-sticky="1" @click.native="loadActivity(true, 'custom_event')">
									<span class="link">{{ ii('ACTION') }}
										<i
											v-if="params.sortDesc !== null && params.sort === 'custom_event'"
											:class="'sort-icon zmdi ' + (params.sortDesc === 0 ? 'zmdi-triangle-up' : 'zmdi-triangle-down')"
										></i>
									</span>
								</head-cell-inner>
							</th>
							<th style="max-width: 300px !important;">
								<head-cell-inner no-sticky="1">{{ ii('INFO') }}</head-cell-inner>
							</th>
							<th>
								<head-cell-inner no-sticky="1" @click.native="loadActivity(true, 'ip')">
									<span class="link">{{ ii('IP') }}
										<i
											v-if="params.sortDesc !== null && params.sort === 'ip'"
											:class="'sort-icon zmdi ' + (params.sortDesc === 0 ? 'zmdi-triangle-up' : 'zmdi-triangle-down')"
										></i>
									</span>
								</head-cell-inner>
							</th>
							<th colspan="3"><head-cell-inner no-sticky="1">{{ii('AGENT')}}</head-cell-inner></th>
							<th><head-cell-inner no-sticky="1">{{ii('SCREEN')}}</head-cell-inner></th>
							<th>
								<head-cell-inner no-sticky="1" @click.native="loadActivity(true, 'geo')">
									<span class="link">{{ ii('COUNTRY') }}
										<i
											v-if="params.sortDesc !== null && params.sort === 'geo'"
											:class="'sort-icon zmdi ' + (params.sortDesc === 0 ? 'zmdi-triangle-up' : 'zmdi-triangle-down')"
										></i>
									</span>
								</head-cell-inner>
							</th>
							<th>
								<head-cell-inner no-sticky="1"  @click.native="loadActivity(true, 'lang')">
									<span class="link">{{ ii('LANGUAGE') }}
										<i
											v-if="params.sortDesc !== null && params.sort === 'lang'"
											:class="'sort-icon zmdi ' + (params.sortDesc === 0 ? 'zmdi-triangle-up' : 'zmdi-triangle-down')"
										></i>
									</span>
								</head-cell-inner>
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(row, i) in activity" :key="'activityRow' + i">
							<td class="text-center">{{ i + 1 }}</td>
							<td><datetime :timestamp="row.ts"></datetime></td>
							<td class="activity-link-cell" v-title="row.url">
								<a
									v-if="row.url"
									:href="row.url"
									rel="noreferrer noopener"
									target="_blank"
								>
									{{ getRelativeUrl(row.url) }}
								</a>
							</td>
							<td class="text-center">
								<span
									v-if="row.time != null"
									v-title="'Time on page'"
									class="help"
								>{{ getHumanTime(row.time) }}</span>
							</td>
							<td class="text-center">
								<span
									v-if="row.scroll != null"
									v-title="'Page scrolled %'"
									class="help"
								>{{ row.scroll }}%</span>
							</td>
							<td>{{ row.custom_event }}</td>
							<td style="word-break: break-all;">
								<div
									v-if="row.custom_data && row.custom_data.length > 0 && Array.isArray(row.custom_data)"
									style="max-width: 300px"
								>
									<span v-if="row.custom_data[0]">{{ row.custom_data[0].toString().slice(0,200) }} |</span>
									<span v-if="row.custom_data[1]">{{ row.custom_data[1].toString().slice(0,200) }}</span>
									<span v-if="row.custom_data[2]"> | {{ row.custom_data[2].toString().slice(0,200) }}</span>
								</div>
								<div
									v-else-if="row.custom_data && !Array.isArray(row.custom_data)"
									style="max-width: 300px"
								>
									{{ String(row.custom_data).slice(0, 200) }}
								</div>
							</td>
							<td>{{ row.ip }}</td>
							<td class="text-center">
								<i v-if="row.user_agent" v-title="row.user_agent" class="fa fa-desktop"></i>
							</td>
							<td class="text-center">
								<i
									v-if="row.os"
									:class="getOsSystem(row.os)" v-title="row.os + ' ' + row.os_ver"
								></i>
							</td class="text-center">
							<td class="text-center">
								<i
									v-if="row.browser"
									:class="getBrowser(row.browser)" v-title="row.browser + ' ' + row.browser_ver"
								></i>
							</td>
							<td class="text-center">
								<span v-if="row.screen_x && row.screen_y">
									{{ row.screen_x }}x{{ row.screen_y }}
								</span>
							</td>
							<td>
								<flag :cc="row.geo" no-title="1"></flag>
								{{ getCountryByCode(row.geo) }}
							</td>
							<td>{{ getLanguageByCode(row.lang) }}</td>
						</tr>
						<tr v-if="activity && isNoMore" class="text-center">
							<td colspan="100" class="general-upper">{{ ii('NO_MORE_DATA') }}</td>
						</tr>
						<tr v-if="!activity" class="text-center">
							<td colspan="100" class="general-upper">{{ ii('NO_DATA') }}</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="activity-bottom">
				<btn
					size="small"
					v-if="activity && !isNoMore"
					@click.native="loadActivity(false)"
					:loading="isLoadingMore"
				>
					<i class="fa fa-fw fa-cloud-download" slot="icon"></i>
					{{ ii('LOAD_MORE') }}
				</btn>
			</div>
		</template>
	</div>
`;

export default {
	template,
	data() {
		return {
			isLoading: true,
			isLoadingMore: false,
			activity: null,
			isNoMore: false,
			totalCnt: null,
			opacity: false,
			params: {
				ip: null,
				user: null,
				geo: null,
				lang: null,
				time: null,
				event: null,
				url: null,
				custom_user: null,
				months: null,
				noWorkIp: null,
				portion: null,
				sort: null,
				sortDesc: null
			}
		};
	},
	props: ['idu'],
	computed: {
		...Store.mapState(['geoMap'])
	},
	methods: {
		async loadActivity(firstTime = true, sort = null) {
			let currentYear = new Date().getFullYear();
			let currentMonth = new Date().getMonth();

			if(firstTime) {
				this.opacity = true;
				this.params.portion = null;
				this.isNoMore = false;
			} else {
				this.isLoadingMore = true;
			}

			if(this.params.months === null) {
				this.params.months = `${currentYear}-${currentMonth+1},${currentYear}-${currentMonth}`;
			}

			if(this.isNoMore) return;

			if(sort !== null) {
				this.params.sort = sort;

				if(this.params.sortDesc !== null) {
					this.params.sortDesc = this.params.sortDesc ? 0 : 1;
				}
				if(this.params.sortDesc === null) {
					this.params.sortDesc = 0;
				}
			}

			if(this.params.user === '') {
				this.params.user = null;
			}

			this.totalCnt = null;
			let result = await this.get('UserCard.getActivity', this.params);
			this.isLoading = false;
			this.isLoadingMore = false;
			if (result.error) {
				this.activity = null;
				this.totalCnt = 0;
				return;
			}

			let rows = result.hits;
			this.totalCnt = rows.total;
			this.params.portion = rows.hits.length ? rows.hits[rows.hits.length - 1].sort : null;

			let resultR = result.hits.hits.map(hit => {
				if(hit._source.custom_data) {
					hit._source.custom_data = this.parseJson(hit._source.custom_data);
					if(!Array.isArray(hit._source.custom_data) && typeof hit._source.custom_data === 'object') {
						hit._source.custom_data = JSON.stringify(hit._source.custom_data);
					}
				}

				return hit._source;
			});

			if(resultR.length === 0) {
				resultR = null;
			}

			if(!firstTime) {
				this.activity = this.activity.concat(resultR);
			} else {
				this.activity = resultR;
			}

			this.opacity = false;
			if(rows.hits.length < 50) {
				this.isNoMore = true;
			}
		},
		getMonthsFromCurrentDate(year) {
			let monthsMap = [
				'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September',
				'October', 'November', 'December'
			];
			let result = [];
			let currentYear = new Date().getFullYear();
			let currentMonth = new Date().getMonth();

			for (; year <= currentYear; year++) {
				for (let i = 0; i < monthsMap.length; i++) {
					if ((i === currentMonth + 1) && year === currentYear) break;

					let key = `${year}-${i+1}`;
					let val = `${year} ${monthsMap[i]}`;
					result.push({key, val});
				}
			}
			return result;
		},
		getOsSystem(os) {
			if(!os) return 'fa fa-question-circle';
			let result = '';

			if(os.match(/Win(\D*)/gi)) {
				result = 'fa fa-windows';
			} else if (os.match(/Mac OS X/gi) || os.match(/iOS/gi)) {
				result = 'fa fa-apple';
			} else if (os.match(/Android/gi)) {
				result = 'fa fa-android';
			} else if (os.match(/Linux/gi)) {
				result = 'fa fa-linux';
			} else {
				result = 'fa fa-question-circle';
			}

			return result;
		},
		getBrowser(br) {
			if(!br) return 'fa fa-question-circle';
			let result = '';

			if(br.match(/Chrome/gi)) {
				result = 'fa fa-chrome';
			} else if (br.match(/Firefox/gi)) {
				result = 'fa fa-firefox';
			} else if (br.match(/Opera/gi)) {
				result = 'fa fa-opera';
			}  else if (br.match(/Edge/gi)) {
				result = 'fa fa-edge';
			} else if (br.match(/Safari/gi)) {
				result = 'fa fa-safari';
			} else if (br.match(/Apple Mail/gi)) {
				result = 'fa fa-apple';
			} else if (br.match(/Facebook/gi)) {
				result = 'fa fa-facebook-official';
			} else if (br.match(/IE/gi)) {
				result = 'fa fa-internet-explorer';
			} else {
				result = 'fa fa-question-circle';
			}

			return result;
		}
	},
	async mounted() {
		if (this.idu) {
			this.params.user = this.idu;
			await this.loadActivity(true);
		}
		this.isLoading = false;
	}
};
