import Store from '../../store.js';

const template = `
	<span>
		<img
			src="/img/pixel.png"
			v-if="cc"
			:class="'flag flag-' + String(cc || '').toLowerCase()"
			v-title="!noTitle ? countryName : null"
		/>
		<span v-else class="empty-flag"></span>
	</span>
`;

export default {
	template: template,
	props: ['cc', 'no-title'],
	computed: {
		...Store.mapState(['geoMap']),
		countryName() {
			const cc = String(this.cc || '').toUpperCase();
			return this.getCountryByCode(cc) || cc;
		}
	}
};
