const template = `
	<div class="multisel" :class="[disabled == 1 ? 'is-disabled' : '']" v-mousedown-outside="hideItems">
		<span v-if="!noClear" v-show="value" class="multisel-clear zmdi zmdi-close" @click="clear"></span>
		<input type="text" class="multisel-val-tf" :value="tfText" @focus="showItems" :disabled="disabled == 1" :placeholder="placeholder" readonly>
		<div v-show="itemsShown" ref="itemsBox" :class="'multisel-dd multisel-dd-' + ddPos">
			<div class="multisel-search-box">
				<input
					type="text"
					class="multisel-search-tf"
					v-model="searchVal"
					ref="searchTf"
					@keydown.esc="hideItems"
					@keydown.enter="onEnterAction"
					:placeholder="ii('SEARCH')"
				>
			</div>
			<div>
				<div class="multisel-dd-inner" ref="scrollableBox" v-block-scroll>
					<div class="multisel-presets" v-if="presets && !searchVal">
						<div v-for="preset in presets" :key="preset.key">
							<a href="javascript:;" @click="choosePreset($event, preset)">{{ preset.text }}</a>
						</div>
					</div>
					<div v-if="withNot" class="multisel-invert-box">
						<label class="nowrap chb">
							<input
								type="checkbox"
								:checked="inverted"
								@click="setInverted(!inverted)"
							>
							<span>{{ ii('NOT') }}</span>
						</label>
					</div>
					<div
						v-for="(item, index) in filteredItems"
						:key="item.key"
						:class="['multisel-dd-item', item.className]"
						:ref="'item' + item.key"
					>
						<label class="nowrap chb">
							<input
								type="checkbox"
								:checked="!!checkedKeysMap[item.key]"
								@click="check($event, item, index)"
							>
							<span v-if="item.html" v-html="item.html"></span>
							<span v-else>{{ item.val || '&nbsp;' }}</span>
						</label>
					</div>
				</div>
				<div class="multisel-bottom clearfix">
					<span class="left pic-link" @click="checkAll">
						<i class="fa fa-check"></i>
						<span>{{ ii('CHECK_ALL') }}</span>
					</span>
					<span class="right pic-link" @click="apply">
						<i class="fa fa-save"></i>
						<span>
							{{ ii('APPLY') }}
							<span v-if="checkedItemsCnt">({{ checkedItemsCnt }})</span>
						</span>
					</span>
				</div>
			</div>
		</div>
	</div>
`;

export default {
	template: template,
	data() {
		return {
			itemsShown: false,
			itemsChbLastIndex: null,
			isItemsCheckedAll: false,
			inverted: false,
			movingFastStep: 10,
			checkedKeysMap: {},
			stickyCheckedKeysMap: {},
			ddPos: null,
			searchVal: '',
			tfText: ''
		};
	},
	props: ['value', 'items', 'with-not', 'presets', 'no-sort', 'debug', 'defaultShow', 'no-clear', 'disabled', 'placeholder'],
	computed: {
		itemsWithSort() {
			let items = [...this.items].map((item, i) => {
				if (item.sortVal == null) {
					item.sortVal = i;
				}
				return item;
			});
			return items;
		},
		filteredItems() {
			if (!this.items) return this.items;
			let items = this.itemsWithSort.filter(this.checkItemMatch);

			items = items.sort((a, b) => {
				let aChecked = this.stickyCheckedKeysMap[a.key];
				let bChecked = this.stickyCheckedKeysMap[b.key];

				if (!aChecked && bChecked) return 1;
				if (aChecked && !bChecked) return -1;

				if (a.sortVal > b.sortVal) return 1;
				if (a.sortVal < b.sortVal) return -1;

				return 0;
			});

			return items;
		},
		altSearchVal() {
			if (!this.searchVal) return this.searchVal;

			return this.toKeyboardLatin(this.searchVal.toLowerCase());
		},
		checkedItemsCnt() {
			return Object.keys(this.checkedKeysMap).length;
		}
	},
	methods: {
		showItems() {
			let winWidth = window.innerWidth;

			this.itemsShown = true;
			this.searchVal = '';
			this.ddPos = 'left';
			this.itemsChbLastIndex = null;

			this.stickyCheckedKeysMap = {...this.checkedKeysMap};

			this.$nextTick(() => {
				let boxRight = this.$refs.itemsBox.getBoundingClientRect().right;
				let overlay = boxRight - winWidth + 10;
				this.ddPos = overlay < 0 ? 'left' : 'right';

				this.tryFocusSearch();
				this.$refs.scrollableBox.scrollTop = 0;
			});
		},
		hideItems() {
			this.itemsShown = false;
			this.updateVal();
		},
		tryFocusSearch() {
			if (this.$refs.searchTf && this.win.width >= this.win.LG) {
				this.$refs.searchTf.focus();
			}
		},
		updateTfText() {
			let texts = Object.keys(this.checkedKeysMap).map(key => {
				let item = this.checkedKeysMap[key];
				return item.displayVal || item.val;
			});
			this.tfText = (this.inverted ? this.ii('NOT') + ': ' : '') + texts.join(', ');
		},
		checkItemMatch(item) {
			if (!this.searchVal) return true;

			if (!item) return false;

			let regex = new RegExp(this.escapeRegex(this.searchVal), 'i');
			if (regex.test(item.sVal || item.val)) return true;

			if (this.altSearchVal === this.searchVal) return false;

			let altRegex = new RegExp(this.escapeRegex(this.altSearchVal), 'i');
			return altRegex.test(item.sVal || item.val);
		},
		setInverted(isInv) {
			this.inverted = isInv;
			this.tryFocusSearch();
		},
		check(e, item, index) {
			let checked = e.target.checked;

			if (e.shiftKey && this.itemsChbLastIndex != null) {
				let start = Math.min(index, this.itemsChbLastIndex);
				let end = Math.max(index, this.itemsChbLastIndex) + 1;

				this.filteredItems.slice(start, end).forEach(item => {
					if (e.target.checked) {
						this.$set(this.checkedKeysMap, item.key, item);
					} else {
						this.$delete(this.checkedKeysMap, item.key);
					}
				});
			} else if (checked) {
				this.$set(this.checkedKeysMap, item.key, item);
			} else {
				this.$delete(this.checkedKeysMap, item.key);
			}

			this.itemsChbLastIndex = index;
			this.tryFocusSearch();
		},
		updateModel() {
			this.updateTfText();

			let keys = Object.keys(this.checkedKeysMap);
			let val;
			if (keys.length) {
				val = (this.inverted ? 'n_' : '') + keys.join(',');
			} else {
				val = '';
			}

			this.$emit('input', val);
			this.$emit('change', {isTrusted: true});
		},
		updateVal() {
			if (!this.value || !this.items) {
				this.inverted = false;
				this.checkedKeysMap = {};
				return;
			}
			let map = {};
			let val = this.value;
			if (val.indexOf('n_') === 0) {
				val = val.slice(2);
				this.inverted = true;
			} else {
				this.inverted = false;
			}
			val.split(',').forEach(key => {
				let item = this.items.filter(el => el.key == key)[0];
				if (!item) return;

				map[key] = item;
			});
			this.checkedKeysMap = map;
		},
		choosePreset(e, preset) {
			let keys = preset.key;
			if (e.ctrlKey) {
				let checkedKeys = Object.keys(this.checkedKeysMap).join(',');
				keys = (checkedKeys ? checkedKeys + ',' : '') + keys;
				keys = keys.split(',').filter((v, i, a) => a.indexOf(v) === i).join(',');
			}

			let map = {};
			keys.split(',').forEach(key => {
				let item = this.items.find(it => it.key == key);
				if (item) {
					map[key] = item;
				}
			});
			this.checkedKeysMap = map;
			let filtredKeys = Object.keys(map).join(',');

			this.$emit('input', filtredKeys);
			this.$emit('change', {isTrusted: true});

			this.updateTfText();
			this.hideItems();
		},
		clear() {
			this.$emit('input', '');
			this.$emit('change', {isTrusted: true});

			this.hideItems();
			this.tfText = '';
		},
		checkAll() {
			let uncheckedExist = this.filteredItems.filter(item => !this.checkedKeysMap[item.key]).length;

			this.filteredItems.forEach(item => {
				if (uncheckedExist) {
					this.$set(this.checkedKeysMap, item.key, item);
				} else {
					this.$delete(this.checkedKeysMap, item.key);
				}
			});

			this.itemsChbLastIndex = null;
			this.tryFocusSearch();
		},
		onEnterAction() {
			if (this.isObjectEmpty(this.checkedKeysMap) && !this.isObjectEmpty(this.filteredItems) && this.searchVal) {
				this.$set(this.checkedKeysMap, this.filteredItems[0].key, this.filteredItems[0]);
			}

			this.apply();
		},
		apply() {
			this.updateModel();
			this.hideItems();
		}
	},
	watch: {
		value() {
			this.updateVal();
		},
		items() {
			this.updateVal();
			this.updateTfText();
		},
		searchVal() {
			this.itemsChbLastIndex = null;
		}
	},
	mounted() {
		this.itemsShown = this.defaultShow;
		this.updateVal();
		this.updateTfText();
	}
};
