const template = `
	<div class="line-chart">
		<div class="chart chart-with-background">
			<div v-show="selectedSeChartLoading" class="chart-loading">
				<span>
					<i class="fa fa-spinner spin-fast center"></i>
					<span class="general-upper">Chart loading...</span>
				</span>
			</div>
			<div class="chart-inner-wrapper">
				<highcharts v-if="chartOpts" :options="chartOpts"></highcharts>
			</div>
		</div>
	</div>
`;

export default {
	template,
	props: [
		'remoteMethod',
		'options'
	],
	data() {
		return {
			selectedSeChartLoading: null,
			chartOpts: null
		};
	},
	methods: {
		async loadCharts() {
			this.chartOpts = null;

			let result = null;

			if (this.remoteMethod) {
				result = await this.get(this.remoteMethod);
			}

			if (!result && this.options) result = this.options;

			if(!result) return null;

			this.chartOpts = this.merge(this.getDefaultChartOpts(), result);
		},
	},
	watch: {
		options() {
			this.loadCharts();
		},
		chartOpts() {
			this.chartOpts === null ? this.selectedSeChartLoading = true : this.selectedSeChartLoading = false;
		}
	},
	async mounted() {
		await this.ensureHighCharts();
		await this.loadCharts();
		this.selectedSeChartLoading = (this.chartOpts === null);
	}
};
