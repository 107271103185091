import Store from '../../store.js';

const template = `
	<div class="mail-history">
		<div class="padding5">
			<a
				v-if="resellerInfo.slug === 'semalt'"
				:href="'https://tasks.semalt.com/frames/emails.php?idu=' + idu"
				target="_blank"
				rel="noreferrer noopener"
			>{{ ii('MAILS') }}</a>
		</div>
		<iframe :src="'/frame/gmail?idu=' + idu" frameborder="0"></iframe>
	</div>
`;

export default {
	template,
	props: ['idu'],
	computed: {
		...Store.mapState(['resellerInfo'])
	}
};
