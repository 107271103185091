/*jshint esversion: 6 */
'use strict';
const template = `
<span
	class="tooltip-area" 
	:style="{display: display}" 
	@click="showTooltip($event)"
>
	<slot>{{ displayText }}</slot>
</span>
`;

export default {
	template: template,
	props: ['amount', 'currency', 'currencies', 'display', 'is-round', 'empty-text'],
	computed: {
		displayText() {
			let amount = this.handleAmount(this.amount);
			if (!amount && this.emptyText !== undefined) return this.emptyText;
			return this.numFormat(amount, this.numSep);
		}
	},
	methods: {
		showTooltip(e) {
			let tooltipH 	= 110,
				tooltipW 	= 160,
				tooltipEl 	= e.currentTarget.getBoundingClientRect(),
				ifCase 		= e.clientY > tooltipH,
				tooltipStrY = e.pageY - e.clientY + tooltipEl.top;

			let left 	= tooltipEl.left,
				bottom 	= ifCase ? null : 0,
				animate = ifCase ? 'anim-from-top' : 'anim-from-bottom',
				top 	= ifCase ? tooltipStrY - tooltipH : tooltipStrY,
				hiddenH = tooltipH + tooltipEl.height,
				hiddenW = tooltipEl.width,
				margin 	= (hiddenW - tooltipW) / 2;

			let	money = {
				show: 	true,
				converted: this.turnToConverted(),
				left, top, bottom, animate, hiddenH, hiddenW, margin
			};

			this.$set(this.$root, 'moneyTooltip', money);
		},
		handleAmount(val) {
			return this.isRound ? Math.round(val) : Number(val).toFixed(2);
		},
		turnToConverted() {
			let result = [];

			let amount = this.amount;
			let currency = this.currency || this.getPartnerCurrency();
			let currencies = this.currencies || this.getPartnerAllCurrencies();
			let rates = this.currencyRates;
			let usdAmount = null;

			if (currency !== 'USD') {
				for (let key in rates) {
					let rate = key.split('_')[0];
					if (currency === rate) {
						usdAmount = amount * rates[key];
						result.push({key: 'USD', sum: this.handleAmount(usdAmount)});
					}
				}
			} else {
				usdAmount = amount;
				result.push({key: 'USD', sum: this.handleAmount(amount)});
			}
			if (usdAmount === null) return;

			if (typeof currencies === 'undefined') {
				for (let key in rates) {
					let rate = key.split('_')[0];
					if (rate !== currency) {
						let division = usdAmount / rates[key];
						result.push({key: rate, sum: this.handleAmount(division)});
					}
				}
			} else {
				for (let key in rates) {
					let rate = key.split('_')[0];
					if (currencies.includes(rate) && rate !== currency) {
						let division = usdAmount / rates[key];
						result.push({key: rate, sum: this.handleAmount(division)});
					}
				}
			}

			return result;
		}
	}
};
