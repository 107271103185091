import Store from '../../store';

const template = `
	<page-table
		v-if="inited"
		ref="posTable"
		remote-method="KwPos.getKwTable"
		export-method="KwPos.export"
		:before-export-fn="beforeExport"
		@load="onLoad"
	>
		<template slot="stat">
			<span v-if="allowImport">
				<span class="general-upper">Import:</span>
				<i v-if="isKwBulkAdding" class="fa fa-fw fa-spinner spin-fast"></i>
				<span v-else class="link fa fa-fw fa-plus" @click="showImportPopup"></span>
			</span>
			<span v-if="checkedKws.length">
				<span class="general-upper">Checked:</span>
				{{ checkedKws.length }}
				<span class="general-upper link m-left5" @click="onChooseKeywords">Choose</span>
				<span class="general-upper link m-left5" @click="showCheckedKeywords">Show</span>
			</span>
		</template>
		<template slot="head">
			<tr>
				<th class="num-th">
					<head-cell-inner sort="">#</head-cell-inner>
				</th>
				<th v-if="withChb">
					<head-cell-inner>&nbsp;</head-cell-inner>
				</th>
				<th colspan="2">
					<head-cell-inner sort="kw">{{ ii('KEYWORD') }}</head-cell-inner>
				</th>
				<th class="metrics-th">
					<head-cell-inner sort="py" sort-rev="1">
						<span v-title="ii('POPULARITY')">{{ ii('PY') }}</span>
					</head-cell-inner>
				</th>
				<th class="metrics-th">
					<head-cell-inner>
						<span v-title="ii('SEARCHES')">{{ ii('SEARCHES') }}</span>
					</head-cell-inner>
				</th>
				<th>
					<head-cell-inner>{{ ii('PAGE') }}</head-cell-inner>
				</th>
				<th v-for="(date, i) in kwDates" class="pos-th">
					<head-cell-inner :sort="'date' + i" sort-rev="1">{{ date }}</head-cell-inner>
				</th>
			</tr>
		</template>
		<template slot="row" scope="p">
			<tr>
				<td class="text-center">
					{{ p.index + 1 }}
				</td>
				<td v-if="withChb" class="text-center">
					<label class="chb">
						<input type="checkbox" v-model="p.row.checked" @click="onChbClick($event, p.index)">
					</label>
				</td>
				<td>
					{{ p.row.kw }}
				</td>
				<td class="text-center">
					<a
						:href="getSerpUrl(p.row.kw)"
						class="fa fa-external-link"
						v-title="ii('GO_TO_REAL_SEARCH_RESULTS')"
						target="_blank"
						rel="noreferrer noopener"
					></a>
				</td>
				<td class="text-center">
					{{ getPopularityByLastDate(p.row.data) | numFormat }}
				</td>
				<td class="text-center">
					{{ getSearchesByLastDate(p.row.data) | numFormat }}
				</td>
				<td>
					<a
						:href="getFullPageUrlByLastDate(p.row)"
						target="_blank"
						rel="noreferrer noopener"
						class="kw-url"
						v-title="getFullPageUrlByLastDate(p.row)"
					>{{ getFullPageUrlByLastDate(p.row) }}</a>
				</td>
				<td v-for="date in kwDates"  class="text-center">
						<a
							v-if="p.row.data[date]"
							:href="getPosUrl(p.row.data[date])"
							class="pos-cell"
							:class="!p.row.data[date].page ? 'cursor-default' : ''"
							:style="getPosStyle(p.row.data[date].pos)"
							v-title="decodeURIComponent(p.row.data[date].page)"
							target="_blank"
							rel="noreferrer noopener"
						>{{ p.row.data[date].pos != null ? (p.row.data[date].pos || '-') : 'n/a' }}</a>
						<span v-else class="text-center">-</span>
				</td>
			</tr>
		</template>
	</page-table>
`;

export default {
	template,
	props: ['allow-import'],
	data() {
		return {
			inited: false,
			project: {},

			kwDates: null,
			domain: null,

			se: 1,
			//checkedRows: [],
			checkedKws: [],

			isKwBulkAdding: false
		};
	},
	computed: {
		...Store.mapState(['searchEngines']),
		withChb() {
			return !!this.app.query.seocase && this.checkPermission('page:seocase');
		}
	},
	methods: {
		forceReload() {
			this.$refs.posTable.reload({force: 1});
		},
		onChbClick(e, index) {
			let checked = e.target.checked;

			if (e.shiftKey && this.kwPosChbLastIndex != null) {
				let start = Math.min(index, this.kwPosChbLastIndex);
				let end = Math.max(index, this.kwPosChbLastIndex) + 1;

				this.$refs.posTable.rows.slice(start, end).forEach(row => {
					this.$set(row, 'checked', checked);

					let idx = this.checkedKws.findIndex(kwData => kwData.kw === row.kw);

					if (checked) {
						if(idx === -1){
							this.checkedKws.push({
								kw: row.kw,
								start: row.cols[0].pos,
								end: row.cols[row.cols.length - 1].pos,
								vol: row.vol || 0
							});
						}
					} else if(idx !== -1){
						this.checkedKws.splice(idx, 1);
					}
				});
			} else {
				let row = this.$refs.posTable.rows[index];
				this.$set(row, 'checked', checked);

				if (checked) {
					this.checkedKws.push({
						kw: row.kw,
						start: row.cols[0].pos,
						end: row.cols[row.cols.length - 1].pos,
						vol: row.vol || 0
					});
				} else {
					let idx = this.checkedKws.findIndex(kwData => kwData.kw === row.kw);
					this.checkedKws.splice(idx, 1);
				}
			}

			this.kwPosChbLastIndex = index;
		},
		async onChooseKeywords() {
			let msg = `Choose ${this.checkedKws.length} keywords for SEO case?`;
			let ok = await this.confirm(msg);
			if (!ok) return;

			await this.post('SEOCase.chooseKeywords', this.app.query.projectId, this.project.domain, this.checkedKws);

			let url = `/seocase?domain=${this.project.domain}#keywords`;
			location.href = url;
		},
		showCheckedKeywords() {
			let kws = this.checkedKws.map(r => r.kw);
			this.editQueryParams({
				kw: kws.join(','),
				groupId: undefined,
				top: undefined,
				minVol: undefined,
				notKw: undefined,
				url: undefined,
				change: undefined,
			});
		},
		getPosUrl(col) {
			if (!col.page) return 'javascript:;';

			let url = '';
			url += (col.https ? 'https://' : 'http://');
			url += (col.www ? 'www.' : '');
			url += this.domain;
			url += decodeURIComponent(col.page);
			return url;
		},
		getSerpUrl(keyword, pos) {
			let se = this.searchEngines.find(s => s.id == this.se);
			if (!se) return '';
			let start = pos ? Math.floor((pos - 1) / 10) * 10 : 0;

			if (se.id === 360) {
				let seUrl = `http://www.baidu.com/s?ie=utf-8&wd=${this.urlEnc(keyword)}&pn=${start}`;
				return seUrl;
			}
			let seUrl = `https://${se.domain}/search?q=${this.urlEnc(keyword)}&hl=${se.langCode}&num=100&gws_rd=cr`;
			if (se.id > 1 && this.project && this.project.se2 >= 10000 && se.uule) {
				seUrl += `&uule=${se.uule}`;
			}
			if (se.gl) {
				seUrl += `&gl=${se.gl}`;
			}

			return seUrl;
		},
		getFullPageUrlByLastDate(row) {
			let domain = this.domain.replace(/^www\./, '');
			let col;
			this.kwDates.forEach(date => {
				if(row.data[date])
					col = row.data[date];
			});
			if (col.www) {
				domain = 'www.' + domain;
			}
			let proto = col.https ? 'https' : 'http';
			let url = `${proto}://${domain}/`;
			if (col.page) {
				url += decodeURIComponent(col.page);
			}
			return url;
		},

		getPopularityByLastDate(data){
			let popularity = 0;

			if(!this.kwDates) return popularity;

			this.kwDates.forEach(date => {
				if(data[date])
					popularity = data[date].popularity;
			});

			return popularity;
		},
		getSearchesByLastDate(data){
			let searches = 0;

			if(!this.kwDates) return searches;

			this.kwDates.forEach(date => {
				if(data[date])
					searches = data[date].searches;
			});

			return searches;
		},
		async beforeExport() {
			let items = [
				{
					key: 'format',
					label: 'Format',
					type: 'select',
					items: [
						{key: 'txt', val: 'TXT'},
						{key: 'csv', val: 'CSV'}
					],
					val: 'csv',
					weight: 2
				},
				{key: 'kw', label: 'Keyword', type: 'checkbox', val: 1},
				{key: 'popularity', label: 'Popularity', type: 'checkbox'},
				{key: 'searches', label: 'Searches', type: 'checkbox'},
				{key: 'page', label: 'Page', type: 'checkbox', val: 1}
			];
			this.kwDates.forEach(date => {
				items.push({key: date, label: date, type: 'checkbox', val: 1});
			});

			let res = await this.multiInput({
				header: this.ii('EXPORT_OPTIONS'),
				split: 2,
				items
			});
			if (!res || !res.data) return;

			if (!res.data.format) {
				this.alert('Please choose format');
				return false;
			}

			res.close();
			return res.data;
		},
		onLoad(data) {
			this.kwDates = data.dates;
			this.domain = data.domain;
			this.se = data.se;
			this.$emit('load');

			let kwMap = {};
			this.checkedKws.forEach(kwData => {
				kwMap[kwData.kw] = true;
			});

			data.rows.forEach(row => {
				if (kwMap[row.kw]) {
					this.$set(row, 'checked', true);
				}
			});
		},

		async showImportPopup() {
			let text = await this.input({
				title: 'Input keywords one per line:',
				type: 'textarea'
			});
			if (!text) return;

			let kws = text
				.toLowerCase()
				.split('\n')
				.map(line => line.trim())
				.filter(kw => kw)
				.filter(this.filterUnique);

			let {projectId, groupId} = this.app.query;

			this.isKwBulkAdding = true;
			await this.post('KwPos.addKeywords', projectId, kws, groupId);
			this.isKwBulkAdding = false;

			this.$emit('groups-changed');
		}
	},
	async mounted() {
		await this.loadState(['searchEngines']);
		this.project = await this.get('Site.getFullInfo', this.app.query.projectId);

		if (this.app.query.seocase) {
			this.checkedKws = await this.get('SEOCase.getKeywords', this.app.query.seocase);
		}

		this.inited = true;
	}
};