import Store from '../../store.js';

const template = `
	<popup
		cls="reminder-msg-popup"
		width="400"
		content-min-height="280"
		close-on-back="1"
		@close="emitClose()"
		:data-id="eventId"
	>
		<div slot="header">Reminder</div>
		<div>
			<div>Author: 
				<strong v-if="!event.googleCalendarApi">{{ getWorker(eventId ? event.idw : worker.id).name }}</strong>
				<strong v-else>{{ event.creator ? event.creator : 'No data' }}</strong>
			</div>
			<div v-if="event.idu">User: <a :href="'/leads?idu=' + event.idu" target="_blank">#{{ event.idu }}</a></div>
			<div v-if="event.idc">Cold lead: <a :href="'/cold?id=' + event.idc" target="_blank">#{{ event.idc }}</a></div>
			<template v-if="event.type !== 'rebill'">
				<div v-if="!event.googleCalendarApi">
					<date-picker v-model="date" @change=""></date-picker>
					<input type="time" v-model="time" required>
					<select v-model="prio" v-title="'Priority'">
						<option :value="0">Low</option>
						<option :value="1">Normal</option>
						<option :value="2">High</option>
					</select>
				</div>
				<div v-else>
					<div>
						<span style="display: inline-block; width: 90px">Event starts at:</span>
						<strong>{{ date }}, {{ time }}</strong>
					</div>
					<div>
						<span style="display: inline-block; width: 90px">Event ends at:</span> 
						<strong v-if="dateEnd && timeEnd">{{ dateEnd }}, {{ timeEnd }}</strong>
						<strong v-else>Sometime in the future</strong>
					</div>
					<div>
						<span>Title:</span>
						<strong>{{ !comment || !comment.trim() ? 'No title' : comment }}</strong>
					</div>
				</div>
				<div v-if="!event.googleCalendarApi">
					<div class="rp-notif">
						<label class="chb">
							<input type="checkbox" v-model="shouldNotify">
							Send notification
						</label>
					</div>
					<div v-for="(notif, i) in additNotifs" class="rp-addit-notif">
						Notify
						<input type="number" min="1" max="1440" v-model="additNotifs[i]">
						minutes before event
						<i class="rp-close zmdi zmdi-close" @click="removeNotification(i)"></i>
					</div>
					<div class="rp-add-notif-btn-box">
						<a href="javascript:;" @click="addNotification">Add another notification</a>
					</div>
				</div>
			</template>
			<div v-else class="rp-date-readonly">
				<div>REBILL</div>
				<div>{{ String(event.remindAt).slice(0, 16) }}</div>
			</div>
			<div v-if="!event.googleCalendarApi">
				<textarea v-model="comment" :disabled="event.type === 'rebill'"></textarea>
			</div>
			<div v-else>
				<textarea :value="event.description" disabled="disabled"></textarea>
			</div>
			<div v-if="event.googleCalendarApi && event.attendees && event.attendees.length > 0">
				<div><strong>Attendees:</strong></div>
				<div v-for="attendee in event.attendees">
					<span
						class="link fa fa-fw"
						:class="prepareFontAwesomeStatus(attendee[1])" 
						v-title="attendee[1]"
					></span>
					<span>{{ attendee[0] }}</span>
				</div>
			</div>
		</div>
		<div v-if="!event.googleCalendarApi" slot="footer">
			<div v-if="event.status !== 1 && event.type !== 'rebill'" class="msg-send-box">
				<btn
					size="small"
					:disabled="trim(comment) === '' || !date || (eventId && event.idw !== worker.id)"
					:loading="isSaving"
					@click.native="save"
				>
					<i slot="icon" :class="'fa fa-fw ' + (eventId ? 'fa-save' : 'fa-plus')"></i>
					{{ eventId ? 'Save edits' : 'Create' }}
				</btn>
				
				<btn
					v-if="eventId"
					v-title="'Mark event as completed'"
					size="small"
					:disabled="eventId && event.idw !== worker.id"
					:loading="isCompleting"
					@click.native="complete"
				>
					<i slot="icon" class="fa fa-fw fa-check"></i>
					Complete
				</btn>
				<slot name="buttons"></slot>
			</div>
		</div>
		<slot name="addition"></slot>
	</popup>
`;

export default {
	template,
	props: [
		'event'
	],
	data() {
		return {
			date: null,
			time: null,
			dateEnd: null,
			timeEnd: null,
			prio: 1,
			comment: '',
			shouldNotify: true,
			additNotifs: [],
			text: '',
			isSaving: false,
			isCompleting: false
		};
	},
	computed: {
		...Store.mapState(['worker', 'workersMap']),
		eventId() {
			return this.event ? this.event.id : 0;
		}
	},
	methods: {
		addNotification() {
			let min = 5;
			if (this.additNotifs.length) {
				let maxMin = Math.max.apply(Math, this.additNotifs);
				if (maxMin) {
					min = maxMin * 2;
				}
			}
			this.additNotifs.push(min);
		},
		removeNotification(i) {
			this.additNotifs.splice(i, 1);
		},
		async save() {
			let additNotifs = this.additNotifs
				.map(min => parseInt(min))
				.filter(this.filterUnique);

			let momentUTC = moment(`${this.date} ${this.time}:00`).utc();

			let params = {
				id: this.eventId,
				idu: this.event && this.event.idu || 0,
				idc: this.event && this.event.idc || 0,
				ide: this.event.ide,
				date: momentUTC.format('YYYY-MM-DD'),
				time: momentUTC.format('HH:mm'),
				prio: this.prio,
				comment: this.comment,
				shouldNotify: this.shouldNotify,
				additNotifs
			};
			this.isSaving = true;
			let res = await this.post('Reminder.save', params);
			this.isSaving = false;
			if (!res.success) return alert('An error occurred');

			this.emitClose(res.event);
		},
		async complete() {
			let qPrompt = await this.prompt('Mark this event as completed?', '');

			if (qPrompt === '') qPrompt = 'empty';
			if (!qPrompt) return;

			let params = {
				eventId: this.eventId,
				idu: this.event && this.event.idu || 0,
				idc: this.event && this.event.idc || 0,
				comment: this.comment,
				text: qPrompt
			};
			this.isCompleting = true;
			let res = await this.post('Reminder.markCompleted', params);
			this.isCompleting = false;
			if (!res.success) return alert('An error occurred');

			this.emitClose(res.event);
		},
		emitClose(event) {
			this.$emit('close', event);
		},
		prepareFontAwesomeStatus(status) {
			let statusMap = {
				declined: 'fa-ban',
				accepted: 'fa-check-circle',
				tentative: 'fa-question-circle',
				needsAction: 'fa-pause-circle-o',
			};
			if (statusMap[status]) return statusMap[status];
			return statusMap.tentative;
		}
	},
	created() {
		this.date = this.event.remindDate || moment().add(1, 'days').format('YYYY-MM-DD');
		this.time = this.event.remindTime || moment().format('HH:mm');
		this.dateEnd = this.event.remindDateEnd || null;
		this.timeEnd = this.event.remindTimeEnd || null;

		this.prio = this.event.prio || 1;
		this.comment = this.event.comment || '';

		this.shouldNotify = this.event.shouldNotify !== 0;
		if (this.event.additNotifs) {
			this.additNotifs = this.event.additNotifs.split(',').map(min => parseInt(min));
		} else {
			this.additNotifs = [5];
		}

		if (this.event.id) {
			let momentUTC = moment.utc(`${this.event.remindDate} ${this.event.remindTime}`);
			this.date = momentUTC.local().format('YYYY-MM-DD');
			this.time = momentUTC.local().format('HH:mm');
		}
	}
};
