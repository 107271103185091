const template = `
	<span class="dropdown" v-mousedown-outside="hideItems">
		<span class="dropdown-val-tf" @click="showItems">
			<slot></slot>
		</span>
		
		<transition name="fade">
			<div v-if="itemsShown" ref="itemsBox" :class="'anim-from-bottom dropdown-dd dropdown-dd-' + ddPos">
				<div class="dropdown-dd-inner" ref="scrollableBox" v-block-scroll>
					<div
						v-for="(item, index) in filteredItems"
						class="dropdown-dd-item"
						:class="[{'active': value === item.key}]"
						:ref="'item' + item.key"
						v-title.left="item.title"
						@click="apply(item)"
					>
						<span v-if="item.html" v-html="item.html"></span>
						<template v-else>
							<span
								v-if="item.classNames"
								class="link no-line fa fa-fw"
								:class="item.classNames"
							></span>
							<span class="item-text">{{ item.val || item.text }}</span>
						</template>
					</div>
				</div>
			</div>
		</transition>
	</span>
`;

export default {
	template: template,
	data() {
		return {
			itemsShown: false,
			movingFastStep: 10,
			checkedKeysMap: {},
			stickyCheckedKeysMap: {}, //remove excessive code
			ddPos: null,
			searchVal: ''
		};
	},
	props: ['value', 'items'],
	computed: {
		itemsWithSort() {
			let items = [...this.items].map((item, i) => {
				if (item.sortVal == null) {
					item.sortVal = i;
				}
				return item;
			});
			return items;
		},
		filteredItems() {
			if (!this.items) return this.items;
			let items = this.itemsWithSort.filter(this.checkItemMatch);
			return items;
		},
		altSearchVal() {
			if (!this.searchVal) return this.searchVal;

			return this.toKeyboardLatin(this.searchVal.toLowerCase());
		},
		checkedItemsCnt() {
			return Object.keys(this.checkedKeysMap).length;
		}
	},
	methods: {
		showItems() {
			let winWidth = window.innerWidth;

			this.itemsShown = true;
			this.searchVal = '';
			this.ddPos = 'left';

			this.stickyCheckedKeysMap = {...this.checkedKeysMap};

			this.$nextTick(() => {
				let boxRight = this.$refs.itemsBox.getBoundingClientRect().right;
				let overlay = boxRight - winWidth + 10;
				this.ddPos = overlay < 0 ? 'left' : 'right';

				this.$refs.scrollableBox.scrollTop = 0;
			});
		},
		hideItems() {
			this.itemsShown = false;
		},
		checkItemMatch(item) {
			if (!this.searchVal) return true;

			if (!item) return false;

			let regex = new RegExp(this.escapeRegex(this.searchVal), 'i');
			if (regex.test(item.sVal || item.val)) return true;

			if (this.altSearchVal === this.searchVal) return false;

			let altRegex = new RegExp(this.escapeRegex(this.altSearchVal), 'i');
			return altRegex.test(item.sVal || item.val);
		},
		apply(r) {
			this.$emit('input', r.key, r);
			this.$emit('change', {isTrusted: true, val: r.key});

			this.hideItems();
		}
	}
};
