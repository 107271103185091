const template = `
<div class="maincard" ref="card" :style="{maxWidth: maxWidth}">
	<div v-if="idu <= 0 || !idu" class="general-upper text-center padding10">{{ ii('NO_USER') }}</div>
	<div v-else-if="accessDenied" class="general-upper text-center padding10">{{ ii('ACCESS_DENIED') }}</div>
	<div v-else-if="!inited" class="general-upper text-center padding10">
		<i class="fa fa-spinner spin-fast"></i>
		{{ ii('LOADING') }}...
	</div>
	<div v-else-if="deleted" class="general-upper text-center padding10 red">
		{{ ii('USER_DELETED_OR_NOT_EXIST') }}
	</div>
	<template v-else>
		<header>
			<nav>
				<div class="subpanels-box">
					<template v-for="el in headerButtons">
						<a
							v-if="shouldShowTab(el)"
							:href="'/user/' + el.key + '?idu=' + idu"
							:class="frame == el.key ? 'active' : ''"
							:key="el.key + '_btn'"
							@click="onTabClick($event, el.key)"
						>
							<span class="header-nav-item">
								<span class="spanTitle" v-if="el.spanTitle">{{ ii(el.spanTitle) }}</span>
								<span class="num" v-if="el.spanNum">{{ el.spanNum }}</span>
							</span>
						</a>
					</template>
				</div>
			</nav>
		</header>
		<div class="bodycard">
			<div class="accountcard" v-show="frame === 'user'">
				<keep-alive>
					<user-profile
						v-if="frame === 'user'"
						:idu="idu"
						@delete="checkUserStatus()"
					></user-profile>
				</keep-alive>
			</div>
			<div class="activitycard" v-show="frame === 'activity'">
				<keep-alive>
					<activity v-if="frame === 'activity'" :idu="idu"></activity>
				</keep-alive>
			</div>
			<div class="newscard" v-show="frame === 'news'">
				<keep-alive>
					<news v-if="frame === 'news'" :idu="idu"></news>
				</keep-alive>
			</div>
			<div class="popupscard" v-show="frame === 'popups'">
				<keep-alive>
					<user-popups
						v-if="frame === 'popups'"
						:idu="idu"
					></user-popups>
				</keep-alive>
			</div>
		</div>
	</template>
</div>`;
export default {
	template,
	props: ['idu', 'tab'],
	data() {
		return {
			inited: false,
			accessDenied: false,
			headerButtons: [
				{
					key: 'user',
					spanTitle: 'Info',
					spanNum: ''
				},
				{
					key: 'activity',
					spanTitle: 'Activity',
					spanNum: ''
				},
				{
					key: 'news',
					spanTitle: 'News',
					spanNum: ''
				},
				{
					key: 'popups',
					spanTitle: 'Popups',
					spanNum: ''
				}
			],
			frame: 'user',
			maxWidth: 'initial',
			deleted: false
		};
	},
	methods: {
		shouldShowTab(el) {
			return !el.hidden;
		},
		onTabClick(e, key) {
			e.preventDefault();
			this.frame = key;
		},
		fixWidth() {
			let parentWidth = this.$refs.card.parentNode.getBoundingClientRect().width;
			this.maxWidth = parentWidth + 'px';
		},
		async checkUserStatus() {
			this.deleted = !(await this.get('UserCard.checkUserStatus', this.idu));
		}
	},
	watch: {
		tab() {
			this.act('usercard_tab', this.tab);
			this.frame = this.tab;
		},
		frame() {
			this.$emit('tabChanged', {tab: this.frame});
		}
	},
	async mounted() {
		this.act('usercard', this.idu);

		if (this.tab) {
			this.frame = this.tab;
			this.act('usercard_tab', this.tab);
		}

		this.fixWidth();
		await this.loadLangTexts([this.$root.language], ['net_usercard']);
		await this.checkUserStatus();

		if (!this.idu) return;
		if (this.accessDenied) return;

		this.inited = true;
	}
};
