import Store from '../../store.js';

const template = `
	<popup 
		width="700px"
		content-max-height="80vh"
		@close="close"
		class="surver-config-builder"
	>	
		<div v-for="block, i in value" class="survey-block-wrapper">	
			<span class="dragger">
				<i v-title="'Delete'" class="pointer fa fa-times" @click="deleteFromArray(value, i)"></i>
				<i v-title="'Move up'" class="pointer fa fa-arrow-up"  @click="moveInArray(value, i, i - 1)"></i>
				<i v-title="'Move down'" class="pointer fa fa-arrow-down"  @click="moveInArray(value, i, i + 1)"></i>
			</span>	
			<div class="survey-block">
				<div class="setters">
					<span class="selector-label">Type:</span>
					<select v-model="block.type" @change="changeType(block)">
						<option v-for="name, type in surveyTypes" :value="type">{{name}}</option>
					</select> 
				</div>
				<div class="setters">
					<span class="selector-label">Default status:</span>
					<drop-down v-model="block.st" :items="getQuestionsStItems(1)"  class="drop-down">
						<img :src="'/img/smile/' + surveyStatuses[block.st]">
					</drop-down>
				</div>
				<div class="setters">
					<span class="selector-label">Question:</span>
					<input class="local-id" type="text" v-model="block.id">
					<i class="fa fa-plus info add-new pointer" v-title="'Add new'" @click="addNewMultilang(block)"></i>
					<span v-html="quizLocalization[block.id] ? quizLocalization[block.id].en : 'No such value'"></span>
				</div>
				<div class="setters answer-setter">
					<span class="selector-label">Answers:</span>
					
					<template v-if="block.type == 'radio' || block.type == 'check'">
						<div class="survey-block-wrapper survey-answer-block-wrapper" v-for="answer, k in block.answers">	
							<span class="dragger">
								<i v-title="'Delete'" class="pointer fa fa-times" @click="deleteFromArray(block.answers, k)"></i>
								<i v-title="'Move up'" class="pointer fa fa-arrow-up"  @click="moveInArray(block.answers, k, k - 1)"></i>
								<i v-title="'Move down'" class="pointer fa fa-arrow-down"  @click="moveInArray(block.answers, k, k + 1)"></i>
							</span>	
							<div class="survey-answer-block">
								<div class="settings-row">	
									<drop-down v-model="answer.st" :items="getQuestionsStItems()" class="drop-down">
										<img :src="'/img/smile/' + surveyStatuses[answer.st]">
									</drop-down>
									<p>
										<input class="local-id" type="text" v-model="answer.id">
										<i class="fa fa-plus info pointer" v-title="'Add other answer'" @click="addNewMultilang(answer)"></i>
										<i 
											class="fa fa-window-maximize info pointer" 
											v-title="'Add text field for custom answer'" 
											@click="addTextField(answer)"
										></i>
										<span v-html="quizLocalization[answer.id] ? quizLocalization[answer.id].en : 'No such value'"></span >
									</p>
								</div>
								<textarea v-if="answer.textarea" class="custom-field">Custom answer. A lot of unnecessary information</textarea>
							</div>
						</div>
						<btn size="small" class="add-new-btn" @click.native="addNewAnswer(block)"><i class="fa fa-plus pointer"></i>Add new answer</btn>
					</template>
					<template v-else-if="block.type == 'field'">
						<textarea class="custom-field">Custom answer. A lot of unnecessary information</textarea>
					</template>
					<template v-else-if="block.type == 'tel'">
						<phone-input :value="+380"></phone-input>
					</template>
					<template v-else-if="block.type == 'range'">
						<table class="statuses-table">
							<tr>
								<td v-for="i in 10">{{i}}</td>
							</tr>
							<tr>
								<td v-for="st, i in block.statuses">
									<drop-down v-model="block.statuses[i]" :items="getQuestionsStItems()" class="drop-down">
										<img :src="'/img/smile/' + (surveyStatuses[st] || surveyStatuses[1])">
									</drop-down>
								</td>
							</tr>
						</table>
					</template>
					
				</div>
			</div>
		</div>
		<btn size="small" class="add-new-btn" @click.native="addNewQuestion(value)"><i class="fa fa-plus pointer"></i>Add new question</btn>
		
		<div slot="footer">
			<btn v-if="checkPermission('edit:survey_config')" @click.native="close(true)" class="save-btn"><i class="fa fa-save pointer"></i>Save config</btn>
		</div>
	</popup>
`;

export default {
	template: template,
	props: ['value'],
	computed: {
		...Store.mapState(['quizLocalization'])
	},
	data() {
		return {
			surveyConfig: [],
			surveyTypes: {
				radio: 'Block with radiobuttons',
				check: 'Block with checkboxes',
				field: 'Block with textarea only',
				range: 'Block with range selector only',
				tel: 'Block with phone field only',
			},
			surveyStatuses: ['all.svg', 'happy.svg', 'poo.svg', 'phone-receiver.svg', 'confused.svg', 'question.svg']
		};
	},
	methods: {
		changeType(block) {
			if (block.type == 'radio') block.answers = block.answers ? block.answers : [];
			if (block.type == 'check') block.answers = block.answers ? block.answers : [];
			if (block.type == 'range') block.statuses = block.statuses ? block.statuses : [2,2,2,2,2,1,1,1,1,1];
		},
		moveInArray(arr, i, j) {
			let newArr = [...arr.slice(0, i), ...arr.slice(i + 1)];
			newArr = [...newArr.slice(0, j), arr[i], ...newArr.slice(j)];
			while (arr.length) {
				arr.pop();
			}
			newArr.forEach(e => arr.push(e));
		},
		deleteFromArray(arr, i) {
			let newArr = [...arr.slice(0, i), ...arr.slice(i + 1)];
			while (arr.length) {
				arr.pop();
			}
			newArr.forEach(e => arr.push(e));
		},
		addNewAnswer(block) {
			if (!block.answers) this.$set(block, 'answers', []);
			block.answers.push({st:'1',id:'',});
		},
		addNewQuestion(value) {
			value.push({st:'1',id:'',type:'radio',answers:[], statuses:[2,2,2,2,2,1,1,1,1,1]});
		},
		addTextField(answer) {
			this.$set(answer, 'textarea', !answer.textarea);
		},
		async addNewMultilang(block) {
			let text = await this.prompt('Enter text', null, {type: 'textarea'});
			let id = await this.post('SurveyBuilder.addMultilangText', text);
			if (id) {
				this.quizLocalization[id] = {en: text};
				block.id = id;
			}
		},
		getQuestionsStItems(expanded) {
			return this.surveyStatuses
				.map((e, i) => {
					return {
						key: i,
						sortVal: i,
						html: e ? `<img src="/img/smile/${e}">` : '',
						title: e ? '' : 'No status'
					};
				});
		},
		close(save) {
			let value = save ? this.value : null;
			this.$emit('close', value);
		}
	},
	async mounted() {
		await this.loadState(['quizLocalization']);
	}
};
