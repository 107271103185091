const template = `
	<div>
		<video
			v-if="isVideo"
			:src="videoUrl"
			ref="bgVideo"
			class="bg-video"
			autoplay="autoplay"
			loop="loop"
			@error="onVideoError"
		></video>
		<a
			href="javascript:;"
			class="toggle-bg-btn"
			@click="toggleBackground"
		>{{ ii('RELAX_MODE') }}</a>
	</div>
`;

export default {
	template,
	data() {
		return {
			isVideo: false
		};
	},
	methods: {
		toggleBackground() {
			this.isVideo = !this.isVideo;
			localStorage.setItem('bgVideo', this.isVideo ? 1 : 0);
			if (this.isVideo) {
				document.body.classList.add('with-video');
			} else {
				document.body.classList.remove('with-video');
			}
			
			if (this.isVideo && !this.videoUrl) {
				this.videoUrl = 'https://storage.semalt.com/video/palms.mp4';
			}
		},
		onVideoError(e) {
			console.log(e);
		}
	},
	mounted() {
		if (localStorage.getItem('bgVideo') === '1') {
			this.toggleBackground();
		}
	}
};