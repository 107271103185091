const template = `
	<div class="user-projects">
		<div v-if="isLoading" class="loading">
			<span>
				<i class="fa fa-spinner spin-fast center"></i>
				<span class="general-upper">{{ ii('LOADING') }}...</span>
			</span>
		</div>
		<table v-else class="mc-tbl hover-tbl">
			<thead>
				<tr>
					<th>
						<head-cell-inner no-sticky="1">#</head-cell-inner>
					</th>
					<th>
						<head-cell-inner no-sticky="1">{{ ii('ID') }}</head-cell-inner>
					</th>
					<th colspan="2">
						<head-cell-inner no-sticky="1">{{ ii('SITE') }}</head-cell-inner>
					</th>
					<th>
						<head-cell-inner no-sticky="1">{{ ii('KEYWORDS') }}</head-cell-inner>
					</th>
					<th>
						<head-cell-inner no-sticky="1">{{ ii('SEARCH_ENGINE') }}</head-cell-inner>
					</th>
					<th>
						<head-cell-inner no-sticky="1">{{ ii('ACCOUNT') }}</head-cell-inner>
					</th>
					<th>
						<head-cell-inner no-sticky="1">{{ ii('REPEATS') }}</head-cell-inner>
					</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(row, i) in sortedSites">
					<td class="text-center">{{ i + 1 }}</td>
					<td>
						<a :href="'/project?projectId=' + row.id">{{ row.id }}</a>
					</td>
					<td>
						<a :href="'http://' + row.domain" target="_blank" rel="noreferrer noopener">{{ row.domain }}</a>
					</td>
					<td class="text-center">
						<a
							:href="getCapturePageLink(row.domain)"
							v-title="'Preview'"
							class="fa fa-image static-color"
							data-image-preview="1"
							target="_blank"
						></a>
					</td>
					<td class="text-center">{{ row.kwsCnt }}</td>
					<td>
						<flag v-if="row.seGeo" :cc="row.seGeo"></flag>
						{{ row.google }} - {{ row.seLang }}
					</td>
					<td class="text-center">
						<a :href="row.goLink" target="_blank"><i class="fa fa-external-link"></i></a>
					</td>
					<td class="text-center">
						<template v-for="(r, i) in row.repeats" v-if="i < 10">
							<a
								:href="'/leads?idu=' + r"
								target="_blank"
							>{{ r }}</a><span v-if="i < row.repeats.length - 1 && i < 9">, </span>
							<br v-if="i === 4">
						</template>
						<div v-if="row.repeats.length > 10" class="lower">
							{{ iiArgs('AND_N_MORE', row.repeats.length - 10) }}
						</div>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
`;

export default {
	template,
	data() {
		return {
			isLoading: true,
			http: null
		};
	},
	props: ['idu', 'partner'],
	computed: {
		sortedSites() {
			if (!this.http) return [];
			let sites = [...this.http];
			sites.sort((a, b) => {
				if (a.domain > b.domain) return 1;
				if (a.domain < b.domain) return -1;
				return 0;
			});
			return sites;
		}
	},
	methods: {

	},
	async mounted() {
		if (this.idu) {
			let params = {idu: this.idu, partner: this.partner};
			this.http = await this.get('UserCard.getHttpInfo', params);
		}
		this.isLoading = false;
	}
};
