import Store from '../../store.js';

const template = `
	<popup
		width="400"
		cls="seo-task-popup"
		@close="close"
		no-scroll="1"
		close-on-back="1"
	>
		<div slot="header">{{ ii('SEO_TASK') }}</div>
		<div v-if="!inited" class="project-loading">
			<i class="fa fa-spinner spin-fast"></i>
			{{ ii('LOADING') }}...
		</div>
		<template v-else>
			<div class="seo-task-content">
				<div class="clearfix">
					<div class="seo-task-caption general-upper">{{ ii('TYPE') }}:</div>
					<rich-sel
						v-model="typePopup"
						:items="getTypesItems()"
					></rich-sel>
				</div>
				<div class="clearfix">
					<div class="seo-task-option left" :style="typePopup === 'dev' ? 'width: 100%' : ''">
						<div class="seo-task-caption general-upper">
							{{ ii('PROJECT') }}:&nbsp;
							<span v-if="typePopup === 'seo'" class="control-add-chb">
								<input
									type="checkbox"
									v-model="freeDomain"
									id="checkboxProject"
								>
								<em>
									<label for="checkboxProject" class="lower">unpaid client</label>
								</em>
							</span>
						</div>
						<input v-if="freeDomain && typePopup === 'seo'" v-model="domain" type="text">
						<rich-sel
							v-else
							v-model="domain"
							items-url="/api/seo-sites-suggestions"
							no-filter="1"
						></rich-sel>
					</div>
					<div class="seo-task-option right" v-if="typePopup !== 'dev'">
						<div class="seo-task-caption general-upper">{{ ii('WORKER') }}:</div>
						<rich-sel
							v-model="toIdw"
							:items="typePopup == 'sales' ? getManagersItems() : getWorkersItems({groups: [typePopup == 'dev' ? 'seodev' : 'qm']})"
						></rich-sel>
					</div>
				</div>
				<div class="clearfix" v-if="typePopup == 'seo'">
					<div class="seo-task-option left">
						<div class="seo-task-caption general-upper">{{ ii('CATEGORY') }}:</div>
						<rich-sel
							v-model="catId"
							:items="getCategoriesItems()"
						></rich-sel>
					</div>
					<div class="seo-task-option right">
						<div class="seo-task-caption general-upper">{{ ii('PRIORITY') }}:</div>
						<select v-model="priority">
							<option :value="void 0"></option>
							<option value="0">{{ ii('NORMAL') }}</option>
							<option value="1">{{ ii('HIGH') }}</option>
							<option value="2">{{ ii('URGENT') }}</option>
						</select>
					</div>
				</div>
				<!--
				<div class="clearfix">
					<div class="seo-task-option left">
						<div class="seo-task-caption general-upper">{{ ii('GROUP') }}:</div>
						<select v-model="group">
							<option v-for="g in groups" :value="g">{{ ii(g) }}</option>
						</select>
					</div>
				</div>
				-->
				<div class="clearfix">
					<div class="seo-task-caption general-upper">{{ ii('TASK') }}:</div>
					<textarea v-model="taskText"></textarea>
				</div>
			</div>
			<div slot="footer" class="clearfix">
				<div class="right">
					<btn size="small" :loading="isCreating" @click.native="createTask">
						<i class="fa fa-fw fa-plus" slot="icon"></i>
						<span>{{ ii('CREATE') }}</span>
					</btn>
				</div>
			</div>
		</template>
	</popup>
`;

export default {
	template,
	props: ['taskId', 'stat', 'type'],
	data() {
		return {
			inited: false,
			domain: null,
			toIdw: null,
			catId: null,
			priority: 0,
			taskText: '',
			categories: null,
			typePopup: 'seo',
			//group: null,
			//groups: ['content', 'wmt', 'cp'],
			isCreating: false,
			freeDomain: false
		};
	},
	computed: {
		...Store.mapState(['workersMap'])
	},
	methods: {
		close() {
			this.$emit('close');
		},
		async loadTask() {

		},
		getTypesItems() {
			let items = [{key: 'seo', val: 'Seo'}];
			if (this.checkPermission('seotasks:approved')) {
				items.push({key: 'sales', val: 'Sales'});
			}
			items.push({key: 'dev', val: 'Development'});
			return items;
		},
		getCategoriesItems() {
			if (!this.categories) return;
			return this.categories.map((item, i) => {
				if (this.checkPermission('seotasks:approved') && item.id === 9) return;
				return ({
					key: item.id,
					val: item.title
				});
			});
		},
		async loadCategories() {
			this.categories = await this.get('SEOTasks.getTasksCategories');
		},
		async createTask() {
			if (!this.typePopup) return this.alert(this.ii('PLEASE_CHOOSE_TYPE'));
			if (!this.domain) return this.alert(this.ii('PLEASE_CHOOSE_PROJECT'));
			if (!this.catId && this.typePopup == 'seo') return this.alert(this.ii('PLEASE_CHOOSE_CATEGORY'));
			if (!this.taskText) return this.alert(this.ii('PLEASE_INPUT_TASK'));
			if (!this.toIdw) this.toIdw = 0;
			if (!this.priority) this.priority = 0;

			if (this.freeDomain && this.typePopup === 'seo') {
				let domain = await this.getFreeDomain();
				if (!domain) return this.alert(this.ii('PLEASE_ENTER_PROJECT'));

				this.domain = domain;
			}

			let domainId = await this.get('SEOTasks.getDomainId', this.domain);
			if (!domainId) return this.alert(this.ii('PLEASE_CHOOSE_PROJECT'));

			let err = null;
			let data = {
				domainId,
				toIdw: this.toIdw,
				//group: this.group,
				taskText: this.taskText
			};

			this.isCreating = true;

			if (this.typePopup == 'sales') {
				data.idm = this.toIdw ? this.toIdw : null;
				data.domain = this.domain;
				err = await this.post('SEOTasks.createSalesTask', data);
			} else if (this.typePopup == 'dev') {
				data.domain = this.domain;
				err = await this.post('SEOTasks.createDevTask', data);
				this.post('SEOProjects.autoSetPlatform', this.domain);
			} else {
				data.catId = this.catId;
				data.priority = this.priority;
				err = await this.post('SEOTasks.createTask', data);
			}

			this.isCreating = false;
			if (err) this.alert(err);

			this.$emit('task-created');
		},
		async getFreeDomain() {
			let domain = this.domain.split(' ').join('');
			let domainArgs = domain.replace(/https?:\/\/([^\s]+)|https?:\/\/www.([^\s]+)/gi, '$1').split('/');
			if (!domainArgs.length || !domainArgs[0]) return null;

			domain = domainArgs[0];
			await this.post('SEOTasks.addFreeDomain', domain);

			return domain;
		}
	},
	async mounted() {
		this.loadCategories();
		//this.group = this.groups[0];
		if (this.type == 'sales') this.typePopup = 'sales';
		if (this.isObjectEmpty(this.workersMap)) {
			await this.loadState(['workersMap']);
		}
		if (this.taskId) {
			await this.loadTask();
		}
		if (this.stat) {
			if (this.stat.domain) this.domain = this.stat.domain;
			if (this.stat.catId) this.catId = this.stat.catId;
		}

		this.inited = true;
	}
};
