import Store from '../../store.js';

const template = `
	<div class="worker-card" v-if="init">

		<div class="content">
			<div class="content-box">
				<div class="content-division">
					<div class="group-label prim-color"><i class="fa fa-user"></i> {{ ii('MAIN_INFO') }}</div>
					<div>
						<p class="item-label">{{ ii('REAL_NAME') }}:</p>
						<input placeholder="Test User" type="text" v-model.trim="employee.realName">
					</div>
					<div>
						<p class="item-label">{{ ii('PSEUDONYM') }}:</p>
						<input placeholder="Test pseudonym" type="text" v-model.trim="employee.name" >
					</div>
					<div>
						<p class="item-label">{{ ii('BIRTHDAY') }}:</p>
						<date-picker v-model="employee.birthday"></date-picker>
					</div>
					<div>
						<p class="item-label">{{ ii('NATION') }}:</p>
						<rich-sel
							:items="getGeoSuggestions()"
							v-model="employee.nation"
						></rich-sel>
					</div>
					<div v-if="reseller === 'semalt'">
						<p class="item-label">{{ ii('OFFICE') }}:</p>
						<rich-sel
							:items="getOfficesSuggestions({withOther: true})"
							v-model="employee.office"
						></rich-sel>
					</div>
				</div>
				<div class="content-division">
					<div class="group-label prim-color"><i class="fa fa-phone"></i> {{ ii('CONTACTS') }}</div>
					<div>
						<p class="item-label">{{ ii('SKYPE') }}:</p>
						<input v-model.trim="employee.skype" placeholder="example" type="text">
					</div>
					<div>
						<p class="item-label">{{ ii('E_MAIL') }}:</p>
						<input type="email" placeholder="example@semalt.com" v-model.trim="employee.email"
							v-valid:email="{
								style: 'min-width: 200px;'
							}">
					</div>
					<div>
						<p class="item-label">{{ ii('PHONE') }}:</p>
						<input type="tel" placeholder="+380-12-345-67-89" v-model.trim="employee.phone"
							v-valid:tel="{
								style: 'min-width: 200px;'
							}">
					</div>
				</div>
			</div>

			<div class="content-box">
				<div class="content-division">
					<div class="group-label prim-color"><i class="fa fa-user-plus"></i> {{ ii('POSITION_INFO') }}</div>
					<div>
						<p class="item-label">{{ ii('WORKER_POSITION') }}:</p>
						<rich-sel :items="categorySuggestion" v-model="employee.work"></rich-sel>
					</div>
					<div>
						<p class="item-label">{{ ii('ROLE') }}:</p>
						<select v-model="employee.roleId">
							<option  v-for="(val, key) in rolesMap" :value="key">{{ val }}</option>
						</select>
					</div>
					<div>
						<p class="item-label">{{ ii('GROUPS') }}:</p>
						<multi-sel
							:items="getGroupsSuggestion()"
							v-model="employee.groups"
						></multi-sel>
					</div>
				</div>
				<div v-if="reseller === 'semalt'" class="content-division">
					<div class="group-label prim-color"><i class="fa fa-bicycle"></i>Worker hobbies:</div>
					<div>
						<p class="item-label">{{ ii('HOBBY') }}:</p>
						<input placeholder="football" type="text" v-model.trim="hobbies[0]">
					</div>
					<div>
						<p class="item-label">{{ ii('HOBBY') }}:</p>
						<input placeholder="pocker" type="text" v-model.trim="hobbies[1]">
					</div>
					<div>
						<p class="item-label">{{ ii('HOBBY') }}:</p>
						<input placeholder="family" type="text" v-model.trim="hobbies[2]">
					</div>
				</div>
			</div>

			<div class="content-box">
				<!--<div class="content-division"  v-if="checkPermission('*') && id">
					<div class="group-label prim-color">
						<i class="fa fa-lock"></i>{{ ii('PERMISSIONS') }}:
						<btn
							@click.native="setWorkerPermissions()"
							:type="'primary'"
							:size="'small'"
							class="btn-center">
							<i class="fa fa-unlock"></i>
							<span>{{ ii('SET_PERMISSIONS') }}</span>
						</btn>
					</div>
					<div>
						<p class="item-label">{{ ii('PERMISSIONS') }}:</p>
						<multi-sel
							:items="getPermissionsSuggestion()"
							v-model="workerPermissions"
						></multi-sel>
					</div>
					<div>
						<p class="item-label">{{ ii('COPY_PERMISSIONS') }}:</p>
						<rich-sel
							:items="getWorkersItems()"
							v-model="workerIdToCopyPermission"
							@change="getWorkerPermissions(workerIdToCopyPermission)"
						></rich-sel>
					</div>
				</div>-->
				<div class="content-division">
					<div class="group-label prim-color"><i class="fa fa-lock"></i>{{ ii('PASSWORDS') }}:</div>
					<div>
						<p class="item-label">{{ ii('GENERAL_PASSWORD') }}:</p>
						<input type="text" v-model="employee.password" :disabled="!canAccessPasswords">
					</div>
					<div>
						<p class="item-label">{{ ii('TASKS_PASSWORD') }}:</p>
						<input type="text" v-model="employee.tasksPassword" :disabled="!canAccessPasswords">
					</div>
					<div>
						<p class="item-label">{{ ii('E_MAIL') }} {{ ii('PASSWORD') }}:</p>
						<input type="text" v-model="employee.emailPassword" :disabled="!canAccessPasswords">
					</div>
					<div>
						<p class="item-label">{{ ii('SKYPE') }} {{ ii('PASSWORD') }}:</p>
						<input type="text" v-model="employee.skypePassword" :disabled="!canAccessPasswords">
					</div>
				</div>
				<div class="content-division" :class="employee.isSales ? '' : 'hidden'">
					<div class="group-label prim-color"><i class="fa fa-shopping-bag"></i>{{ ii('SALES') }}:</div>
					<div>
						<p class="item-label">{{ ii('SALES_ID') }}:</p>
						<input type="number" v-model="employee.idManager">
					</div>
					<div>
						<p class="item-label">{{ ii('E_MAIL') }}:</p>
						<input type="text" v-model.trim="employee.emailManager">
					</div>
					<div>
						<p class="item-label">{{ ii('SKYPE') }}:</p>
						<input type="text" v-model.trim="employee.skypeManager">
					</div>
					<div>
						<p class="item-label">{{ ii('PHONE') }}:</p>
						<input type="text" v-model.trim="employee.phoneManager">
					</div>
					<div>
						<p class="item-label">{{ ii('3CX') }}:</p>
						<input type="number" v-model="employee.number3cx">
					</div>
				</div>
			</div>

			<div class="content-box">
				<div class="user-pic">
					<div class="user-pick-editor">
						<img v-if="employee.img" :src="'https://' + resellerInfo.semaltDomain + employee.img">
						<div
							class="pick-editor-btn"
							@click="showUploadPhotoPopup">
							{{ ii('CHANGE') }}
						</div>
					</div>
					<br>
					<div>
						<strong>{{ ii('WORKER_ID') }}: {{ employee.id }}</strong>
						<template v-if="employee.idm"> |
							<strong>{{ ii('SALES_ID') }}: {{ employee.idm }}</strong>
						</template>
					</div>
					<br>
					<trigger
						v-model="employee.active"
						:size="'trigger-small'"
						:on="'Active'"
						:off="'Dismissed'"
						:true-value="1"
						:false-value="0"
					></trigger>
					<br>
					<trigger
						v-model="employee.isSales"
						:size="'trigger-small'"
						:on="'Sales'"
						:off="'Not sales'"
						:true-value="1"
						:false-value="0"
					></trigger>
					<br>
					<trigger
						v-model="employee.public"
						:size="'trigger-small'"
						:on="'Public'"
						:off="'Not public'"
						:true-value="1"
						:false-value="0"
					></trigger>
				</div>

				<btn
					@click.native="saveAll"
					:type="'primary'"
					:size="'normal'"
					class="save-btn"
				>
					<i class="fa fa-save"></i>
					<span>{{ ii('SAVE_CHANGES') }}</span>
				</btn>
			</div>
		</div>

		<transition name="fade">
			<div class="saved-message" v-if="saved">
				<i class="fa fa-save"></i>
				{{ ii('SAVED') }}
			</div>
		</transition>
		<div class="loading-inner" v-if="isLoading">
			<i class="fa fa-spinner spin-fast"></i>
			{{ ii('LOADING') }}...
		</div>
	</div>
`;

export default {
	template: template,
	data() {
		return({
			saved: false,
			init: false,
			employee: null,
			rolesMap: null,
			categotyMap: {
				'Artist': 'vid',
				'Business Development Manager': 'sal',
				'CEO & Founder': 'biz',
				'Content management / QA': 'seo',
				'Content Strategist': 'mar',
				'Copywriter': 'mar',
				'Customer Success Manager': 'sal',
				'Customer Support Manager': 'sal',
				'Designer': 'mar',
				'E-mail Marketing Manager': 'mar',
				'Head of Customer Success Department': 'biz',
				'Head of Marketing Department': 'mar',
				'Head of SEO Department': 'seo',
				'Head of Video Production Department': 'vid',
				'Head of WebDev': 'biz',
				'Human Resources Manager': 'hum',
				'Junior Web Developer': 'dev',
				'Marketing Analyst': 'mar',
				'Marketing Manager': 'mar',
				'Motion Designer': 'vid',
				'PM': 'dev',
				'PR Manager': 'mar',
				'Product Manager': 'mar',
				'Programmer Analyst': 'de',
				'Project Manager': 'biz',
				'Recruiting Interviewer': 'hum',
				'Sales Manager': 'sal',
				'Senior Customer Success Manager': 'sal',
				'Senior Sales Manager': 'sal',
				'Senior SEO Specialist': 'seo',
				'Senior Web Developer': 'dev',
				'SEO Specialist': 'seo',
				'SMM manager': 'mar',
				'Tech Lead': 'dev',
				'Translator': 'mar',
				'Web Analyst': 'mar',
				'Web Designer': 'mar',
				'Web Developer': 'dev',
				'Web/UI Designer': 'mar',
			},
			groupsMap: null,
			hobbies: [],
			//permissionsMap: null,
			//workerIdToCopyPermission: '',
			//workerPermissions: '',
			isLoading: false,
			picData: null,
			uploadedPic: '',
			DnDFieldShadow: '',
			passwordType: 'password',
		});
	},
	props: [
		'idw',
		'idm',
		'cls',
		'width',
		'content-min-height',
		'content-max-height',
		'scroll-to-bottom',
		'hard-close',
		'no-scroll'
	],
	computed: {
		...Store.mapState(['workersMap', 'geoMap', 'groups', 'permissions', 'resellerInfo', 'offices']),
		canAccessPasswords() {
			if (this.checkPermission('passwords:access')) return true;
			if (!this.employee || !this.employee.roleId) return false;
			if (this.employee.roleId === 3 && this.checkPermission('access:manager_passwords')) return true;
			return false;
		},
		style() {
			let style = {};
			if (this.width) {
				style.width = this.width + 'px';
			}
			return style;
		},
		contentStyle() {
			let style = {};
			if (this.contentMinHeight) {
				style.minHeight = this.contentMinHeight + 'px';
			}
			if (this.contentMaxHeight) {
				style.maxHeight = this.contentMaxHeight + 'px';
			}
			return style;
		},
		id() {
			let idw;
			if (this.idw) {
				idw = this.idw;
			} else if (this.idm) {
				for (let i in this.workersMap) {
					if (!this.workersMap.hasOwnProperty(i)) continue;

					let worker = this.workersMap[i];
					if (this.idm === worker.idm) {
						idw = worker.id;
						break;
					}
				}
			}
			if (!idw) return null;
			return idw;
		},
		categorySuggestion() {
			let temp = [];
			for (let k in this.categotyMap) {
				temp.push({
					key: k,
					val: k,
					displayVal: k
				});
			}
			return temp;
		}
	},
	created() {
		if (this.scrollToBottom == 1) {
			this.$nextTick(() => {
				this.$refs.content.scrollTop = this.$refs.content.scrollHeight;
			});
		}
	},
	methods: {
		getDate(str) {
			return moment(str).format('DD MMMM YYYY');
		},
		async getWorker() {
			let res = await this.get('Worker.getWorkerDataById', this.id, {withPassword: true});
			if (res) {
				this.$set(this, 'employee', res);
				this.$set(this.employee, 'isSales', 0);
				this.$set(this.employee, 'public', this.employee.public < 1 ? 0 : 1);

				if (!this.employee.idm) return;

				let manager = await this.get('Manager.getManagerById', this.employee.idm);
				if (!manager) return;

				this.$set(this.employee, 'isSales', manager.st);
				this.$set(this.employee, 'idManager', this.employee.idm);
				this.$set(this.employee, 'emailManager', manager.emailSend);
				this.$set(this.employee, 'skypeManager', manager.skype);
				this.$set(this.employee, 'phoneManager', manager.phone);
				this.$set(this.employee, 'number3cx', manager['number_3cx']);
			}
		},
		async getRolesMap() {
			let res = await this.get('Worker.getRolesMap');
			if (res) {
				this.$set(this, 'rolesMap', res);
			}
		},
		/*async getPermissionsMap() {
			let res = await this.get('Worker.getPermissionsMap');
			if (res) {
				this.$set(this, 'permissionsMap', res);
			}
		},
		getPermissionsSuggestion() {
			return this.permissionsMap.map(e => {
				return {
					key: e,
					val: e,
					displayVal: e,
					sortVal: e,
				};
			});
		},
		async getWorkerPermissions(id) {
			let res = await this.get('Worker.getPermissions', id);
			if (res) {
				this.$set(this, 'workerPermissions', res.join(','));
			}
		},
		async setWorkerPermissions() {
			this.isLoading = true;
			await this.post('Worker.multiSetPermissions', this.id, this.workerPermissions);

			this.isLoading = false;
			this.saved = true;
			setTimeout(() => {
				this.saved = false;
			}, 1000);
			this.workerIdToCopyPermission = null;
		},*/
		async getGroupsMap() {
			let res = await this.get('Groups.getGroupsMap', this.id);
			if (res) this.$set(this, 'groupsMap', res);
		},
		getGroupsSuggestion() {
			let temp = [];
			for (let k in this.groupsMap) {
				temp.push({
					key: k,
					val: this.groupsMap[k],
					displayVal: this.groupsMap[k],
					sortVal: this.groupsMap[k],
				});
			}
			return temp;
		},
		async getWorkerGroups() {
			let res = await this.get('Groups.getWorkerGroupsIds', this.id);
			if (res) {
				this.$set(this.employee, 'groups', res.join(','));
			}
		},
		async setWorkerGroups(id) {
			return await this.post('Groups.multiSetGroups', this.employee.groups, id);
		},
		async setWorkerInfo() {
			this.employee.cat = this.categotyMap[this.employee.work] || '';
			return await this.post('Worker.setWorkerInf', this.employee);
		},
		async saveAll() {
			if (this.saved === true) return;
			if (!this.employee.email || (this.employee.isSales && !this.employee.emailManager)) {
				return this.alert('EMAIL_IS_EMPTY');
			}

			this.isLoading = true;

			let res = await this.setWorkerInfo();

			if (this.id) {
				await this.setWorkerGroups(this.id);
			}
			else {
				await this.setWorkerGroups(res);
				this.employee.idw = res;
				this.employee.id = res;
			}

			// if (this.checkPermission('*')) await setWorkerPermissions();

			this.isLoading = false;
			this.saved = true;
			setTimeout(() => {
				this.saved = false;
				this.employee.status = 1;
				this.$emit('saveworker', this.employee);
			}, 1000);
		},

		async uploadPhoto(file) {
			let form = new FormData;
			form.append('userpic', file);
			this.picData = form;

			this.uploadedPic = '';
			this.uploadedPic = await this.upload('Worker.uploadPhoto', this.picData);
			this.$set(this.employee, 'img', '/img/org/' + file.name);
		},
		async showUploadPhotoPopup() {
			let file = await this.input({type: 'file'});
			if (!file) return;

			this.uploadPhoto(file);
		},
	},
	async mounted() {
		this.isLoading = true;
		await this.loadState(['offices']);
		if (this.id) {
			this.getWorker().then(() => this.getRolesMap())
				//.then(() => this.getWorkerPermissions(this.id))
				//.then(() => this.getPermissionsMap())
				.then(() => this.getWorkerGroups())
				.then(() => this.getGroupsMap())
				.then(() => {
					if (this.employee.interest) {
						this.hobbies = this.employee.interest.split('|').map(e => e.trim());
					}
					this.isLoading = false;
					this.init = true;
				});
		}
		else {
			this.getRolesMap()
				//.then(() => this.getPermissionsMap())
				.then(() => this.getGroupsMap())
				.then(() => {
					this.$set(this, 'employee', {
						'active': 1,
						'dateReg': moment(new Date()).format('YYYY-MM-DD'),
						'email': '',
						'groups': '',
						'img': '',
						'interest': '',
						'nation': '',
						'office': this.reseller === 'semalt' ? 'kiev' : '',
						'name': '',
						'cat': '',
						'phone': '',
						'realName': '',
						'roleId': '',
						'skype': '',
						'work': '',
						'isSales': 0,
						'public': 0,
						'emailManager': '',
						'skypeManager': '',
						'phoneManager': '',
						'number3cx': 0
					});
					this.isLoading = false;
					this.init = true;
				});
		}
	},
	watch: {
		hobbies() {
			if (this.employee) {
				this.employee.interest = this.hobbies.join(' | ');
			}
		}
	}
};
