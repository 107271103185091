import Store from '../../store.js';

const template = `
	<div class="worker-profile-component">
		<div v-if="init" class="switch-ver-block">
			<span
				class="switch-version-btn"
				@click="switchVersion()"
			>{{ oldVersion ? ii('NEW') :  ii('OLD')}}</span>
		</div>
		<worker-card v-if="oldVersion" :idw="id"></worker-card>
		<div v-else class="worker-profile">
			<div class="loading-action" v-if="isLoading">
				<i class="fa fa-spinner spin-fast"></i>
				{{ ii('LOADING') }}...
			</div>
			<template v-if="init">
				<div class="subpanels-box">
					<a
						@click="setTab('worker')"
						:class="tab === 'worker' ? 'active' : ''"
					>{{ ii('WORKER') }}</a><a
						@click="setTab('manager')"
						:class="tab === 'manager' ? 'active' : ''"
					>{{ ii('MANAGER') }}</a>
				</div>
				<div class="content">
					<template v-if="tab === 'worker'">
						<div v-if="notExistingWorker" class="msg-block">
							{{ ii('THERE_IS_NO_SUCH_WORKER') }}
						</div>
						<template v-else>
							<div  class="content-box">
									<div class="content-division">
										<div class="group-label prim-color"><i class="fa fa-phone"></i> {{ ii('CONTACTS') }}</div>
										<div>
											<p class="item-label">{{ ii('E_MAIL') }}:</p>
											<input type="email" placeholder="example@semalt.com" v-model.trim="workerInfo.email"
												v-valid:email="{
													style: 'min-width: 200px;'
												}">
										</div>
										<div>
											<p class="item-label">{{ ii('SKYPE') }}:</p>
											<input v-model.trim="workerInfo.skype" placeholder="example" type="text">
										</div>

										<div>
											<p class="item-label">{{ ii('PHONE') }}:</p>
											<input type="tel" placeholder="+380-12-345-67-89" v-model.trim="workerInfo.phone"
												v-valid:tel="{
													style: 'min-width: 200px;'
												}">
										</div>
									</div>
									<div class="content-division">
										<div class="group-label prim-color"><i class="fa fa-user-plus"></i> {{ ii('POSITION_INFO') }}</div>
										<div v-if="reseller === 'semalt'">
											<p class="item-label">{{ ii('OFFICE') }}:</p>
											<rich-sel
												:items="getOfficesSuggestions({withOther: true})"
												v-model="workerInfo.office"
											></rich-sel>
										</div>
										<div>
											<p class="item-label">{{ ii('WORKER_POSITION') }}:</p>
											<rich-sel :items="categorySuggestion" v-model="workerInfo.work"></rich-sel>
										</div>
										<div>
											<p class="item-label">{{ ii('ROLE') }}:</p>
											<select v-model="workerInfo.roleId">
												<option  v-for="(val, key) in rolesMap" :value="key">{{ val }}</option>
											</select>
										</div>
										<div>
											<p class="item-label">{{ ii('GROUPS') }}:</p>
											<multi-sel
												:items="groupsSuggestion"
												v-model="workerInfo.groups"
											></multi-sel>
										</div>
										<div>
											<p class="item-label">{{ ii('START_DATE') }}:</p>
											<date-picker v-model="workerInfo.dateReg"></date-picker>
										</div>
									</div>
								</div>
							<div class="content-box">
									<div class="content-division">
										<div class="group-label prim-color"><i class="fa fa-user"></i> {{ ii('PERSONAL_INFO') }}</div>
										<div>
											<p class="item-label">{{ ii('REAL_NAME') }}:</p>
											<input placeholder="Test User" type="text" v-model.trim="workerInfo.realName">
										</div>
										<div>
											<p class="item-label">{{ ii('PSEUDONYM') }}:</p>
											<input placeholder="Test pseudonym" type="text" v-model.trim="workerInfo.name">
										</div>
										<div>
											<p class="item-label">{{ ii('NATION') }}:</p>
											<rich-sel
												:items="getGeoSuggestions()"
												v-model="workerInfo.nation"
											></rich-sel>
										</div>
										<div>
											<p class="item-label">{{ ii('LANGUAGES') }}:</p>
											<multi-sel
												:items="getLangItems()"
												v-model="workerInfo.langs"
											></multi-sel>
										</div>

									</div>
									<div v-if="reseller === 'semalt'" class="content-division">
										<div class="group-label prim-color"><i class="fa fa-bicycle"></i>Worker hobbies:</div>
										<div>
											<p class="item-label">{{ ii('HOBBY') }}:</p>
											<input placeholder="football" type="text" v-model.trim="hobbies[0]">
										</div>
										<div>
											<p class="item-label">{{ ii('HOBBY') }}:</p>
											<input placeholder="pocker" type="text" v-model.trim="hobbies[1]">
										</div>
										<div>
											<p class="item-label">{{ ii('HOBBY') }}:</p>
											<input placeholder="family" type="text" v-model.trim="hobbies[2]">
										</div>
									</div>
								</div>
							<div class="content-box">
									<div class="content-division">
										<div class="group-label prim-color"><i class="fa fa-lock"></i>{{ ii('PASSWORDS') }}:</div>
										<div>
											<p class="item-label">{{ ii('PASSWORD') }}:</p>
											<input type="text" v-model.trim="workerInfo.password" :disabled="!canAccessPasswords">
										</div>
									</div>
								</div>
							<div class="content-box">
									<div class="user-pic">
										<div class="user-pick-editor">
											<img v-if="photoSrc.worker" :src="photoSrc.worker" class="profilePhoto">
											<div
												class="pick-editor-btn"
												@click="showUploadPhotoPopup">
												{{ ii('CHANGE') }}
											</div>
										</div>
										<br>
										<div>
											<strong>{{ ii('WORKER_ID') }}: {{ workerInfo.id }}</strong>
											<template v-if="workerInfo.idm"> |
												<strong>{{ ii('SALES_ID') }}: {{ workerInfo.idm }}</strong>
											</template>
										</div>
										<br>
										<trigger
											v-model="workerInfo.active"
											:size="'trigger-small'"
											:on="'Active'"
											:off="'Dismissed'"
											:true-value="1"
											:false-value="0"
										></trigger>
										<br>
										<trigger
											v-model="workerInfo.public"
											:size="'trigger-small'"
											:on="'Public'"
											:off="'Not public'"
											:true-value="1"
											:false-value="0"
										></trigger>
									</div>
									<btn
										v-if="id"
										@click.native="updateWorkerInfo"
										:type="'primary'"
										:size="'normal'"
										class="save-btn"
									>
										<i class="fa fa-save"></i>
										<span>{{ ii('SAVE_CHANGES') }}</span>
									</btn>
									<btn
										v-else
										@click.native="createWorkerAcc"
										:type="'primary'"
										:size="'normal'"
										class="save-btn"
									>
										<i class="fa fa-save"></i>
										<span>{{  ii('CREATE_WORKER') }}</span>
									</btn>
								</div>
						</template>
					</template>
					<template v-if="tab === 'manager'">
						<div v-if="!id" class="msg-block">
							{{ ii('WORKER_ACCOUNT_MUST_BE_CREATED_FIRST') }}
						</div>
						<div v-else-if="id && !workerInfo.idm" class="msg-block">
							{{ this.ii('THERE_IS_NO_MANAGER_ACCOUNT_YET') }}.
							<span @click="createManagerAcc" class="create-manager">
								{{ ii('CREATE') }}
							</span>
						</div>
						<template v-else-if="id && workerInfo.idm" class="content">
							<div class="content-box">
								<div class="content-division">
									<div class="group-label prim-color"><i class="fa fa-phone"></i> {{ ii('CONTACTS') }}</div>
									<div>
										<p class="item-label">
											{{ ii('E_MAIL_FOR_CLIENTS') }}:
											<i
												class="fa fa-repeat pointer"
												v-title="ii('COPY_FROM_WORKER_INFO')"
												@click="repeatField('emailSend')"
											></i>
										</p>
										<input type="email" placeholder="example@semalt.com" v-model.trim="managerInfo.emailSend"
											v-valid:email="{
												style: 'min-width: 200px;'
											}">

									</div>
									<div>
										<p class="item-label">
											{{ ii('SKYPE') }}:
											<i
												class="fa fa-repeat pointer"
												v-title="ii('COPY_FROM_WORKER_INFO')"
												@click="repeatField('skype')"
											></i>
										</p>
										<input v-model.trim="managerInfo.skype" placeholder="example" type="text">
									</div>
									<div>
										<p class="item-label">
											{{ ii('PHONE') }}:
											<i
												class="fa fa-repeat pointer"
												v-title="ii('COPY_FROM_WORKER_INFO')"
												@click="repeatField('phone')"
											></i>
										</p>
										<input type="tel" placeholder="+380-12-345-67-89" v-model.trim="managerInfo.phone"
											v-valid:tel="{
												style: 'min-width: 200px;'
											}">
									</div>
									<div>
										<p class="item-label">{{ ii('3CX') }}:</p>
										<input type="number" v-model="managerInfo.number_3cx">
									</div>
								</div>
							</div>
							<div class="content-box">
								<div class="content-division">
									<div class="group-label prim-color"><i class="fa fa-user"></i> {{ ii('PERSONAL_INFO') }}</div>
									<div>
										<p class="item-label">
											{{ ii('REAL_NAME') }}:
											<i
												class="fa fa-repeat pointer"
												v-title="ii('COPY_FROM_WORKER_INFO')"
												@click="repeatField('realName')"
											></i>
										</p>
										<input placeholder="Test User" type="text" v-model.trim="managerInfo.realName">
									</div>
									<div>
										<p class="item-label">
											{{ ii('PSEUDONYM') }}:
											<i
												class="fa fa-repeat pointer"
												v-title="ii('COPY_FROM_WORKER_INFO')"
												@click="repeatField('emailName')"
											></i>
										</p>
										<input placeholder="Test pseudonym" type="text" v-model.trim="managerInfo.emailName" >
									</div>
									<div>
										<p class="item-label">{{ ii('SALES_ID') }}:</p>
										<input type="number" v-model="managerInfo.id">
									</div>
								</div>
							</div>
							<div class="content-box">
								<div class="content-division">
									<div class="group-label prim-color"><i class="fa fa-lock"></i>{{ ii('PASSWORDS') }}:</div>
									<div>
										<p class="item-label">{{ ii('TASKS_PASSWORD') }}:</p>
										<input type="text" v-model="managerInfo.tasksPassword" :disabled="!canAccessPasswords">
									</div>
									<div>
										<p class="item-label">{{ ii('E_MAIL') }} {{ ii('PASSWORD') }}:</p>
										<input type="text" v-model="managerInfo.emailPassword" :disabled="!canAccessPasswords">
									</div>
									<div>
										<p class="item-label">{{ ii('SKYPE') }} {{ ii('PASSWORD') }}:</p>
										<input type="text" v-model="managerInfo.skypePassword" :disabled="!canAccessPasswords">
									</div>
								</div>
							</div>
							<div class="content-box">
								<div class="user-pic">
									<div class="user-pick-editor">
										<img v-if="photoSrc.manager" :src="photoSrc.manager" class="profilePhoto">
										<div
											class="pick-editor-btn"
											@click="showUploadPhotoPopup">
											{{ ii('CHANGE') }}
										</div>
									</div>
									<br>
									<div>
										<strong>{{ ii('WORKER_ID') }}: {{ workerInfo.id }}</strong>
										<template v-if="managerInfo.id"> |
											<strong>{{ ii('SALES_ID') }}: {{ managerInfo.id }}</strong>
										</template>
									</div>
									<br>
									<trigger
										v-model="managerInfo.st"
										:size="'trigger-small'"
										:on="'Active'"
										:off="'Dismissed'"
										:true-value="1"
										:false-value="0"
									></trigger>
								</div>

								<btn
									@click.native="updateManagerInfo"
									:type="'primary'"
									:size="'normal'"
									class="save-btn"
								>
									<i class="fa fa-save"></i>
									<span>{{ ii('SAVE_CHANGES') }}</span>
								</btn>
							</div>
						</template>
					</template>
				</div>
				<transition name="fade">
						<div class="saved-message" v-if="saved">
							<i class="fa fa-save"></i>
							{{ savedMsg }}
						</div>
					</transition>
			</template>
		</div>
	</div>

`;

export default {
	template: template,
	data() {
		return({
			oldVersion: false,
			init: false,
			isLoading: false,
			saved: false,
			notExistingWorker: null,
			tab: null,
			id: null,
			hobbies: [],
			photo: null,
			workerInfo: {},
			managerInfo: {},
			rolesMap: null,
			groupsMap: null,
			photoSrc: {
				worker: null,
				manager:null
			},
			categotyMap: {
				'Artist': 'vid',
				'Business Development Manager': 'sal',
				'CEO & Founder': 'biz',
				'Co-Founder': 'biz',
				'Content management / QA': 'seo',
				'Content Strategist': 'mar',
				'Copywriter': 'mar',
				'Customer Success Manager': 'sal',
				'Customer Support Manager': 'sal',
				'Designer': 'mar',
				'E-mail Marketing Manager': 'mar',
				'Head of Customer Success Department': 'biz',
				'Head of Marketing Department': 'mar',
				'Head of SEO Department': 'seo',
				'Head of Video Production Department': 'vid',
				'Head of WebDev': 'biz',
				'Human Resources Manager': 'hum',
				'Junior Web Developer': 'dev',
				'Marketing Analyst': 'mar',
				'Marketing Manager': 'mar',
				'Motion Designer': 'vid',
				'PM': 'dev',
				'PR Manager': 'mar',
				'Product Manager': 'mar',
				'Programmer Analyst': 'de',
				'Project Manager': 'biz',
				'Recruiting Interviewer': 'hum',
				'Sales Manager': 'sal',
				'Senior Customer Success Manager': 'sal',
				'Senior Sales Manager': 'sal',
				'Senior SEO Specialist': 'seo',
				'Senior Web Developer': 'dev',
				'SEO Specialist': 'seo',
				'SMM manager': 'mar',
				'Tech Lead': 'dev',
				'Translator': 'mar',
				'Web Analyst': 'mar',
				'Web Designer': 'mar',
				'Web Developer': 'dev',
				'Web/UI Designer': 'mar',
			},
			fieldsListForRepeat: {
				'emailSend': 'email',
				'skype': 'skype',
				'phone': 'phone',
				'realName': 'realName',
				'emailName': 'name'
			},
			availableFormatsPhoto: ['png', 'jpg', 'jpeg', 'webp']


		});
	},
	props: [
		'idw',
		'idm'
	],
	computed: {
		...Store.mapState(['workersMap', 'geoMap', 'groups', 'permissions', 'resellerInfo', 'offices']),
		canAccessPasswords() {
			if (this.checkPermission('passwords:access')) return true;
			if (!this.workerInfo || !this.workerInfo.roleId) return false;
			if (this.workerInfo.roleId === 3 && this.checkPermission('access:manager_passwords')) return true;
			return false;
		},
		categorySuggestion() {
			let temp = [];
			for (let k in this.categotyMap)
				temp.push({key: k, val: k, displayVal: k});
			return temp;
		},
		groupsSuggestion() {
			let temp = [];
			for (let k in this.groupsMap)
				temp.push({key: k, val: this.groupsMap[k], displayVal: this.groupsMap[k], sortVal: this.groupsMap[k]});
			return temp;
		},
	},
	methods: {
		idsHandling() {
			if(this.idw)
				this.id = this.idw;
			else if(this.idm){
				for(let key in this.workersMap){
					let worker = this.workersMap[key];
					if(this.idm === worker.idm){
						this.id = worker.id;
						break;
					}
				}
			}
		},
		async updateWorkerInfo(){
			if(!this.checkingFields()) return;

			this.prepareWorkerData();

			this.isLoading = true;
			try {
				if(this.photo) await this.changePhoto();
				this.id = await this.post('Worker.updateWorkerInfo', this.workerInfo);
			} catch (e) {
				this.alert(this.ii(e.msg));
			}
			this.isLoading = false;
			this.showSavedNotification('SAVED');
		},
		async createWorkerAcc(){
			if(!this.checkingFields()) return;

			this.prepareWorkerData();

			this.isLoading = true;
			try {
				this.id = await this.post('Worker.createWorkerAcc', this.workerInfo);
				if(this.photo) await this.changePhoto();
				await this.loadWorkerInfo();
			} catch (e) {
				this.alert(this.ii(e.msg));
			}
			this.isLoading = false;
			this.showSavedNotification('CREATED');
		},
		async loadWorkerInfo(){
			if(!this.id) return;

			let workerInfo = await this.get('Worker.getWorkerDataById', this.id, {withPassword: true});

			if(!workerInfo){
				this.id = null;
				this.notExistingWorker = true;
				return;
			}

			let additionalInfo = await this.getWorkerGroupsAndLangs();

			this.workerInfo = workerInfo;
			this.workerInfo.groups = additionalInfo.groups;
			this.workerInfo.langs = additionalInfo.langs;
			if(this.workerInfo.interest)
				this.hobbies = this.workerInfo.interest.split('|').map(e => e.trim());
			if(this.workerInfo.img)
				this.photoSrc.worker = 'https://' + this.resellerInfo.semaltDomain + this.workerInfo.img;
		},
		async updateManagerInfo(){
			this.managerInfo.idw = this.id;

			this.isLoading = true;
			try {
				if(this.photo) await this.changePhoto();
				await this.post('Manager.updateManagerInfo', this.managerInfo);
			} catch (e) {
				this.alert(this.ii(e.msg));
			}
			this.isLoading = false;
			this.showSavedNotification('SAVED');
		},
		async loadManagerInfo(){
			if(!this.workerInfo.idm) return;

			this.isLoading = true;
			this.managerInfo = await this.get('Manager.getManagerById', this.workerInfo.idm, {withPassword: true});
			if(this.managerInfo.img)
				this.photoSrc.manager = 'https://' + this.resellerInfo.semaltDomain + this.managerInfo.img;
			this.isLoading = false;
		},
		async createManagerAcc(){
			if(!this.checkingFields()) return;

			this.isLoading = true;
			try {
				this.workerInfo.idm = await this.post('Manager.createManagerAcc', this.workerInfo);
				await this.loadManagerInfo();
			} catch (e) {
				this.alert(this.ii(e.msg));
			}
			this.isLoading = false;
			this.showSavedNotification('CREATED');
		},
		async changePhoto(){
			if(!this.photo) return;

			let form = new FormData;
			form.append('userpic', this.photo);
			form.append('idw', this.id);

			try {
				let src = await this.upload('Worker.uploadPhotoForProfile', form);
				let fullSrc = 'https://' + this.resellerInfo.semaltDomain + src;
				this.photoSrc.worker = fullSrc;
				this.photoSrc.manager = fullSrc;
			} catch (e) {
				this.alert(this.ii(e.msg));
			}
			this.photo = null;
		},
		async getWorkerGroupsAndLangs() {
			let info = await this.get('Worker.getWorkerGroupsAndLangs', this.id);
			info.langs = info.langs.join(',');
			info.groups = info.groups.join(',');
			return info;
		},
		async getRolesMap() {
			this.rolesMap = await this.get('Worker.getRolesMap');
		},
		async getGroupsMap() {
			this.groupsMap = await this.get('Groups.getGroupsMap', this.id);
		},
		async showUploadPhotoPopup() {
			this.photo = await this.input({type: 'file'});
			if (!this.photo) return;

			let photoName = this.photo.name;
			let dotPos = photoName.lastIndexOf('.');
			let format =  photoName.slice(dotPos + 1);

			if(!this.availableFormatsPhoto.includes(format)){
				this.alert(this.ii('WRONG_IMAGE_FORMAT'));
				this.photo = null;
				return;
			}

			let reader = new FileReader();
			reader.onload = () => {
				let src = reader.result;
				this.photoSrc.worker = src;
				this.photoSrc.manager = src;
			};
			reader.readAsDataURL(this.photo);
		},
		showSavedNotification(savedMsg){
			this.savedMsg = this.ii(savedMsg);
			this.saved = true;
			setTimeout(() => this.saved = false, 1000);
		},
		repeatField(managerField){
			let workerField = this.fieldsListForRepeat[managerField];
			this.managerInfo[managerField] = this.workerInfo[workerField];
		},
		checkingFields(){
			let errors = [];

			if (!this.workerInfo.realName) errors.push(this.ii('REAL_NAME'));
			if (!this.workerInfo.name) errors.push(this.ii('PSEUDONYM'));
			if (!this.workerInfo.email) errors.push(this.ii('E_MAIL'));
			if (!this.workerInfo.password) errors.push(this.ii('PASSWORD'));
			if (errors.length) {
				let msg = `${this.ii('MISSING_REQUIRED_FIELDS')}: ${errors.join(', ')}`;
				this.alert(msg);
				return false;
			}

			return true;
		},
		prepareWorkerData(){
			this.workerInfo.cat = this.categotyMap[this.workerInfo.work] || '';
			this.workerInfo.interest = this.hobbies.join(' | ');
		},
		async setTab(tab){
			switch(tab){
				case 'worker':
					if(this.id && !this.workerInfo.id) await this.loadWorkerInfo();
					break;
				case 'manager':
					if(this.workerInfo.idm && !this.managerInfo.id) await this.loadManagerInfo();
					break;
			}
			this.tab = tab;
		},
		switchVersion(){
			this.oldVersion = !this.oldVersion;
		}

	},
	async mounted() {
		this.isLoading = true;

		this.idsHandling();
		await this.getRolesMap();
		await this.getGroupsMap();
		await this.setTab('worker');
		await this.loadState(['offices']);

		this.isLoading = false;
		this.init = true;
	}
};
