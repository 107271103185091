const template = `
	<button
		:type="submit != null ? 'submit' : 'button'"
		:class="getClasses"
		:disabled="disabled || loading"
		v-title="title"
		@click="onClick"
	>
		<span v-if="!loading">
			<slot name="icon"></slot>
			<i v-if="icon" class="fa fa-fw" :class="'fa-' + icon"></i>
		</span>
		<span><i v-if="loading" class="fa fa-fw fa-spinner spin-fast"></i></span>
		<slot></slot>
		<span v-if="filesCnt">({{ filesCnt }})</span>
		<input
			v-if="type === 'file' && fileAvailable"
			ref="file"
			type="file"
			:multiple="multiple"
			@dragenter="isDragOver = true"
			@dragleave="isDragOver = false"
			@drop="isDragOver = false"
			@change="onFileChanged"
		>
		<div class="btn-drop-layer"></div>
	</button>
`;

export default {
	template: template,
	props: ['type', 'color', 'size', 'hidden', 'disabled', 'loading', 'submit', 'multiple', 'noFileInfo', 'icon'],
	data() {
		return {
			title: null,
			filesCnt: 0,
			fileAvailable: true,
			isDragOver: false
		};
	},
	computed: {
		getClasses() {
			let COLORS = ['primary', 'cancel', 'orange', 'red', 'transparent', 'soft-red', 'green'];
			let SIZES = ['normal', 'small'];

			let color = COLORS.indexOf(this.color) !== -1 ? this.color : COLORS[0];
			let size = SIZES.indexOf(this.size) !== -1 ? this.size : SIZES[0];

			let classes = ['btn', 'btn-' + color, 'btn-' + size, 'nowrap'];
			if (this.hidden) {
				classes.push('btn-hidden');
			}

			if (this.isDragOver) {
				classes.push('btn-dragover');
			}

			return classes.join(' ');
		}
	},
	methods: {
		onClick(e) {
			this.$emit('click', e);
		},
		onFileChanged(e) {
			if (!e.target.files || !e.target.files.length) {
				this.title = null;
				this.filesCnt = 0;
			} else if (!this.noFileInfo) {
				this.title = [...e.target.files].map(file => file.name).join('\n');
				this.filesCnt = e.target.files.length;
			}

			this.$emit('change', e);
		},
		resetFile() {
			this.title = null;
			this.filesCnt = 0;

			this.fileAvailable = false;
			this.$nextTick(() => {
				this.fileAvailable = true;
			});
		}
	}
};
