const template = `
	<div class="controls-box-wr" :style="style" :class="[{'filters-fixed': fixed, 'active': filtersActive}]">
		<div class="controls-filters" :style="fixed ? 'height: ' + (win.height - 80) + 'px' : ''">
			<div class="controls-box row">
				<div v-for="(filter, i) in visibleFilters" class="control-filter col" :class="getItemClass(filter)">
					<label v-if="filter.type !== 'checkbox' && filter.type !== 'button'">
						{{ shouldTranslate ? ii(filter.label) : filter.label }}:
						<a
							v-if="filter.extLink"
							class="fa fa-external-link static-color"
							:href="filter.extLink"
							target="_blank"
							rel="noreferrer noopener"
						></a>
						<span v-if="filter.help" class="hint" v-title="filter.help">[?]</span>
						<span v-if="filter.additionalFilters" class="control-add-chb">
							<template v-for="addFilter in filter.additionalFilters">
								<input
									v-if="addFilter.queryNot"
									type="checkbox"
									:true-value="getChbTrueValue(addFilter)"
									:false-value="getChbFalseValue(addFilter)"
									:disabled="addFilter.disabled"
									@change="onFilterChanged($event, addFilter)"
									:checked="$parent[addFilter.key]"
								>
								<input
									v-else
									type="checkbox"
									v-model="app.query[addFilter.key]"
									:true-value="getChbTrueValue(addFilter)"
									:false-value="getChbFalseValue(addFilter)"
									:disabled="addFilter.disabled"
									@change="onFilterChanged($event, addFilter)"
								>
								<em v-title="addFilter.title">
									<label class="lower">
										{{ shouldTranslate ? ii(addFilter.label) : addFilter.label }}
									</label>
								</em>
							</template>
						</span>
					</label>
					<multi-sel
						v-if="filter.type === 'multisel'"
						v-model.lazy="app.query[filter.key]"
						:items="filter.items"
						:presets="filter.presets"
						:with-not="filter.withNot != null ? filter.withNot : 1"
						@change="onFilterChanged($event, filter)"
						:disabled="filter.disabled"
					></multi-sel>
					<rich-sel
						v-else-if="filter.type === 'richsel'"
						v-model.lazy="app.query[filter.key]"
						:items="filter.items"
						:items-url="filter.itemsUrl"
						:no-filter="filter.noFilter"
						:search-placeholder="filter.searchPlaceholder"
						:no-empty="filter.noEmpty"
						:default-text="filter.defaultText"
						@change="onFilterChanged($event, filter)"
						:disabled="filter.disabled"
					></rich-sel>
					<select
						v-else-if="filter.type === 'select'"
						v-model.lazy="app.query[filter.key]"
						:disabled="filter.disabled"
						@change="onFilterChanged($event, filter)"
					>
						<option v-if="!filter.noEmpty" :value="void 0">{{ filter.emptyVal || '' }}</option>
						<option
							v-for="item in filter.items"
							:value="item.key"
							:disabled="item.disabled"
						>{{ item.val || item.text || item.title }}</option>
					</select>
					<date-picker
						v-else-if="filter.type === 'date'"
						:type="filter.dateType"
						:with-time="filter.withTime"
						:min-val="filter.minVal"
						:max-val="filter.maxVal"
						:empty-val="filter.emptyVal"
						:empty-text="filter.emptyText"
						v-model="app.query[filter.key]"
						@change="onFilterChanged($event, filter)"
					></date-picker>
					<date-list-picker
						v-else-if="filter.type === 'dateList'"
						:dates-count="filter.datesCount"
						:type="filter.dateType"
						:with-time="filter.withTime"
						v-model="app.query[filter.key]"
						@change="onFilterChanged($event, filter)"
					></date-list-picker>
					<range-date-picker
						v-else-if="filter.type === 'daterange'"
						v-model="app.query[filter.key]"
						@change="onFilterChanged($event, filter)"
						:with-time="filter.withTime"
						:empty-text="filter.emptyText"
						:disabled="filter.disabled"
					></range-date-picker>
					<label v-else-if="filter.type === 'checkbox'" class="nowrap chb">
						<input
							v-if="filter.queryNot"
							type="checkbox"
							:true-value="getChbTrueValue(filter)"
							:false-value="getChbFalseValue(filter)"
							:disabled="filter.disabled"
							@change="onFilterChanged($event, filter)"
							:checked="$parent[filter.key]"
						>
						<input
							v-else
							type="checkbox"
							v-model="app.query[filter.key]"
							:true-value="getChbTrueValue(filter)"
							:false-value="getChbFalseValue(filter)"
							:disabled="filter.disabled"
							@change="onFilterChanged($event, filter)"
						>
						<span>{{ shouldTranslate ? ii(filter.label) : filter.label }}</span>
						<span v-if="filter.help" class="hint" v-title="filter.help">[?]</span>
					</label>
					<btn
						v-else-if="filter.type === 'button'"
						size="small"
						:title="filter.title"
						:icon="filter.icon"
						:loading="filter.loading"
						:disabled="filter.disabled"
						@click.native="filter.onClick"
					>
						<span>{{ filter.label }}</span>
					</btn>
					<input
						v-else-if="filter.type === 'number'"
						type="number"
						:placeholder="filter.placeholder"
						v-model.lazy="app.query[filter.key]"
						@change="onFilterChanged($event, filter)"
						:disabled="filter.disabled"
					>
					<suggestion-field
						v-else-if="filter.type === 'suggestionField'"
						:items="filter.items"
						:items-url="filter.itemsUrl"
						:items-method="filter.itemsMethod"
						:max-items="filter.maxItems"
						:placeholder="filter.placeholder"
						v-model="app.query[filter.key]"
						@change="onFilterChanged($event, filter)"
					></suggestion-field>
					<input
						v-else-if="filter.type === 'text-realtime'"
						type="text"
						:placeholder="filter.placeholder"
						v-model="app.query[filter.key]"
						@change="onFilterChanged($event, filter)"
						:disabled="filter.disabled"
					>
					<div v-else-if="filter.type === 'range'">
						<input
							type="number"
							class="left"
							style="width: 45%"
							:placeholder="filter.placeholderFrom || ii('FROM')"
							v-model.lazy="app.query[filter.keyFrom]"
							@change="onFilterChanged($event, filter)"
							:disabled="filter.disabled"
						>
						<input
							type="number"
							class="right"
							style="width: 45%"
							:placeholder="filter.placeholderTo || ii('TO')"
							v-model.lazy="app.query[filter.keyTo]"
							@change="onFilterChanged($event, filter)"
							:disabled="filter.disabled"
						>
					</div>
					<input
						v-else
						type="text"
						:placeholder="filter.placeholder"
						v-model.lazy="app.query[filter.key]"
						:disabled="filter.disabled"
						@change="onFilterChanged($event, filter)"
					>
				</div>
			</div>
			<div v-if="favFilters" class="fav-filters">
				<div class="fav-filters-inner">
					<span
						class="link fa"
						:class="curFavLabel ? 'fa-star' : 'fa-star-o'"
						v-title="curFavLabel ? ii('REMOVE_FROM_FAVORITES') : ii('ADD_TO_FAVORITES')"
						@click="toggleFavorite"
					></span>
					<drop-down v-model="favFilter" :items="favFilters" @change="onFavFilterChange">
						<span
							class="link fa fa-list"
							:class="!favFilters.length ? 'opacity02' : ''"
							v-title="ii('FAVORITES')"
						></span>
					</drop-down>
				</div>
			</div>
		</div>

		<a v-if="fixed" ref="tf" class="toggle-filters" @click="filtersActive = !filtersActive">
			<i class="fa fa-angle-double-left"></i>
			<span>{{ ii('COLLAPSE_FILTERS') }}</span>
		</a>
	</div>
`;

export default {
	template,
	props: [
		'fixed',
		'filters',
		'width',
		'itemSize',
		'favorites-key',
		'should-translate'
	],
	data() {
		return {
			filtersActive: true,
			filtersList: [],
			favFilter: null,
			favFilters: null
		};
	},
	computed: {
		queryJson() {
			return this.toJson(this.app.query);
		},
		curFavLabel() {
			if (!this.favFilters) return null;
			let filter = this.favFilters.find(f => f.key === this.queryJson);
			if (!filter) return null;
			return filter.val;
		},
		visibleFilters() {
			return this.filtersList.filter(f => f.visible == null || f.visible);
		},
		defaultWidth() {
			let inRow = Math.min(this.visibleFilters.length, 6);
			return inRow * 200;
		},
		style() {
			let width = this.width || this.defaultWidth;
			let w = isNaN(width) ? width : (width + 'px');
			let style = {maxWidth: w};

			if (this.fixed) style.height = this.win.height - 40 + 'px';

			return style;
		}
	},
	methods: {
		getItemClass(filter) {
			if (this.itemSize) return this.itemSize;

			let cnt = this.visibleFilters.length;
			if (filter.half) {
				if (cnt <= 1) return 'xs-12';
				if (cnt === 2) return 'xs-6';
				if (cnt === 3) return 'xs-6 sm-4';
				if (cnt === 4) return 'xs-6 sm-4 md-3';
				if (cnt === 5) return 'xs-16 sm-4 md-3 lg-2-4';
				return 'xs-6 sm-4 md-3 lg-2';
			}

			if (cnt <= 1) return 'xs-24';
			if (cnt === 2) return 'xs-12';
			if (cnt === 3) return 'xs-12 sm-8';
			if (cnt === 4) return 'xs-12 sm-8 md-6';
			if (cnt === 5) return 'xs-12 sm-8 md-6 lg-4-8';
			return 'xs-12 sm-8 md-6 lg-4';
		},
		getChbTrueValue(filter) {
			if (filter.trueValue === void 0) return 1;
			if (filter.trueValue === null) return void 0;
			return filter.trueValue;
		},
		getChbFalseValue(filter) {
			if (filter.falseValue === void 0 || filter.falseValue === null) return void 0;
			return filter.falseValue;
		},
		async updateFiltersList() {
			if (!this.filters) {
				this.filtersList = [];
			} else if (typeof this.filters.then === 'function') {
				this.filtersList = await this.filters;
			} else {
				this.filtersList = this.filters;
			}
		},
		async loadFavorites(force) {
			if (!this.favoritesKey) return;

			let key = this.favoritesKey;
			let cacheKey = 'fav_filter_' + key;
			let data = this.app.cache[cacheKey];
			if (!data || force) {
				this.$set(this.app.cache, cacheKey, 'LOADING');
				data = await this.get('Page.getFavoriteFilters', key);
				this.$set(this.app.cache, cacheKey, data);
			}

			this.favFilters = data;
		},
		async toggleFavorite() {
			let key = this.favoritesKey;
			if (this.curFavLabel) {
				let ok = await this.confirm(this.ii('REMOVE_FILTER_FROM_FAVORITES_QST'));
				if (!ok) return;

				await this.post('Page.removeFilterFromFavorites', key, this.curFavLabel);
			} else {
				let label = await this.prompt(this.ii('INPUT_FILTER_NAME'));
				if (!label) return;

				await this.post('Page.saveFilterToFavorites', key, label, this.queryJson);
			}

			this.loadFavorites(true);
		},
		onFavFilterChange() {
			let query = this.parseJson(this.favFilter);
			this.navigate({query});
		},
		onFilterChanged(e, filter) {
			if (typeof filter.onChange === 'function') {
				return filter.onChange(e);
			}

			this.onControlChanged(e);
			if (filter.queryNot) {
				this.$set(this.$parent, filter.key, e.target.checked);
			}
		}
	},
	watch: {
		filters() {
			this.updateFiltersList();
		},
		favoritesKey() {
			this.loadFavorites();
		}
	},
	mounted() {
		this.updateFiltersList();
		this.loadFavorites();
	}
};
