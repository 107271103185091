import {loadScript, loadStyle} from './methods/loaders.js';

let methods = {
	loadScript,
	loadStyle,

	numFormat(number, sep = ',', decimalsCnt = null) {
		let parts = String(number).split('.', 2);
		let text = parts[0].replace(/(\d)(?=(\d{3})+$)/g, '$1' + sep);
		if (parts[1]) {
			let decimals = parts[1];

			if (decimalsCnt !== null) {
				decimals = decimals.slice(0, decimalsCnt);
			}
			text += '.' + decimals;
		}
		return text;
	},
	getRandomString(len = 10) {
		let abc = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
		let str = '';
		for (let i = 0; i < len; i++) {
			let idx = Math.floor(Math.random() * abc.length);
			str += abc.charAt(idx);
		}
		return str;
	},
	shorten(str, maxLen, ending = '...') {
		str = String(str);
		if (str.length <= maxLen) return str;
		return str.slice(0, maxLen) + ending;
	},
	sleep(timeout) {
		return new Promise(resolve => {
			setTimeout(resolve, timeout);
		});
	},
	initComponents(components) {
		for (let tag in components) {
			if (!components.hasOwnProperty(tag)) continue;
			Vue.component(tag, components[tag]);
		}
	},
	setState(key, val) {
		this.$set(this.app.store, key, val);
	},
	loadState(keys) {
		let keysStr = keys.join(',');

		return axios('/api/panel?fields=' + keysStr).then(res => {
			keys.forEach(key => {
				let val = res.data[key];
				this.setState(key, val);
			});
			return res.data;
		});
	},
	updateDatetimeRefresher() {
		this.setState('datetimeRefresher', !this.app.store.datetimeRefresher);
	},
	tick() {
		return new Promise(resolve => {
			this.$nextTick(() => {
				resolve();
			});
		});
	},
	alert(msg, opts = {}) {
		this.$root.modalPopup.type = 'alert';
		this.$root.modalPopup.msg = msg;
		this.$root.modalPopup.opts = opts;

		let res;
		this.$root.onModalAction = e => res(e);

		return new Promise((resolve, reject) => {
			res = resolve;
		});
	},
	prompt(title, defaultVal = '', opts = {}) {
		this.$root.modalPopup.type = 'prompt';
		this.$root.modalPopup.val = defaultVal;
		this.$root.modalPopup.promptTitle = title;
		this.$root.modalPopup.opts = opts;

		let res;
		this.$root.onModalAction = e => res(e);

		return new Promise((resolve, reject) => {
			res = resolve;
		});
	},
	confirm(msg, opts = {}) {
		this.$root.modalPopup.type = 'confirm';
		this.$root.modalPopup.msg = msg;
		this.$root.modalPopup.opts = opts;

		let res;
		this.$root.onModalAction = e => res(e);

		return new Promise((resolve, reject) => {
			res = resolve;
		});
	},
	input(opts) {
		let val = opts.val;
		if (val === void 0) {
			val = opts.defaultVal;
		}
		if (opts.type === 'color' && val) {
			val = val.toLowerCase();
			if (val.length === 4) {
				val = val.replace(/([0-9a-f])([0-9a-f])([0-9a-f])/, '$1$1$2$2$3$3');
			}
		}
		return this.prompt(opts.title, val, opts);
	},
	inputFile(opts = {}) {
		opts.type = 'file';
		return this.prompt('', null, opts);
	},
	multiInput(opts) {
		this.$root.multiInputPopup = opts;

		let res;
		this.$root.onMultiInputComplete = e => {
			if (!e || !opts.beforeComplete || opts.beforeComplete(e)) {
				res(e);
			}
		};

		return new Promise((resolve, reject) => {
			res = resolve;
		});
	},
	showText(text, opts = {}) {
		this.$root.modalPopup.type = 'text';
		this.$root.modalPopup.text = text;

		if (!opts.customClass) {
			opts.customClass = 'modal-content-popup';
		}
		this.$root.modalPopup.opts = opts;

		let res;
		this.$root.onModalAction = e => res(e);

		return new Promise((resolve, reject) => {
			res = resolve;
		});
	},
	act(event, data) {
		if (!window.CleverJump) return;

		window.CleverJump.hit(event, data);
	},
	async chooseUserProject(idu, opts = {}) {
		let projects = await this.get('Site.getUserProjects', idu, null, opts.partner);
		opts.onLoadProjects && opts.onLoadProjects();

		let items = projects.map(pr => {
			return {
				key: pr.id,
				val: `#${pr.id} - ${pr.url} - google.${pr.google} (${pr.seLang})`
			};
		});
		let newProjectId = await this.input({
			type: 'richsel',
			customClass: 'richsel-picker-popup',
			val: opts.defaultProjectId,
			items
		});
		return newProjectId;
	},
	getCurrency(key) {
		let map = {
			'USD': {sign: '$', cc: 'US'},
			'EUR': {sign: '&#8364;', cc: 'EU'},
			'GBP': {sign: '&#163;', cc: 'GB'},
			'TRY': {sign: '&#8378;', cc: 'TR'},
			'UAH': {sign: '&#8372;', cc: 'UA'},
			'VND': {sign: '&#8363;', cc: 'VN'}
		};
		return map[key];
	},
	getPartnerCurrency(partner) {
		if (!partner) {
			partner = this.reseller;
		}
		switch (partner) {
			case 'semalt': return 'USD';
			case 'azaseo': return 'VND';
		}
		return 'USD';
	},
	getPartnerAllCurrencies(partner) {
		if (!partner) {
			partner = this.reseller;
		}
		switch (partner) {
			case 'semalt': return ['USD', 'UAH'];
			case 'azaseo': return ['USD', 'VND'];
		}
		return ['USD', 'EUR'];
	},
	getCountryCodeForLang(lang, def) {
		if (!this.app.langsInfo) return def;

		let filteredItems = this.app.langsInfo.filter(item => item.lang === lang).map(item => item.countryCode);
		return filteredItems[0] || def;
	},
	getCountryCodeForCurrency(cur, def = '') {
		switch (cur) {
			case 'USD': return 'US';
			case 'EUR': return 'EU';
			case 'GBP': return 'GB';
			case 'UAH': return 'UA';
			case 'RUB': return 'RU';
		}
		return def;
	},
	getLangDirection(lang = '') {
		let reversed = ['ar', 'he', 'ur'].indexOf(String(lang).toLowerCase()) !== -1;
		return reversed ? 'rtl' : 'ltr';
	},
	getGeoSalesPreset() {
		if (this.reseller !== 'semalt') return [];

		return [
			{key: 'AU,JP,NC,NZ,PG,VN,ID,MY,SG,CN,KR,HK,KP,PH,MY,TH,TW,KH', text: 'Group #1: Morning'},
			{key: 'AL,DZ,AD,AM,AT,AZ,BH,BD,BE,BZ,BJ,BT,BA,BW,BG,CM,CV,TD,CG,CD,HR,CI,CY,CZ,DK,DJ,EG,EE,FO,FR,GF,GA,GM,GE,DE,GH,GL,GR,GI,GN,GW,HU,IS,IN,IR,IQ,IE,IL,IT,KE,KI,KW,KG,LV,LB,LI,LT,LU,MK,MG,MW,MY,MV,ML,MT,MC,NL,OM,NO,PT,PL,PK,QA,RE,RO,RS,SK,SI,SG,ES,SD,SE,CH,TJ,TH,TR,AE,GB,UZ,NG,SA,LK,NP,MA,PS,SN,TN,YE,FI,ZA,SO,EU,RW', text: 'Group #2: Midday'},
			{key: 'US,CA,AS,AI,AG,AR,AW,BS,BB,BZ,BM,BR,BO,CL,KY,CO,CU,DM,DO,EC,CR,SV,FK,FJ,GP,GD,GT,HT,HN,JM,JE,MX,PA,PY,PE,PR,LC,MF,PM,VC,ST,TT,UM,VE,TC', text: 'Group #3: Evening'},
			{key: 'UA,RU,MD,BY', text: 'Group #4: Russian'},
			{key: 'BJ,BF,BI,CM,CF,TD,KM,CD,CG,DJ,DM,GQ,FR,GA,GN,HT,LU,MG,ML,MC,NE,RW,LC,SN,SC,TG,VU', text: 'Group #5: French'},
			{key: 'ES,AR,BO,CL,CO,CR,CU,DO,EC,GQ,SV,GT,HN,MX,NI,PA,PY,PR,UY,VE', text: 'Group #6: Spanish'},
			{key: 'BR,MZ,AO,PT,GW,TL,GQ,CV,ST', text: 'Group #7: Portuguese'}
		];
	},
	getRegionsPreset() {
		if (!this.regions) return [];

		return this.regions.map(r => {
			return {
				key: r.cc,
				text: this.ii(r.region)
			};
		});
	},
	getLocalizedMonths() {
		let months = [];
		for (let i = 0; i < 12; i++) {
			let month = this.capitalize(moment().startOf('year').add(i, 'month').format('MMMM'));
			months.push(month);
		}
		return months;
	},
	getLocalizedDows() {
		let dows = [];
		for (let i = 0; i < 7; i++) {
			let dow = this.capitalize(moment().startOf('isoWeek').add(i, 'day').format('dd'));
			dows.push(dow);
		}
		return dows;
	},
	getPaymentVariant(type, variant) {
		let res = '';
		if (!this.inArray(type, ['connectum', 'creditcard', 'alipay'])) {
			res += type;
		}
		if (variant) {
			res += ' ' + variant;
		}
		return res.trim();
	},
	async setLang(lang) {
		if (!this.$root.langTagMap[lang]) {
			await this.loadLangTexts([lang]);
		}
		this.$root.language = lang;
		moment.locale(this.getMomentLocalLang(lang));
		this.updateDatetimeRefresher();
		localStorage.setItem('lang', lang);
	},
	getMomentLocalLang(lang) {
		switch (lang) {
			case 'zh': return 'zh-CN';
		}
		return lang;
	},
	async loadLangTexts(langs = null, tags = null) {
		if (!langs) {
			langs = [this.$root.language];
		}
		if (langs.indexOf('en') === -1) {
			langs.push('en');
		}
		if (tags) {
			tags.forEach(tag => {
				if (this.$root.langTags.indexOf(tag) === -1) {
					this.$root.langTags.push(tag);
				}
			});
		}
		let tagsToLoad = this.$root.langTags.filter(tag => {
			let shouldLoad = false;
			for (let i = 0; i < langs.length; i++) {
				let lang = langs[i];
				let key = lang + '_' + tag;
				if (!this.$root.langTagMap[key]) {
					this.$root.langTagMap[key] = true;
					shouldLoad = true;
				}
			}
			return shouldLoad;
		});
		if (!tagsToLoad.length) return;

		langs = langs.filter(lang => lang && lang.length === 2);
		if (!langs.length) return;
		let res = await this.get('MultiLang.getTexts', langs, tagsToLoad, {flat: true});

		Object.keys(res).forEach(key => {
			this.$set(this.$root.i18n, key, res[key]);
		});
	},
	ii(origKey, def, lang = null) {
		if (!origKey) return def;
		if (this.isObjectEmpty(this.$root.i18n)) return def;

		let key = origKey.toUpperCase().replace(/[\s-]/g, '_').replace(/[()]/g, '');

		if (!lang) {
			lang = this.$root.language;
		}
		let txt = this.$root.i18n[lang + '_' + key];
		if (!txt) {
			if (def === void 0) {
				txt = this.$root.i18n['en_' + key];
			} else {
				txt = def;
			}
		}

		if (!txt) {
			//console.log('i18n missing:', key, this.$root.i18n);
			return def !== void 0 ? txt : origKey;
		}
		return txt;
	},
	iiArgs(key, ...params) {
		let text = this.ii(key);
		if (!text) return text;

		text = text.replace(/@(\d+)/g, (m, num) => {
			return params[num - 1];
		});
		return text;
	},
	showMailContent(mailId, header) {
		let url = '/frame/mail-content-url?mid=' + this.urlEnc(mailId);
		if (header == null) {
			header = mailId;
		}
		this.$root.framePopup = {url, header};
	},
	showMarketingMailContent(mailId, idu, header) {
		let url = '/frame/marketing-mail-content-url?mid=' + this.urlEnc(mailId) + '&idu=' + this.urlEnc(idu);
		if (header == null) {
			header = mailId;
		}
		this.$root.framePopup = {url, header};
	},
	showFramePopup(url, header) {
		this.$root.framePopup = {url, header};
	},
	showCodePopup(content, header) {
		this.$root.codePopup = {content, header};
	},
	camelizeObject(obj) {
		let keys = Object.keys(obj).filter(key => key.indexOf('_') !== -1);
		keys.forEach(key => {
			let newKey = key.replace(/_([a-z]?)/g, (all, p) => p.toUpperCase());
			let val = obj[key];
			obj[newKey] = val;
			delete obj[key];
		});
		return obj;
	},
	getSiteCategoryItems(opts = {}) {
		if (!this.siteCategories) return [];

		let items = [];
		this.siteCategories.forEach(cat => {
			cat.subcategories.forEach(subcat => {
				items.push({
					key: subcat.id,
					val: cat.title === subcat.title ? cat.title : cat.title + ' - ' + subcat.title
				});
			});
		});

		return items;
	},
	getSiteCategoryPreset() {
		if (!this.siteCategories) return [];

		let items = [];
		this.siteCategories.forEach(cat => {
			if (!cat.subcategories || !cat.subcategories.length) return;

			let subcategories = cat.subcategories.map(subcat => subcat.id);
			if (!subcategories.length) return;

			items.push({
				key: subcategories.join(','),
				text: 'Category: ' + cat.title
			});
		});

		return items;
	},
	getPaymentVariantItems() {
		let variants = ['visa', 'mastercard', 'paypal', 'amex', 'discover', 'jcb', 'unionpay'];
		let items = variants.map(v => {
			return {key: v, val: v};
		});
		return items;
	},
	copyText(text) {
		return new Promise(resolve => {
			let tf = document.createElement('textarea');
			tf.value = text;
			tf.style.position = 'fixed';
			tf.style.left = '-2000px';
			document.body.appendChild(tf);
			tf.focus();
			tf.select();
			document.execCommand('copy');
			resolve();
		});
	},
	removeFromArray(arr, val) {
		let idx = arr.indexOf(val);
		while (idx !== -1) {
			arr.splice(idx, 1);
			idx = arr.indexOf(val);
		}
		return arr;
	},
	compareDates(first, second = null) {
		let firstTs = moment(first).unix();
		let secondTs = moment(second || this.getCurDate()).unix();

		if (firstTs > secondTs) return 1;
		if (firstTs < secondTs) return -1;
		return 0;
	},
	parseJson(json, showError = false) {
		try {
			return JSON.parse(json);
		} catch (err) {
			if (showError) console.log(err);
			return null;
		}
	},
	toJson(data, opts = {}) {
		if (opts.pretty) return JSON.stringify(data, '', 4);
		return JSON.stringify(data);
	},
	beautifyJson(json) {
		return this.toJson(this.parseJson(json), {pretty: true});
	},
	inArray(item, array) {
		if (!this.isArray(array)) return false;
		return array.indexOf(item) !== -1;
	},
	sizeToInt(raw) {
		if (typeof raw !== 'string') return null;

		let m = raw.match(/^(\d+(?:\.\d+)?)([kmgt]?)(i?b)?$/i);
		if (!m) return null;

		let val = parseFloat(m[1]);
		if (isNaN(val)) return null;

		let unit = m[2];
		let multiplier = 1;
		switch (unit.toLowerCase()) {
			case 'k': multiplier = 1024; break;
			case 'm': multiplier = 1024 * 1024; break;
			case 'g': multiplier = 1024 * 1024 * 1024; break;
			case 't': multiplier = 1024 * 1024 * 1024 * 1024; break;
		}
		return Math.round(val * multiplier);
	},
	intToSize(num, isRound = true) {
		num = parseInt(num);
		if (isNaN(num)) return '';

		let arr = [];
		let units = ['b', 'k', 'm', 'g', 't'];

		for (let i = 0; i < units.length; i++) {
			let part = num % 1024;
			if (part || i > 0) {
				arr.unshift(part + units[i]);
			}

			if (num < 1024) break;

			num = Math.round(num / 1024);
		}
		if (isRound) return arr[0];
		return arr.join(' ');
	},
	round(val) {
		return Math.round(val);
	},
	checkIsoDatetime(raw) {
		return /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,})?Z$/.test(raw);
	},
	getHumanTime(sec) {
		if (!sec) return '';
		sec = Math.round(sec);

		let s = sec % 60;

		let min = Math.floor(sec / 60);
		let m = min % 60;

		let hr = Math.floor(min / 60);
		let h = hr % 24;
		let d = Math.floor(hr / 24);

		let str = s + 's';
		if (m) {
			str = m + 'm ' + str;
		}
		if (h) {
			str = h + 'h ' + str;
		}
		if (d) {
			str = d + 'd ' + str;
		}
		return str;
	},
	beautifyPhone(phone) {
		if (!phone) return '';
		return phone.replace(/[^\d]/g, '').replace(/(\d\d\d)(\d\d\d)(\d\d)(\d\d)$/, '($1) $2-$3-$4');
	},
	backButtonPush(fn) {
		let frame = document.querySelector('#history-frame');
		frame.contentWindow.postMessage({semaltPushState: true}, location.origin);
		this.$root.backButtonFns.push(fn);
	},
	backButtonPop() {
		let fns = this.$root.backButtonFns;
		fns[fns.length - 1] = null;

		history.back();
	},
	tokens(tokenName) {
		let tokens = this.$root.cancelTokens;
		if (!tokenName) return tokens;
		if (tokenName in tokens) {
			tokens[tokenName].cancel();
		}
		this.$set(tokens, tokenName, axios.CancelToken.source());
		return tokens[tokenName].token;
	},
	setPartnerMode(partner) {
		this.$root.partnerMode = partner;
	},
	getMethodUrl(classMethod, ...args) {
		let argsJson = JSON.stringify(args);
		let params = {argsJson};
		let url = '/api/call/' + classMethod + '?' + this.queryToString(params);
		return url;
	},
	logRequestTime(startTime, classMethod, argsJson, res) {
		if (!window.console || !console.debug) return;

		let end = Date.now();
		let fullTime = (end - startTime) / 1000;

		let serverTime = Math.round(parseFloat(res.headers['x-request-time']) * 1000) / 1000;
		let networkTime = Math.round((fullTime - serverTime) * 1000) / 1000;
		console.debug(classMethod, networkTime, serverTime, argsJson);
	},
	async getFull(classMethod, ...args) {
		let callback;
		let config = {method: 'GET'};
		let argsJson = '';
		if (typeof args[args.length - 1] === 'function') {
			callback = args.pop();
		}

		if(typeof classMethod === 'object'){
			argsJson = JSON.stringify(classMethod.params);
			config.url = '/api/call/' + classMethod.method;
			config.headers = classMethod.headers || {};
			if (classMethod.repo) {
				config.headers['X-Repo'] = classMethod.repo;
			}
		}
		else {
			argsJson = JSON.stringify(args);
			config.url = '/api/call/' + classMethod;
		}
		if (!config.headers) {
			config.headers = {};
		}
		config.headers['X-Page-ID'] = this.app.pageId;
		config.params = {argsJson};
		let start = Date.now();
		let res = await axios(config);
		//this.logRequestTime(start, classMethod, argsJson, res);
		callback && callback(res);

		return res;
	},
	async postFull(classMethod, ...args) {
		let config = {method: 'POST'};
		let argsJson = '';
		let url = '';
		if(typeof classMethod === 'object'){
			argsJson = JSON.stringify(classMethod.data);
			url = '/api/call/' + classMethod.method;
			config.headers = classMethod.headers || {};
			if (classMethod.repo) {
				config.headers['X-Repo'] = classMethod.repo;
			}
		}
		else {
			argsJson = JSON.stringify(args);
			url = '/api/call/' + classMethod;
		}
		if (!config.headers) {
			config.headers = {};
		}
		config.headers['X-Page-ID'] = this.app.pageId;
		let data = {argsJson};
		let start = Date.now();
		let res = await axios.post(url, data, config);
		//this.logRequestTime(start, classMethod, argsJson, res);
		return res;
	},
	async uploadFull(classMethod, form) {
		let url = '/api/call/' + classMethod;
		let res = await axios({
			method: 'POST',
			url,
			data: form,
			headers: {'Content-Type': 'multipart/form-data'}
		});

		return res;
	},
	async get(...allArgs) {
		let resource = await this.getFull.apply(this, allArgs);
		if (resource.data.err) {
			throw this.getRemoteCallError(resource);
		}
		return resource.data.res;
	},
	async post(...allArgs) {
		let resource = await this.postFull.apply(this, allArgs);
		if (resource.data.err) {
			throw this.getRemoteCallError(resource);
		}
		return resource.data.res;
	},
	async upload(...allArgs) {
		let resource = await this.uploadFull.apply(this, allArgs);
		return resource.data.res;
	},
	getRemoteCallError(res) {
		let err = {
			msg: res.data.err.msg,
			details: res.data.err.details,
			toString: () => res.data.err.msg
		};
		return err;
	},
	async extGet(classMethod, ...args) {
		let callback;
		if (typeof args[args.length - 1] === 'function') {
			callback = args.pop();
		}

		let argsJson = JSON.stringify(args);

		return new Promise(e => {
			let xhr = new XMLHttpRequest();
			let dom = document.cookie.includes('test=1234') ? 'test.supersemalt.com' : 'supersemalt.com';
			dom = document.location.href.includes('test=1234') ? 'test.supersemalt.com' : dom;
			if (/mail\.google\.com\/mail\/u\/\d+?\/#inbox\/[a-zA-Z]+/.test(location.href)) {
				dom = 'test.supersemalt.com'; // gmail doesnt allow user to put get-parameters into url
			}
			xhr.open('GET', 'https://' + dom + '/api/call/' + classMethod + '?argsJson=' + encodeURIComponent(argsJson), true);
			xhr.withCredentials = true;
			xhr.send();
			xhr.onerror = data => {
				e(null);
			};
			xhr.onload = data => {
				if (data.currentTarget.readyState == 4) {
					let res = JSON.parse(data.currentTarget.response).res;
					callback && callback(res);
					e(res);
				}
			};
		});
	},
	async extPost(classMethod, ...args) {
		let callback;
		if (typeof args[args.length - 1] === 'function') {
			callback = args.pop();
		}

		let argsJson = JSON.stringify(args);
		let params = {argsJson};
		let body = 'argsJson=' + encodeURIComponent(argsJson);

		return new Promise(e => {
			let xhr = new XMLHttpRequest();
			let dom = document.cookie.includes('test=1234') ? 'test.supersemalt.com' : 'supersemalt.com';
			dom = document.location.href.includes('test=1234') ? 'test.supersemalt.com' : dom;
			if (/mail\.google\.com\/mail\/u\/\d+?\/#inbox\/[a-zA-Z]+/.test(location.href)) {
				dom = 'test.supersemalt.com'; // gmail doesnt allow user to put get-parameters into url
			}
			xhr.open('POST', 'https://' + dom + '/api/call/' + classMethod, true);
			xhr.withCredentials = true;
			xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
			xhr.send(body);
			xhr.onerror = data => {
				e(null);
			};
			xhr.onload = data => {
				if (data.currentTarget.readyState == 4) {
					let res = JSON.parse(data.currentTarget.response).res;
					callback && callback(res);
					e(res);
				}
			};
		});
	},
	async extGetPresets(fields = []) {
		return new Promise(e => {
			let xhr = new XMLHttpRequest();
			let dom = document.cookie.includes('test=1234') ? 'test.supersemalt.com' : 'supersemalt.com';
			dom = document.location.href.includes('test=1234') ? 'test.supersemalt.com' : dom;
			if (/mail\.google\.com\/mail\/u\/\d+?\/#inbox\/[a-zA-Z]+/.test(location.href)) {
				dom = 'test.supersemalt.com'; // gmail doesnt allow user to put get-parameters into url
			}
			xhr.open('GET', 'https://' + dom + '/api/panel?fields=' + fields.join(','), true);
			xhr.withCredentials = true;
			xhr.send();
			xhr.onload = data => {
				e(JSON.parse(data.currentTarget.response));
			};
		});
	},
	downloadLegacy(classMethod, ...args) {
		let argsJson = JSON.stringify(args);
		let url = '/api/call/download/' + classMethod + '?argsJson=' + this.urlEnc(argsJson);
		let frame = document.getElementById('download-frame');
		frame.src = url;
	},
	async download(classMethod, ...args) {
		let cookieName = 'd_' + this.getRandomString(10);
		let expires = moment().add(10, 'minute').toString();

		document.cookie = `${cookieName}=1; path=/; expires=${expires}`;

		let argsJson = JSON.stringify(args);
		let url = '/api/call/' + classMethod + '?argsJson=' + this.urlEnc(argsJson) + '&downloadCookie=' + cookieName;
		let frame = document.getElementById('download-frame');
		frame.src = url;

		let isDownloading;
		do {
			isDownloading = document.cookie.indexOf(cookieName + '=1') !== -1;
			await this.sleep(500);
		} while (isDownloading);
	},
	setLoadTime(res) {
		if (!res || !res.headers) return;
		this.loadTime = res.headers['x-request-time'];
	},
	normalizePhone(raw, str = false) {
		if (!raw) return '';
		if (str) {
			let match = String(raw).match(/[+()\-0-9]/g);
			if (!match || match.length < 10) return raw;
		}

		return '+' + String(raw).replace(/[^0-9]/g, '');
	},
	getPath(obj, path) {
		path.split('.').forEach(part => {
			obj = (obj || {})[part];
		});
		return obj;
	},
	logErr(...args) {
		window.console && console.error.apply(console, args);
	},
	compare(a, b) {
		if (a > b) return 1;
		if (a < b) return -1;
		return 0;
	},
	getGeoFromPhone(number) {
		number = this.normalizePhone(number);
		for (let len = 6; len > 0; len--) {
			let code = parseInt(number.slice(0, len));
			if (this.phoneCodeGeoMap[code]) return this.phoneCodeGeoMap[code];
		}
		return null;
	},
	getLanguageByCode(code) {
		code = String(code);
		return this.ii('LANG_' + code.toUpperCase(), '') || this.langsMap[code.toLowerCase()];
	},
	getCountryByCode(code) {
		if (!code) return '';
		code = String(code).toUpperCase();
		return this.ii('GEO_' + code, '') || this.geoMap && this.geoMap[code];
	},
	getGeoSuggestions(opts = {}) {
		let items = [...this.geoSuggestions];
		if (opts.withFullName) {
			items.forEach(item => {
				let localeCountry = this.getCountryByCode(item.key) || item.val;
				item.displayVal = localeCountry;
			});
		}
		return items;
	},
	getUsercardTabsSuggestions(opts = {}) {
		let items = [...this.usercardTabsSuggestions];
		return items;
	},
	getTimezonesSuggestions(opts = {}) {
		let items = [];
		this.timezonesMap.forEach(timezone => {
			timezone.utc.forEach(utc => {
				if (items.filter(i => i.key === utc).length) return;

				items.push({
					key: utc,
					val: timezone.text + ' ' + utc,
					sort: timezone.offset
				});
			});
		});
		return items;
	},
	getUserSourcesSuggs() {
		let items = Object.keys(this.userSourcesMap).map(key => {
			let val = key + ' - ' + this.userSourcesMap[key];
			return {key, val};
		});
		items.unshift({
			key: 0,
			val: '0'
		});
		return items;
	},
	getOfficesSuggestions(opts = {}) {
		let items = [...this.officesSuggestions];
		if (opts.withOther) {
			items.push({key: 'other', val: this.ii('Other')});
		}
		return items;
	},
	getResellerByName(name) {
		return this.resellers.filter(r => r.name === name)[0] || {};
	},
	getPackageSlug(opts, packageMap = null) {
		if (!packageMap) {
			packageMap = this.packages;
		}
		if (!packageMap) return null;
		if (opts.package) {
			let items = Object.keys(packageMap).filter(key => {
				return packageMap[key].title === opts.package;
			});
			return items[0] || null;
		}
		return null;
	},
	editQueryParams(params, isReplace = false) {
		let query = {...this.app.query, ...params};
		this.navigate({query}, isReplace);
	},
	checkResellersAccess() {
		let reseller = this.app.store.reseller;
		let resellers = this.app.store.resellers;

		return reseller === 'semalt' && resellers && resellers.length;
	},
	checkPermission(permission) {
		let perms = this.app.store.permissions;
		return this.inArray(permission, perms) || this.inArray('*', perms);
	},
	getGroupTitle(group) {
		if (!group || !this.app.store.groups) return group;

		let groups = this.app.store.groups.filter(g => g.name === group);
		if (!groups.length) return group;

		return groups[0].title || group;
	},
	checkGroup(group) {
		if (!group) return false;
		if (!this.app.store.worker) return false;
		if (!this.app.store.worker.groups) return false;
		return this.inArray(group, this.app.store.worker.groups);
	},
	checkGroupPermission(group, permission) {
		if (this.checkGroupAdmin(group)) return true;

		return this.checkPermission('group:' + group + ':' + permission);
	},
	checkGroupAdmin(group) {
		return this.checkPermission('group:' + group + ':admin');
	},
	hasGroupPermission(permission) {
		if (!this.app.store.groups) return false;

		let filtered = this.app.store.groups.filter(g => this.checkGroupPermission(g.name, permission));
		return filtered.length > 0;
	},
	isGroupAdmin() {
		if (!this.app.store.groups) return false;

		let filtered = this.app.store.groups.filter(g => this.checkGroupAdmin(g.name));
		return filtered.length > 0;
	},
	checkGroupByIdm(idm, group) {
		let workersMap = this.app.store.workersMap;
		let idws = Object.keys(workersMap).filter(idw => workersMap[idw].idm === idm);
		if (!idws.length) return false;

		let idw = idws[0];
		return this.checkGroupByIdw(idw, group);
	},
	checkGroupByIdw(idw, group) {
		let worker = this.app.store.workersMap[idw];
		if (!group || !worker || !worker.groups || !worker.groups.length) return false;

		return this.inArray(group, worker.groups);
	},
	setLoading(isLoading) {
		this.app.store.isLoading = isLoading;
	},
	formatDate(datetime, format) {
		return moment(datetime).format(format);
	},
	getTimePassed(timestamp, format = 'YYYY-MM-DD') {
		if (isNaN(timestamp)) {
			timestamp = moment(timestamp, format).unix();
		}
		let clientTimestamp = parseInt(timestamp) + this.app.store.timeDiff;
		return moment.unix(clientTimestamp).fromNow();
	},
	toUnix(datetime) {
		return moment(datetime).unix();
	},
	getCurMonth() {
		return moment().format('YYYY-MM');
	},
	getCurDate() {
		return moment().format('YYYY-MM-DD');
	},
	now(format = 'YYYY-MM-DD HH:mm:ss') {
		return moment().format(format);
	},
	daysInMonth(date, format = 'YYYY-MM') {
		return moment(date, format).daysInMonth();
	},
	switchDatetimeFormat() {
		this.app.store.isDatetimeFull = !this.app.store.isDatetimeFull;
		localStorage.setItem('isDatetimeFull', this.app.store.isDatetimeFull);
	},
	isObjectEmpty(obj) {
		if (!obj) return true;
		for (let i in obj) return false;
		return true;
	},
	isArray(data) {
		return data instanceof Array;
	},
	capitalize(val) {
		if (!val) return '';
		val = val.toString();
		return val.charAt(0).toUpperCase() + val.slice(1);
	},
	lowerCaseFirst(val) {
		if (!val) return '';
		val = val.toString();
		return val.charAt(0).toLowerCase() + val.slice(1);
	},
	async ensureHighStock() {
		await this.loadScript('/lib/highstock.concat.js', true);
		Highcharts.setOptions({
			global: {useUTC: false}
		});
	},
	async ensureHighCharts() {
		await this.loadScript('/lib/highcharts.js', true);
		await this.loadScript('/lib/highcharts-annotations.js', true);
		Highcharts.setOptions({
			global: {useUTC: false}
		});
	},
	getDefaultChartOpts() {
		let opts = {
			title: {
				text: '',
				style: {
					textTransform: 'uppercase',
					fontWeight: 500
				}
			},
			credits: {
				enabled: false
			},
			chart: {
				spacingTop: 35,
				marginTop: 85,
				style: {
					fontFamily: this.getChartFonts()
				}
			},
			rangeSelector: {
				enabled: false
			},
			colors: ['#2f7ed8', '#0d233a', '#f28f43', '#8bbc21', '#1aadce', '#492970', '#910000', '#77a1e5', '#c42525', '#a6c96a'],
			tooltip: {
				hideDelay: 100
			},
			yAxis: {
				title: {
					text: ''
				},
				labels: {},
				min: 0
			},
			legend: {
				layout: 'horizontal',
				align: 'center'
			},
			xAxis: {
				crosshair: {
					width: 1,
					color: '#ddd'
				}
			},
			series: []
		};
		return opts;
	},
	getUsersRegSources() {
		return [
			'cold', 'audit', 'franchise', 'webdev', 'reseller', 'video', 'facebook', 'wow',
			'pharma_seo', 'api', 'casino', 'crawler'
		];
	},
	getChartFonts() {
		return 'Roboto, "Open Sans", sanf-serif, helvetica, tahoma, verdana';
	},
	getFsOrderUrl(orderRef) {
		return 'https://springboard.fastspring.com/order/search.xml?query=' + this.urlEnc(orderRef) + '&mRef=&cRef=';
	},
	getInvoiceUrl(orderRef, ps = null, isPdf = false) {
		if (!orderRef) return '';

		if (!ps) {
			if (orderRef.indexOf('SEM') === 0) {
				ps = 'fs';
			} else if (orderRef.indexOf('201') === 0) {
				ps = 'alipay';
			} else {
				ps = 'connectum';
			}
		}

		if(isPdf){
			if (ps === 'fs') {
				return 'https://semalt.com/handlers/download_invoice_pdf.php?reference=' + orderRef;
			}
			return 'https://semalt.com/connectum/connectum.php?pdf-invoice=' + orderRef;
		}

		if (ps === 'fs') {
			return 'https://sites.fastspring.com/semalt/order/invoice/' + orderRef;
		}
		return 'https://semalt.com/connectum/connectum.php?invoice=' + orderRef;
	},
	onControlChanged(e, opts = {}) {
		if (!e.isTrusted) return;

		const query = this.app.query;
		for (let i in query) {
			if (query[i] === '' || query[i] == null) {
				delete query[i];
			}
		}

		let hash = opts.keepHash ? this.app.hash : void 0;

		this.navigate({query, hash});
	},
	navigate(opts, isReplace = false) {
		const router = this.$router;
		let query = opts.query || {};

		let method = isReplace ? 'replace' : 'push';
		router[method]({...opts, query});
	},
	navigateHash(hash) {
		let query = this.$route.query || {};
		this.$router.push({query, hash});
	},
	navigateOutHash(isReplace = false) {
		let query = this.$route.query || {};
		this.$router[isReplace ? 'replace' : 'push']({query});
	},
	clearQuery() {
		let path = this.$route.path;
		this.navigate({path});
	},
	queryToString(obj) {
		let pairs = [];
		for (let key in obj) {
			if (!obj.hasOwnProperty(key)) continue;
			let val = obj[key];
			if (val === undefined) continue;

			let pair = this.urlEnc(key) + '=' + this.urlEnc(val);
			pairs.push(pair);
		}
		return pairs.join('&');
	},
	getExtendedQueryUrl(query) {
		let newQuery = {...this.app.query, ...query};
		return {query: newQuery};
	},
	toggleMinicard(row) {
		this.$set(row, 'minicardShown', !row.minicardShown);
	},
	toKeyboardLatin(raw) {
		return raw.toLowerCase().replace(this.cyrToLatinCharRegex, char => {
			return this.cyrToLatinCharMap[char] || char;
		});
	},
	setTitle(route) {
		let title = '';
		if (typeof route === 'string') {
			title = route;
		} else if (route.meta && route.meta.title) {
			title = route.meta.title;
			if (title instanceof Function) {
				title = title(route);
			}
		}

		document.title = (title ? title + ' - ' : '') + this.app.dsdClient.company + ' DSD CRM';
	},
	getPaymentTypesItems() {
		let items = [
			{key: 'order', val: this.ii('ORDER')},
			{key: 'rebill', val: this.ii('REBILL')},
			{key: 'order_failed', val: this.ii('ORDER_FAIL')},
			{key: 'payment_failed', val: this.ii('REBILL_FAIL')},
			{key: 'cancel', val: this.ii('CANCEL')},
			{key: 'refund', val: this.ii('REFUND')}
		];
		return items;
	},
	getPaymentTypeBySlug(slug) {
		let orderTypesMap = {
			'order': 'Order',
			'rebill': 'Rebill',
			'order_failed': 'Order fail',
			'payment_failed': 'Rebill fail',
			'cancel': 'Cancel',
			'refund': 'Refund'
		};
		return orderTypesMap[slug];
	},
	getUserSourcesPresets() {
		let list = [];
		list.push({
			key: '31,32,33,34',
			text: 'Group: Cold'
		});
		return list;
	},
	getWorkerGroupsPreset(opts = {}) {
		let list = [];
		let groupsToAdd = [];

		groupsToAdd = this.groups.filter(group => {
			if (group.name && group.title) return true;
		}).sort((a, b) => {
			if (a.title < b.title) return -1;
			if (a.title > b.title) return 1;
			return 0;
		}).map(group => group.name);

		if (opts.ownGroups) {
			groupsToAdd = groupsToAdd.filter(group => {
				if (this.checkPermission('access:all_workers')) return true;
				if (this.checkGroupPermission(group, 'all_workers')) return true;
			});
		}

		if (opts.groups) groupsToAdd = opts.groups;

		groupsToAdd.forEach(group => {
			let idws = Object.keys(this.workersMap).filter(idw => this.checkGroupByIdw(idw, group));
			if (!idws || !idws.length) return;

			list.push({
				key: idws.join(','),
				text: 'Group: ' + this.getGroupTitle(group)
			});
		});

		return list;
	},
	getManagerOfficesPreset() {
		let map = {};

		Object.keys(this.workersMap).forEach(idw => {
			let worker = this.workersMap[idw];
			if (!worker.idm || !worker.office) return;

			if (!map[worker.office]) {
				map[worker.office] = [];
			}
			map[worker.office].push(worker.idm);
		});

		let list = [];
		Object.keys(map).forEach(office => {
			list.push({
				key: map[office].join(','),
				text: 'Office: ' + this.capitalize(office)
			});
		});

		return list;
	},
	getManagerGroupsPreset(opts = {}) {
		let list = [];
		let groupsToAdd = ['retention', 'pakistan', 'turkey', 'uruguay'];

		if (opts.ownGroups) {
			groupsToAdd = groupsToAdd.filter(group => {
				if (this.checkPermission('access:all_managers')) return true;
				if (this.checkGroupPermission(group, 'all_managers')) return true;
			});
		}

		if (opts.groups) groupsToAdd = opts.groups;

		groupsToAdd.forEach(group => {
			let idms = [];
			let idws = Object.keys(this.workersMap).filter(idw => this.checkGroupByIdw(idw, group));
			idws.forEach(idw => {
				let idm = this.workersMap[idw].idm;
				if (idm) idms.push(idm);
			});
			if (!idms || !idms.length) return;

			list.push({
				key: idms.join(','),
				text: 'Group: ' + this.getGroupTitle(group)
			});
		});

		return list;
	},
	hideAllTitles() {
		if (this.$root === this) return;
		this.$root.hideAllTitles();
	},
	getManagersItems(opts = {}) {
		let workersMap = this.app.store.workersMap;
		if (!workersMap) return [];

		let list = [];

		if (opts.withNone) {
			list.push({
				key: 'none',
				val: 'None',
				sortVal: 0
			});
		}
		if (opts.withSlack) {
			list.push({
				key: -1,
				val: '#-1 - Slack Manager',
				sortVal: 0
			});
		}

		for (let i in workersMap) {
			let worker = workersMap[i];

			if (!worker.idm || !this.checkGroupByIdw(worker.id, 'sales')) continue;

			if (!opts.withFired && worker.active !== 1) continue;
			if (opts.ownGroups && !this.checkPermission('access:all_managers')) {
				let workerGroups = this.worker.groups.filter(group => {
					if (!this.checkGroupPermission(group, 'all_managers')) return false;
					if (!this.checkGroupByIdw(worker.id, group)) return false;
					return true;
				});
				if (!workerGroups.length) continue;
			}
			if (opts.groups) {
				let workerGroups = opts.groups.filter(group => this.checkGroupByIdw(worker.id, group));
				if (!workerGroups.length) continue;
			}
			if (opts.idws) {
				if (!this.inArray(worker.id, opts.idws)) continue;
			}
			if (opts.idms) {
				if (!this.inArray(worker.idm, opts.idms)) continue;
			}

			let val = '#' + worker.idm + ' - ' + worker.realName;
			if (worker.name !== worker.realName) {
				val += ' (' + worker.name + ')';
			}
			let displayVal = worker.realName;
			let sortVal = worker.idm;
			let sVal;
			if (opts.searchByIdw) {
				sVal = val + ' ' + worker.id;
			}

			list.push({
				key: worker.idm,
				val,
				displayVal,
				sortVal,
				sVal
			});
		}

		return list;
	},
	getWorkersItems(opts = {}) {
		let workersMap = this.app.store.workersMap;
		if (!workersMap) return [];

		let list = [];
		let key = opts.key || 'id';

		if (opts.withNone) {
			list.push({
				key: 'none',
				val: 'None',
				sortVal: 0
			});
		}

		for (let i in workersMap) {
			let worker = workersMap[i];

			if (!opts.withFired && worker.active !== 1) continue;
			if (opts.ownGroups && !this.checkPermission('access:all_workers')) {
				let workerGroups = this.worker.groups.filter(group => {
					if (!this.checkGroupPermission(group, 'all_workers')) return false;
					if (!this.checkGroupByIdw(worker.id, group)) return false;
					return true;
				});
				if (!workerGroups.length) continue;
			}
			if (opts.sales) {
				if (!worker.idm || !this.checkGroupByIdw(worker.id, 'sales')) continue;
			}
			if (opts.groups) {
				let workerGroups = opts.groups.filter(group => this.checkGroupByIdw(worker.id, group));
				if (!workerGroups.length) continue;
			}
			if (opts.idws) {
				if (!this.inArray(worker.id, opts.idws)) continue;
			}
			if (opts.idms) {
				if (!this.inArray(worker.idm, opts.idms)) continue;
			}

			let val = '#' + worker[key] + ' - ' + worker.realName;
			if (worker.name !== worker.realName) {
				val += ' (' + worker.name + ')';
			}
			let displayVal = worker.realName;
			let sortVal = worker[key];
			let sVal;
			if (opts.searchByIdm) {
				sVal = val + ' ' + worker.idm;
			}

			list.push({
				key: worker[key],
				val,
				displayVal,
				sortVal,
				sVal
			});
		}

		return list;
	},
	getCapturePageLink(url) {
		url = this.getFullUrl(url);
		return '/api/capture?url=' + encodeURIComponent(url);
	},
	getListFromLeadMetrics(metrics) {
		return metrics && metrics.map(el => {
			let html, sVal, sortVal;
			switch (el.metric + ':' + el.department) {
				case 'status:conversion':
					html = `<strong>C1: </strong> ${el.title}`;
					sVal = `C1: ${el.title}`;
					sortVal = '1:' + el.id;
					break;
				case 'status:conversion2':
					html = `<strong>C2: </strong> ${el.title}`;
					sVal = `C2: ${el.title}`;
					sortVal = '2:' + el.id;
					break;
				case 'status:retention':
					html = `<strong>R: </strong> ${el.title}`;
					sVal = `R: ${el.title}`;
					sortVal = '3:' + el.id;
					break;
				case 'status:account':
					html = `<strong>A: </strong> ${el.title}`;
					sVal = `A: ${el.title}`;
					sortVal = '0:' + el.id;
					break;
			}
			let data = {
				key: el.id,
				val: el.title,
				className: 'metric_' + el.slug
			};
			if (html) {
				data.html = html;
			}
			if (sVal) {
				data.sVal = sVal;
			}
			if (sortVal) {
				data.sortVal = sortVal;
			}
			return data;
		});
	},
	getMetricById(metricType, id) {
		let metrics = this.leadMetrics[metricType];
		if (!metrics) return {};

		id = parseInt(id) || 0;
		let metric = metrics.filter(el => el.id === id)[0];
		return metric || {};
	},
	getShortManagerName(m) {
		if (!m || !m.realName) return '';
		return m.realName.split(' ').pop();
	},
	getSearchEngineById(seId) {
		return this.searchEngines.find(item => item.id == seId);
	},
	getSearchEnginesItems() {
		if (!this.searchEngines) return [];
		return this.searchEngines.map(se => {
			let key = se.id;
			let country = this.geoMap[se.geo.toUpperCase()] || se.geo;
			let val = se.domain;
			let displayVal = se.domain;
			if (country) {
				val += ' - ' + country;
			}
			val += ' - ' + se.lang;
			displayVal += ' - ' + se.lang;
			let sVal = val + ' #' + se.id + '#';
			return {key, val, displayVal, sVal};
		});
	},
	getRegionSearchEnginesItems() {
		if (!this.regionSearchEngines) return [];
		return this.regionSearchEngines.map(se => {
			let key = se.id;
			let country = this.geoMap[se.geo.toUpperCase()] || se.geo;
			let val = se.domain;
			let displayVal = se.domain;
			if (country) {
				val += ' - ' + country;
			}
			val += ' - ' + se.lang;
			displayVal += ' - ' + se.lang;
			let sVal = val + ' #' + se.id + '#';
			return {key, val, displayVal, sVal};
		});
	},
	initRouter(ctx, routes, callback) {
		const router = ctx.$router;

		router.afterEach((to, from) => {
			if (to.hash === '#sidebar') {
				return;
			}
			if (from.hash === '#sidebar') {
				this.$root.hideSidebar();
				return;
			}

			let page = location.pathname.split('/')[1];
			this.act('visit', page);

			this.setTitle(to);

			if (callback) {
				ctx.$nextTick(() => callback(to, from));
			}

			let relPath = location.pathname.replace(/^\/p\//, '');
			document.body.setAttribute('data-path', relPath);
		});
		routes && router.addRoutes(routes);
		this.app.pageRouterInited = true;
	},
	async waitRouterInited() {
		while (!this.app.pageRouterInited) {
			await this.sleep(100);
		}
	},
	sortTable(col, isReverse = false, singleQuery = false) {
		let sort = col;
		let sortDesc;
		if (!col) {
			sort = sortDesc = void 0;
		} else if (col === this.app.query.sort) {
			if (this.app.query.sortDesc == null) {
				sortDesc = 0;
			} else {
				sortDesc = (1 - (parseInt(this.app.query.sortDesc) || 0));
			}
		} else {
			sortDesc = isReverse ? 0 : 1;
		}

		let query;
		if (singleQuery) {
			let querySort = this.app.query.sort;
			if (querySort && querySort.startsWith('-')) {
				querySort = querySort.substr(1);
			}
			if (col === querySort) {
				if (!this.app.query.sort.startsWith('-')) {
					sort = '-' + sort;
				}
			} else {
				sort = isReverse ? sort : '-' + sort;
			}
			query = {...this.app.query, sort};
		} else {
			query = {...this.app.query, sort, sortDesc};
		}
		this.$router.push({query});
	},
	getClusterUrl(key) {
		let urls = this.clusters.filter(cluster => cluster.key === key).map(cluster => cluster.url);
		return urls[0];
	},
	getLangItems(langs = null) {
		let items = Object.keys(this.langsMap).filter(key => {
			return !langs || langs.indexOf(key) !== -1;
		}).map(key => {
			let val = this.ii('LANG_' + key.toUpperCase(), '');
			let sortVal;
			if (val) {
				sortVal = '1' + val;
			} else {
				val = this.langsMap[key.toLowerCase()];
				sortVal = '2' + val;
			}
			return {key, val, sortVal};
		});
		return items;
	},
	getLanguagesSuggestions() {
		let items = Object.keys(this.langsMap).map(key => {
			let val = this.langsMap[key];
			let sortVal = val;
			return {key, val, sortVal};
		});
		return items;
	},
	getPlans() {
		let plans = [
			{key: 'analytics', val: 'Analytics'},
			{key: 'autoseo', val: 'AutoSEO'},
			{key: 'autoseotrial', val: 'AutoSEO trial'},
			{key: 'fullseo', val: 'FullSEO'},
			{key: 'video', val: 'Video'},
			{key: 'all', val: 'All inclusive'},
			{key: 'additional', val: 'Additional'},
			{key: 'webdev', val: 'WebDev'},
			{key: 'smm', val: 'SMM'},
			{key: 'ecommerce', val: 'E-Commerce'},
			{key: 'email', val: 'E-mail marketing'},
			{key: 'amazon', val: 'Amazon'}
		];
		return plans;
	},
	getWorker(idw) {
		return this.app.store.workersMap && this.app.store.workersMap[idw] || {};
	},
	hasWorkerAnotherName(idw) {
		let worker = this.getWorker(idw);
		return worker.name !== worker.realName;
	},
	getManager(idm) {
		return this.managersMap && this.managersMap[idm] || {};
	},
	hasManagerAnotherName(idm) {
		let manager = this.getManager(idm);
		return manager.name !== manager.realName;
	},
	getProxyUrl(url, handler = null) {
		let domain = this.getDomainFromUrl(url);
		if (domain === location.host) return url;

		let proxyUrl = '/api/proxy?u=' + encodeURIComponent(url);
		if (handler) {
			proxyUrl += '&handler=' + encodeURIComponent(handler);
		}
		return proxyUrl;
	},
	getRelativeUrl(url) {
		if (!url) return '';
		return url.replace(/^https?:\/\/[^\/]+/i, '');
	},
	urlEnc(raw) {
		return encodeURIComponent(raw);
	},
	urlDec(raw) {
		return decodeURIComponent(raw);
	},
	trim(str) {
		if (!str) return '';
		return String(str).trim();
	},
	isNumeric(input) {
		return !/[^0-9]/.test(input);
	},
	hilite(val, phrase) {
		if (!val || !phrase) return val;

		val = this.escapeHtml(val);
		phrase = this.escapeHtml(phrase);

		let regex = new RegExp('(' + this.escapeRegex(phrase) + ')', 'gi');
		val = val.replace(regex, '<span class="hilite">$1</span>');

		return val;
	},
	escapeRegex(str, delimiter) {
		let regex = new RegExp('[.\\\\+*?\\[\\^\\]$(){}=!<>|:\\' + (delimiter || '') + '-]', 'g');
		return (str + '').replace(regex, '\\$&');
	},
	escapeHtml(html) {
		if (!html) return '';
		return html
			.replace(/&/g, '&amp;')
			.replace(/</g, '&lt;')
			.replace(/>/g, '&gt;')
			.replace(/"/g, '&quot;')
			.replace(/'/g, '&#039;');
	},
	unescapeHtml(html) {
		if (!html) return '';
		return html
			.replace(/&amp;/g, '&')
			.replace(/&lt;/g, '<')
			.replace(/&gt;/g, '>')
			.replace(/&quot;/g, '"')
			.replace(/&#039;/g, "'");
	},
	wrapLinks(html) {
		if (!html) return html;

		html = this.escapeHtml(html);
		html = html.replace(/(https?:\/\/[^\s"'(),]+)[^\.\s,]/gi, '<a href="$&" target="_blank" rel="noopener noreferrer">$&</a>');
		return html;
	},
	stripTags(html) {
		if (!html) return '';
		return html.replace(/<.*?>/g, '');
	},
	newLineToBr(raw, maxNewLines = null) {
		let str = String(raw || '').replace(/\r?\n/g, '<br>');

		maxNewLines = parseInt(maxNewLines);
		if (maxNewLines) {
			let re = new RegExp('((<br>){' + maxNewLines + '})(<br>)+');
			str = str.replace(re, '$1');
		}
		return str;
	},
	getDomainFromUrl(url, keepWww = false) {
		let domain = String(url).trim().toLowerCase().replace(/^https?:\/\//i, '').replace(/\/.*/, '');
		if (!keepWww) {
			domain = domain.replace(/^www\./i, '');
		}
		return domain;
	},
	getFullUrl(url) {
		if (/^https?:\/\//i.test(url)) return url;
		if (/^\/\//.test(url)) return 'http:' + url;
		return 'http://' + url;
	},
	tryRestoreQueryString() {
		if (Object.keys(this.app.query).length !== 0) return false;

		let key = 'qs_' + this.app.routeName;
		let qs = localStorage.getItem(key);
		if (!qs) return false;

		this.$router.replace(this.$route.path + '?' + qs);
		return true;
	},
	getVmPresets() {
		let map = {};
		this.vms.forEach(vm => {
			vm.tags.forEach(tag => {
				if (!map[tag]) {
					map[tag] = [];
				}
				map[tag].push(vm.hostname);
			});
		});

		let presets = Object.keys(map).sort().map(tag => {
			return {
				text: tag,
				key: map[tag].join(',')
			};
		});
		return presets;
	},
	getPosStyle(pos) {
		const naStyle = {
			color: '#000'
		};
		const noPosStyle = {
			color: '#fff',
			backgroundColor: '#ccc'
		};

		if (pos == null) return naStyle;
		if (!pos) return noPosStyle;

		const goodColor = '18ad06';
		const badColor = 'ff0000';

		const goodRgb = goodColor.match(/../g).map(part => parseInt(part, 16));
		const badRgb = badColor.match(/../g).map(part => parseInt(part, 16));

		let avgRgb = [];
		for (let i = 0; i < 3; i++) {
			let avg = Math.floor(badRgb[i] + (goodRgb[i] - badRgb[i]) * (101 - pos) / 100);
			avgRgb.push(avg);
		}

		let hexFull = '#' + avgRgb
				.map(dec => dec.toString(16))
				.map(hex => hex.length === 1 ? ('0' + hex) : hex)
				.join('');

		return {
			color: '#fff',
			backgroundColor: hexFull
		};
	},
	getColorMix(color1, color2, percent) {
		if (!percent) return '#' + color2.toLowerCase().replace(/^#/, '');

		let rgb1 = color1.toLowerCase().replace(/^#/, '').match(/../g).map(part => parseInt(part, 16));
		let rgb2 = color2.toLowerCase().replace(/^#/, '').match(/../g).map(part => parseInt(part, 16));

		let avgRgb = [];
		for (let i = 0; i < 3; i++) {
			let avg = Math.floor(rgb1[i] + (rgb2[i] - rgb1[i]) * (101 - percent) / 100);
			avgRgb.push(avg);
		}

		let hexFull = '#' + avgRgb
				.map(dec => dec.toString(16))
				.map(hex => hex.length === 1 ? ('0' + hex) : hex)
				.join('');

		return hexFull;
	},
	scrollLeft() {
		return window.scrollX != null ? window.scrollX : window.pageXOffset;
	},
	scrollTop() {
		return window.scrollY != null ? window.scrollY : window.pageYOffset;
	},
	filterUnique(value, index, self) {
		return self.indexOf(value) === index;
	},
	getEventPrioritySlug(prio) {
		let prios = ['low', 'medium', 'high', 'rebill'];
		return prios[prio];
	},
	getEventPriorityText(prio) {
		let prios = ['Low', 'Medium', 'High', 'Rebill'];
		return prios[prio];
	},
	padLeft(text, len, char = '0') {
		text = String(text);
		while (text.length < len) {
			text = char + text;
		}
		return text;
	},
	preventDefault: e => e.preventDefault(),
	getWinWidth() {
		return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
	},
	getWinHeight() {
		return window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
	},
	onServerEvent(event, callback) {
		this.app.join(event);
		if (!this.app.serverPushFns[event]) {
			this.app.serverPushFns[event] = [callback];
		} else {
			this.app.serverPushFns[event].push(callback);
		}
	},
	offServerEvent(event, callback) {
		this.app.leave(event);
		this.app.serverPushFns[event] = this.app.serverPushFns[event].filter(fn => fn !== callback);
	},
	beep() {
		let sound = new Audio('/media/beep.mp3');
		sound && sound.play();
	},
	copyObj(source){
		let newObj = JSON.parse(JSON.stringify(source));

		return newObj;
	},
	getMySQLHostsItems() {
		let items = this.hosts.map((h, i) => {
			let key;
			if (i > 0) {
				key = h.host;
			}
			let val = h.host;
			let html = this.escapeHtml(h.host);
			if (h.comment) {
				html += ' - <span class="sel-comment">' + this.escapeHtml(h.comment) + '</span>';
			}
			let sVal = h.host + '*' + h.comment;
			return {key, val, html, sVal};
		});
		return items;
	},
	localSetItem(key, value) {
		try {
			localStorage.setItem(key, value);
		} catch (e) {
			window.console.error('Error writing to the storage for key = ' + key +
				'! (this is expected in private mode in Safari)');
		}
	},
	localGetItem(key) {
		try {
			return localStorage.getItem(key);
		} catch (e) {
			window.console.error('Error reading to the storage for key = ' + key +
				'! (this is expected in private mode in Safari)');
		}
	},
	localRemoveItem(key) {
		try {
			localStorage.removeItem(key);
		} catch (e) {
			window.console.error('Error removing to the storage for key = ' + key +
				'! (this is expected in private mode in Safari)');
		}
	},
	sub(event, fn) {
		if (!this.app.store.pubSub[event]) {
			this.app.store.pubSub[event] = [];
		}
		this.app.store.pubSub[event].push(fn);
	},
	unsub(event, fn) {
		if (!this.app.store.pubSub[event]) return;
		this.removeFromArray(this.app.store.pubSub[event], fn);
	},
	pub(event, data) {
		if (!this.app.store.pubSub[event]) return;

		this.app.store.pubSub[event].forEach(fn => fn(data));
	},
	arrayUnique(arr) {
		if (!this.isArray(arr)) return arr;
		return arr.filter((v, i, a) => a.indexOf(v) === i);
	},
	isObject(obj) {
		return typeof obj === 'object';
	},
	warn(msg) {
		console.warn(msg);
	},
	merge(obj, ...sources) {
		if (typeof _ === 'undefined') return this.warn('lo-dash is not set up');
		return _.merge.apply(_, [obj, ...sources]);
	},
	debounce(func, wait = 0) {
		if (typeof _ === 'undefined') return this.warn('lo-dash is not set up');
		return _.debounce(func, wait);
	},
	getObjectDeepVal(object, path, defaultValue) {
		if (typeof _ === 'undefined') return this.warn('lo-dash is not set up');
		return _.get(object, path, defaultValue);
	},
	cloneDeep(value) {
		if (typeof _ === 'undefined') return this.warn('lo-dash is not set up');
		return _.cloneDeep(value);
	},
	initFramePage(bgColor = null) {
		document.documentElement.style.height = '100%';
		document.body.classList.add('frame-page');
		if (bgColor) {
			document.body.style.background = bgColor;
		}
	},
	checkManagerAltAccs(altAccs) {
		if (!altAccs || !this.isObject(altAccs)) return false;

		let status = true;
		let idms = [0, 1, 114, 120, 150, 200];
		let map = ['byEmail', 'byPhone', 'bySite'];
		map.forEach(key => {
			if (!status) return;
			if (!altAccs[key]) return;

			altAccs[key].forEach(item => {
				if (!status) return;
				if (!this.inArray(item.idm, idms)) status = false;
			});
		});

		return status;
	},
	checkAbsentWorker(idw) {
		if (!idw || idw < 1) return;
		if (!this.app.store.absentWorkers || !this.app.store.absentWorkers.length) return false;

		let worker = this.app.store.absentWorkers.filter(w => w.idw === idw);
		return worker.length > 0;
	},
	checkAbsentManager(idm) {
		if (!idm || idm < 1) return;
		if (!this.app.store.absentWorkers || !this.app.store.absentWorkers.length) return false;

		let worker = this.app.store.absentWorkers.filter(w => w.idm === idm);
		return worker.length > 0;
	},
	googleTranslate(fromLang, toLang, phrase) {
		let url = this.getGoogleTranslateUrl(fromLang, toLang, phrase);
		return new Promise((resolve, reject) => {
			axios(url).then(res => {
				let data = res.data;
				let text;
				try {
					text = data[0][0][0];
				} catch (err) {}

				if (text) {
					resolve({text, data});
				} else {
					reject(data);
				}
			});
		});
	},
	getGoogleTranslateUrl(fromLang, toLang, phrase) {
		let params = [
			'client=gtx',
			'dt=t',
			'dt=bd',
			'dt=rm',
			'hl=en',
			'sl=' + this.urlEnc(fromLang),
			'tl=' + this.urlEnc(toLang),
			'q=' + this.urlEnc(phrase)
		];
		let url = 'https://translate.googleapis.com/translate_a/single?' + params.join('&');
		return url;
	},
	async loadPartnerWorkersMap(partner = null) {
		let partnerWorkers = await this.get('Worker.loadWorkersMap', [], 'all', partner);
		this.setState('workersMap', partnerWorkers);
	},
	async loadPartnerManagersMap(partner = null) {
		let partnerManagers = await this.get('Manager.loadManagersMap', partner);
		this.setState('managersMap', partnerManagers);
	},
	checkPageAllowed() {
		let page = this.app.pages.find(page => (page.slug === this.app.currentPage));
		if (!page) return null;

		let plan = this.app.plan;

		if (plan === 'standard') return true;
		if (page.plan === '') return true;
		if (page.plan === 'lite') return (plan === 'lite' || plan === 'pro' || plan === 'trial');
		if (page.plan === 'pro') return (plan === 'pro');

		return null;
	},
	haveAdditions(addition) {
		let page = this.app.pages.find(page => (page.slug === this.app.currentPage));
		if (!page) return null;

		let additions = this.app.additions;
		return additions.includes(addition);
	},
	isFreePlan() {
		return this.app.plan === '';
	},
	getPageLabel(pageSlug){
		let page = this.app.pages.find(page => (page.slug === pageSlug));
		if (!page) return null;

		return page.label;
	}
};

export default methods;
