const template = `
	<tab-links :links="links"></tab-links>
`;

export default {
	template,
	data() {
		return {
			links: [
				{
					permission: 'page:co-generator',
					url: '/cogenerator',
					route: ['coGenerator', 'projectPreset', 'pricesPreset', 'workPreset'],
					text: this.ii('CREATE_OFFER')
				},
				{
					permission: 'page:co-settings',
					url: '/cosettings',
					route: ['coSettings', 'companySettings', 'workSettings', 'priceSettings'],
					text: this.ii('TEMPLATE_SETTINGS')
				},
				{
					permission: 'page:co-downloads',
					url: '/codownloads',
					route: 'coDownloads',
					text: this.ii('DOWNLOADS')
				}
			]
		};
	}
};
