const template = `
	<div class="user-score" v-if="reseller === 'semalt'">
		<div v-if="percent != null && percent !== 'WAITING'" class="user-score-wr" :data-score-percent="percent" v-title="'User score: ' + percent + '%'">
			<div class="user-score-bar"></div>
			<div class="user-score-negative" :style="{width: (100 - percent) + '%'}"></div>
		</div>
	</div>
`;

export default {
	template: template,
	props: ['idu', 'val', 'axios'],
	data() {
		return {
			WAITING: 'WAITING',

			isWaked: false
		};
	},
	computed: {
		percent() {
			let percent = this.app.store.userScores[this.idu];
			if (percent > 100) {
				percent = 100;
			}
			return percent;
		}
	},
	methods: {
		loadScore() {
			if (this.app.store.userScores[this.idu]) return;

			this.rememberUserScore(this.idu, this.WAITING);

			let url = '/api/user-score?idu=' + this.idu;
			(this.axios || axios)(url).then(res => {
				this.rememberUserScore(this.idu, res.data.scorePercent);
			});
		},
		rememberUserScore(idu, score) {
			this.$set(this.app.store.userScores, idu, score);
		},
		tryWake() {
			if (this.isWaked) return;

			let elRect = this.$el.getBoundingClientRect();
			if (elRect.bottom > 0 && elRect.top < window.innerHeight) {
				this.isWaked = true;
				this.removeEvents();
				this.loadScore();
			}
		},
		addEvents() {
			window.addEventListener('scroll', this.tryWake);
			window.addEventListener('resize', this.tryWake);
		},
		removeEvents() {
			window.removeEventListener('scroll', this.tryWake);
			window.removeEventListener('resize', this.tryWake);
		}
	},
	created() {
		if (this.reseller !== 'semalt') return;

		if (this.val != -1 && this.val != null) {
			this.rememberUserScore(this.idu, this.val);
			this.removeEvents();
			return;
		}
		this.addEvents();
		this.$nextTick(() => {
			this.tryWake();
		});
	},
	destroyed() {
		this.removeEvents();
	}
};
