const template = `
	<div class="subscriptions">
		<div v-if="isLoading" class="loading">
			<span>
				<i class="fa fa-spinner spin-fast center"></i>
				<span class="general-upper">{{ ii('LOADING') }}...</span>
			</span>
		</div>
		<template v-else>
			<h3 class="text-center">
				Deprecated tab. Please use
				<strong class="pointer" @click="$emit('choose-tab', 'services')">Services</strong>
				tab instead.
			</h3>
			<div class="row margin-bottom-15">
				<div class="control-filter col xs-12 sm-8 md-6 lg-4">
					<label class="chb">
						<input type="checkbox" v-model="rebillEnabled">
						<span>{{ ii('REBILL_ENABLED_ONLY') }}</span>
					</label>
				</div>
				<div class="control-filter col xs-12 sm-8 md-6 lg-4">
					<label class="chb">
						<input type="checkbox" v-model="rebillInFuture">
						<span>{{ ii('REBILL_IN_FUTURE_ONLY') }}</span>
					</label>
				</div>
				<div class="control-filter col xs-12 sm-8 md-6 lg-4">
					<label class="chb">
						<input type="checkbox" v-model="campaignEnabled">
						<span>{{ ii('CAMPAIGN_ENABLED_ONLY') }}</span>
					</label>
				</div>
				<div class="control-filter col xs-12 sm-8 md-6 lg-4">
					<label class="chb">
						<input type="checkbox" v-model="oneTimeOnly">
						<span>{{ ii('ONE_TIME_ONLY') }}</span>
					</label>
				</div>
			</div>
			
			<table class="mc-tbl hover-tbl">
				<thead>
					<tr>
						<th><head-cell-inner no-sticky="1">#</head-cell-inner></th>
						<th><head-cell-inner no-sticky="1">{{ ii('SITE') }}</head-cell-inner></th>
						<th><head-cell-inner no-sticky="1">{{ ii('PLAN') }}</head-cell-inner></th>
						<th colspan="2"><head-cell-inner no-sticky="1">{{ ii('METHOD') }}</head-cell-inner></th>
						<th><head-cell-inner no-sticky="1">{{ ii('PRICE') }}</head-cell-inner></th>
						<th><head-cell-inner no-sticky="1">{{ ii('SUBSCRIPTION') }}</head-cell-inner></th>
						<th><head-cell-inner no-sticky="1">{{ ii('NEXT_DATE') }}</head-cell-inner></th>
						<th><head-cell-inner no-sticky="1">{{ ii('REBILL') }}</head-cell-inner></th>
						<th><head-cell-inner no-sticky="1">{{ ii('SERVICE') }}</head-cell-inner></th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(row, i) in filteredSubs">
						<td class="text-center">{{ i + 1 }}</td>
						<td>
							<a :href="'http://' + getDomainFromUrl(row.site)" target="_blank" rel="noreferrer noopener">{{ row.site }}</a>
							<span v-if="row.siteId">({{ row.siteId }})</span>
						</td>
						<td class="text-center">
							<span v-title="row.package" class="help">{{ row.plan }}</span>
						</td>
						<td class="text-center upper">{{ row.paymentSystem || "FS" }}</td>
						<td class="text-center">{{ getPaymentVariant(row.paymentType, row.paymentVariant) }}</td>
						<td class="text-center">{{ row.lastCost | toFixed(2) }}</td>
						<td>{{ row.psId }}</td>
						<td>
							{{ row.nextRebillDate }}
							<em v-if="row.nextRebillDate" class="m-left5 grey">
								{{ getTimePassed(toUnix(row.nextRebillDate)) }}
							</em>
						</td>
						<td class="text-center">
							<i v-if="row.rebillSt" class="fa fa-check green"></i>
							<i v-else class="fa fa-remove red"></i>
						</td>
						<td class="text-center">
							<i v-if="row.serviceSt === 1" class="fa fa-check green"></i>
							<i v-else-if="row.serviceSt === 0" class="fa fa-remove red"></i>
							<span v-else>?</span>
						</td>
					</tr>
				</tbody>
			</table>
		</template>
	</div>
`;

export default {
	template,
	data() {
		return {
			rebillEnabled: false,
			rebillInFuture: true,
			campaignEnabled: false,
			oneTimeOnly: false,
			isLoading: true,
			subscriptions: null
		};
	},
	props: ['idu', 'partner'],
	computed: {
		filteredSubs() {
			let subs = this.subscriptions;
			if (!subs || !subs.length) return [];

			if (this.rebillEnabled) {
				subs = subs.filter(s => s.rebillSt);
			}
			if (this.campaignEnabled) {
				subs = subs.filter(s => s.serviceSt === 1);
			}
			if (this.rebillInFuture) {
				subs = subs.filter(s => s.nextRebillDate >= this.getCurDate());
			}
			if (this.oneTimeOnly) {
				subs = subs.filter(s => s.nextRebillDate === null);
			}

			return subs;
		}
	},
	async mounted() {
		if (this.idu) {
			let params = {
				idu: this.idu,
				sort: 'nextRebill',
				sortDesc: 0,
				partner: this.partner
			};
			this.subscriptions = await this.get('UserCard.getUserSubscriptions', params);
		}
		this.isLoading = false;
	}
};