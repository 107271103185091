const template = `
	<div class="user-campaigns">
		<div v-if="isLoading" class="loading">
			<span>
				<i class="fa fa-spinner spin-fast center"></i>
				<span class="general-upper">{{ ii('LOADING') }}...</span>
			</span>
		</div>
		<template v-else>
			<h3 class="text-center">
				Deprecated tab. Please use
				<strong class="pointer" @click="$emit('choose-tab', 'services')">Services</strong>
				tab instead.
			</h3>
			<table class="mc-tbl hover-tbl">
				<thead>
					<tr>
						<th><head-cell-inner no-sticky="1">{{ ii('ID') }}</head-cell-inner></th>
						<th><head-cell-inner no-sticky="1">{{ ii('SITE') }}</head-cell-inner></th>
						<th><head-cell-inner no-sticky="1">{{ ii('CAMPAIGN_DATE') }}</head-cell-inner></th>
						<th><head-cell-inner no-sticky="1">{{ ii('REFERENCE') }}</head-cell-inner></th>
						<th><head-cell-inner no-sticky="1">{{ ii('PAYMENT_SYSTEM') }}</head-cell-inner></th>
						<th><head-cell-inner no-sticky="1">{{ ii('NEXT_REBILL') }}</head-cell-inner></th>
						<th><head-cell-inner no-sticky="1">{{ ii('ACTIVE') }}</head-cell-inner></th>
					</tr>
				</thead>
				<tbody>
				<template v-if="campaigns && campaigns[type.key] && campaigns[type.key].length" v-for="type in types">
					<tr :key="type.key">
						<th colspan="100">
							<head-cell-inner no-sticky="1">
								{{ ii(type.text) }}
							</head-cell-inner>
						</th>
					</tr>
					<tr v-for="c in campaigns[type.key]">
						<td>{{ c.siteId }}</td>
						<td>
							<a :href="c.url" target="_blank" rel="noreferrer noopener">{{ c.site }}</a>
						</td>
						<td class="text-center">
							<span v-if="checkPermission('edit:seolaunch') && reseller === 'semalt'">
								<template v-if="c.camapaignStart">
									<span class="link" @click="changeCampaignDate(c, 'camapaignStart', 'start', type.key)">
										{{ c.camapaignStart }}
									</span> -
								</template>
								<span class="link" @click="changeCampaignDate(c, 'camapaignEnd', 'end', type.key)">
									{{ c.camapaignEnd }}
								</span>
							</span>
							<span v-else>
								{{ (c.camapaignStart ? c.camapaignStart + ' - ' : '') + c.camapaignEnd }}
							</span>
						</td>
						<td class="text-center">
							<a :href="'/subscriptions?subs=' + c.reference">{{ c.reference }}</a>
						</td>
						<td class="text-center">
							{{ c.paymentSystem || '' }}
						</td>
						<td class="text-center">
							<span v-if="!c.reference"></span>
							<template v-else>
								<span
									v-if="checkPermission('subscriptions:admin')"
									@click="changeNextRebillDate(c)"
									class="link"
								>
									{{ c.nextRebill || '' }}
								</span>
								<span v-else>
									{{ c.nextRebill || '' }}
								</span>
								<i
									v-if="c.nextRebill && c.camapaignEnd && c.nextRebill !== c.camapaignEnd"
									class="fa fa-fw fa-exclamation-triangle yellow"
									v-title="ii('END_CAMPAIGN_DATE_NOT_EQUAL_NEXT_REBILL_DATE')"
								></i>
							</template>
						</td>
						<td class="text-center">
							<i v-if="c.active" class="fa fa-fw fa-check green"></i>
							<i v-else class="fa fa-fw fa-remove danger"></i>
						</td>
					</tr>
				</template>
				<tr v-if="campaignsisEmpty">
					<td colspan="100" class="text-center">{{ ii('NO_DATA') }}</td>
				</tr>
				</tbody>
			</table>
		</template>
	</div>
`;

export default {
	template,
	data() {
		return {
			isLoading: true,
			campaigns: null,
			types: [
				{key: 'fullseo', text: 'FULLSEO'},
				{key: 'autoseo', text: 'AUTOSEO'},
				{key: 'analytics', text: 'ANALYTICS'}
			]
		};
	},
	props: ['idu', 'partner'],
	computed: {
		campaignsisEmpty() {
			let empty = true;
			if (!this.campaigns) return empty;

			this.types.forEach(type => {
				if (this.campaigns[type.key] && this.campaigns[type.key].length) empty = false;
			});

			return empty;
		}
	},
	methods: {
		async changeCampaignDate(row, field, type, plan) {
			let date = await this.input({
				type: 'date',
				title: this.ii('EDIT_' + type.toUpperCase() + '_CAMPAIGN_DATE'),
				val: row[field] || '0000-00-00'
			});
			if (!date) return;

			let params = {id: row.id, date, type, plan};
			let res = await this.post('SEOLaunch.changeCampaignDate', params);
			if (res.err) return this.alert(res.err);

			this.$set(row, field, date);
		},
		async changeNextRebillDate(row) {
			let nextRebill = await this.input({
				type: 'date',
				title: this.ii('EDIT_NEXT_REBILL_DATE'),
				val: row.nextRebill || '0000-00-00'
			});
			if (!nextRebill) return;

			let err = await this.post('Subscription.setRebillDate', row.reference, nextRebill);
			if (err) return this.alert(err);

			this.$set(row, 'nextRebill', nextRebill);
		}
	},
	async mounted() {
		if (this.idu) {
			this.campaigns = await this.get('UserCard.getUserCampaigns', this.idu, this.partner);
		}

		this.isLoading = false;
	}
};