import Store from '../../store.js';

const template = `
	<div v-if="pages.length">
		<template v-if="app.plan === 'standard' || app.plan === 'free-2'">
			<div class="panel-pages-box">
				<h2>{{ ii('STANDARD') }}</h2>
				<table>
					<template v-for="(group, i) in getGroups('lite')">
						<tr class="welcome-group">
							<td>{{ ii(group.label) }}</td>
						</tr>

						<template v-for="(page, i) in getGroupPages(group.id)" v-if="page.displaySt">
							<tr>
								<td class="page-name-cell" style="padding-left: 15px !important;">
									<a :href="'/' + page.slug">
										<i :class="'fa fa-fw fa-' + page.fa"></i>
										<strong>{{ ii(page.label) }}</strong>
										<template v-if="app.pagesNumbers[page.slug]">({{ app.pagesNumbers[page.slug] }})</template>
									</a>
								</td>
								<td>{{ getPageDesc(page.slug) }}</td>
							</tr>
						</template>
					</template>
					<template v-for="(group, i) in getGroups('')">
						<tr class="welcome-group">
							<td>{{ ii(group.label) }}</td>
						</tr>

						<template v-for="(page, i) in getGroupPages(group.id)" v-if="page.displaySt">
							<tr>
								<td class="page-name-cell" style="padding-left: 15px !important;">
									<a :href="'/' + page.slug">
										<i :class="'fa fa-fw fa-' + page.fa"></i>
										<strong>{{ ii(page.label) }}</strong>
										<template v-if="app.pagesNumbers[page.slug]">({{ app.pagesNumbers[page.slug] }})</template>
									</a>
								</td>
								<td>{{ getPageDesc(page.slug) }}</td>
							</tr>
						</template>
					</template>
				</table>
			</div>
			<div class="panel-pages-box">
				<h2>{{ ii('ADD_ONS') }}</h2>
				<table>
					<template v-for="(group, i) in getGroups('pro')">
						<tr class="welcome-group">
							<td>{{ ii(group.label) }}</td>
						</tr>

						<template v-for="(page, i) in getGroupPages(group.id)" v-if="page.displaySt">
							<tr>
								<td class="page-name-cell" style="padding-left: 15px !important;">
									<a :href="'/' + page.slug">
										<i :class="'fa fa-fw fa-' + page.fa"></i>
										<strong>{{ ii(page.label) }}</strong>
										<template v-if="app.pagesNumbers[page.slug]">({{ app.pagesNumbers[page.slug] }})</template>
									</a>
								</td>
								<td>{{ getPageDesc(page.slug) }}</td>
							</tr>
						</template>
					</template>
				</table>
			</div>
		</template>
		<div  v-else v-for="package in packages" class="panel-pages-box">
			<h2>{{ ii(package.title) }}</h2>
			<table>
				<template v-for="(group, i) in getGroups(package.key)">
					<tr class="welcome-group">
						<td>{{ ii(group.label) }}</td>
					</tr>

					<template v-for="(page, i) in getGroupPages(group.id)" v-if="page.displaySt">
						<tr>
							<td class="page-name-cell" style="padding-left: 15px !important;">
								<a :href="'/' + page.slug">
									<i :class="'fa fa-fw fa-' + page.fa"></i>
									<strong>{{ ii(page.label) }}</strong>
									<template v-if="app.pagesNumbers[page.slug]">({{ app.pagesNumbers[page.slug] }})</template>
								</a>
							</td>
							<td>{{ getPageDesc(page.slug) }}</td>
						</tr>
					</template>
				</template>
			</table>
		</div>
	</div>
`;

export default {
	template,
	data() {
		return {
			pages: [],
			groups: [],
			packages: [
				{key: 'lite', title: 'Lite package'},
				{key: 'pro', title: 'Pro package'},
				{key: '', title: 'Free package'}
			]
		};
	},
	computed: {
		...Store.mapState(['reseller', 'resellerInfo'])
	},
	methods: {
		getDeveloperLinks() {
			let links = [
				{table: 'adm_pages', text: 'Pages'},
				{table: 'adm_page_groups', text: 'Groups'}
			];
			return links;
		},
		getPageDesc(slug) {
			let text = this.ii('PAGE_DESC_' + slug);
			if (text.indexOf('PAGE_DESC_') === 0) return '';
			return text;
		},
		getGroups(packageKey) {
			let groupIds = this.pages
				.filter(p => p.displaySt && p.slug && p.plan === packageKey)
				.map(p => p.groupId)
				.filter(this.filterUnique);

			let groups = this.groups.filter(g => groupIds.indexOf(g.id) !== -1);
			if (groupIds.indexOf(0) !== -1) {
				groups.push({
					id: 0,
					label: this.ii('OTHER')
				});
			}
			return groups;
		},
		getGroupPages(groupId) {
			let pages = this.pages.filter(p => p.displaySt && p.groupId === groupId && !p.parentId);

			pages = pages.map(page => {
				let childPages = this.pages.filter(c => c.displaySt && c.groupId === page.groupId && c.parentId === page.id);
				if (!childPages.length) {
					return page.isLink ? page : false;
				}

				page.pages = childPages;
				page.isParent = true;
				return page;
			});

			pages = pages.filter(p => p);

			return pages;
		},
	},
	async mounted() {
		this.pages = await this.get('Page.getAllowedPages');
		this.groups = await this.get('Page.getPageGroups');
	}
};
