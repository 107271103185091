const template = `
	<div class="trigger-wrapper" :style="width ? ('width: ' + width + 'px;') : ''">
		<strong class="trigger-title">
			{{ status ? on : off}}	
		</strong>
		<div 
			class="trigger" 
			@click="trig()" 
			:value="status"
			:class="size"
		>
			<div :style="status ? onStyle : offStyle">
				|||
			</div>
		</div>
		<div class="clearfix"></div>
	</div>
`;

export default {
	template: template,
	data() {
		return({
			onStyle: 'left: 45%',
			offStyle: 'background: #898c8c;',
			status: null,
		});
	},
	props: ['value', 'size', 'on', 'off', 'width', 'true-value', 'false-value', 'static-color'],
	computed: {
		onVal() {
			return this.trueValue !== void 0 ? this.trueValue : true;
		},
		offVal() {
			return this.falseValue !== void 0 ? this.falseValue : false;
		}
	},
	methods: {
		trig() {
			this.status = !this.status;
			let val = this.status ? this.onVal : this.offVal;
			this.$emit('input', val);
			this.$emit('change', {isTrusted: true});
		},
		updateVal() {
			if (this.trueValue !== void 0) {
				this.status = (this.value === this.trueValue);
			} else {
				this.status = !!this.value;
			}
		}
	},
	watch: {
		value() {
			this.updateVal();
		}
	},
	mounted() {
		this.updateVal();
		if (this.staticColor) this.offStyle = '';
		if (this.size === 'trigger-round') {
			this.onStyle = 'transform: rotate(90deg)';
		}
	}
};

