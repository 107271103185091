import Store from '../../store.js';

const template = `
	<div class="mail-sender">
		<div>
			<table class="mail-sender-params-tbl width100p">
				<tr>
					<td class="general-upper">{{ ii('FROM') }}:</td>
					<td>{{ sender.emailName }} &lt;{{ sendType !== 'smtp' ? sendType : sender.emailSend }}&gt;</td>
				</tr>
				<tr v-if="!toEmails.length">
					<td class="general-upper">{{ ii('TO') }}:</td>
					<td><input type="text" class="align-middle width200" disabled></td>
				</tr>
				<tr v-for="(email, i) in toEmails">
					<td class="general-upper">
						<template v-if="i === 0">{{ ii('TO') }}:</template>
					</td>
					<td>
						<input type="text" v-model="toEmails[i]" class="align-middle width200">
						<template v-if="i === 0">
							<span
								class="align-middle link fa fa-plus"
								v-title="ii('ADD_RECIPIENT')"
								@click="addRecipient()"
							></span>
						</template>
						<template v-else>
							<span
								v-for="email in altEmails"
								class="align-middle link m-right5"
								@click="setRecipient(i, email)"
							>{{ email }}</span>
							<span
								class="align-middle link fa fa-remove"
								@click="removeRecipient(i)"
							></span>
						</template>
					</td>
				</tr>
				<tr>
					<td class="general-upper">{{ ii('TEMPLATE') }}:</td>
					<td>
						<rich-sel
							v-model="tmplId"
							:items="getTemplateItems()"
							class="width100p"
						></rich-sel>
					</td>
				</tr>
				<tr>
					<td class="general-upper">{{ ii('SUBJECT') }}:</td>
					<td>
						<input type="text" v-model="subject" class="width100p">
					</td>
				</tr>
				<tr v-if="sentEmails.length">
					<td class="general-upper">{{ ii('SEND_TYPE') }}:</td>
					<td>
						<select v-model="sendType" @change="onChangeSendType">
							<option value="smtp">SMTP</option>
							<option v-for="email in sentEmails" :value="email">API - {{ email }}</option>
						</select>
					</td>
				</tr>
			</table>
		</div>
		<div>
			<editor :idu="idu" ref="editor" with-payment-link="1" @blur="onEditorBlur" @switchmode="onEditorSwitchMode"></editor>
		</div>
		<div class="mail-sender-bottom">
			<btn size="small" type="file" multiple="1" @change="onAttachChanged" noFileInfo="true">
				<i class="fa fa-fw fa-file-archive-o" slot="icon"></i>
				{{ ii('ATTACH_FILES') }}
			</btn>
			<btn size="small" @click.native="send" :loading="isSending" :disabled="sourceShown">
				<i class="fa fa-fw fa-send" slot="icon"></i>
				{{ ii('SEND') }}
			</btn>
			<div class="right padding-5-0">
				<span class="attachment" v-for="item in attachments">{{ item.name }}</span>
			</div>
		</div>
		<div class="attach-files-bottom" v-if="drag">{{ ii('DRAG_N_DROP_FILES_HERE') }}</div>
		
		<popup
			v-if="payLinkPopup"
			close-on-back="1"
			hard-close="1"
			no-scroll="1"
			width="500"
			@close="onLinkPopupClosed"
		>
			<div slot="header">Payment link</div>
			<pay-link
				class="padding10"
				:idu="idu"
				:package-id="payLinkPopup.pack"
				with-link-text-tf="1"
				@generate="onLinkGenerated"
			></pay-link>
		</popup>
	</div>
`;

export default {
	template,
	props: ['idu'],
	data() {
		return {
			toEmails: [],
			subject: '',
			user: {},
			domains: [],
			links: [],
			manager: {},
			ownTemplates: [],
			genTemplates: [],
			sentEmails: [],
			semaltTechIds: [],
			tmplId: 0,
			sendType: 'smtp',
			attachments: null,
			isSending: false,
			payLinkPopup: null,
			maxDraftAge: 3 * 3600 * 1000,
			saveDraftTm: null,
			sourceShown: false,
			drag: false
		};
	},
	computed: {
		...Store.mapState(['worker', 'workersMap']),
		altEmails() {
			let emails = this.user.altEmails.filter(email => {
				return this.toEmails.indexOf(email) === -1;
			});
			return emails;
		},
		sender() {
			if (this.worker.behalfIdm) {
				for (let idw in this.workersMap) {
					let w = this.workersMap[idw];
					if (w.idm === this.worker.behalfIdm) return w;
				}
				return null;
			}
			return this.worker;
		}
	},
	methods: {
		async loadMailsData() {
			let info = await this.get('Mail.getMailParams', this.idu);

			let templates = info.tmpl || {};
			this.user = info.user;
			this.domains = info.domains;
			this.links = info.links;
			this.manager = info.manager;
			this.semaltTechIds = info.semaltTechIds || [];
			this.ownTemplates = info.tmpl.ownTemplates || [];
			this.genTemplates = info.tmpl.genTemplates || [];

			//if (this.semaltTechIds.length) this.loadSemaltTechMailsData(templates);

			this.toEmails.push(info.user.email);

			let draft = this.getDraft();
			this.setMailHtml(draft || info.manager.emailSignature);
		},
		async loadSemaltTechMailsData(templates) {
			let info = await this.post('Mail.getSemaltTechMailsData', templates, this.domains);

			this.ownTemplates = info.ownTemplates || [];
			this.genTemplates = info.genTemplates || [];

			if (!this.inArray(this.tmplId, this.semaltTechIds)) return;

			this.setMailTemplate(this.tmplId);
		},
		getMailHtml() {
			if (!this.$refs.editor) return;
			return this.$refs.editor.getHtml();
		},
		setMailHtml(html) {
			if (!this.$refs.editor) return;
			this.$refs.editor.setHtml(html);
		},
		templateToSelItem(tmpl, startChar) {
			let key = startChar + tmpl.id;
			let val = tmpl.title || tmpl.subject;
			return {key, val};
		},
		getTemplateItems() {
			let ownItems = this.ownTemplates.map(t => this.templateToSelItem(t, 'o'));
			let genItems = this.genTemplates.map(t => this.templateToSelItem(t, 'g'));

			let items = [...ownItems, null, ...genItems];
			return items;
		},
		addRecipient(email) {
			this.toEmails.push(email || '');
		},
		setRecipient(i, email) {
			this.$set(this.toEmails, i, email);
		},
		removeRecipient(i) {
			this.toEmails.splice(i, 1);
		},
		onAttachChanged(e) {
			this.attachments = e.target.files;
		},
		async send() {
			let html = this.getMailHtml();

			if (!this.toEmails[0]) return this.alert(this.ii('ENTER_RECIPIENT_EMAIL'));
			if (!this.subject) return this.alert(this.ii('ENTER_SUBJECT'));
			if (!html) return this.alert(this.ii('ENTER_MESSAGE'));

			let data = new FormData;
			this.toEmails.forEach((email, i) => {
				if (!email) return;
				if (i === 0) {
					data.append('toEmail', email);
				} else {
					data.append('toEmailAdd[]', email);
				}
			});

			data.append('idu', this.idu);
			data.append('subject', this.subject);
			data.append('html', html);
			data.append('sendType', this.sendType);

			if (this.attachments) {
				[...this.attachments].forEach(file => {
					data.append('files[]', file);
				});
			}

			this.isSending = true;
			let res = await axios.post('/api/mail/send', data);
			this.isSending = false;

			if (res.data.success) {
				await this.alert(this.ii('MAIL_HAS_BEEN_SENT'));
				return;
			}
			if (!res.data.showLinks) return this.alert(res.data.reason);

			this.showSmtpError(res.data.reason);
		},
		showSmtpError(error) {
			let links = [
				'https://security.google.com/settings/security/activity?hl=en&pli=1',
				'https://accounts.google.com/b/0/DisplayUnlockCaptcha',
				'https://www.google.com/settings/u/0/security/lesssecureapps',
				'http://support.google.com/mail/bin/answer.py?answer=14257'
			];
			let linksHtml = links.map(link => {
				return `<li><a href="${link}" target="_blank" rel="noreferrer noopener">${link}</a></li>`;
			}).join('');
			let errMessageHtml = `
				<div class="smtp-error">
					<strong>${error}</strong>
					<div>${this.ii('TRY_THE_FOLLOWING')}:</div>
					<ul>${linksHtml}</ul>
				</div>
			`;
			this.alert(errMessageHtml.trim(), {width: 500, html: true});
		},
		async handleDynamicContent() {
			await this.handlePaymentLinks();
			await this.handleCustomPlaceholders();
		},
		async handlePaymentLinks() {
			let html = this.getMailHtml();

			let match;
			let regex = /{PAYMENT_LINK:(.*?)}/g;
			while (match = regex.exec(html)) {
				let pack = match[1];

				let res = await this.generateLink({pack});
				if (!res) return;

				let linkHtml = `<a href="${this.escapeHtml(res.url)}">${this.escapeHtml(res.text || res.url)}</a>`;
				html = html.replace(match[0], linkHtml);
				this.setMailHtml(html);
			}
		},
		async handleCustomPlaceholders() {
			let html = this.getMailHtml();

			let match;
			let regex = /{(SUMM|CUSTOM_TEXT)}/g;
			while (match = regex.exec(html)) {
				let input = await this.prompt('');
				if (input === null) return;

				html = html.replace(match[0], input);
				this.setMailHtml(html);
			}
		},
		generateLink(opts) {
			return new Promise(resolve => {
				this.payLinkPopup = opts;
				this.payLinkPopup.onClose = res => resolve(res);
			});
		},
		onLinkGenerated(res) {
			this.payLinkPopup.onClose(res);
			this.payLinkPopup = null;
		},
		onLinkPopupClosed() {
			this.payLinkPopup.onClose();
			this.payLinkPopup = null;
		},
		onEditorBlur() {
			this.saveDraft();
		},
		onEditorSwitchMode(e) {
			this.sourceShown = !!e.sourceShown;
			this.saveDraft();
		},
		saveDraft() {
			let html = this.getMailHtml();
			let key = 'draft' + this.idu;
			if (html) {
				let val = Date.now() + ':' + html;
				localStorage.setItem(key, val);
			} else {
				localStorage.removeItem(key);
			}
		},
		getDraft() {
			let tsLen = Date.now().toString().length;
			let val = localStorage.getItem('draft' + this.idu);
			if (!val) return '';
			let html = val.slice(tsLen + 1);
			return html;
		},
		clearOldDrafts() {
			Object.keys(localStorage).filter(key => /^draft\d+$/.test(key)).forEach(key => {
				let val = localStorage.getItem(key);
				let now = Date.now();
				let tsLen = now.toString().length;
				let ts = parseInt(val.slice(0, tsLen));
				if (now - ts < this.maxDraftAge) return;

				localStorage.removeItem(key);
			});
		},
		setupAttachFilesDnd() {
			document.addEventListener('dragenter', this.onAttachDragEnter);
			document.addEventListener('dragover', this.onAttachDragOver);
			document.addEventListener('drop', this.onAttachDrop);
		},
		unsetAttachFilesDnd() {
			document.removeEventListener('dragenter', this.onAttachDragEnter);
			document.removeEventListener('dragover', this.onAttachDragOver);
			document.removeEventListener('drop', this.onAttachDrop);
		},
		onAttachDragEnter(e) {
			e.preventDefault();
		},
		onAttachDragOver(e) {
			e.preventDefault();
			let card = e.path.filter(i => i.className == 'maincard');
			if (!card.length) return this.drag = false;
			this.drag = true;
		},
		onAttachDrop(e) {
			e.preventDefault();
			this.drag = false;
			let card = e.path.filter(i => i.className == 'maincard');
			if (!e.dataTransfer || !e.dataTransfer.files || !card.length) return;

			this.attachments = e.dataTransfer.files;
		},
		async getGmailSendTypeStatus() {
			this.sentEmails = await this.get('Gmail.getGmailSentEmails');
			if (!this.sentEmails || !this.sentEmails.length) return;

			let type = this.localGetItem('mailSendType');
			if (type) this.sendType = type;
		},
		onChangeSendType() {
			this.localSetItem('mailSendType', this.sendType);
		},
		setMailTemplate(tmplId = 0) {
			if (!tmplId) return;

			let letter = tmplId.charAt(0);
			let id = parseInt(tmplId.slice(1));
			let templates = (letter === 'o' ? this.ownTemplates : this.genTemplates);
			let template = templates.filter(tmpl => tmpl.id === id)[0];

			this.subject = this.stripTags(template.subject.replace(/<br>/ig, ' '));
			this.setMailHtml(template.body + '<br>' + this.manager.emailSignature);

			this.$nextTick(this.handleDynamicContent);
		}
	},
	watch: {
		tmplId() {
			this.setMailTemplate(this.tmplId);
		}
	},
	mounted() {
		this.getGmailSendTypeStatus();
		this.clearOldDrafts();
		this.loadMailsData();
		this.setupAttachFilesDnd();

		this.saveDraftTm = setInterval(() => {
			this.saveDraft();
		}, 2000);
	},
	destroyed() {
		clearInterval(this.saveDraftTm);
		this.unsetAttachFilesDnd();
	}
};
