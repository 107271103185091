import Store from '../../store.js';
import methods from '../../mixin/methods.js';

const template = `
	<div class="fb-chat" :class="inputFocused ? 'fb-chat-input-focused' : ''" :style="{height: height}">
		<div class="fb-chat-header">
			<div class="fb-chat-controls">
				<span v-if="isLoading" class="fa fa-fw fa-spinner spin-fast"></span>
				<span v-else class="link fa fa-fw fa-refresh" @click="loadChat"></span>
				<span class="link fa fa-remove m-left5" @click="onClose"></span>
			</div>
			
			<div v-for="(user, i) in fbUsers">
				<span>
					<span class="weight500">{{ ii('FB') }}: </span>
					{{ user.fbName }} 
					<a
						v-if="user.fbLink"
						:href="user.fbLink"
						class="fa fa-fw fa-external-link"
						target="_blank"
						rel="noreferrer noopener"
						v-title="ii('GO_TO_FB_PROFILE')"
					></a>
					<span
						class="link zmdi zmdi-edit"
						v-title="ii('EDIT_FB_PROFILE_URL')"
						v-if="checkPermission('fbusers:admin')"
						@click="addFbLink(user)"
					></span>
				</span>
				<span class="m-left5">
					<span class="weight500">{{ ii('SEMALT') }}: </span>
					<template v-if="user.idu">
						<a
							:href="'/leads?idu='+user.idu+'#usercard'"
							target="_blank"
							class="static-color"
						>{{ user.idu || '' }}</a>
						<flag :cc="user.countryCode" v-if="user.countryCode"></flag>
						<a
							:href="user.accountLink"
							target="_blank"
							v-title="ii('GO_TO_ACCOUNT') + '\\n' + user.email"
							class="static-color"
						>
							<i class="fa fa-fw fa-user-circle link"></i>
						</a>
					</template>
					<template v-else>No account</template>
				</span>
				<span v-if="user.idm" class="m-left5">
					<span class="weight500">{{ ii('MANAGER') }}: </span>
					<worker-tooltip :idm="user.idm">
						{{ getManager(user.idm).realName || user.idm }}
					</worker-tooltip>
				</span>
				<span 
					v-if="i > 0 && fbUsers.length && user.fbId !== fbUsers[0].fbId"
					@click="changeChat(user.fbId, i)"
					class="right link fa fas fa-comment"
				></span>
			</div>
		</div>
		<div
			class="fb-chat-content"
			v-show="!inputFocused || win.width > win.MD"
			ref="contentBox"
			v-block-scroll
			@scroll="onScroll"
		>
			<table class="fb-chat-tbl">
				<thead v-if="recentUrl">
					<tr>
						<td colspan="100" class="text-center">
							<span v-if="isLoadingMore" class="fa fa-spinner spin-fast"></span>
							<span v-else class="link" @click="loadPrevMessages">{{ ii('LOAD_PREVIOUS_MSG') }}</span>
						</td>
					</tr>
				</thead>
				<tbody>
					<tr v-if="messages && !messages.length">
						<td colspan="100" class="text-center grey">
							<em>{{ ii('NO_MESSAGES_YET') }}</em>
						</td>
					</tr>
					<tr v-for="msg in messages" class="msg-row">
						<td class="msg-sender-cell">
							<span
								v-title="getWorker(msg.idw).realName || ''"
								:class="msg.idw ? 'help' : ''"
							>{{ getSenderName(msg) }}</span>
						</td>
						<td class="msg-message-cell">
							<div v-html="newLineToBr(wrapLinks(msg.text), 2)"></div>
							<div
								v-if="msg.isLoadingAttachment"
								class="fb-attachment fb-attachment-loading"
							>
								<i class="fa fa-spinner spin-fast"></i>
							</div>
							<div v-for="file in msg.files" class="fb-attachment">
								<a
									v-if="file.type === 'image'"
									:href="file.url"
									data-no-image-proxy="1"
									target="_blank"
									rel="noreferrer noopener"
								>
									<img :src="file.previewUrl" @load="tryScrollToBottom">
								</a>
								<template v-else-if="file.type === 'audio'">
									<audio :src="file.url" controls></audio>
									<div v-if="file.name"><em>{{ file.name }}</em></div>
								</template>
								<a
									v-else-if="file.url"
									:href="file.url"
									target="_blank"
									rel="noreferrer noopener"
								>
									<i class="fa fa-file-archive-o"></i>
									{{ file.name || 'Open file' }}
								</a>
							</div>
						</td>
						<td class="msg-time-cell">
							<span><datetime :timestamp="msg.ts"></datetime></span>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="fb-chat-send-box">
			<textarea
				ref="msgInput"
				v-model="msgToSend"
				:disabled="isSending"
				maxlength="2000"
				@focus="onTextAreaFocus"
				@blur="onTextAreaBlur"
				@input="updateMsg"
				@keydown.enter="enterAction($event)"
			></textarea>
			<btn type="file" ref="attachment" size="small" :loading="isSending">
				<i class="fa fa-fw fa-file-archive-o" slot="icon"></i>
				{{ ii('ATTACH_FILE') }}
			</btn>
			<btn size="small" :loading="isSending" @click.native="sendMessage" @mousedown.native.prevent>
				<i class="fa fa-fw fa-send" slot="icon"></i>
				{{ ii('SEND') }}
			</btn>
			<!--
			<div v-for="user in fbUsers" v-if="user.fbId == fbId" class="padding5">
				{{ user.ctm < 24*60*60 ? iiArgs('FB_SEND_MSG_LIMIT', getHumanTime(24*60*60 - user.ctm)) : ii('FB_SEND_MSG_LIMIT_ONE') }}
			</div>
			-->
			<btn class="right" size="small" :loading="isSending" @click.native="addPaymentLink" @mousedown.native.prevent>
				<i class="fa fa-fw fa-dollar"></i>
				{{ ii('PAYMENT_LINK') }}
			</btn>
		</div>
		
		<popup
			v-if="payLinkPopup"
			close-on-back="1"
			no-scroll="1"
			width="500"
			@close="payLinkPopup = null"
		>
			<div slot="header">{{ ii('PAYMENT_LINK') }}</div>
			<pay-link class="padding10" :idu="fbUsers[0].idu" with-link-text-tf="1" @generate="onLinkGenerated"></pay-link>
		</popup>
	</div>
`;

export default {
	template,
	data() {
		return {
			fbPageId: null,
			fbId: null,
			fbUsers: null,
			messages: null,
			msgToSend: '',
			listeningEvent: null,
			isSending: false,
			isScrolledBottom: false,
			isLoading: false,
			isLoadingMore: false,
			recentUrl: null,
			info: null,
			height: null,
			inputFocused: false,
			payLinkPopup: null,
			selection: null
		};
	},
	props: ['idu', 'facebookId'],
	computed: {
		...Store.mapState(['workersMap', 'managersMap']),
		fbUserName() {
			if (!this.fbUsers || !this.fbId) return null;
			let user = this.fbUsers.filter(u => u.fbId === this.fbId)[0];
			if (!user) return null;
			return user.fbName;
		}
	},
	methods: {
		addPaymentLink() {
			this.saveSelection();
			this.payLinkPopup = true;
		},
		saveSelection() {
			let position = this.$refs.msgInput.selectionStart;
			position ? this.selection = position : this.selection = null;
		},
		onLinkGenerated(e) {
			this.payLinkPopup = null;

			let url = this.escapeHtml(e.url);
			if (this.selection) {
				this.msgToSend = this.msgToSend.slice(0, this.selection) + url + this.msgToSend.slice(this.selection);
				return;
			}

			if (!this.msgToSend) {
				this.msgToSend = url;
				return;
			}

			this.msgToSend = this.msgToSend + url;
		},
		async loadChat() {
			if (!this.fbId) return;

			this.isLoading = true;
			this.recentUrl = null;
			let res = await this.get('FacebookChat.getConversationByFbId', this.fbId);
			this.messages = this.addAttachmentsToMessages(res.messages);
			this.recentUrl = res.recentUrl;
			this.isLoading = false;
			this.$nextTick(this.scrollToBottom);

			if (res.error) return this.alert(res.error);

			this.markRead(this.fbId);
		},
		async loadPrevMessages() {
			if (!this.recentUrl) return;

			let heightBefore = this.$refs.contentBox.scrollHeight;
			let scrollBefore = this.$refs.contentBox.scrollTop;

			this.isLoadingMore = true;
			let res = await this.get('FacebookChat.getConversationByUrl', this.recentUrl);
			this.messages = this.addAttachmentsToMessages(res.messages).concat(this.messages);
			this.recentUrl = res.recentUrl;
			this.isLoadingMore = false;

			this.$nextTick(() => {
				let heightAfter = this.$refs.contentBox.scrollHeight;
				let newTop = heightAfter - heightBefore + scrollBefore;
				this.$refs.contentBox.scrollTop = newTop;
			});
		},
		onTextAreaFocus() {
			this.inputFocused = true;
			if (this.isUnread) {
				this.markRead(this.fbId);
			}
		},
		onTextAreaBlur() {
			this.inputFocused = false;
		},
		addAttachmentsToMessages(messages) {
			if (!messages) return messages;

			messages.forEach(msg => {
				if (msg.text) return;

				this.$set(msg, 'isLoadingAttachment', true);
				this.get('FacebookChat.getMsgAttachments', msg.id).then(res => {
					for (let key in res) {
						this.$set(msg, key, res[key]);
					}
					this.$set(msg, 'isLoadingAttachment', false);
				});
			});
			return messages;
		},
		async sendMessage() {
			let file = this.$refs.attachment.$refs.file.files[0];
			let msg;

			if (this.msgToSend) msg = this.msgToSend.trim();
			if (!msg && !file) return;

			if (file) {
				let sizeMb = file.size / 1024 / 1024;
				if (sizeMb > 25) return this.alert('The file you have selected is too large. The maximum size is 25MB.');
			}

			this.isSending = true;
			if (msg) {
				let err = await this.post('FacebookChat.sendMessageFromGroup', this.fbId, msg);
				if (err) {
					this.isSending = false;
					return this.alert(err);
				}
			}
			if (file) {
				let form = new FormData;
				form.append('fbId', this.fbId);
				form.append('attachment', file);
				let err = await this.upload('FacebookChat.uploadMessageFromGroup', form);
				if (err) {
					this.isSending = false;
					return this.alert(err);
				}
			}
			this.isSending = false;

			if (this.app.routeName === 'fbusersChat') this.localRemoveItem('fbChat' + this.app.params.fbId);
			this.msgToSend = '';
			this.$refs.attachment.resetFile();
			this.$nextTick(() => {
				this.$refs.msgInput.focus();
			});
		},
		getSenderName(msg) {
			if (msg.fromId === this.fbPageId) return 'Semalt';
			return this.fbUserName || this.ii('UNNAMED_USER');
		},
		onEvent(data) {
			let files = null;
			if (data.msg.message.attachments) {
				files = data.msg.message.attachments.map(file => {
					return {
						type: file.type,
						url: file.payload.url,
						previewUrl: file.payload.url,
						name: file.payload.name
					};
				});
			}
			this.messages.push({
				text: data.msg.message.text,
				fromId: data.msg.sender.id,
				ts: Math.round(data.msg.timestamp / 1000),
				files
			});
			this.$nextTick(this.tryScrollToBottom);
			if (data.msg.sender.id !== this.fbPageId) {
				this.beep();

				this.isUnread = true;
				if (document.visibilityState && document.visibilityState !== 'visible') return;
				this.markRead(data.msg.sender.id);
			}
		},
		onScroll() {
			let box = this.$refs.contentBox;
			if (!box) return;
			this.isScrolledBottom = (box.offsetHeight + box.scrollTop >= box.scrollHeight);
		},
		scrollToBottom() {
			if (!this.$refs.contentBox) return;
			this.$refs.contentBox.scrollTop = this.$refs.contentBox.scrollHeight;
		},
		tryScrollToBottom() {
			if (!this.isScrolledBottom) return;
			this.scrollToBottom();
		},
		checkOwnLead() {
			if (!this.worker.idm) return false;

			let own = this.fbUsers.filter(u => u.fbId === this.fbId && u.idm == this.worker.idm);
			return !!own.length;
		},
		async markRead(fbId) {
			let isOwn = this.checkOwnLead();
			if (!isOwn) return;

			await this.post('FacebookChat.markRead', fbId);
			this.isUnread = false;
			await this.sleep(1000);
			this.app.updatePagesNews();
		},
		async initChat() {
			if (!this.idu && !this.facebookId) return;

			if (!this.idu && this.facebookId) {
				this.info = await this.get('FacebookChat.getUsersInfo', 0, this.facebookId);
			} else {
				this.info = await this.get('FacebookChat.getUsersInfo', this.idu, 0);
			}

			this.fbUsers = this.info.users;
			if (!this.fbUsers.length) return;

			let user;
			if (this.facebookId) {
				user = this.fbUsers.filter(u => u.fbId === this.facebookId)[0];
			}
			if (!this.fbId) {
				user = this.fbUsers[0];
			}
			this.fbId = user.fbId;
			this.fbPageId = user.pageId;

			this.$emit('load');
			if (this.win.width > this.win.MD && this.$refs.msgInput) {
				this.$refs.msgInput.focus();
			}

			if (this.app.routeName !== 'fbusersChat') return;
			this.msgToSend = this.localGetItem('fbChat' + this.app.params.fbId);
		},
		unInitChat() {
			this.messages = null;
			this.fbId = null;
			if (this.listeningEvent) {
				this.offServerEvent(this.listeningEvent, this.onEvent);
			}
		},
		onClose() {
			this.$emit('close');
		},
		setHeight(height) {
			if (height != null) {
				height += 'px';
			}
			this.height = height;
		},
		enterAction(e) {
			if (!e.shiftKey) {
				e.preventDefault();
				this.sendMessage();
			}
		},
		async addFbLink(user) {
			let url = await this.prompt(this.ii('ENTER_FB_PROFILE_URL'), user.fbLink);
			if (url === null) return;

			if (url && !/^https?:\/\/.*?facebook\.com\/.+/i.test(url)) {
				return this.alert(this.ii('INVALID_FB_PROFILE_URL'));
			}

			let err = await this.post('FacebookChat.addFbLink', user.fbId, url);
			if (err) return this.alert(this.ii(err));

			this.fbUsers.forEach(r => {
				if (r.fbId === user.fbId) this.$set(r, 'fbLink', url);
			});
		},
		updateMsg: methods.debounce(function() {
			if (this.app.routeName !== 'fbusersChat') return;

			this.localSetItem('fbChat' + this.app.params.fbId, this.msgToSend);
			if (!this.msgToSend) this.localRemoveItem('fbChat' + this.app.params.fbId);
		}, 500),
		changeChat(fbId, i) {
			let tmp = this.fbUsers[0];
			this.$set(this.fbUsers, 0, this.fbUsers[i]);
			this.$set(this.fbUsers, i, tmp);
			this.fbId = fbId;
		}
	},
	watch: {
		fbId() {
			this.loadChat();

			if (this.listeningEvent) {
				this.offServerEvent(this.listeningEvent, this.onEvent);
			}

			let event = 'fbchat' + this.fbId;
			this.listeningEvent = event;
			this.onServerEvent(event, this.onEvent);
		},
		facebookId() {
			this.unInitChat();
			this.initChat();
		},
		idu() {
			this.unInitChat();
			this.initChat();
		}
	},
	async mounted() {
		await this.loadState(['managersMap']);
		this.initChat();
	},
	destroyed() {
		this.unInitChat();
	}
};
