const template = `
	<popup
		close-on-back="1"
		class="callback-request-popup"
		@close="onClose"
	>
		<div slot="header">{{ header || ii('CALLBACK_REQUEST') }}</div>
		<div v-if="isLoading" class="popup-loading">
			<i class="fa fa-spinner spin-fast"></i> {{ ii('LOADING') }}...
		</div>
		<div v-else>
			<table>
				<tr v-for="param in params" :key="param.key">
					<td class="cl-req-key general-upper">{{ param.key }}:</td>
					<td>
						<template v-if="param.key === 'geo'">
							<flag :cc="param.val" no-title="1"></flag>
							{{ getCountryByCode(param.val) }}
						</template>
						<datetime v-else-if="param.key === 'time'" :timestamp="param.val"></datetime>
						<template v-else>{{ param.val }}</template>
					</td>
				</tr>
			</table>
		</div>
	</popup>
`;

export default {
	template,
	props: ['header', 'request-id'],
	data() {
		return {
			isLoading: true,
			params: null
		};
	},
	methods: {
		async loadInfo() {
			this.isLoading = true;
			let request = await this.get('ClientRequest.getCallbackRequestById', this.requestId);
			this.isLoading = false;
			if (!request) return;

			let params = [];
			for (let key in request) {
				params.push({key, val: request[key]});
			}

			this.params = params;
		},
		onClose(e) {
			this.$emit('close', e);
		}
	},
	watch: {
		requestId() {
			this.loadInfo();
		}
	},
	mounted() {
		this.loadInfo();
	}
};