const template = `
	<div class="editor">
		<div
			v-show="!sourceShown"
			contenteditable="1"
			ref="field"
			class="editor-field"
			v-html="html"
			@focus="onFocus"
			@blur="onBlur"
			@click="settings"
		></div>
		<textarea
			v-show="sourceShown"
			v-model="sourceCode"
			ref="source"
			class="editor-source monospace"
			:style="{height: sourceHeight}"
		></textarea>
		<div class="editor-controls clearfix" ref="controls" :style="{top: controlsTop}">
			<i class="fa fa-fw fa-bold" v-title="ii('BOLD')" @mousedown.prevent @click="apply('bold')">
			</i><i class="fa fa-fw fa-italic" v-title="ii('ITALIC')" @mousedown.prevent @click="apply('italic')">
			</i><i class="fa fa-fw fa-underline" v-title="ii('UNDERLINE')" @mousedown.prevent @click="apply('underline')">
			</i><i class="fa fa-fw fa-strikethrough" v-title="ii('STRIKETHROUGH')" @mousedown.prevent @click="apply('strikethrough')">
			</i>
			<span class="sep"></span>
			<i class="fa fa-fw fa-list-ol" v-title="ii('ORDERED_LIST')" @mousedown.prevent @click="apply('insertOrderedList')">
			</i><i class="fa fa-fw fa-list-ul" v-title="ii('UNORDERED_LIST')" @mousedown.prevent @click="apply('insertUnorderedList')">
			</i>
			<span class="sep"></span>
			<i class="fa fa-fw fa-align-left" v-title="ii('ALIGN_LEFT')" @mousedown.prevent @click="apply('justifyLeft')">
			</i><i class="fa fa-fw fa-align-center" v-title="ii('ALIGN_CENTER')" @mousedown.prevent @click="apply('justifyCenter')">
			</i><i class="fa fa-fw fa-align-right" v-title="ii('ALIGN_RIGHT')" @mousedown.prevent @click="apply('justifyRight')">
			</i><i class="fa fa-fw fa-align-justify" v-title="ii('ALIGN_FULL')" @mousedown.prevent @click="apply('justifyFull')">
			</i>
			<span class="sep"></span>
			<i class="fa fa-fw fa-eraser" v-title="ii('REMOVE_FORMATTING')" @mousedown.prevent @click="apply('removeFormat')"></i>
			<span class="sep"></span>
			<i class="fa fa-fw fa-link" v-title="ii('LINK')" @mousedown.prevent @click="makeHyperlink()"></i>
			<i class="fa fa-fw fa-picture-o" v-title="ii('IMAGE')" @mousedown.prevent @click="insertImage()"></i>
			<span class="sep"></span>
			<i class="fa fa-fw fa-text-height editor-picker-btn" @mousedown.prevent>
				<div class="editor-picker-panel">
					<span
						v-for="item in sizes"
						class="link nowrap editor-picker-item"
						:style="{fontSize: item.labelSize + 'px'}"
						@click="applyFontSize(item.size)"
					>{{ ii(item.label) }}</span>
				</div>
			</i><i class="fa fa-fw fa-font editor-picker-btn" @mousedown.prevent>
				<div class="editor-picker-panel">
					<span
						v-for="font in fonts"
						class="link nowrap editor-picker-item"
						:style="{fontFamily: font}"
						@click="applyFontFamily(font)"
					>{{ font }}</span>
				</div>
			</i>
			<span class="sep"></span>
			<i class="fa fa-fw fa-paint-brush editor-picker-btn" @mousedown.prevent>
				<div class="editor-picker-panel">
					<div class="editor-color-row nowrap" v-for="row in colorsGrid">
						<div
							class="editor-color-cell"
							v-for="color in row"
							:style="{background: color}"
							@click="setColor(color)"
						></div>
					</div>
				</div>
			</i>
			<template v-if="domains && domains.length">
				<span class="sep"></span>
				<i class="fa fa-fw fa-globe editor-picker-btn" @mousedown.prevent>
					<div class="editor-picker-panel" v-block-scroll>
						<span
							v-for="domain in domains"
							class="link nowrap editor-picker-item"
							@click="insertSiteLink(domain)"
						>{{ domain }}</span>
					</div>
				</i>
			</template>
			<span class="sep"></span>
			<i class="fa fa-fw fa-dollar" v-if="withPaymentLink" v-title="ii('PAYMENT_LINK')" @mousedown.prevent @click="addPaymentLink"></i>
			<i
				v-for="btn in plugins"
				:class="btn.className"
				v-title="btn.title"
				@mousedown.prevent
				@click="onPluginBtnClick(btn)"
			></i>

			<i class="fa fa-fw fa-code right" v-title.left="ii('SOURCE_CODE')" @mousedown.prevent @click="toggleSource"></i>
		</div>

		<popup
			v-if="payLinkPopup"
			close-on-back="1"
			no-scroll="1"
			width="500"
			@close="payLinkPopup = null"
		>
			<div slot="header">{{ ii('PAYMENT_LINK') }}</div>
			<pay-link class="padding10" :idu="idu" with-link-text-tf="1" @generate="onLinkGenerated"></pay-link>
		</popup>

		<popup
			v-if="imgSettingsPopup"
			close-on-back="1"
			no-scroll="1"
			width="240"
			@close="imgSettingsPopup = false"
		>
			<div slot="header">{{ ii('IMAGE_SETTINGS') }}</div>
			<table>
				<tr>
					<td class="general-upper">{{ ii('ALT') }}:</td>
					<td class="text-center">
						<input type="text" v-model.trim="imgSettings.alt" class="width100p">
					</td>
				</tr>
				<tr>
					<td class="general-upper">{{ ii('TITLE') }}:</td>
					<td class="text-center">
						<input type="text" v-model.trim="imgSettings.title" class="width100p">
					</td>
				</tr>
				<tr>
					<td class="general-upper">{{ ii('WIDTH') }}:</td>
					<td class="text-center">
						<input type="text" v-model.trim="imgSettings.width" class="width100p" @input="imgSizeChange('width')">
					</td>
				</tr>
				<tr>
					<td class="general-upper">{{ ii('HEIGHT') }}:</td>
					<td class="text-center">
						<input type="text" v-model.trim="imgSettings.height" class="width100p"  @input="imgSizeChange('height')">
					</td>
				</tr>
				<tr>
					<td class="general-upper">{{ ii('FLOAT') }}:</td>
					<td class="text-center">
						<select v-model="imgSettings.float" class="width100p">
							<option v-for="item in floatSuggestion" :value="item.key">{{ item.val }}</option>
						</select>
					</td>
				</tr>
			</table>
			<div class="img-actions">
				<btn size="small" type="button" @click="applyImgSettings">
					<i class="fa fa-fw fa-upload"></i>
					{{ ii('APPLY') }}
				</btn>
				<btn size="small" color="red" type="button" @click="deleteImg">
					<i class="fa fa-fw fa-remove"></i>
					{{ ii('DELETE') }}
				</btn>
			</div>

		</popup>
	</div>
`;

export default {
	template,
	props: ['value', 'idu', 'init-html', 'with-payment-link', 'plugins'],
	data() {
		return {
			html: '',
			payLinkPopup: null,
			imgSettingsPopup: false,
			imgSettings:{
				target: null,
				width: null,
				height: null,
				proportion: null,
				float: null,
				alt: null,
				title: null
			},
			selection: null,
			sizes: [
				{
					label: 'Small text',
					labelSize: 11,
					size: 1
				},
				{
					label: 'Normal text',
					labelSize: 14,
					size: 2
				},
				{
					label: 'Large text',
					labelSize: 18,
					size: 4
				}
			],
			fonts: [
				'serif', 'Arial', 'Segoe UI', 'Lucida Grande', 'Roboto',
				'Helvetica', 'Verdana', 'Tahoma', 'Comic Sans MS', 'Courier New'
			],
			focused: false,
			controlsOffset: 0,
			domains: null,
			sourceShown: false,
			sourceCode: '',
			sourceHeight: 100
		};
	},
	computed: {
		controlsTop() {
			if (!this.focused) return 0;
			return -this.controlsOffset + 'px';
		},
		colorsGrid() {
			let grid = [];

			grid.push([]);
			for (let i = 0; i < 8; i++) {
				let percent = 100 / 7 * i;
				let color = this.getColorMix('#ffffff', '#000000', percent);
				grid[0].push(color);
			}

			let colors = [
				'FF0000',
				'FF9900',
				'FFFF00',
				'00FF00',
				'00FFFF',
				'0000FF',
				'9900FF',
				'FF00FF'
			];
			for (let i = 1; i < 8; i++) {
				let row = [];
				colors.forEach(col => {
					let percent = 100 / 7 * i;
					let color = this.getColorMix('#' + col, '#ffffff', percent);
					row.push(color);
				});
				grid.push(row);
			}
			return grid;
		},
		floatSuggestion(){
			return [
				{key: 'none', val: this.ii('DEFAULT')},
				{key: 'left', val: this.ii('LEFT')},
				{key: 'right', val: this.ii('RIGHT')}
			];
		}
	},
	methods: {
		apply(action) {
			document.execCommand(action);
		},
		applyFontSize(size) {
			document.execCommand('fontSize', false, size);
		},
		applyFontFamily(font) {
			document.execCommand('fontName', false, font);
		},
		addPaymentLink() {
			this.saveSelection();
			this.payLinkPopup = true;
		},
		toggleSource() {
			this.sourceShown = !this.sourceShown;
			if (this.sourceShown) {
				this.sourceCode = this.$refs.field.innerHTML.replace(/<(.*?)>/g, '<$1>\n');
				this.sourceHeight = this.$refs.field.offsetHeight + 'px';
			} else {
				this.html = this.sourceCode.replace(/<(.*?)>\n/g, '<$1>');
			}
			this.$nextTick(() => {
				this.$emit('switchmode', {sourceShown: this.sourceShown});
			});
		},
		onLinkGenerated(e) {
			this.payLinkPopup = null;

			this.$refs.field.focus();
			this.restoreSelection();

			let url = this.escapeHtml(e.url);
			let text = this.escapeHtml(e.text) || url;
			let html = `<a href="${url}">${text}</a>`;
			document.execCommand('insertHTML', false, html);
		},
		insertSiteLink(domain) {
			if (!this.focused) {
				this.$refs.field.focus();
				this.restoreSelection();
			}

			let escapedDomain = this.escapeHtml(domain);
			let url = this.escapeHtml('http://' + domain);
			let html = `<a href="${url}" rel="noreferrer noopener">${escapedDomain}</a>`;
			document.execCommand('insertHTML', false, html);
		},
		setColor(color) {
			document.execCommand('foreColor', false, color);
		},
		onFocus() {
			this.focused = true;
			this.$emit('focus');
			this.tryMoveControls();
		},
		onBlur() {
			this.saveSelection();
			this.focused = false;
			this.$emit('blur');
			this.tryMoveControls();
		},
		saveSelection() {
			this.selection = null;
			if (window.getSelection) {
				let sel = window.getSelection();
				if (sel.getRangeAt && sel.rangeCount) {
					let ranges = [];
					for (let i = 0, len = sel.rangeCount; i < len; ++i) {
						ranges.push(sel.getRangeAt(i));
					}
					this.selection = ranges;
				}
			} else if (document.selection && document.selection.createRange) {
				this.selection = document.selection.createRange();
			}
		},
		restoreSelection() {
			if (!this.selection) return;

			if (window.getSelection) {
				let sel = window.getSelection();
				sel.removeAllRanges();
				for (let i = 0, len = this.selection.length; i < len; ++i) {
					sel.addRange(this.selection[i]);
				}
			} else if (document.selection && this.selection.select) {
				this.selection.select();
			}
		},
		getHtml() {
			return this.$refs.field.innerHTML;
		},
		setHtml(html) {
			this.html = html;
		},
		tryMoveControls() {
			if (!this.focused) {
				this.controlsOffset = 0;
				return;
			}

			let rect = this.$refs.controls.getBoundingClientRect();
			let controlsBottom = rect.bottom || rect.y;
			if (!controlsBottom) return;

			let winHeight = this.getWinHeight();
			let diff = controlsBottom + this.controlsOffset - winHeight;
			this.controlsOffset = Math.max(diff, 0);
		},
		async getUserDomains() {
			let domains = await this.get('Site.getUniqueSites', this.idu);
			domains.sort();
			this.domains = domains;
		},
		async insertImage() {
			let selection = this.saveSelection();
			let imgUrl = await this.prompt('Enter image URL');
			if (!imgUrl) return;

			let img = new Image();
			img.src = imgUrl;
			img.onload = () => {
				this.restoreSelection(selection);
				let html = '<img src="' + img.src + '" alt="">';
				document.execCommand('insertHTML', false, html);
			};
			img.onerror = err => this.alert('Broken image URL. ' + err);
		},
		settings(event) {
			if (event.target.tagName === 'IMG') {
				this.showImgSettingsPopus(event.target);
			}
		},
		showImgSettingsPopus(target){
			this.imgSettings.target = target;
			this.imgSettings.alt = target.alt;
			this.imgSettings.title = target.title;
			this.imgSettings.width = target.width;
			this.imgSettings.height = target.height;
			this.imgSettings.float = target.style.float || 'none';
			this.imgSettings.proportion = this.imgSettings.width / this.imgSettings.height;
			this.imgSettingsPopup = true;
		},
		applyImgSettings(){
			let target = this.imgSettings.target;

			target.alt = this.imgSettings.alt;
			target.title = this.imgSettings.title;

			target.style.width = `${this.imgSettings.width}px`;
			target.style.height =  `${this.imgSettings.height}px`;

			target.style.float = this.imgSettings.float;
			if (target.style.float === 'right'){
				target.style.marginLeft = '10px';
				target.style.marginRight = '';
			}
			if (target.style.float === 'left'){
				target.style.marginRight = '10px';
				target.style.marginLeft = '';
			}

			this.imgSettingsPopup = false;
		},
		deleteImg(event){
			let target = this.imgSettings.target;
			target.remove();
			this.imgSettingsPopup = false;
		},
		imgSizeChange(param){
			if(param === 'width') this.imgSettings.height = Math.round(this.imgSettings.width / this.imgSettings.proportion);
			if(param === 'height') this.imgSettings.width = Math.round(this.imgSettings.height * this.imgSettings.proportion);
		},
		async makeHyperlink() {
			let text = window.getSelection().toString();
			let selection = this.saveSelection();
			let linkUrl = await this.prompt('Enter URL');

			let html = text.link(linkUrl);
			this.restoreSelection(selection);
			document.execCommand('insertHTML', false, html);
		},
		onScroll() {
			this.tryMoveControls();
		},
		onPluginBtnClick(btn) {
			let selection = this.saveSelection();
			btn.onClick({
				insertHtml: (html) => {
					this.restoreSelection(selection);
					document.execCommand('insertHTML', false, html);
				}
			});
		}
	},
	mounted() {
		if (this.idu) {
			this.getUserDomains();
		}

		if (this.initHtml) {
			this.setHtml(this.initHtml);
		}

		this.loadLangTexts([this.$root.language], ['editor']);

		window.addEventListener('scroll', this.onScroll);
	},
	destroyed() {
		window.removeEventListener('scroll', this.onScroll);
	}
};
